import React, { Component } from 'react';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class ProcessFile extends Component {  
     constructor(props) {
          super(props);
          this.state = {
               mfgNameen:'',
               mfgID:'',
               mfgNameList:[],
               selectedOptionglang:'',
            langlist:[
              
        ],
        countryList:[
          
     ],
               selectedOptioncountry:'',
               upload:false,
               selectedFile: null,
               ifCloudsds:false,
               docType:[],
               docTypeDrop:[],
               docTypeDropSelected:'',
               isd:'',
               ied:'',
               reprocess:'1',
               fileList:[],
               data:[],
               modelName:'',
               publishedList:[],
               selpublishedList:'',
               group:[],
               metalist:[],
               ginfo:false,
               groupName:'',
               checkAll:false,
               ptype:false,
               process_type:{}
              
          };
        
          this.updateValueCountry = this.updateValueCountry.bind(this)
       this.updateValuelang = this.updateValuelang.bind(this);
       this.updatedocTypeDrop = this.updatedocTypeDrop.bind(this)
        }
        updatedocTypeDrop(newValue){
          this.state.docTypeDropSelected = newValue
          this.setState({
            docTypeDropSelected: this.state.docTypeDropSelected
          });  
        }
// manusearch
selectNFG(e,id,name){    
     this.state.mfgID = id;
     this.state.mfgNameen = name;
     this.state.mfgId = id;
     this.setState({
       mfgID: this.state.mfgID,
       mfgNameen:this.state.mfgNameen,
       mfgId:this.state.mfgId
   })
   document.getElementById('mfglist').classList.add('d-none')
   console.log(this.state.mfgNameen+this.state.mfgID)
   }

   componentDidMount() {  
    let preProcess_group = cookies.get("process_group");
    let ptype = cookies.get("process_ptype");
  //   fetch(process.env.REACT_APP_ENV+'/api/Common/SelectCountry',{
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'Authorization':'bearer '+cookies.get("auth")
        
  //     },
  //     body: {}
  //     })
  //   .then((response) => response.json()) 
  //   .then((responseJson) => {
  //     let tempData = responseJson;  
  //    // console.log(tempData.data.cname)   
  //     if(tempData.status.statusMessage === 'Success'){
  //          let exdate = new Date();
  //          cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
  //         this.state.countryList = tempData.data
  //          this.setState({
  //           countryList:this.state.countryList
     
  //          })
  //     }
  
     
  //   })
  //   .catch(error =>{
  // console.log(error); 
  //   })
    let ifCloudsds = false;
    if(cookies.get("cid") == '1'){
      ifCloudsds = true;
     }
this.state.ifCloudsds = ifCloudsds;
this.state.ptype = ptype;
      this.setState({
        ifCloudsds:this.state.ifCloudsds,
        ptype:this.state.ptype
      })
     document.getElementById('loader').classList.add('d-block'); 
     fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocumentTypeList',{
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'bearer '+cookies.get("auth")
            
          },
          body: JSON.stringify({ 
          
         })
          })
        .then((response) => response.json())
        .then((responseJson) => {
          let tempData = responseJson;  
        //  console.log(tempData)   
          if(tempData.status.statusMessage === 'Success'){
               let exdate = new Date();
               cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
               this.state.docType =  tempData.data;
               let storedata = [];
              // let preProcess_manu = cookies.get("process_manu");
               let preProcess_category = cookies.get("process_category");
               //let process_manu_name = cookies.get("process_manu_name");
               let process_model= cookies.get("process_model");
               storedata.push({value:'',label:'Select'});
               let processType = {};
               for (var i = 0; i < tempData.data.length; i++) {
                           storedata.push({
                             value: tempData.data[i].id, 
                             label:  tempData.data[i].doctype_name,
                           })
                           processType[tempData.data[i].id] = tempData.data[i].process_mode.toString();
                          
                           } 
              console.log(processType)
              this.state.process_type = processType;
              if(preProcess_category){
                this.setState({
                  docType:this.state.docType,
                  docTypeDrop:storedata,
                  docTypeDropSelected:preProcess_category,
                  //mfgNameen:process_manu_name,
                 // mfgID:preProcess_manu,
                  modelName:process_model,
                  process_type:this.state.process_type
                })
              }else{
                this.setState({
                  docType:this.state.docType,
                  docTypeDrop:storedata,
                  process_type:this.state.process_type
                })
              }
               
        //       document.getElementById('loader').classList.add('d-block'); 
        // fetch(process.env.REACT_APP_ENV+'/api/common/GetPublishedModel',{
        //      method: 'POST',
        //      headers: {
        //        'Accept': 'application/json',
        //        'Content-Type': 'application/json',
        //        'Authorization':'bearer '+cookies.get("auth")
               
        //      },
        //      body: JSON.stringify({ 
        //       category:this.state.docTypeDropSelected.value
        //     })
        //      })
        //    .then((response) => response.json())
        //    .then((responseJson) => {
        //      let tempData = responseJson;  
        //    //  console.log(tempData)   
        //    if(tempData.status.statusMessage === 'Success'){
        //     this.setState({
        //       publishedList: [],
        //       })
        //    const timestamp = new Date().getTime();
        //    const expireauth = timestamp + 1800000;
        //    const exdate =  new Date(expireauth);
        //    cookies.set('auth', tempData.status.authtoken, { path: '/'});   
               
        //       if(cookies.get("process_model")){                
        //         this.state.publishedList = tempData.data
        //         this.setState({
        //           publishedList: this.state.publishedList,
        //           selpublishedList:cookies.get("process_model")
        //       })
        //       }else{
        //         this.state.publishedList = tempData.data
        //         this.setState({
        //           publishedList: this.state.publishedList,
        //       })
        //       }
        //       document.getElementById('loader').classList.remove('d-block'); 
        //     // console.log(this.state.id)
        //     }else{
        //        toast.danger('Error ocured. Please Try Again')
        //        document.getElementById('loader').classList.remove('d-block'); 
        //   }
            
            
        //    })
        //    .catch(error =>{
        //      console.log(error); 
        //      document.getElementById('loader').classList.remove('d-block'); 
        //    })         
              
               document.getElementById('loader').classList.remove('d-block');  
            
          }else{
               toast.error('Error ocured. Please Try Again');
               document.getElementById('loader').classList.remove('d-block'); 
          }
         
         
        })
        .catch(error =>{
          console.log(error); 
          document.getElementById('loader').classList.remove('d-block'); 
        })      
   
      //get group info
      fetch(process.env.REACT_APP_ENV+'/api/Document/GetGroupList',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
          
        },
        body: JSON.stringify({              
             Id : preProcess_group
       })
        })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson; 
        console.log(tempData.data[0].id)   
         if(tempData.status.statusMessage === 'Success'){
              let exdate = new Date();
              cookies.set('auth', tempData.status.authtoken, { path: '/'});  
              this.setState({
                group : tempData.data,
                groupName:tempData.data[0].groupname
             })
             
             let ArrMetaList = [];
             JSON.parse(tempData.data[0].field_json).forEach((ml,index,arr) => { 
               if(ml.type === '1'){
                 ArrMetaList.push({
                   api: ml.api,
                   document_type_id: ml.document_type_id,
                   error: ml.error,
                   id: ml.id,
                   key: ml.key,
                   label: ml.label,
                   mandatroy: ml.mandatroy,
                   type: ml.type,
                   value:ml.value,
                   option:[],
                   selectedOptiongDrop:[],
                   valueLabel:''
                 })
                 fetch(process.env.REACT_APP_ENV+ml.api,{
                   method: 'POST',
                   headers: {
                     'Accept': 'application/json',
                     'Content-Type': 'application/json',
                     'Authorization':'bearer '+cookies.get("auth")
                     
                   },
                   body: JSON.stringify({              
                     MfgName:'',
                     mfgId:ml.value
                  })
                   })
                 .then((response) => response.json())
                 .then((responseJson) => {
                   let tempData = responseJson; 
                  // console.log(tempData.data[0].mnfName)   
                    if(tempData.status.statusMessage === 'Success'){
                     const timestamp = new Date().getTime();
                     const expireauth = timestamp + 1800000;
                     const exdate =  new Date(expireauth);
                     cookies.set('auth', tempData.status.authtoken, { path: '/'});   
                      let gindex = index;
                      this.state.metalist[gindex].valueLabel = tempData.data[0].mnfName;
                      this.setState({
                       metalist:this.state.metalist,
                       mfgNameen:tempData.data[0].mnfName,
                       mfgID:this.state.metalist[gindex].value
                        })
                             
                      }else{
                         toast.success('Error ocured. Please Try Again')
                    }
                    
                  
                 })
                 .catch(error =>{
                   console.log(error); 
                 })
                
                
               }
               if(ml.type === '2'){
                 ArrMetaList.push({
                   api: ml.api,
                   document_type_id: ml.document_type_id,
                   error: ml.error,
                   id: ml.id,
                   key: ml.key,
                   label: ml.label,
                   mandatroy: ml.mandatroy,
                   type: ml.type,
                   value:ml.value,
                   option:[],
                   selectedOptiongDrop:'',
                   valueLabel:''
                 })  
                document.getElementById('loader').classList.add('d-block'); 
                fetch(process.env.REACT_APP_ENV+ml.api,{
                     method: 'POST',
                     headers: {
                       'Accept': 'application/json',
                       'Content-Type': 'application/json',
                       'Authorization':'bearer '+cookies.get("auth")
                       
                     },
                     body: JSON.stringify({ 
                    })
                     })
                   .then((response) => response.json())
                   .then((responseJson) => {
                     let tempData = responseJson;  
                   //  console.log(tempData)   
                     if(tempData){
                          // let exdate = new Date();
                          // cookies.set('auth', tempData.status.authtoken, { path: '/',expires: new Date(Date.now()+2592000) }); 
                         // console.log(tempData.replace(/'/g,"'");
                         let optionsold = tempData.data;
                         let options = [{'value':'','label':'----'}, ...optionsold];
                         console.log(options)
                          let gindex = index;
                          let val = ml.value;
                          this.state.metalist[gindex].option = options;
                          this.state.metalist[gindex].selectedOptiongDrop = options.filter(option => option.value === val);
                          let valulabel = options.filter(option => option.value === val);
                          this.state.metalist[gindex].valueLabel = valulabel[0].label;
                          if(this.state.metalist[gindex].key ==="langId"){
                            this.state.langlist = options;                            
                            this.state.selectedOptionglang = {value: val, label: valulabel[0].label} ;
                            console.log(this.state.selectedOptionglang)                       
                            this.setState({
                              metalist:this.state.metalist,
                              langlist:this.state.langlist,
                              selectedOptionglang:this.state.selectedOptionglang,
                             
                             
                         })
                          }else if(this.state.metalist[gindex].key ==="countrycode"){
                            this.state.countryList = options;
                            this.state.selectedOptioncountry = {value: val, label: valulabel[0].label} ;
                            console.log(this.state.selectedOptioncountry)
                            this.setState({
                              metalist:this.state.metalist,
                              countryList:this.state.countryList,
                              selectedOptioncountry:this.state.selectedOptioncountry,
                             
                             
                         })
                          }else{
                            this.setState({
                              metalist:this.state.metalist,
                             
                         })
                          }
                          
                                         
                          document.getElementById('loader').classList.remove('d-block'); 
                     }else{
                          toast.error('Error ocured. Please Try Again');
                          document.getElementById('loader').classList.remove('d-block'); 
                     }
                      
                    
                   })
                   .catch(error =>{
                     console.log(error); 
                     document.getElementById('loader').classList.remove('d-block'); 
                   }) 
                
               }
               
             })
             this.setState({
               metalist:ArrMetaList
                })
            
           }else{
              toast.success('Error ocured. Please Try Again');
         }
       document.getElementById('loader').classList.remove('d-block'); 
       
      })
      .catch(error =>{
        console.log(error); 
      })  
 }  
//update lang
updateValuelang(newValue) {
     this.setState({
      selectedOptionglang: newValue
   });
  }

// update country
updateValueCountry(newValue) {
     this.setState({
       selectedOptioncountry: newValue
   });
  }

   onChange=event=> {
     this.state.mfgNameen = event.target.value;
     this.setState({mfgNameen: this.state.mfgNameen})
    // console.log(this.state.mfgNameen.length);
     //if(this.state.mfgNameen.length>1){
       this.setState({mfgNameList:[]})
       document.getElementById("loader").classList.add("show");
           fetch(process.env.REACT_APP_ENV+'/api/Common/SearchManufacturer', {       
             method: 'POST',
             headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json',
               'Authorization':'bearer '+cookies.get("auth")
      
             },
             body: JSON.stringify({
               MfgName: this.state.mfgNameen,
            })
          })
          .then((response) => response.json())
          .then((responseJson) => {
            document.getElementById("loader").classList.remove("show");
            let tempData = responseJson.data;
            const timestamp = new Date().getTime();
            const expireauth = timestamp + 1800000;
            const exdate =  new Date(expireauth);
            cookies.set('auth', responseJson.status.authtoken, { path: '/'});  
             this.setState({
               mfgNameList: [...this.state.mfgNameList, ...tempData ],
           })
         document.getElementById('mfglist').classList.remove('d-none')
           console.log(this.state.mfgNameList)
          
          })
          .catch(error =>{
            console.log(error);          
            document.getElementById("loader").classList.remove("show");
          })
         
     //}
   }
       
      
       
        checkMimeType=(event)=>{    
          //getting file object
          let files = event.target.files;
          //define message container
          let err = []
          // list allow mime type
        // const types = ['application/excel','application/x-msexcel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
        const types = ['application/pdf','image/jpeg','image/png']
          // loop access array
          for(var x = 0; x<files.length; x++) {   
           // compare file type find doesn't matach
               if (types.every(type => files[x].type !== type)) {
               // create error message and assign to container   
               err[x] = files[x].type+' is not a supported format\n';
             }
           };
           for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
               // discard selected file
              toast.error(err[z])
              event.target.value = null
          }
          if(err.length){
               return false;
              
          }else{
               return true; 
          }
         
        }
        maxSelectFile=(event)=>{
          let files = event.target.files;
              if (files.length > 10) { 
                 const msg = 'Only 10 file can be uploaded at a time'
                 event.target.value = null
                 toast.warn(msg)
                 return false;
            }
          return true;
       }
       checkFileSize=(event)=>{
        let files = event.target.files;
        let size = 500000000 
        let err = []; 
        for(var x = 0; x<files.length; x++) {
        if (files[x].size > size) {
         err[x] = files[x].type+'is too large, please pick a smaller file\n';
       }
      };
      for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
        // discard selected file
       toast.error(err[z])
       event.target.value = null
      }
      return true;
      }
      onChangeHandler=event=>{
        var files = event.target.files
        if(this.maxSelectFile(event) && this.checkMimeType(event) &&    this.checkFileSize(event)){ 
        // if return true allow to setState
        this.state.selectedFile = files
           this.setState({
           selectedFile: this.state.selectedFile,
        })
      } else{
        
          this.setState({
          selectedFile: null,
       })
      }
      //console.log(this.state.selectedFile)
      } 
         onClickHandlerg = async () => {          
           if(this.state.docTypeDropSelected.value){
            if(this.state.selectedFile.length > 0){
              let getfilelength = this.state.selectedFile.length;
              let lastitem = false;
              let uurl = '/api/common/UploadFileForProcessing';
              if(this.state.docTypeDropSelected.value === 1){
                uurl = '/api/common/UploadFileForProcessingSDS';
              }
              for(var x = 0; x<this.state.selectedFile.length; x++) {
                document.getElementById("loader").classList.add("d-block");
                const formData = new FormData();
                if(this.state.docTypeDropSelected.value === 1){
                  formData.append('category', this.state.docTypeDropSelected.value.toString())
                  formData.append('s3filename', '')
                  formData.append('file', this.state.selectedFile[x])
                  //formData.append('mnfId', this.state.group[0].id.toString())
                  formData.append('mnfId', this.state.mfgID)
                  formData.append('process_mode', JSON.stringify(this.state.process_type))
                }else{
                formData.append('category', this.state.docTypeDropSelected.value.toString())
                 formData.append('s3filename', '')
                 formData.append('file', this.state.selectedFile[x])
                 formData.append('process_mode', JSON.stringify(this.state.process_type))
                }
                if(getfilelength === x+1){
                  lastitem = true

                }
                
                const response = await fetch(process.env.REACT_APP_ENV+uurl, {  
                    headers: {
                           'Authorization':'bearer '+cookies.get("auth")						   
                         },     
               method: 'POST',
              body: formData
            })
            const json = await  response.json();
            cookies.set('auth', json.status.authtoken, { path: '/'});      
            if(json.status.statusMessage === 'Success'){            
             if(getfilelength === x+1){
              document.getElementById("loader").classList.remove("d-block");
              toast.success(getfilelength+' file successfully sent for process');
              document.getElementById('selectedFile').value = null;
              this.setState({
              selectedFile: []
             })
             }
            }
             
                 }
            }
           }else{
            toast.error('Please select document type.');
          }
          
            } 

            onChangeInput = (e) => {
              this.setState({[e.target.name]: e.target.value})
            
            }         
            
            processFiles = async() => {
              let category = this.state.docTypeDropSelected.value;
              let model_file_name = this.state.modelName;
              let manufacturer_id = this.state.mfgID;
              let spath = [];
              let checkedlength = 0;
              this.state.fileList.forEach ((s,index) => {  
                if(s.is_checked){
                  checkedlength = checkedlength+1
                }
              })
              let checkedlengthcheck = 0;
              for(var x = 0; x<this.state.fileList.length; x++) {
                             // this.state.fileList.forEach ((s,index) => {  
                               let ischecked = this.state.fileList[x].is_checked;
                               if(ischecked){
              checkedlengthcheck = checkedlengthcheck+1;

                const formData = new FormData();
                  formData.append('category', category.toString())
                    formData.append('s3filename', this.state.fileList[x].msdS_S3_Path)
                    formData.append('file', null)
                    formData.append('mnfId', this.state.mfgID.toString())
                    formData.append('process_mode', JSON.stringify(this.state.process_type))
                document.getElementById('loader').classList.add('d-block'); 
                const response = await fetch(process.env.REACT_APP_ENV+'/api/common/UploadFileForProcessingSDS', {  
                  headers: {
                         'Authorization':'bearer '+cookies.get("auth")						   
                       },     
             method: 'POST',
            body: formData
          }) 
                    const json = await  response.json();                        
                    let tempData = json;  
                    if(tempData.status.statusMessage === 'Success'){     
                      if(checkedlength === checkedlengthcheck){
                        document.getElementById("loader").classList.remove("d-block");
                        toast.success('File(s) successfully sent for process');
                      //  document.getElementById('selectedFile').value = null;
                        this.setState({
                          fileList: []
                       })
                       }                
                     
                        cookies.set('auth', tempData.status.authtoken, { path: '/'});                        
                     //   toast.success('Files successfully sent for process');                     
                      
                    }else{
                      toast.error('Error ocured. Please Try Again');
                      document.getElementById('loader').classList.remove('d-block'); 
                    }
                  
                  }
                   
              }
             
            }
            addFromCs = () =>{	
              this.setState({
                fileList: [],
               })
              document.getElementById('loader').classList.add('d-block'); 
              fetch(process.env.REACT_APP_ENV+'/api/common/GetCSDocList',{
                 method: 'POST',
                 headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 'Authorization':'bearer '+cookies.get("auth")
                 
                 },
                 body: JSON.stringify({  
                langId:this.state.selectedOptionglang.value,
                mfgId:this.state.mfgID.toString(),
                startIssueDate:this.state.isd,
                endIssueDate:this.state.ied,
                countryID:this.state.selectedOptioncountry.value
                })
                 })
               .then((response) => response.json())
               .then((responseJson) => {
                 let tempData = responseJson; 
                // console.log(tempData)   
                let exdate = new Date();
                 cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
                 if(tempData.status.statusMessage == 'Success'){
                if(!tempData.data.length){
                  toast.error('No Record Found.')
                    
                    // console.log(this.state.grouplist)
                   }else{		
                     let storedata = [];
                     for (var i = 0; i < tempData.data.length; i++) {
                     storedata.push({
                       id: tempData.data[i].id, 
                       is_GHS:  tempData.data[i].is_GHS,
                       msdS_FileName:tempData.data[i].msdS_FileName,
                       msdS_ID:tempData.data[i].msdS_ID,
                       msdS_Issue_Date:tempData.data[i].msdS_Issue_Date,
                       msdS_Product_Name:tempData.data[i].msdS_Product_Name,
                       msdS_S3_Path:tempData.data[i].msdS_S3_Path,
                       is_checked:false
                     })	
                   }
                    this.setState({
                      fileList: storedata,
                  })
                  //console.log(this.state.data)
                   }
                   document.getElementById('loader').classList.remove('d-block'); 
                 }else{
                toast.error('Error ocured.Please try again later.')
                document.getElementById('loader').classList.remove('d-block'); 
                 }
                 
               
                
               })
               .catch(error =>{
                 console.log(error); 
                 document.getElementById('loader').classList.remove('d-block'); 
               })
              } 
              
  onChangeField = (e) =>{	
    this.setState({[e.target.name]: e.target.value})
  }	 
  collapse = (e) =>{
    this.setState({ginfo: false})
  }
  openginfo = (e) =>{
    this.setState({ginfo: !this.state.ginfo})
  }    
  pfcheck = (e,index) =>{
    this.state.fileList[index].is_checked= !this.state.fileList[index].is_checked;
    let checkboxes = document.querySelectorAll('input[name="pfcheck"]:checked');
    let boxes = document.querySelectorAll('input[name="pfcheck"]');
    if(checkboxes.length === boxes.length){
      this.state.checkAll = true;
      this.setState({
        fileList:this.state.fileList,
        checkAll: this.state.checkAll,
      })
    }else{
      this.state.checkAll = false;
      this.setState({
        fileList:this.state.fileList,
        checkAll: this.state.checkAll,
      })
    }
    
  }     
  checkAll  = (e,index) =>{
    let storedata = [];
                     for (var i = 0; i < this.state.fileList.length; i++) {
                     storedata.push({
                       id: this.state.fileList[i].id, 
                       is_GHS:  this.state.fileList[i].is_GHS,
                       msdS_FileName:this.state.fileList[i].msdS_FileName,
                       msdS_ID:this.state.fileList[i].msdS_ID,
                       msdS_Issue_Date:this.state.fileList[i].msdS_Issue_Date,
                       msdS_Product_Name:this.state.fileList[i].msdS_Product_Name,
                       msdS_S3_Path:this.state.fileList[i].msdS_S3_Path,
                       is_checked:!this.state.checkAll
                     })	
                   }
                   this.state.fileList = storedata
    this.setState({
      checkAll: !this.state.checkAll,
      fileList: this.state.fileList
    })
  } 
  goBack=() =>{
    alert('j')
    window.history.back();
  }
     render() {
       console.log(this.state.metalist)
         // const { tgname, tgdesc} = this.state;
          let mfgname = this.state.mfgNameen;
    let mfgNameLists = []; 
    let publishedList = [];    
    this.state.publishedList.forEach ((s,index) => {   
      publishedList.push(
        <option key={index} value={s.model_file_name}>{s.model_file_name.split("/").pop()}</option>
      )
    })
   this.state.mfgNameList.forEach ((s,index) => {   
   //  console.log(s.companyName)
    mfgNameLists.push(
            <li onClick={(e) => this.selectNFG(e,s.mnfID,s.mnfName)}  key={index} >{s.mnfName+' ('+s.mnfID+')'}</li>
      )
   })

   let sdsAccess = false;
   let otherAccess = false;
   if(this.state.docTypeDropSelected.value){   
     if(!this.state.ifCloudsds){
      otherAccess = true;  
     }
     if(this.state.ifCloudsds && this.state.docTypeDropSelected.value != 1){
      otherAccess = true;
     }
    
   }
   if(this.state.ifCloudsds && this.state.docTypeDropSelected.value && this.state.docTypeDropSelected.value === 1){
    sdsAccess = true;
   }
   let filelist = [];
   let filelistCount = 0;
   this.state.fileList.forEach ((s,index) => {   
 let ghslbl = '';
   if(s.is_GHS){
   let ghs = s.is_GHS.trim();
   if(ghs == 'true'){
     ghslbl = 'Yes'
   }else{
     ghslbl = 'No'
   }
   }
   if(s.is_checked){
    filelistCount = filelistCount+1
   }
   let idate = s.msdS_Issue_Date.split("T")[0];
 //  console.log(drawindex)
 filelist.push(
   <Row key={index}> 
   <Cell>
     {s.is_checked && 
     <input checked={true}  onChange={(e) => this.pfcheck(e,index)} type='checkbox' name='pfcheck' /> 
     }
     {!s.is_checked && 
     <input type='checkbox' onChange={(e) => this.pfcheck(e,index)}  name='pfcheck' /> 
     }
      </Cell>
     <Cell>{s.msdS_Product_Name}</Cell>
     <Cell>{s.msdS_FileName}</Cell>
   <Cell className='issudateWIdth'>{idate}</Cell>
   <Cell>{ghslbl}</Cell>
  
 </Row>
 
)
 })
 let groupListMeta = [];
 groupListMeta.push(
  <div key='1000'  className=''> 
               <label><b>Name :</b> {this.state.groupName}</label>
  </div>
)
 this.state.metalist.forEach ((gl,index) => {
   let label = gl.label.replace("Search", "").replace("Select", "");
 
    groupListMeta.push(
      <div key={index} className=''> 
                   <label><b>{label} :</b> {gl.valueLabel}</label>
      </div>
    )
  
   
  
   
 })
 
   //alert(this.state.ifCloudsds)
  return (
    <div className="modal-body p-4">
   
            <ToastContainer />
            <div className='container-fluid h100percent'>
            <div className='h100percent text-selection-none bg-white  round-corner-all'>
              <div className='row'>
              <div className='col-md-4' >
        <div className="form-group ">
                 <div >
                 <label>Document Type</label>
         <Select    
         className ='orgindex'        
         isDisabled={true}
        value={this.state.docTypeDropSelected}
        onChange={this.updatedocTypeDrop}
        options={this.state.docTypeDrop}
        defaultValue={{ value: '', label: '----' }}
      />
                 </div>
                 </div> 
               </div>
               {this.state.docTypeDropSelected.value === 1 && groupListMeta.length > 0 &&  
        <div className='col-md-4'>
           <div className="form-group ">
        <label>Group Information</label>
        <div className={this.state.ginfo ?'viewgInfo-parent open':'viewgInfo-parent'}>
          <div className='oc'>
            <span onBlur={ this.collapse } onClick={this.openginfo} tabIndex="0" className='tools openinfo'><i className="fas fa-info-circle"></i></span>
            
          </div>
        <div className='bg-light viewgInfo'>{groupListMeta}</div>
        </div>
        
          
        </div>     
        </div> 
    
    } 
              </div>
             {otherAccess &&   
                 <div id='forOthers' >
                 <div className='row'>
               <div className="col-md-12 "> 
     <div className=' pb-3 mb-3'>
     <label >Upload Files </label>
		 <div className="form-group files">
				 <input type="file" className="form-control" id='selectedFile' multiple     onChange={this.onChangeHandler}/>
			   </div> 
         {this.state.selectedFile && <button type="button" className="btn btn-primary " onClick={this.onClickHandlerg}>Process Files</button>}
         {!this.state.selectedFile && <button type="button" disabled className="btn btn-primary " >Process Files</button>}
         
			   </div>
         </div>
     
     </div>
     
                
                 </div>
     }
                 {sdsAccess &&
                 <div id='forCS' >
           <Form onSubmit={this.onSubmit}>
             <div className="row">
      	  <div className="col-md-4">
          <FormGroup>
          <label>Manufacturer Name</label>
                <input  autoComplete="off" type="text" name='mfgNameen'   value={mfgname} onChange={this.onChange} className="form-control" />      
          </FormGroup>
          <div className="form-group position-relative">                
                <ul id='mfglist'  className='manudrop d-none'>
                {mfgNameLists}
                </ul>
                </div>
     </div>
    
    
     <div className="col-md-4">
          <FormGroup>
          <Label >Select Language</Label>
          <Select 
          className='orgindex'               
                 value={this.state.selectedOptionglang}
                 onChange={this.updateValuelang}
                 options={this.state.langlist}
               />    
          </FormGroup>
     </div>
     <div className="col-md-4">
          <FormGroup>
          <label>Select Country</label>
				 <Select
         className='orgindex'  
		 value={this.state.selectedOptioncountry}
		 onChange={this.updateValueCountry}
		 options={this.state.countryList}
		 defaultValue={{ value: '', label: '----' }}
	   /> 
          </FormGroup>
     </div>
     <div className="col-md-4">
          <FormGroup>
          <Label >Issue Start Date</Label>
          <input type="date" name="isd" rows='5' className='form-control' onChange={this.onChangeInput} value={this.state.isd}     placeholder="Issue Start Date" />     
          </FormGroup>
     </div>
     <div className="col-md-4">
          <FormGroup>
          <Label >Issue End Date</Label>
          <input type="date" name="ied" rows='5' className='form-control'  onChange={this.onChangeInput} value={this.state.ied}    placeholder="Issue Start Date" />     
          </FormGroup>
     </div>
     <div className="col-md-4">
          <FormGroup>
          <Label>Reprocess</Label>
          <select onChange={this.onChangeInput} value={this.state.reprocess} name='reprocess' className='form-control'>
               <option value="1">No</option>
               <option value="2">Yes</option> 
          </select>
            
          </FormGroup>
     </div>
    
     <div className="col-md-6">
     <Button color="primary" onClick={this.addFromCs}   >Show Files</Button>
     {this.state.fileList.length >0 && 
      <Button color="primary"  onClick={this.processFiles} className="ml-2" >Process Files</Button>
     }
    
     </div>
     <div className="col-md-6 text-right">
     {filelistCount > 0 && 
     <button type="button" className="btn btn-dark">
   <span className="badge badge-light">{filelistCount}</span>  File(s) selected for process
 
</button>
     }
     </div>
    </div>
    </Form>
    <div id='processfileList' className="row">
    <div className="col-md-12  h-100">
        <div style={{width: '100%', height: '100%'}}>
         <StickyTable leftStickyColumnCount={0} >
            <Row>
            <Cell>
              {this.state.checkAll &&
              <input checked={true} type='checkbox' onChange={(e) => this.checkAll(e)} name='checkAll' /> 
              }
              {!this.state.checkAll &&
              <input  type='checkbox' onChange={(e) => this.checkAll(e)} name='checkAll' /> 
              }
              </Cell>
            <Cell>Product Name</Cell>
              <Cell>File Name</Cell>
              <Cell>Issue Date</Cell>
              <Cell>GHS</Cell>
            </Row>
            {filelist}
            
          </StickyTable>
         </div>
    </div>
    </div>
    </div>
   } 
    </div>
    </div>
                           
                              </div>
  )};
};

export default ProcessFile;