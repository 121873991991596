import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
class EvaluateStatus extends Component { 
  constructor(props) {
    super(props);
  this.taggfile = this.taggfile.bind(this)
  }
  componentDidMount(){
   
  }
  tabOpen(id){
    document.getElementById(id).classList.add('card-collapsed')
    }
    tabClose(id){
      document.getElementById(id).classList.remove('card-collapsed')
      }
      openModelFiles(id){
        document.querySelector('#'+id+' .model-files').classList.toggle('d-none')
      }
      taggfile(e){
        localStorage.setItem("TaggedFile", 'Phenytoin Sodium Injection, USP.pdf');
        localStorage.setItem("TaggedFilePage", 1);
        localStorage.setItem("mfgid", '17164');
        localStorage.setItem("docID", '1803');
        this.props.history.push('/tagged-file-read-only') 
        console.log(docID)
      } 
    goBack() {
        window.history.back();
      }
    render(){
        return(
            <div className="modal fade show d-block manu-modal "     >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header px-4 d-block">
            
              
               <div className="row">
              	<div className="col-md-10">
              		 <h4 className="modal-title" ><a onClick={this.goBack}   className="back-button cpointer" >
                <i className="fas fa-arrow-circle-left"></i></a> Group Name -  Evaluate Status</h4>
              	</div>
              	<div className="col-md-2 text-right">
              	</div>
              </div>
            
            </div>
            <div className="modal-body p-4">
              
            <div id='manu-12345' className="card ">
  <div className="card-header d-block">
  <div className='row'>
  <div className='col-md-3'>
        <div><strong>  Group Name</strong></div>
      </div>
      <div className='col-md-3'>
        <div><strong>  Manufacturer Name</strong></div>
      </div>
      <div className='col-md-3'>
        <div><strong>Model Train Date</strong> 2020-02-07</div>
      
      </div>
      <div className='col-md-3'>
        <div><strong>Model Evaluate Date</strong> 2020-02-07</div>
       
      </div>
     
    </div>
  
  
  </div>
  <div className="card-body ">
    
    <div className='row'>
      <div className='col-md-3'>
        <div className='g-pie mt-0'>
          <div className='info'>25%</div>
        </div>
        <h5 className='text-center mb-0'>Accuracy</h5>
      </div>
      <div className='col-md-9'>
     
        <table className='table mb-0 graph-table'>
          <tbody>
            <tr>
              <td> 
                <div title='84_Sigma-Aldrich_132191670629220443_289275685.pdf'  className='g-bar'><div  style={{height:'8%'}} className='error-percentage'><span>8%</span></div></div>
              </td>
              <td><div title='84_Sigma-Aldrich_132191670629220443_289275685.pdf' className='g-bar'><div style={{height:'10%'}} className='error-percentage'><span>10%</span></div></div></td>
              <td><div title='84_Sigma-Aldrich_132191670629220443_289275685.pdf' className='g-bar'><div style={{height:'1%'}} className='error-percentage'><span>1%</span></div></div></td>
              <td><div title='84_Sigma-Aldrich_132191670629220443_289275685.pdf' className='g-bar'><div style={{height:'3%'}} className='error-percentage'><span>3%</span></div></div></td>
              <td><div title='84_Sigma-Aldrich_132191670629220443_289275685.pdf' className='g-bar'><div style={{height:'13%'}} className='error-percentage'><span>13%</span></div></div></td>
              <td><div title='84_Sigma-Aldrich_132191670629220443_289275685.pdf' className='g-bar'><div style={{height:'4%'}} className='error-percentage'><span>4%</span></div></div></td>
              <td><div title='84_Sigma-Aldrich_132191670629220443_289275685.pdf' className='g-bar'><div style={{height:'5%'}} className='error-percentage'><span>5%</span></div></div></td>
              <td><div title='84_Sigma-Aldrich_132191670629220443_289275685.pdf' className='g-bar'><div style={{height:'2%'}} className='error-percentage'><span>2%</span></div></div></td>
              <td><div title='84_Sigma-Aldrich_132191670629220443_289275685.pdf' className='g-bar'><div style={{height:'9%'}} className='error-percentage'><span>9%</span></div></div></td>
              <td><div title='84_Sigma-Aldrich_132191670629220443_289275685.pdf' className='g-bar'><div style={{height:'63%'}} className='error-percentage'><span>63%</span></div></div></td>
              <td><div title='84_Sigma-Aldrich_132191670629220443_289275685.pdf' className='g-bar'><div style={{height:'1%'}} className='error-percentage'><span>1%</span></div></div></td>
              <td><div title='84_Sigma-Aldrich_132191670629220443_289275685.pdf' className='g-bar'><div style={{height:'5%'}} className='error-percentage'><span>5%</span></div></div></td>
              <td><div title='84_Sigma-Aldrich_132191670629220443_289275685.pdf' className='g-bar'><div style={{height:'3%'}} className='error-percentage'><span>3%</span></div></div></td>
              <td><div title='84_Sigma-Aldrich_132191670629220443_289275685.pdf' className='g-bar'><div style={{height:'7%'}} className='error-percentage'><span>7%</span></div></div></td>
              <td><div title='84_Sigma-Aldrich_132191670629220443_289275685.pdf' className='g-bar'><div style={{height:'8%'}} className='error-percentage'><span>8%</span></div></div></td>
              <td><div title='84_Sigma-Aldrich_132191670629220443_289275685.pdf' className='g-bar'><div style={{height:'9%'}} className='error-percentage'><span>9%</span></div></div></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th className='text-center'>1</th>
              <th className='text-center'>2</th>
              <th className='text-center'>3</th>
              <th className='text-center'>4</th>
              <th className='text-center'>5</th>
              <th className='text-center'>6</th>
              <th className='text-center'>7</th>
              <th className='text-center'>8</th>
              <th className='text-center'>9</th>
              <th className='text-center'>10</th>
              <th className='text-center'>11</th>
              <th className='text-center'>12</th>
              <th className='text-center'>13</th>
              <th className='text-center'>14</th>
              <th className='text-center'>15</th>
              <th className='text-center'>16</th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div  className='col-md-12 mt-2 model-files '>
        
      <table className="table table-striped mb-0">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col">File Name</th>
      <th scope="col">Accuracy</th>
      <th scope="col">Upload Date</th>
      <th scope="col">Tagged Date</th>
      <th scope="col">Train Date</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td >1</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button onClick={(e)=> this.taggfile(e)} className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >2</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button onClick={(e)=> this.taggfile(e)} className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >3</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button  onClick={(e)=> this.taggfile(e)} className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >4</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button onClick={(e)=> this.taggfile(e)} className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >5</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button onClick={(e)=> this.taggfile(e)} className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >6</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button onClick={(e)=> this.taggfile(e)} className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >7</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button onClick={(e)=> this.taggfile(e)} className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >8</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button onClick={(e)=> this.taggfile(e)} className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >9</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button onClick={(e)=> this.taggfile(e)} className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >10</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button onClick={(e)=> this.taggfile(e)} className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    
  </tbody>
</table>
      </div>
    </div>
  
  </div>
  
</div>
        
              
           
</div>
          </div>
        
        </div>
      </div>
        )
    }
}
export default EvaluateStatus