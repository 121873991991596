import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody , Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class ViewFile extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      modal:true,
      selectedFilename:'',
      TaggedFilePage:1,
      category:''
 }; 
 this.toggleModal = this.toggleModal.bind(this);
  }
  toggleModal(){
    this.props.close(true)
}
PrevImage = (e) =>{
  let page = parseInt(this.state.TaggedFilePage)
  this.state.TaggedFilePage = page-1;
  this.setState({
    TaggedFilePage:this.state.TaggedFilePage
  })
  this.getImage(this.state.TaggedFilePage,'Previous')
}
NextImage = (e) =>{
//   alert(this.state.TaggedFilePage)
  let page = parseInt(this.state.TaggedFilePage)
  this.state.TaggedFilePage = page+1;
  this.setState({
    TaggedFilePage:this.state.TaggedFilePage
  })
  this.getImage(this.state.TaggedFilePage,'Next')
}


getImage = (page,type) => {      
  document.getElementById('loader').classList.add('d-block');      
  fetch(process.env.REACT_APP_ENV+'/api/TagV2/ViewTagImage',{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'bearer '+cookies.get("auth")

    },
    body: JSON.stringify({ 
      PageNo:this.state.TaggedFilePage,
      // FileName:  localStorage.getItem("TaggedFile"),
      FileName: this.state.selectedFilename,
      category:this.state.category
   })
    })
  .then((response) => response.json())
  .then((responseJson) => {
    let tempData = responseJson;    
    if(tempData){
      this.state.TaggedFilePage = page;
      this.setState({
        selectedImage: tempData,
        TaggedFilePage: this.state.TaggedFilePage
    })
    document.getElementById('imageview').classList.add('d-block'); 
   
    }else{
      if(type !==''){
        toast.warn(type+" page doesn't exist");
      }else{
        toast.warn("Page doesn't exist");
      }
     
      this.state.TaggedFilePage = this.state.TaggedFilePage - 1;
      this.setState({
        TaggedFilePage:this.state.TaggedFilePage
      })
    }
  
  document.getElementById('loader').classList.remove('d-block')
  })
  .catch(error =>{
    console.log(error);          
    document.getElementById('loader').classList.remove('d-block')
  })
}
  
  componentDidMount(){
    this.state.selectedFilename = this.props.file
    this.state.TaggedFilePage = 1;
    this.state.category = this.props.category
    this.setState({
      selectedFilename: this.state.selectedFilename,
      category:this.state.category
  });
  this.getImage(this.state.TaggedFilePage,'')
  }
   
    render(){
        return(
          <Modal isOpen={this.state.modal}  size="lg" toggle={this.toggleModal} >
          <ModalHeader toggle={this.toggleModal}>View Image</ModalHeader>
          <ModalBody id='modal-ad-cs'>
      <div className="row ">			 
          <div className='col-md-12'>
            <img id='imageview' className='d-none' src={"data:image/png;base64,"+this.state.selectedImage} />
          </div>
          </div>
           
          </ModalBody>
          <ModalFooter>
          <ul id='pagination' className="pagination mx-auto mt-0 mb-0 ">
      <li className="page-item">
        <button disabled = {this.state.TaggedFilePage < 2 || this.state.TaggedFilePage===1 ? true:false} onClick={(e)=>this.PrevImage(e)} className="page-link "><span aria-hidden="true">&laquo;</span>
          <span className="sr-only">Previous</span></button>
       
      </li>
      <li className="page-item active">
              <button className="page-link cpointer ">{this.state.TaggedFilePage}</button>
      
        </li>
      {/* <li className="page-item"><a className="page-link cpointer" onClick={(e)=>this.getPageno(e,2)}>2</a></li>
      <li className="page-item"><a className="page-link cpointer" onClick={(e)=>this.getPageno(e,3)} >3</a></li>
      <li className="page-item"><a className="page-link cpointer" onClick={(e)=>this.getPageno(e,4)}>4</a></li> */}
      <li className="page-item">
        <button onClick={(e)=>this.NextImage(e)} className="page-link">
        <span aria-hidden="true">&raquo;</span>
          <span className="sr-only">Next</span>
        </button>
       
      </li>
    </ul>
          </ModalFooter>
        </Modal> 
          
          )
    }
}
export default ViewFile