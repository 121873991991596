import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class AddMetaData extends Component { 
  constructor(props) {
    super(props);
    this.state = {
        mandatory:'1',
        label:'',
        type:'',
        validation:'',
        docTypeDrop:[],
      docTypeDropSelected:'',
      key:'',
      apiPath:'',
      staticOptions:''
  };
  this.onSubmit = this.onSubmit.bind(this);
  this.updatedocTypeDrop = this.updatedocTypeDrop.bind(this)
  }
  onSubmit = (e) => {
    e.preventDefault();
    let error = false;
    const { mandatory, label, type, validation,docTypeDropSelected,key,apiPath,staticOptions} = this.state;
    if(!docTypeDropSelected){
        toast.error('Please Select Document Type.')
         error = true;
        
    }
    if(!key){
        toast.error('Please Enter Key.')
         error = true;
        
    }
    if(!label){
        toast.error('Please Enter Label.')
         error = true;
        
    }
    if(!type){
        toast.error('Please Select Type.')
         error = true;
        
    }
    if(error !== true){ 
        toast.error('Meta Data Added.')
    }
    if(error === false){
      document.getElementById('loader').classList.add('d-block'); 
      fetch(process.env.REACT_APP_ENV+'/api/Document/AddMetaData',{
           method: 'POST',
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'Authorization':'bearer '+cookies.get("auth")
             
           },
           body: JSON.stringify({              
            docid : this.state.docTypeDropSelected.value,
            fieldtype : type,
            mandatory:mandatory, 
            key:key,
            apiPath:apiPath,
            staticOptions:staticOptions,
            cid : cookies.get("cid"),
            userid : cookies.get("userid"), 
            label : label, 
            validation : validation,
            created_by : cookies.get("created_by"),
            created_date : new Date()
          })
           })
         .then((response) => response.json())
         .then((responseJson) => {
           let tempData = responseJson; 
           //console.log(tempData.data[0].id)   
            if(tempData.status.statusMessage === 'Success'){
              const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', tempData.status.authtoken, { path: '/'});    
                 toast.success('Successfully Created Meta data.');
                 setTimeout(function(){ 
                  window.history.back();
                  }, 1000);
              }else{
                 toast.error('Error ocured. Please Try Again');
                 document.getElementById('loader').classList.remove('d-block'); 
            }
          
          
         })
         .catch(error =>{
           console.log(error); 
         })
    }
  }
  updatedocTypeDrop(newValue){
    this.state.docTypeDropSelected = newValue
    this.setState({
      docTypeDropSelected: this.state.docTypeDropSelected
    });
  
  }
  componentDidMount(){
    document.getElementById('loader').classList.add('d-block'); 
    fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocumentTypeList',{
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'Authorization':'bearer '+cookies.get("auth")
           
         },
         body: JSON.stringify({ 
         
        })
         })
       .then((response) => response.json())
       .then((responseJson) => {
         let tempData = responseJson;  
       //  console.log(tempData)   
         if(tempData.status.statusMessage === 'Success'){
              let exdate = new Date();
              cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
              this.state.docType =  tempData.data;
              let storedata = [];
              storedata.push({value:'',label:'Select'});
              for (var i = 0; i < tempData.data.length; i++) {
                          storedata.push({
                            value: tempData.data[i].id, 
                            label:  tempData.data[i].doctype_name,
                          })
                         
                          }
              this.setState({
                docType:this.state.docType,
                docTypeDrop:storedata
              })
              document.getElementById('loader').classList.remove('d-block'); 
         }else{
              toast.error('Error ocured. Please Try Again');
              document.getElementById('loader').classList.remove('d-block'); 
         }
        
        
       })
       .catch(error =>{
         console.log(error); 
       })  
  }
//   updateValueCompany(newValue) {
//     this.state.SelectedCompany = newValue
//   this.setState({
//     SelectedCompany: this.state.SelectedCompany
//   });
// }
  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
  
    goBack() {
        window.history.back();
      }
    render(){
        let mandatory = this.state.mandatory 
        return(
            <div className="modal fade show d-block"     >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header px-4 d-block">
            
              
               <div className="row">
              	<div className="col-md-10">
              		 <h4 className="modal-title" ><a onClick={this.goBack}   className="back-button cpointer" >
                <i className="fas fa-arrow-circle-left"></i></a> Add Meta Data</h4>
              	</div>
              	<div className="col-md-2 text-right">
              	</div>
              </div>
            
            </div>
            <div className="modal-body p-4">
            <ToastContainer />
            <Form onSubmit={this.onSubmit}>
            
              <div className='row'>
                <div className='col-md-8'>
                <div className="row">
              
              {/* <div className="form-group col-md-6">
              <label>Select Organization</label>
				 <Select                
        value={this.state.SelectedCompany}
        onChange={this.updateValueCompany}
        options={this.state.companyList}
        defaultValue={{ value: '', label: '----' }}
      />
              </div> */}
              <div className="form-group col-md-4">
                <label>Document Type <span className='text-danger'>*</span></label>
                <div>
                <Select    
         className ='orgindex'            
        value={this.state.docTypeDropSelected}
        onChange={this.updatedocTypeDrop}
        options={this.state.docTypeDrop}
        defaultValue={{ value: '', label: '----' }}
      />
                </div>
               
                
              </div>
              <div className="form-group col-md-4">
                <label>Field Type <span className='text-danger'>*</span></label>
                <select className="form-control" value={this.state.type} onChange={this.onChange} name='type'>
                <option value=''>---</option>
                  <option value='1'>Text</option>
                  <option value='2'>Hidden</option>
                  <option value='3'>Dropdown</option>
                  <option value='4'>Radio</option>
                  <option value='5'>Checkbox</option>
                  <option value='6'>Autosearch</option>
                </select>
               
                
              </div>
              <div className="form-group col-md-4">
                <div className='mb-2'><label>Mandatory <span className='text-danger'>*</span></label></div>
                <div className="form-check-inline">
  <label className="form-check-label">
    <input type="radio" className="form-check-input" checked={mandatory === "1"}  name='mandatory'  onChange={this.onChange} value="1" />Yes
  </label>
</div>
<div className="form-check-inline">
  <label className="form-check-label">
    <input type="radio" className="form-check-input"  checked={mandatory === "0"}  name='mandatory'  onChange={this.onChange} value='0' />No
  </label>
</div>
          </div>
        
              <div className="form-group col-md-6">
                <label>Key <span className='text-danger'>*</span></label>
                <input type='text' value={this.state.key} onChange={this.onChange} name='key'  className="form-control" />
                
              </div>
              <div className="form-group col-md-6">
                <label>Label <span className='text-danger'>*</span></label>
                <input type='text' value={this.state.label} onChange={this.onChange} name='label'  className="form-control" />
                
              </div>
              
              
              
              
          <div className="form-group col-md-12">
                <label>Validation (Regex) </label>
                <textarea type='text'  onChange={this.onChange} name='validation'  className="form-control" >{this.state.validation}</textarea>
               
              </div>
              <div className="form-group col-md-12">
                  <i><small>Applicable Only for the Field tyes: Dropdown / Radio / Checkbox / AutoSearch</small></i>
              </div>
              <div className="form-group col-md-6">
                <label>API Path</label>
                <input type='text' value={this.state.apiPath} onChange={this.onChange} name='apiPath'  className="form-control" />
                
              </div>    
              <div className="form-group col-md-6">
                <label>Static Options</label>
                <input type='text' value={this.state.staticOptions} onChange={this.onChange} name='staticOptions'  className="form-control" />
                
              </div>   
              </div>
             
              
            
              <button type="submit" className="btn btn-primary">Save</button>
                </div>
                
              </div>
             </Form>
             
              
           
</div>
                                  
       
   
          </div>
        
        </div>
      </div>
        )
    }
}
export default AddMetaData