import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class Menu extends Component {  
    constructor(props) {
        super(props);
        this.state = {   
          username:'',
          login:true,
          defaultOrg:'',
          country:[],
          language:[]
        }
        this.logout = this.logout.bind(this);        
      }
    onChangeHandler=event=>{
        let element = document.getElementById("collapsec");
        element.classList.toggle("collapsec");
    }
    logout(e){    
        this.props.handleLogout('logout'); 
         
      }
      componentDidMount(){      
        fetch(process.env.REACT_APP_ENV+'/api/UserAuth/GetCompanyList',{
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'bearer '+cookies.get("auth")
            
          },
          body: JSON.stringify({ 
        Id:parseInt(cookies.get("cid"))
         })
          })
        .then((response) => response.json()) 
        .then((responseJson) => {
          let tempData = responseJson;  
         // console.log(tempData.data.cname)   
          if(tempData.status.statusMessage === 'Success'){
               let exdate = new Date();
               cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
       
               this.setState({
                defaultOrg:tempData.data[0].cname,
         
               })
          }else{
               toast.error('Error ocured. Please Try Again')
          }
         document.getElementById('loader').classList.remove('d-block'); 
         
        })
        .catch(error =>{
      console.log(error); 
      document.getElementById('loader').classList.remove('d-block'); 
        })

      //   fetch(process.env.REACT_APP_ENV+'/api/Common/SelectCountry',{
      //     method: 'POST',
      //     headers: {
      //       'Accept': 'application/json',
      //       'Content-Type': 'application/json',
      //       'Authorization':'bearer '+cookies.get("auth")
            
      //     },
      //     body: {}
      //     })
      //   .then((response) => response.json()) 
      //   .then((responseJson) => {
      //     let tempData = responseJson;  
      //    // console.log(tempData.data.cname)   
      //     if(tempData.status.statusMessage === 'Success'){
      //          let exdate = new Date();
      //          cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
      //         this.state.country = tempData.data
      //          this.setState({
      //           country:this.state.country
         
      //          })
      //     }
      
         
      //   })
      //   .catch(error =>{
      // console.log(error); 
      //   })

      //   fetch(process.env.REACT_APP_ENV+'/api/Common/SelectLanguage',{
      //     method: 'POST',
      //     headers: {
      //       'Accept': 'application/json',
      //       'Content-Type': 'application/json',
      //       'Authorization':'bearer '+cookies.get("auth")
            
      //     },
      //     body: {}
      //     })
      //   .then((response) => response.json()) 
      //   .then((responseJson) => {
      //     let tempData = responseJson;  
      //    // console.log(tempData.data.cname)   
      //     if(tempData.status.statusMessage === 'Success'){
      //          let exdate = new Date();
      //          cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
      //         this.state.language = tempData.data
      //          this.setState({
      //           language:this.state.language
         
      //          })
      //     }
      
         
      //   })
      //   .catch(error =>{
      // console.log(error); 
      //   })
      }
    render() {
      let countrylist = JSON.stringify(this.state.country);
      let language = JSON.stringify(this.state.language)
      const companyid = parseInt(cookies.get('cid'))
      const role =  parseInt(cookies.get('roleid'))
      if(role === 3){
        if(companyid === 1){
          return (
            <div className='sidebar'>
  <div className="logo-header">
  <span className='logo'><img src='https://deepdatum.ai/wp-content/uploads/2020/12/d2studio-logo-2.png' /></span>
      <span onClick={this.onChangeHandler} className='col-nav'><i className="fa fa-bars" aria-hidden="true"></i></span>	
      </div>            
      <div className='profilediv text-center'>
      <span className='uname'>
                  <div className='picon'><i className="fas fa-user-circle"></i></div>
                  {this.props.username}
                  </span>
                  <div>{this.state.defaultOrg}</div>
               <div className='signout' >
               <span>
               <NavLink to="/profile">Profile</NavLink>
               </span>
                 <span  onClick={(e)=>this.logout(e)}>Sign Out</span>
  
                  </div>
        </div>            
             
            <ul className='nav'>
              <li className='nav-item '><NavLink exact  title='Group Management' activeClassName={"active"}  to="/"><i className="fas fa-layer-group"></i><p>Group Management</p></NavLink></li>
              <li className='nav-item '><NavLink   title='Upload File'  activeClassName={"active"} to="/upload-file"><i className="fas fa-upload"></i><p>Upload File</p></NavLink>  </li>
              <li className='nav-item '><NavLink exact title='View Unassigned Files'  activeClassName={"active"} to="/uploaded-file-list"><i className="fas fa-align-justify"></i><p>View Unassigned Files</p></NavLink></li>
                <li className='nav-item '><NavLink  title='Tag files' activeClassName={"active"}  to="/tagged-file"><i className="fas fa-file-upload"></i><p>Tag files</p></NavLink></li>
                
            </ul>
            <div id ='debugdiv'className='debugdiv'></div>
            <div id='country' className='d-none'>{countrylist}</div>
            <div id='language' className='d-none'>{language}</div>         
            </div>
           
        )
        }else{
          return (
            <div className='sidebar'>
  <div className="logo-header">
  <span className='logo'><img src='https://deepdatum.ai/wp-content/uploads/2020/12/d2studio-logo-2.png' /></span>
      <span onClick={this.onChangeHandler} className='col-nav'><i className="fa fa-bars" aria-hidden="true"></i></span>	
      </div>            
      <div className='profilediv text-center'>
      <span className='uname'>
                  <div className='picon'><i className="fas fa-user-circle"></i></div>
                  {this.props.username}
                  </span>
                  <div>{this.state.defaultOrg}</div>
               <div className='signout' >
               <span>
               <NavLink to="/profile">Profile</NavLink>
               </span>
                 <span  onClick={(e)=>this.logout(e)}>Sign Out</span>
  
                  </div>
        </div>            
             
            <ul className='nav'>           
                <li className='nav-item '><NavLink exact title='View Unassigned Files'  activeClassName={"active"} to="/"><i className="fas fa-align-justify"></i><p>View Unassigned Files</p></NavLink></li>
                <li className='nav-item '><NavLink  title='Tag files' activeClassName={"active"}  to="/tagged-file"><i className="fas fa-file-upload"></i><p>Tag files</p></NavLink></li>
                
            </ul>
            <div id ='debugdiv'className='debugdiv'></div>
            <div id='country' className='d-none'>{countrylist}</div>
            <div id='language' className='d-none'>{language}</div>         
            </div>
           
        )
        }
       
      }else if(role === 4){
        return (
          <div className='sidebar'>
<div className="logo-header">
<span className='logo'><img src='https://deepdatum.ai/wp-content/uploads/2020/12/d2studio-logo-2.png' /></span>
    <span onClick={this.onChangeHandler} className='col-nav'><i className="fa fa-bars" aria-hidden="true"></i></span>	
    </div>            
    <div className='profilediv text-center'>
    <span className='uname'>
                <div className='picon'><i className="fas fa-user-circle"></i></div>
                {this.props.username}
                </span>
                <div>{this.state.defaultOrg}</div>
             <div className='signout' >
             <span>
             <NavLink to="/profile">Profile</NavLink>
             </span>
               <span  onClick={(e)=>this.logout(e)}>Sign Out</span>

                </div>
      </div>            
           
          <ul className='nav'>
          <li className='nav-item '><NavLink  exact title='Dashboard'  activeClassName={"active"} to="/"><i className="fas fa-tachometer-alt"></i><p>Dashboard</p></NavLink></li>
         <li className='nav-item '><NavLink  title='Tag Management'  activeClassName={"active"} to="/tag-management"><i className="fas fa-tag"></i><p>Tag Management</p></NavLink></li>
          {/* <li className='nav-item '><NavLink  title='Group Management' activeClassName={"active"}  to="/view-group"><i className="fas fa-layer-group"></i><p>Group Management</p></NavLink></li> */}
          <li className='nav-item '><NavLink    title='Upload File'  activeClassName={"active"} to="/upload-file"><i className="fas fa-upload"></i><p>Upload File</p></NavLink>  </li>
              {/* <li className='nav-item '><NavLink  title='View Uploaded Files'  activeClassName={"active"} to="/uploaded-file-list"><i className="fas fa-align-justify"></i><p>View Uploaded Files</p></NavLink></li> */}
              {/* <li className='nav-item '><NavLink   title='Tag Queue' activeClassName={"active"}    to="/tag-queue"><i className="fas fa-align-justify"></i><p>Tag Queue</p></NavLink></li> */}
              {/* <li className='nav-item '><NavLink  title='Tag File' activeClassName={"active"}  to="/"><i className="fas fa-file-upload"></i><p>Tag File</p></NavLink></li> */}
              <li className='nav-item '><NavLink  title='Assign Files for Tagging'  activeClassName={"active"} to="/all-uploaded-file"><i className="fas fa-align-justify"></i><p>Assign Files for Tagging</p></NavLink></li>
              <li className='nav-item '><NavLink   title='Manage Tagged Files' activeClassName={"active"}    to="/manage-tagged-files"><i className="fas fa-tags"></i><p>Manage Tagged Files</p></NavLink></li>
              {/* <li className='nav-item '><NavLink   title='Tagging' activeClassName={"active"}    to="/tagging"><i className="fas fa-tags"></i><p>Tagging</p></NavLink></li> */}
               <li className='nav-item '><NavLink  title='Train Model'  activeClassName={"active"} to="/train-model"><i className="fas fa-book-reader"></i><p>Train Model</p></NavLink></li>
              <li className='nav-item '><NavLink  title='Publish Model'  activeClassName={"active"} to="/publish-model"><i className="fas fa-cloud-upload-alt"></i><p>Publish Model</p></NavLink></li>
              <li className='nav-item '><NavLink  title='Process Files'  activeClassName={"active"} to="/process-files"><i className="fas fa-copy"></i><p>Process Files</p></NavLink></li>
              {/*<li className='nav-item '><NavLink  title='Model Performance'  activeClassName={"active"} to="/published-models"><i className="fas fa-clipboard-check"></i><p>Model Performance</p></NavLink></li>
              <li className='nav-item '><NavLink  title='Admin'  activeClassName={"active"} to="/admin"><i className="fas fa-user-cog"></i><p>Admin</p></NavLink></li> */}
              {/* <li className='nav-item '><NavLink  title='System Admin'  activeClassName={"active"} to="/system-admin"><i className="fas fa-user-shield"></i><p>System Admin</p></NavLink></li> */}
              {/* <li className='nav-item '><NavLink  title='Manufacturer Model Details' activeClassName={"active"}  to="/review-sixteen-section"><i className="fas fa-directions"></i><p>Manufacturer Model Details</p></NavLink></li>
              

              <li className='nav-item '><NavLink  title='Manufacturer Details' activeClassName={"active"}  to="/manufacturer-details"><i className="fas fa-industry"></i><p>Manufacturer Details</p></NavLink></li>
              <li className='nav-item '><NavLink  title='Tagging Details' activeClassName={"active"}  to="/tagging-details"><i className="fas fa-tags"></i><p>Tagging Details</p></NavLink></li>
              <li className='nav-item '><NavLink  title='Processed Files' activeClassName={"active"}  to="/process-files"><i className="fas fa-file-signature"></i><p>Processed Files</p></NavLink></li>
                */}
                        
             
             
          </ul>
          <div id ='debugdiv'className='debugdiv'></div>
          <div id='country' className='d-none'>{countrylist}</div>
          <div id='language' className='d-none'>{language}</div>
          {/* <div   className='log-out'>
              <span className='username'>
              <div><i className="fas fa-user-circle"></i></div>
                {this.props.username}
                </span>
             <span onClick={(e)=>this.logout(e)} className='log-icon'> <i className="fas fa-power-off"></i></span>
              </div> */}
          </div>
         
      )
      }
      else if(role === 2)
      {
        return (
          <div className='sidebar'>
<div className="logo-header clearfix">
<span className='logo'><img src='https://deepdatum.ai/wp-content/uploads/2020/12/d2studio-logo-2.png' /></span>
    <span onClick={this.onChangeHandler} className='col-nav'><i className="fa fa-bars" aria-hidden="true"></i></span>	
    </div>
    <div className='profilediv text-center'>
    <span className='uname'>
                <div className='picon'><i className="fas fa-user-circle"></i></div>
                {this.props.username}
                </span>
                <div>{this.state.defaultOrg}</div>
             <div className='signout' >
             <span>
             <NavLink to="/profile">Profile</NavLink>
             </span>
               <span  onClick={(e)=>this.logout(e)}>Sign Out</span>

                </div>
      </div>            
             <ul className='nav'>
          <li className='nav-item '><NavLink  exact title='Dashboard'  activeClassName={"active"} to="/"><i className="fas fa-tachometer-alt"></i><p>Dashboard</p></NavLink></li>
          <li className='nav-item '><NavLink  title='Tag Management'  activeClassName={"active"} to="/tag-management"><i className="fas fa-tag"></i><p>Tag Management</p></NavLink></li>
          {/* <li className='nav-item '><NavLink  title='Group Management' activeClassName={"active"}  to="/view-group"><i className="fas fa-layer-group"></i><p>Group Management</p></NavLink></li> */}
          <li className='nav-item '><NavLink    title='Upload File'  activeClassName={"active"} to="/upload-file"><i className="fas fa-upload"></i><p>Upload File</p></NavLink>  </li>
              {/* <li className='nav-item '><NavLink  title='View Uploaded Files'  activeClassName={"active"} to="/uploaded-file-list"><i className="fas fa-align-justify"></i><p>View Uploaded Files</p></NavLink></li> */}
              {/* <li className='nav-item '><NavLink   title='Tag Queue' activeClassName={"active"}    to="/tag-queue"><i className="fas fa-align-justify"></i><p>Tag Queue</p></NavLink></li> */}
              {/* <li className='nav-item '><NavLink  title='Tag File' activeClassName={"active"}  to="/tagged-file"><i className="fas fa-file-upload"></i><p>Tag File</p></NavLink></li> */}
              <li className='nav-item '><NavLink  title='Assign Files for Tagging'  activeClassName={"active"} to="/all-uploaded-file"><i className="fas fa-align-justify"></i><p>Assign Files for Tagging</p></NavLink></li>
              <li className='nav-item '><NavLink   title='Manage Tagged Files' activeClassName={"active"}    to="/manage-tagged-files"><i className="fas fa-tags"></i><p>Manage Tagged Files</p></NavLink></li>
              {/* <li className='nav-item '><NavLink   title='Tagging' activeClassName={"active"}    to="/tagging"><i className="fas fa-tags"></i><p>Tagging</p></NavLink></li> */}
              <li className='nav-item '><NavLink  title='Train Model'  activeClassName={"active"} to="/train-model"><i className="fas fa-book-reader"></i><p>Train Model</p></NavLink></li>
              <li className='nav-item '><NavLink  title='Publish Model'  activeClassName={"active"} to="/publish-model"><i className="fas fa-cloud-upload-alt"></i><p>Publish Model</p></NavLink></li>
              <li className='nav-item '><NavLink  title='Process Files'  activeClassName={"active"} to="/process-files"><i className="fas fa-copy"></i><p>Process Files</p></NavLink></li>
              {/* <li className='nav-item '><NavLink  title='Model Performance'  activeClassName={"active"} to="/published-models"><i className="fas fa-clipboard-check"></i><p>Model Performance</p></NavLink></li> */}
              {/* <li className='nav-item '><NavLink  title='Process Files'  activeClassName={"active"} to="/process-file"><i className="fas fa-copy"></i><p>Process Files</p></NavLink></li> */}
              <li className='nav-item '><NavLink  title='Settings'  activeClassName={"active"} to="/settings"><i className="fas fa-cogs"></i><p>Settings</p></NavLink></li>
              {/* <li className='nav-item '><NavLink  title='System Admin'  activeClassName={"active"} to="/system-admin"><i className="fas fa-user-shield"></i><p>System Admin</p></NavLink></li> */}
              {/* <li className='nav-item '><NavLink  title='Manufacturer Model Details' activeClassName={"active"}  to="/review-sixteen-section"><i className="fas fa-directions"></i><p>Manufacturer Model Details</p></NavLink></li>
              

              <li className='nav-item '><NavLink  title='Manufacturer Details' activeClassName={"active"}  to="/manufacturer-details"><i className="fas fa-industry"></i><p>Manufacturer Details</p></NavLink></li>
              <li className='nav-item '><NavLink  title='Tagging Details' activeClassName={"active"}  to="/tagging-details"><i className="fas fa-tags"></i><p>Tagging Details</p></NavLink></li>
              <li className='nav-item '><NavLink  title='Processed Files' activeClassName={"active"}  to="/process-files"><i className="fas fa-file-signature"></i><p>Processed Files</p></NavLink></li>
                */}
                        
             
             
          </ul>
          <div id ='debugdiv'className='debugdiv'></div>
          <div id='country' className='d-none'>{countrylist}</div>
          <div id='language' className='d-none'>{language}</div>
           </div>
         
      )
      }else{
        return (
          <div className='sidebar'>
            
<div className="logo-header clearfix">
<span className='logo'><img src='https://deepdatum.ai/wp-content/uploads/2020/12/d2studio-logo-2.png' /></span>
    <span onClick={this.onChangeHandler} className='col-nav'><i className="fa fa-bars" aria-hidden="true"></i></span>	
    </div>
    <div className='profilediv text-center'>
    <span className='uname'>
                <div className='picon'><i className="fas fa-user-circle"></i></div>
                {this.props.username}
                </span>
                <div>{this.state.defaultOrg}</div>
             <div className='signout' >
             <span>
             <NavLink to="/profile">Profile</NavLink>
             </span>
               <span  onClick={(e)=>this.logout(e)}>Sign Out</span>

                </div>
      </div>            
          <ul className='nav'>
          <li className='nav-item '><NavLink  exact title='Dashboard'  activeClassName={"active"} to="/"><i className="fas fa-tachometer-alt"></i><p>Dashboard</p></NavLink></li>
          {/* <li className='nav-item '><NavLink  title='Document Type'  activeClassName={"active"} to="/view-doc-type"><i className="fas fa-file-alt"></i><p>Document Type</p></NavLink></li> */}
          <li className='nav-item '><NavLink title='Tag Management/tag-management'  activeClassName={"active"} to="/tag-management"><i className="fas fa-tag"></i><p>Tag Management</p></NavLink></li>
          {/* <li className='nav-item '><NavLink  title='Group Management' activeClassName={"active"}  to="/view-group"><i className="fas fa-layer-group"></i><p>Group Management</p></NavLink></li> */}
          <li className='nav-item '><NavLink    title='Upload File'  activeClassName={"active"} to="/upload-file"><i className="fas fa-upload"></i><p>Upload File</p></NavLink>  </li>
              <li className='nav-item '><NavLink  title='Assign Files for Tagging'  activeClassName={"active"} to="/all-uploaded-file"><i className="fas fa-align-justify"></i><p>Assign Files for Tagging</p></NavLink></li>
              <li className='nav-item '><NavLink   title='Manage Tagged Files' activeClassName={"active"}    to="/manage-tagged-files"><i className="fas fa-tags"></i><p>Manage Tagged Files</p></NavLink></li>
              {/* <li className='nav-item '><NavLink  title='Tag File' activeClassName={"active"}  to="/tagged-file"><i className="fas fa-file-upload"></i><p>Tag File</p></NavLink></li> */}
              
              {/* <li className='nav-item '><NavLink   title='Tagging' activeClassName={"active"}    to="/tagging"><i className="fas fa-tags"></i><p>Tagging</p></NavLink></li> */}
              <li className='nav-item '><NavLink  title='Train Model'  activeClassName={"active"} to="/train-model"><i className="fas fa-book-reader"></i><p>Train Model</p></NavLink></li>
              <li className='nav-item '><NavLink  title='Publish Model'  activeClassName={"active"} to="/publish-model"><i className="fas fa-cloud-upload-alt"></i><p>Publish Model</p></NavLink></li>
              <li className='nav-item '><NavLink  title='Process Files'  activeClassName={"active"} to="/process-files"><i className="fas fa-copy"></i><p>Process Files</p></NavLink></li>
              {/* <li className='nav-item '><NavLink  title='Model Performance'  activeClassName={"active"} to="/published-models"><i className="fas fa-clipboard-check"></i><p>Model Performance</p></NavLink></li> */}
              
              {/* <li className='nav-item '><NavLink  title='System Admin'  activeClassName={"active"} to="/system-admin"><i className="fas fa-user-shield"></i><p>System Admin</p></NavLink></li> */}
               {/* <li className='nav-item '><NavLink  title='Process Files'  activeClassName={"active"} to="/process-file"><i className="fas fa-copy"></i><p>Process Files</p></NavLink></li>  */}
              <li className='nav-item '><NavLink  title='Settings'  activeClassName={"active"} to="/settings"><i className="fas fa-cogs"></i><p>Settings</p></NavLink></li>
              {/* <li className='nav-item '><NavLink  title='Manufacturer Model Details' activeClassName={"active"}  to="/review-sixteen-section"><i className="fas fa-directions"></i><p>Manufacturer Model Details</p></NavLink></li>
              

              <li className='nav-item '><NavLink  title='Manufacturer Details' activeClassName={"active"}  to="/manufacturer-details"><i className="fas fa-industry"></i><p>Manufacturer Details</p></NavLink></li>
              <li className='nav-item '><NavLink  title='Tagging Details' activeClassName={"active"}  to="/tagging-details"><i className="fas fa-tags"></i><p>Tagging Details</p></NavLink></li>
              <li className='nav-item '><NavLink  title='Processed Files' activeClassName={"active"}  to="/process-files"><i className="fas fa-file-signature"></i><p>Processed Files</p></NavLink></li>
                */}
                        
             
             
          </ul>
         
          <div id ='debugdiv'className='debugdiv'></div>
          <div id='country' className='d-none'>{countrylist}</div>
          <div id='language' className='d-none'>{language}</div>
          </div>
         
      )
      }
        
      };
    
}
export default Menu;