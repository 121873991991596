import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Select from 'react-select';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class Profile extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      fname: '',
      lname:'',
      email: '',
      phone: '',
      password: '',
      cpassword: '',
      role:'2',
      cname:'',
      docType:'',
      ifCloudsds:false,
      SelectedCompany:'',
      companyList:[],
      selectedOptiongdoc:[],
      loggedRole:'',
      status:'1',
      
 }; 
    this.onSubmit = this.onSubmit.bind(this);
    this.updateValueCompany = this.updateValueCompany.bind(this);   
  }
  updateValueCompany(newValue) {
    this.state.SelectedCompany = newValue
  this.setState({
    SelectedCompany: this.state.SelectedCompany
  });
  
 }


  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
  onSubmit = (e) => {
    e.preventDefault();  
    e.preventDefault();  
    let error = false;
    let emilPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    var phonePattern = new RegExp(/^[0-9\b]+$/);
    const { fname, lname, email, phone,password,cpassword,role} = this.state;
    if(fname === ''){
         toast.error('Please enter first name.')
         error = true;
         document.getElementsByName('fname')[0].focus()
         return false;
    }
    if(lname === ''){
         toast.error('Please enter last name.');         
         error = true;
         document.getElementsByName('lname')[0].focus();
         return false;
    }
    // if(email === ''){
    //      toast.error('Please enter email.')
    //      error = true
    //      document.getElementsByName('email')[0].focus();
    //      return false;
    // }else{
    //   if (!emilPattern.test(email)) {
    //     toast.error('Please enter valid email.')
    //     error = true
    //     document.getElementsByName('email')[0].focus();
    //     return false;
    //   }
    // }
    if(phone === ''){
         toast.error('Please enter phone number.')
         error = true
         document.getElementsByName('phone')[0].focus();
         return false;
    }else{
      if (!phonePattern.test(phone)) {

        toast.error('Please enter only phone number.')
         error = true
         document.getElementsByName('phone')[0].focus();
         return false;
      }else if(phone.length != 10){
    
        toast.error('Please enter valid phone number.')
         error = true
         document.getElementsByName('phone')[0].focus();
         return false;
      }
    }
//     if(role === ''){
//       toast.error('Please select role.')
//       error = true
//       document.getElementsByName('role')[0].focus();
//       return false;
//  }
//     if(password){
//       if(cpassword === ''){
//         toast.error('Please enter confirm password.')
//         error = true
//         document.getElementsByName('cpassword')[0].focus();
//         return false;
//       }
//       if(password !== cpassword){
//         toast.error('Wrong confirm password entered.')
//         error = true
//         document.getElementsByName('cpassword')[0].focus();
//         return false;
//       }
//  }
 
   // console.log('name : ' + tgname+', desc: ' +tgdesc+',tagType:'+tagType+',docType'+docType);
    if(error !== true){
     
         document.getElementById('loader').classList.add('d-block'); 
         fetch(process.env.REACT_APP_ENV+'/api/UserAuth/UpdateUser',{
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'bearer '+cookies.get("auth")
                
              },
              body: JSON.stringify({ 
                id:parseInt(cookies.get("userid")),
                email_id : email,
                mobile_no :  phone,
                password : '',
                first_name : fname,
                last_name : lname,
                cid : this.state.SelectedCompany.value, 
                cname : this.state.cname,
                profilephoto:null,
                utype:role,
                roleid:role,
                status:1,
                created_by : cookies.get("userid")
           
             })
              })
            .then((response) => response.json())
            .then((responseJson) => {
              let tempData = responseJson;  
              console.log(tempData)   
              if(tempData.status.statusMessage === 'Success'){
                   toast.success('Successfully user info updated.');
                   const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', tempData.status.authtoken, { path: '/'});  
              document.getElementById('loader').classList.remove('d-block'); 
              
              }else{
                   toast.success('Error ocured. Please Try Again')
                   document.getElementById('loader').classList.remove('d-block'); 
              }
             
             
            })
            .catch(error =>{
              console.log(error); 
            })
             
            
           
             
           
    }
    
    
  }
  componentDidMount(){
    document.getElementById('loader').classList.add('d-block'); 
    let loggedrole = cookies.get("roleid");
    
    this.setState({
      loggedRole:loggedrole,
     })
    fetch(process.env.REACT_APP_ENV+'/api/UserAuth/GetCompanyList',{
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'Authorization':'bearer '+cookies.get("auth")
           
         },
         body: JSON.stringify({ 
          Id : cookies.get("cid")  
        })
         })
       .then((response) => response.json())
       .then((responseJson) => {
         let tempData = responseJson;  
         console.log(tempData)   
         if(tempData.status.statusMessage === 'Success'){
           this.state.cname = tempData.data.cname;
           this.setState({
            cname:this.state.cname
           })
           let storedata = [];
              let tempDatas = responseJson.data; 
              for (var i = 0; i < tempDatas.length; i++) {
                storedata.push({
                  value: tempDatas[i].id, 
                  label:  tempDatas[i].cname,
                })
                
                }
               this.setState({
              companyList: [...this.state.companyList, ...storedata ],
             })
             
           const timestamp = new Date().getTime();
           const expireauth = timestamp + 1800000;
           const exdate =  new Date(expireauth);
           cookies.set('auth', tempData.status.authtoken, { path: '/'});   
           this.state.SelectedCompany = this.state.companyList[0];
           this.setState({
            SelectedCompany:this.state.SelectedCompany,
           })
           fetch(process.env.REACT_APP_ENV+'/api/UserAuth/GetUserByParam',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization':'bearer '+cookies.get("auth")
              
            },
            body: JSON.stringify({ 
             id : parseInt(cookies.get("userid")),
             cid:parseInt(cookies.get("cid"))
             
           })
            })
          .then((response) => response.json())
          .then((responseJson) => {
            let tempData = responseJson;  
            console.log(tempData)   
            if(tempData.status.statusMessage === 'Success'){
             const timestamp = new Date().getTime();
             const expireauth = timestamp + 1800000;
             const exdate =  new Date(expireauth);
             cookies.set('auth', tempData.status.authtoken, { path: '/'});   
                 this.state.fname =  tempData.data[0].first_name;
                 this.state.lname =  tempData.data[0].last_name;
                 this.state.email =  tempData.data[0].email_id;
                 this.state.phone =  tempData.data[0].mobile_no;
                 this.state.cid = tempData.data[0].cid;
                 let cid = tempData.data[0].cid;
                 console.log(cid)
                 let companyIndex = this.state.companyList.findIndex(companyList => companyList.value === cid);
                 this.state.SelectedCompany = this.state.companyList[companyIndex];
                 console.log( this.state.companyList[companyIndex])
                // this.state.password =  tempData.data[0].password;
                 this.state.role =  tempData.data[0].roleid;
                // this.state.cname =  tempData.data[0].cname;
                 this.setState({
                   useres:this.state.useres,
                 })
                 document.getElementById('loader').classList.remove('d-block'); 
            }else{
                 toast.error('Error ocured. Please Try Again');
                 document.getElementById('loader').classList.remove('d-block'); 
            }
           
           
          })
          .catch(error =>{
            console.log(error); 
          })
         }else{
              toast.success('Error ocured. Please Try Again')
         }
        document.getElementById('loader').classList.remove('d-block'); 
        
       })
       .catch(error =>{
         console.log(error); 
       })

      
  }
    goBack() {
        window.history.back();
      }
    render(){
        let role = this.state.role;
        let roleName = '';
        if(role === 1){
          roleName = 'Super Administrator'
           }
           if(role === 2){
            roleName = 'Administrator'
           }
           if(role === 3){
            roleName = 'Taggers'
           }
           if(role === 4){
            roleName = 'Data Scientists'
           }
        return(
            <div className='h100percent'>
        <div className='main-header'>
          <div className='row'>
              <div className='col-md-6'><h2 className="page-header">Profile</h2></div>
              <div className='col-md-6 text-right'>             
                
                </div>
            </div>
            </div>
            <ToastContainer />
            <div className='main-panel'>
             <div className='content '>
             <div className='container-fluid h100percent '>
           
           
            <div className='row'>
                <div className='col-md-4'>
                    <div className='card'>
                        <div className='card-body'>
                        <div className="d-flex flex-column align-items-center text-center"> 
                <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt={role} className="rounded-circle" width="150" />
                    <div className="mt-3">
                        <h4>{this.state.fname} {this.state.lname}</h4>
                        <p className="text-secondary mb-1">{roleName}</p>
                        <p className="text-muted font-size-sm">{this.state.SelectedCompany.label}</p>
                         <button className="btn btn-primary">Reset Password</button> 
                         </div>
                         </div>
                
                        </div>
                    </div>
               
                </div>
                
                <div className='col-md-8'>
                <Form onSubmit={this.onSubmit}>
                <div className='row'>
                <div className='col-md-6'>
                <div className="form-row">
              <div className="form-group col-md-12">
                <label>First Name <span className='text-danger'>*</span></label>
                <input type='text' onChange={this.onChange} value={this.state.fname} name='fname'  className="form-control" />
                
              </div>
              <div className="form-group col-md-12">
              <label>Last Name <span className='text-danger'>*</span></label>
                <input type='text' onChange={this.onChange} value={this.state.lname}  name='lname'   className="form-control" />
                
              </div>
              <div className="form-group col-md-12">
              <label>Email <span className='text-danger'>*</span></label>
                <input readOnly  type='text' onChange={this.onChange} value={this.state.email}  name='email'    className="form-control" />                
              </div>
              <div className="form-group col-md-12">
              <label>Phone </label>
                <input type='text' onChange={this.onChange} value={this.state.phone} name='phone'    className="form-control" />                
              </div>
              {/* <div className="form-group col-md-6">
              <label>Role <span className='text-danger'>*</span></label>
              <select onChange={this.onChange} name='role' value={this.state.role} className="form-control">
                <option value='2'>Administrator</option>
                <option value='5'>System Administrator</option>
                <option value='3'>Tagger</option>
                <option value='4'>Data Scientists</option>
              </select>
                             
              </div> */}
            
              
             
              {/* <div className="form-group col-md-6">
              <label>Password <span className='text-danger'>*</span></label>
                <input type='password'  onChange={this.onChange} value={this.state.password} name='password'   className="form-control" />                
              </div>
              <div className="form-group col-md-6">
              <label>Confirm Password <span className='text-danger'>*</span></label>
                <input type='password'  onChange={this.onChange} value={this.state.cpassword} name='cpassword'   className="form-control" />                
              </div> */}
              
              </div>
              <button type="submit" className="btn btn-primary">Save</button>
                </div>
                
              </div>
              </Form>
                    </div>
            </div>
              
             
            
              
             </div>
             </div>
        
             </div>
      </div>
        )
    }
}
export default Profile