import React, { Component } from 'react';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Modal, ModalHeader, ModalBody, ModalFooter,FormGroup,Label   } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Select from 'react-select';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class UploadFilesForPrediction extends Component {  
  constructor(props) {
    super(props);    
      this.state = {
		filesAccess:[],
		selectedOptionglang:'',
        selectedFile: null,
        loaded:0,
        activeTab: '1',
        mfgNameen:'',
        mfgID:'',
        mfgNameList:[],
        mfgcoun:'',
        mfglang:'',        
		countryList:[
			{ value:'',label:'----'},
			{ value:'AF',label:'Afghanistan'},
			{ value:'AX',label:'Åland Islands'},
			{ value:'AL',label:'Albania'},
			{ value:'DZ',label:'Algeria'},
			{ value:'AS',label:'American Samoa'},
			{ value:'AD',label:'Andorra'},
			{ value:'AO',label:'Angola'},
			{ value:'AI',label:'Anguilla'},
			{ value:'AQ',label:'Antarctica'},
			{ value:'AG',label:'Antigua and Barbuda'},
			{ value:'AR',label:'Argentina'},
			{ value:'AM',label:'Armenia'},
			{ value:'AW',label:'Aruba'},
			{ value:'AU',label:'Australia'},
			{ value:'AT',label:'Austria'},
			{ value:'AZ',label:'Azerbaijan'},
			{ value:'BS',label:'Bahamas'},
			{ value:'BH',label:'Bahrain'},
			{ value:'BD',label:'Bangladesh'},
			{ value:'BB',label:'Barbados'},
			{ value:'BY',label:'Belarus'},
			{ value:'BE',label:'Belgium'},
			{ value:'BZ',label:'Belize'},
			{ value:'BJ',label:'Benin'},
			{ value:'BM',label:'Bermuda'},
			{ value:'BT',label:'Bhutan'},
			{ value:'BO',label:'Bolivia, Plurinational State of'},
			{ value:'BQ',label:'Bonaire, Sint Eustatius and Saba'},
			{ value:'BA',label:'Bosnia and Herzegovina'},
			{ value:'BW',label:'Botswana'},
			{ value:'BV',label:'Bouvet Island'},
			{ value:'BR',label:'Brazil'},
			{ value:'IO',label:'British Indian Ocean Territory'},
			{ value:'BN',label:'Brunei Darussalam'},
			{ value:'BG',label:'Bulgaria'},
			{ value:'BF',label:'Burkina Faso'},
			{ value:'BI',label:'Burundi'},
			{ value:'KH',label:'Cambodia'},
			{ value:'CM',label:'Cameroon'},
			{ value:'CA',label:'Canada'},
			{ value:'CV',label:'Cape Verde'},
			{ value:'KY',label:'Cayman Islands'},
			{ value:'CF',label:'Central African Republic'},
			{ value:'TD',label:'Chad'},
			{ value:'CL',label:'Chile'},
			{ value:'CN',label:'China'},
			{ value:'CX',label:'Christmas Island'},
			{ value:'CC',label:'Cocos (Keeling) Islands'},
			{ value:'CO',label:'Colombia'},
			{ value:'KM',label:'Comoros'},
			{ value:'CG',label:'Congo'},
			{ value:'CD',label:'Congo, the Democratic Republic of the'},
			{ value:'CK',label:'Cook Islands'},
			{ value:'CR',label:'Costa Rica'},
			{ value:'CI',label:'Côte d\'Ivoire'},
			{ value:'HR',label:'Croatia'},
			{ value:'CU',label:'Cuba'},
			{ value:'CW',label:'Curaçao'},
			{ value:'CY',label:'Cyprus'},
			{ value:'CZ',label:'Czech Republic'},
			{ value:'DK',label:'Denmark'},
			{ value:'DJ',label:'Djibouti'},
			{ value:'DM',label:'Dominica'},
			{ value:'DO',label:'Dominican Republic'},
			{ value:'EC',label:'Ecuador'},
			{ value:'EG',label:'Egypt'},
			{ value:'SV',label:'El Salvador'},
			{ value:'GQ',label:'Equatorial Guinea'},
			{ value:'ER',label:'Eritrea'},
			{ value:'EE',label:'Estonia'},
			{ value:'ET',label:'Ethiopia'},
			{ value:'FK',label:'Falkland Islands (Malvinas)'},
			{ value:'FO',label:'Faroe Islands'},
			{ value:'FJ',label:'Fiji'},
			{ value:'FI',label:'Finland'},
			{ value:'FR',label:'France'},
			{ value:'GF',label:'French Guiana'},
			{ value:'PF',label:'French Polynesia'},
			{ value:'TF',label:'French Southern Territories'},
			{ value:'GA',label:'Gabon'},
			{ value:'GM',label:'Gambia'},
			{ value:'GE',label:'Georgia'},
			{ value:'DE',label:'Germany'},
			{ value:'GH',label:'Ghana'},
			{ value:'GI',label:'Gibraltar'},
			{ value:'GR',label:'Greece'},
			{ value:'GL',label:'Greenland'},
			{ value:'GD',label:'Grenada'},
			{ value:'GP',label:'Guadeloupe'},
			{ value:'GU',label:'Guam'},
			{ value:'GT',label:'Guatemala'},
			{ value:'GG',label:'Guernsey'},
			{ value:'GN',label:'Guinea'},
			{ value:'GW',label:'Guinea-Bissau'},
			{ value:'GY',label:'Guyana'},
			{ value:'HT',label:'Haiti'},
			{ value:'HM',label:'Heard Island and McDonald Islands'},
			{ value:'VA',label:'Holy See (Vatican City State)'},
			{ value:'HN',label:'Honduras'},
			{ value:'HK',label:'Hong Kong'},
			{ value:'HU',label:'Hungary'},
			{ value:'IS',label:'Iceland'},
			{ value:'IN',label:'India'},
			{ value:'ID',label:'Indonesia'},
			{ value:'IR',label:'Iran, Islamic Republic of'},
			{ value:'IQ',label:'Iraq'},
			{ value:'IE',label:'Ireland'},
			{ value:'IM',label:'Isle of Man'},
			{ value:'IL',label:'Israel'},
			{ value:'IT',label:'Italy'},
			{ value:'JM',label:'Jamaica'},
			{ value:'JP',label:'Japan'},
			{ value:'JE',label:'Jersey'},
			{ value:'JO',label:'Jordan'},
			{ value:'KZ',label:'Kazakhstan'},
			{ value:'KE',label:'Kenya'},
			{ value:'KI',label:'Kiribati'},
			{ value:'KP',label:'Korea, Democratic People\'s Republic of'},
			{ value:'KR',label:'Korea, Republic of'},
			{ value:'KW',label:'Kuwait'},
			{ value:'KG',label:'Kyrgyzstan'},
			{ value:'LA',label:'Lao People\'s Democratic Republic'},
			{ value:'LV',label:'Latvia'},
			{ value:'LB',label:'Lebanon'},
			{ value:'LS',label:'Lesotho'},
			{ value:'LR',label:'Liberia'},
			{ value:'LY',label:'Libya'},
			{ value:'LI',label:'Liechtenstein'},
			{ value:'LT',label:'Lithuania'},
			{ value:'LU',label:'Luxembourg'},
			{ value:'MO',label:'Macao'},
			{ value:'MK',label:'Macedonia, the former Yugoslav Republic of'},
			{ value:'MG',label:'Madagascar'},
			{ value:'MW',label:'Malawi'},
			{ value:'MY',label:'Malaysia'},
			{ value:'MV',label:'Maldives'},
			{ value:'ML',label:'Mali'},
			{ value:'MT',label:'Malta'},
			{ value:'MH',label:'Marshall Islands'},
			{ value:'MQ',label:'Martinique'},
			{ value:'MR',label:'Mauritania'},
			{ value:'MU',label:'Mauritius'},
			{ value:'YT',label:'Mayotte'},
			{ value:'MX',label:'Mexico'},
			{ value:'FM',label:'Micronesia, Federated States of'},
			{ value:'MD',label:'Moldova, Republic of'},
			{ value:'MC',label:'Monaco'},
			{ value:'MN',label:'Mongolia'},
			{ value:'ME',label:'Montenegro'},
			{ value:'MS',label:'Montserrat'},
			{ value:'MA',label:'Morocco'},
			{ value:'MZ',label:'Mozambique'},
			{ value:'MM',label:'Myanmar'},
			{ value:'NA',label:'Namibia'},
			{ value:'NR',label:'Nauru'},
			{ value:'NP',label:'Nepal'},
			{ value:'NL',label:'Netherlands'},
			{ value:'NC',label:'New Caledonia'},
			{ value:'NZ',label:'New Zealand'},
			{ value:'NI',label:'Nicaragua'},
			{ value:'NE',label:'Niger'},
			{ value:'NG',label:'Nigeria'},
			{ value:'NU',label:'Niue'},
			{ value:'NF',label:'Norfolk Island'},
			{ value:'MP',label:'Northern Mariana Islands'},
			{ value:'NO',label:'Norway'},
			{ value:'OM',label:'Oman'},
			{ value:'PK',label:'Pakistan'},
			{ value:'PW',label:'Palau'},
			{ value:'PS',label:'Palestinian Territory, Occupied'},
			{ value:'PA',label:'Panama'},
			{ value:'PG',label:'Papua New Guinea'},
			{ value:'PY',label:'Paraguay'},
			{ value:'PE',label:'Peru'},
			{ value:'PH',label:'Philippines'},
			{ value:'PN',label:'Pitcairn'},
			{ value:'PL',label:'Poland'},
			{ value:'PT',label:'Portugal'},
			{ value:'PR',label:'Puerto Rico'},
			{ value:'QA',label:'Qatar'},
			{ value:'RE',label:'Réunion'},
			{ value:'RO',label:'Romania'},
			{ value:'RU',label:'Russian Federation'},
			{ value:'RW',label:'Rwanda'},
			{ value:'BL',label:'Saint Barthélemy'},
			{ value:'SH',label:'Saint Helena, Ascension and Tristan da Cunha'},
			{ value:'KN',label:'Saint Kitts and Nevis'},
			{ value:'LC',label:'Saint Lucia'},
			{ value:'MF',label:'Saint Martin (French part)'},
			{ value:'PM',label:'Saint Pierre and Miquelon'},
			{ value:'VC',label:'Saint Vincent and the Grenadines'},
			{ value:'WS',label:'Samoa'},
			{ value:'SM',label:'San Marino'},
			{ value:'ST',label:'Sao Tome and Principe'},
			{ value:'SA',label:'Saudi Arabia'},
			{ value:'SN',label:'Senegal'},
			{ value:'RS',label:'Serbia'},
			{ value:'SC',label:'Seychelles'},
			{ value:'SL',label:'Sierra Leone'},
			{ value:'SG',label:'Singapore'},
			{ value:'SX',label:'Sint Maarten (Dutch part)'},
			{ value:'SK',label:'Slovakia'},
			{ value:'SI',label:'Slovenia'},
			{ value:'SB',label:'Solomon Islands'},
			{ value:'SO',label:'Somalia'},
			{ value:'ZA',label:'South Africa'},
			{ value:'GS',label:'South Georgia and the South Sandwich Islands'},
			{ value:'SS',label:'South Sudan'},
			{ value:'ES',label:'Spain'},
			{ value:'LK',label:'Sri Lanka'},
			{ value:'SD',label:'Sudan'},
			{ value:'SR',label:'Suriname'},
			{ value:'SJ',label:'Svalbard and Jan Mayen'},
			{ value:'SZ',label:'Swaziland'},
			{ value:'SE',label:'Sweden'},
			{ value:'CH',label:'Switzerland'},
			{ value:'SY',label:'Syrian Arab Republic'},
			{ value:'TW',label:'Taiwan, Province of China'},
			{ value:'TJ',label:'Tajikistan'},
			{ value:'TZ',label:'Tanzania, United Republic of'},
			{ value:'TH',label:'Thailand'},
			{ value:'TL',label:'Timor-Leste'},
			{ value:'TG',label:'Togo'},
			{ value:'TK',label:'Tokelau'},
			{ value:'TO',label:'Tonga'},
			{ value:'TT',label:'Trinidad and Tobago'},
			{ value:'TN',label:'Tunisia'},
			{ value:'TR',label:'Turkey'},
			{ value:'TM',label:'Turkmenistan'},
			{ value:'TC',label:'Turks and Caicos Islands'},
			{ value:'TV',label:'Tuvalu'},
			{ value:'UG',label:'Uganda'},
			{ value:'UA',label:'Ukraine'},
			{ value:'AE',label:'United Arab Emirates'},
			{ value:'GB',label:'United Kingdom'},
			{ value:'US',label:'United States'},
			{ value:'UM',label:'United States Minor Outlying Islands'},
			{ value:'UY',label:'Uruguay'},
			{ value:'UZ',label:'Uzbekistan'},
			{ value:'VU',label:'Vanuatu'},
			{ value:'VE',label:'Venezuela, Bolivarian Republic of'},
			{ value:'VN',label:'Viet Nam'},
			{ value:'VG',label:'Virgin Islands, British'},
			{ value:'VI',label:'Virgin Islands, U.S.'},
			{ value:'WF',label:'Wallis and Futuna'},
			{ value:'EH',label:'Western Sahara'},
			{ value:'YE',label:'Yemen'},
			{ value:'ZM',label:'Zambia'},
			{ value:'ZW',label:'Zimbabwe'}
		],
		selectedOptioncountry:'',
		groupList:[],
		groupListAll:[],
		selectedOptiongroup:'',
		activeTab: 1,
		csdrop:'1',
		cstext:'',
		csPname:'',
		csmname:'',
		csCAS:'',
		csPcode:'',
		tbheight:'',
		modal: false,
        fade: false,
		data : [], 
		langlist:[
			{ value:'',label:'----'},
			{ value:'AF',label:'Afrikaans'},
			{ value:'SQ',label:'Albanian'},
			{ value:'AR',label:'Arabic'},
			{ value:'HY',label:'Armenian'},
			{ value:'EU',label:'Basque'},
			{ value:'BN',label:'Bengali'},
			{ value:'BG',label:'Bulgarian'},
			{ value:'CA',label:'Catalan'},
			{ value:'KM',label:'Cambodian'},
			{ value:'ZH',label:'Chinese (Mandarin)'},
			{ value:'HR',label:'Croatian'},
			{ value:'CS',label:'Czech'},
			{ value:'DA',label:'Danish'},
			{ value:'NL',label:'Dutch'},
			{ value:'EN',label:'English'},
			{ value:'ET',label:'Estonian'},
			{ value:'FJ',label:'Fiji'},
			{ value:'FI',label:'Finnish'},
			{ value:'FR',label:'French'},
			{ value:'KA',label:'Georgian'},
			{ value:'DE',label:'German'},
			{ value:'EL',label:'Greek'},
			{ value:'GU',label:'Gujarati'},
			{ value:'HE',label:'Hebrew'},
			{ value:'HI',label:'Hindi'},
			{ value:'HU',label:'Hungarian'},
			{ value:'IS',label:'Icelandic'},
			{ value:'ID',label:'Indonesian'},
			{ value:'GA',label:'Irish'},
			{ value:'IT',label:'Italian'},
			{ value:'JA',label:'Japanese'},
			{ value:'JW',label:'Javanese'},
			{ value:'KO',label:'Korean'},
			{ value:'LA',label:'Latin'},
			{ value:'LV',label:'Latvian'},
			{ value:'LT',label:'Lithuanian'},
			{ value:'MK',label:'Macedonian'},
			{ value:'MS',label:'Malay'},
			{ value:'ML',label:'Malayalam'},
			{ value:'MT',label:'Maltese'},
			{ value:'MI',label:'Maori'},
			{ value:'MR',label:'Marathi'},
			{ value:'MN',label:'Mongolian'},
			{ value:'NE',label:'Nepali'},
			{ value:'NO',label:'Norwegian'},
			{ value:'FA',label:'Persian'},
			{ value:'PL',label:'Polish'},
			{ value:'PT',label:'Portuguese'},
			{ value:'PA',label:'Punjabi'},
			{ value:'QU',label:'Quechua'},
			{ value:'RO',label:'Romanian'},
			{ value:'RU',label:'Russian'},
			{ value:'SM',label:'Samoan'},
			{ value:'SR',label:'Serbian'},
			{ value:'SK',label:'Slovak'},
			{ value:'SL',label:'Slovenian'},
			{ value:'ES',label:'Spanish'},
			{ value:'SW',label:'Swahili'},
			{ value:'SV',label:'Swedish '},
			{ value:'TA',label:'Tamil'},
			{ value:'TT',label:'Tatar'},
			{ value:'TE',label:'Telugu'},
			{ value:'TH',label:'Thai'},
			{ value:'BO',label:'Tibetan'},
			{ value:'TO',label:'Tonga'},
			{ value:'TR',label:'Turkish'},
			{ value:'UK',label:'Ukrainian'},
			{ value:'UR',label:'Urdu'},
			{ value:'UZ',label:'Uzbek'},
			{ value:'VI',label:'Vietnamese'},
			{ value:'CY',label:'Welsh'},
			{ value:'XH',label:'Xhosa'}
	  ],	  
	  seleFileName:'',
	  seleFilelocation:'',
	  selectfile :true,
	  selectAllfile :false,
	  docTypeDrop:[],
	  docTypeDropSelected:'',
	  meta_json:[],
	  field_json:[],
	  metalist:[],
	  ginfo:false,
	  stDIssue:'',
	  enDIssue:'',
	  countryID:'',
	  OtherFileGroup:''
      }
	  this.onChangeSelect = this.onChangeSelect.bind(this);
	  this.updateValueGroup = this.updateValueGroup.bind(this)
	 // this.updateValueCountry = this.updateValueCountry.bind(this)
	  this.updateValuelang = this.updateValuelang.bind(this);
	  this.toggleModal = this.toggleModal.bind(this);
	  this.updatedocTypeDrop = this.updatedocTypeDrop.bind(this)
  }
  collapse = (e) =>{
	this.setState({ginfo: false})
  }
  openginfo = (e) =>{
	this.setState({ginfo: !this.state.ginfo})
  }
  selectNFGAto(e,id,name,index){    
    this.state.metalist[index].value = id;
    this.state.metalist[index].valueLabel = name;
    this.state.mfgID = id;
    this.state.mfgNameen = name;
    this.state.mfgId = id;
    this.setState({
      metalist: this.state.metalist
  })
  let getid = this.state.metalist[index].key
  document.getElementById(getid).classList.add('d-none');
  //console.log(this.state.metalist)
  }
  onChangeAutoSearch = (e,index) => {
    this.state.metalist[index].valueLabel = e.target.value;
    this.state.metalist[index].option = [];
    this.setState({metalist: this.state.metalist});
   // console.log(this.state.mfgNameen.length);
   let apiurl = this.state.metalist[index].api;
    if(this.state.metalist[index].valueLabel.length>1){   
      document.getElementById("loader").classList.add("d-block");
          fetch(process.env.REACT_APP_ENV+apiurl, {       
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization':'bearer '+cookies.get("auth")
     
            },
            body: JSON.stringify({
             MfgName: e.target.value,
             mfgId:0
           })
         })
         .then((response) => response.json())
         .then((responseJson) => {
           document.getElementById("loader").classList.remove("d-block");
           let tempData = responseJson.data;
           const timestamp = new Date().getTime();
           const expireauth = timestamp + 1800000;
           const exdate =  new Date(expireauth);
           cookies.set('auth', responseJson.status.authtoken, { path: '/'});  
           this.state.metalist[index].option = tempData
            this.setState({
              metalist: this.state.metalist,
          })
          let getid = this.state.metalist[index].key;
        document.getElementById(getid).classList.remove('d-none')
       //   console.log(this.state.metalist[index].option)
         
         })
         .catch(error =>{
           console.log(error);          
           document.getElementById("loader").classList.remove("d-block");
         })
        
    }
  }
  updateValuedrop = (selectName,selectedOption) =>{
    let gindex = parseInt(this.name);
   
    this.state.metalist[selectName].selectedOptiongDrop = selectedOption; 
    this.state.metalist[selectName].valueLabel = selectedOption.label;
    this.state.metalist[selectName].value = selectedOption.value;
    this.setState({
      metalist: this.state.metalist
  })
  }
  updatedocTypeDrop(newValue){
	this.state.docTypeDropSelected = newValue;
	let field_json = [];
	
	//if(newValue.value !== 1){
		let fj = this.state.docType.filter(docType => docType.id == newValue.value);
		field_json = JSON.parse(fj[0].field_json);
	//}
	if(this.state.filesAccess.length){
		document.getElementById('selectedFile').value = null;
	}
    this.setState({
	  docTypeDropSelected: this.state.docTypeDropSelected,
	  filesAccess:[],
	  field_json:field_json
	});
	
	let ArrMetaList = [];
	field_json.forEach((ml,index,arr) => { 
       if(ml.type === '1'){
        ArrMetaList.push({
          api: ml.api,
          document_type_id: ml.document_type_id,
          error: ml.error,
          id: ml.id,
          key: ml.key,
          label: ml.label,
          mandatroy: ml.mandatroy,
          type: ml.type,
          value:'',
          option:[],
          selectedOptiongDrop:[],
          valueLabel:''
        })
        
       }
       if(ml.type === '2'){
        ArrMetaList.push({
          api: ml.api,
          document_type_id: ml.document_type_id,
          error: ml.error,
          id: ml.id,
          key: ml.key,
          label: ml.label,
          mandatroy: ml.mandatroy,
          type: ml.type,
          value:'',
          option:[],
          selectedOptiongDrop:[],
          valueLabel:''
        })
        document.getElementById('loader').classList.add('d-block'); 
        fetch(process.env.REACT_APP_ENV+ml.api,{
             method: 'POST',
             headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json',
               'Authorization':'bearer '+cookies.get("auth")
               
             },
             body: JSON.stringify({ 
            })
             })
           .then((response) => response.json())
           .then((responseJson) => {
             let tempData = responseJson;  
           //  console.log(tempData)   
             if(tempData){
                  // let exdate = new Date();
                  // cookies.set('auth', tempData.status.authtoken, { path: '/',expires: new Date(Date.now()+2592000) }); 
                 // console.log(tempData.replace(/'/g,"'");
                //  let optionsold = JSON.parse(tempData.replace(/'/g,'"'));
				//  let options = [{'value':'','label':'----'}, ...optionsold]
				let options = tempData.data;
                 let gindex = index;
                 let val = ml.value;
                 this.state.metalist[gindex].option = options;
                 this.state.metalist[gindex].selectedOptiongDrop = options.filter(option => option.value === val);
                 this.setState({
                      metalist:this.state.metalist
                 })
                                 
                  document.getElementById('loader').classList.remove('d-block'); 
             }else{
                  toast.error('Error ocured. Please Try Again');
                  document.getElementById('loader').classList.remove('d-block'); 
             }
              
            
           })
           .catch(error =>{
             console.log(error); 
             document.getElementById('loader').classList.remove('d-block'); 
           }) 
        
       }
       
     })
     this.setState({
      metalist:ArrMetaList
       })
	
	   if(this.state.docTypeDropSelected.value !== 1){
		fetch(process.env.REACT_APP_ENV+'/api/Document/GetGroupList',{
			method: 'POST',
			headers: {
			  'Accept': 'application/json',
			  'Content-Type': 'application/json',
			  'Authorization':'bearer '+cookies.get("auth")          
			},
			body: JSON.stringify({  
				  cid : cookies.get("cid"),
				  category:this.state.docTypeDropSelected.value,
				  sortColumn:'',
		  })
			})
		  .then((response) => response.json())
		  .then((responseJson) => {
			let tempData = responseJson; 
		  // console.log(tempData)   
		  const timestamp = new Date().getTime();
					const expireauth = timestamp + 1800000;
					const exdate =  new Date(expireauth);
					cookies.set('auth', tempData.status.authtoken, { path: '/'});
			if(tempData.data === null){
			  toast.error('Error ocured. Please Try Again')
			  }else{
				  this.state.OtherFileGroup = tempData.data[0],
				this.setState({
					OtherFileGroup: this.state.OtherFileGroup,
			  })
			 // console.log(this.state.OtherFileGroup)
			  
			}
		  
		  
		  })
		  .catch(error =>{
			console.log(error); 
		  })
	}
	
	//activeTab
    //this.getGroupList()
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }
  toggleModal(){
    this.setState({
        modal: !this.state.modal
    });
   // console.log( 'after setState: ', this.state );
}
  
  componentDidMount(){
	this.state.tbheight = document.body.clientHeight - 320;
	let langindex =  this.state.langlist.findIndex(option => option.value ==='EN'); 
	this.state.selectedOptionglang = this.state.langlist[langindex]
	
	this.setState({
		 tbheight: this.state.tbheight,
		 selectedOptionglang: this.state.selectedOptionglang
	  });
	if(cookies.get("cid") === '1'){
		this.setState({
			activeTab: '2',
		 })
	  }
	  
	document.getElementById('loader').classList.add('d-block'); 
	fetch(process.env.REACT_APP_ENV+'/api/Document/GetGroupList_UnTrained',{
	   method: 'POST',
	   headers: {
		 'Accept': 'application/json',
		 'Content-Type': 'application/json',
		 'Authorization':'bearer '+cookies.get("auth")
		 
	   },
	   body: JSON.stringify({  
			 cid : cookies.get("cid"),
			 category:1
	  })
	   })
	 .then((response) => response.json())
	 .then((responseJson) => {
	   let tempData = responseJson; 
	  // console.log(tempData)   
	  let exdate = new Date();
	   cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
	   if(tempData.data === null){
		 toast.error('Error ocured. Please Try Again')
			 
		   // console.log(this.state.grouplist)
		  }else{
			let storedata = [];
			let tempDatas = responseJson.data; 
			for (var i = 0; i < tempDatas.length; i++) {
				storedata.push({
				  value: tempDatas[i].id, 
				  label:  tempDatas[i].groupname,
			  })
				
			  }
		   this.setState({
			groupList: [...this.state.groupList, ...storedata ],
			groupListAll:tempDatas
		 })
		// console.log(this.state.groupList)
		}
	  document.getElementById('loader').classList.remove('d-block'); 
	  
	 })
	 .catch(error =>{
	   console.log(error); 
	 })
	 document.getElementById('loader').classList.add('d-block'); 
	 fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocumentTypeList',{
		  method: 'POST',
		  headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization':'bearer '+cookies.get("auth")
			
		  },
		  body: JSON.stringify({ 
		  
		 })
		  })
		.then((response) => response.json())
		.then((responseJson) => {
		  let tempData = responseJson;  
		//  console.log(tempData)   
		  if(tempData.status.statusMessage === 'Success'){
			   let exdate = new Date();
			   cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
			   this.state.docType =  tempData.data;
			   let storedata = [];
			   storedata.push({value:'',label:'Select'});
			   for (var i = 0; i < tempData.data.length; i++) {
						   storedata.push({
							 value: tempData.data[i].id, 
							 label:  tempData.data[i].doctype_name,
						   })
						  
				}
			   this.setState({
				 docTypeDrop:storedata,
				 docTypeDropSelected:storedata[0],
				 docType: this.state.docType
			   })
			   
			  // console.log(this.state.docTypeDropSelected)
			   document.getElementById('loader').classList.remove('d-block'); 
		  }else{
			   toast.error('Error ocured. Please Try Again');
			   document.getElementById('loader').classList.remove('d-block'); 
		  }
		 
		 
		})
		.catch(error =>{
		  console.log(error); 
		  document.getElementById('loader').classList.remove('d-block'); 
		}) 
   }
  onChangeSelect= (e) =>{
    this.setState({[e.target.name]: e.target.value});
   // console.log(this.state.mfgcoun+','+this.state.mfglang)
  }
  addtodstudio = (e,id) =>{
	toast.success('SDS Added');
	this.state.setModal(!this.state.modal)
  }
  onChange=event=> {
	this.state.mfgNameen = event.target.value;
	if(this.state.mfgNameen == ''){
		this.state.mfgID = ''
		this.setState({
			mfgID: this.state.mfgID
		})
	}
    this.setState({
		mfgNameen: this.state.mfgNameen
	})
   // console.log(this.state.mfgNameen.length);
    if(this.state.mfgNameen.length>1){
      this.setState({mfgNameList:[]})
      document.getElementById("loader").classList.add("d-block");
          fetch(process.env.REACT_APP_ENV+'/api/Common/SearchManufacturer', {       
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization':'bearer '+cookies.get("auth")
     
            },
            body: JSON.stringify({
              MfgName: this.state.mfgNameen,
           })
         })
         .then((response) => response.json())
         .then((responseJson) => {
           document.getElementById("loader").classList.remove("d-block");
           let tempData = responseJson.data;
		   this.state.mfgNameList = tempData;
            this.setState({
              mfgNameList: this.state.mfgNameList,
          })
        document.getElementById('mfglist').classList.remove('d-none')
         // console.log(this.state.mfgNameList)
         
         })
         .catch(error =>{
           console.log(error);          
           document.getElementById("loader").classList.remove("d-block");
         })
        
    }else{
		this.setState({
			mfgId: '',
		})
	}
  }
  csSelect=event=>{
	this.state.csdrop = event.target.value;
	this.setState({
		csdrop: this.state.csdrop,
		cstext:'',
		csPname:'',
		csmname:'',
		csCAS:'',
		csPcode:''
	});
	
  }
  cstext=event=>{
	this.state.cstext = event.target.value;
    this.setState({cstext: this.state.cstext})
  }
  csPname=event=>{
	this.state.csPname = event.target.value;
    this.setState({csPname: this.state.csPname})
  }
  csmname=event=>{
	this.state.csmname = event.target.value;
    this.setState({csmname: this.state.csmname})
  }
  csCAS=event=>{
	this.state.csCAS = event.target.value;
    this.setState({csCAS: this.state.csCAS})
  }
  csPcode=event=>{
	this.state.csPcode = event.target.value;
    this.setState({csPcode: this.state.csPcode})
  }
  checkMimeType=(event)=>{
    
    //getting file object
    let files = event.target.files;
    //define message container
    let err = []
    // list allow mime type
   const types = ['application/pdf','image/jpeg','image/png']
    // loop access array
    for(var x = 0; x<files.length; x++) {   
     // compare file type find doesn't matach
         if (types.every(type => files[x].type !== type)) {
         // create error message and assign to container   
         err[x] = files[x].type+' is not a supported format\n';
       }
     };
     for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
         // discard selected file
        toast.error(err[z])
        event.target.value = null
    }
   return true;
  }
  maxSelectFile=(event)=>{
    let files = event.target.files;
        if (files.length > 20) { 
           const msg = 'Only 10 file can be uploaded at a time'
           event.target.value = null
           toast.warn(msg)
           return false;
      }
    return true;
 }
 checkFileSize=(event)=>{
  let files = event.target.files;
  let size = 500000000 
  let err = []; 
  for(var x = 0; x<files.length; x++) {
  if (files[x].size > size) {
   err[x] = files[x].type+'is too large, please pick a smaller file\n';
 }
};
for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
  // discard selected file
 toast.error(err[z])
 event.target.value = null
}
return true;
}
onChangeHandler=event=>{
  var files = event.target.files
  if(this.maxSelectFile(event) && this.checkMimeType(event) &&    this.checkFileSize(event)){ 
  // if return true allow to setState
  //console.log(files)
  this.state.selectedFile = files;
  const data = [];
    for (let i = 0; i < event.target.files.length; i++) {
		if(this.validateName(event.target.files[i].name)){
			event.target.files[i].validate = true
			data.push(event.target.files[i]);
		}else{
			event.target.files[i].validate = false
			data.push(event.target.files[i]);
		}
      
    }
     this.setState({
     selectedFile: this.state.selectedFile,
	 filesAccess:data
  })
} 
//console.log(this.state.selectedFile.FileList)
} 
    onClickHandlerg = () => {		
		let docid = this.state.docTypeDropSelected.value;
		if(docid){
			if(docid === 1){
				let error = false;
				let groupname = this.state.selectedOptiongroup.value;
				if(!groupname){
					toast.error('Please Select Group.')
					error = true
				}
				if(error === false){
					for(var x = 0; x<this.state.filesAccess.length; x++) {
						document.getElementById("loader").classList.add("d-block");
						const formData = new FormData();
					//	this.state.meta_json.forEach((ml,index,arr) => { 
							//formData.append(ml.key, ml.value)
						//})
						formData.append('field_json', JSON.stringify(this.state.meta_json))
						formData.append('document_type', parseInt(this.state.docTypeDropSelected.value))
						 formData.append('file', this.state.filesAccess[x])
						 ///formData.append('MFGId', this.state.mfgId);
						 //formData.append('MFGName', 'Sigma');
						// formData.append('Lang', this.state.mfglang);
						 formData.append('groupid', this.state.selectedOptiongroup.value.toString());
						// formData.append('Country', this.state.selectedOptioncountry.value);
							   fetch(process.env.REACT_APP_ENV+'/api/Document/UploadDocs', {  
								headers: {
										   'Authorization':'bearer '+cookies.get("auth")						   
										 },     
				   method: 'POST',
				  body: formData
				})
				.then((response) => response.json())
				.then((responseJson) => {
				  toast.success('Upload success');
				  document.getElementById("loader").classList.remove("d-block");
				  this.setState({
					filesAccess: [],
					// mfgNameen:'',
					// selectedOptioncountry:'',
					selectedOptiongroup:''
				 })
				 document.getElementById('selectedFile').value = null;
				// console.log(responseJson);
				})
				.catch(error =>{
				  console.log(error);
				  toast.error('upload fail');
				  document.getElementById("loader").classList.remove("d-block");
				  this.setState({
					selectedFile: null,
					filesAccess:[],
					//mfgNameen:'',
					//selectedOptioncountry:'',
					selectedOptiongroup:''
				 })
				 document.getElementById('selectedFile').value = null;
				})
						 for (var value of formData.values()) {
						 // console.log(value); 
					   }
					   }
				}
			}else{
				//if(this.state.OtherFileGroup){
				//	let groupid = this.state.OtherFileGroup.id.toString();
					//let meta_json = this.state.OtherFileGroup.field_json;
					document.getElementById('loader').classList.add('d-block'); 
					for(var x = 0; x<this.state.filesAccess.length; x++) {					
						const formData = new FormData();					
						 formData.append('file', this.state.filesAccess[x])
						  formData.append('groupid', 0);
						//formData.append('groupid', null);
						 formData.append('document_type', parseInt(this.state.docTypeDropSelected.value))
						 formData.append('field_json', '')
						// console.log(formData.values())					
						// formData.append('Country', this.state.selectedOptioncountry.value);
						document.getElementById("loader").classList.add("d-block");
							   fetch(process.env.REACT_APP_ENV+'/api/Document/UploadDocs', {  
								headers: {
										   'Authorization':'bearer '+cookies.get("auth")						   
										 },     
				   method: 'POST',
				  body: formData
				})
				.then((response) => response.json())
				.then((responseJson) => {
				  toast.success('Upload success');
				 
				  this.setState({
					selectedFile: null,
					filesAccess:[],
					// mfgNameen:'',
					// selectedOptioncountry:'',
					selectedOptiongroup:''
				 })
				 document.getElementById('selectedFile').value = null;
				 document.getElementById("loader").classList.remove("d-block");
				})
				.catch(error =>{
				  console.log(error);
				  toast.error('upload fail');
				  document.getElementById("loader").classList.remove("d-block");
				  this.setState({
					selectedFile: null,
					filesAccess:[],
					//mfgNameen:'',
					//selectedOptioncountry:'',
					selectedOptiongroup:''
				 })
				 document.getElementById('selectedFile').value = null;
				})
					// 	 for (var value of formData.values()) {
					// 	  console.log(value); 
					//    }
					   }
		// 		}else{
		// 			var d  = new Date().getTime();
		// 		//var datestring = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + "_" +d.getHours() + ":" + d.getMinutes()+ ":" + d.getSeconds();
		// 		let groupname = cookies.get("cid")+'_'+cookies.get("userid")+'_'+d;
		// 		document.getElementById('loader').classList.add('d-block'); 
        //   fetch(process.env.REACT_APP_ENV+'/api/Document/AddGroup',{
        //        method: 'POST',
        //        headers: {
        //          'Accept': 'application/json',
        //          'Content-Type': 'application/json',
        //          'Authorization':'bearer '+cookies.get("auth")
                 
        //        },
        //        body: JSON.stringify({              
        //         groupname : groupname,
        //         category:docid,
        //         field_json: '[]',
        //         mfgId : 0,
        //         langId:"",                 
        //         cid : cookies.get("cid"),
        //         userid : cookies.get("userid"), 
        //         status : 1, 
        //         workflow_status : 0,
        //         created_by : cookies.get("created_by"),
        //         created_date : new Date()
        //       })
        //        })
        //      .then((response) => response.json())
        //      .then((responseJson) => {
        //        let tempData = responseJson; 
        //        //console.log(tempData.data[0].id)   
        //         if(tempData.status.statusMessage === 'Success'){
		// 		  document.getElementById("loader").classList.remove("d-block");
        //           const timestamp = new Date().getTime();
        //           const expireauth = timestamp + 1800000;
		// 		  const exdate =  new Date(expireauth);
		// 		  let groupid = tempData.data.id.toString();
		// 		  console.log(tempData.data.id)
		// 		  cookies.set('auth', tempData.status.authtoken, { path: '/'});    
		// 		  let meta_json = [];
		// 			this.state.field_json.forEach((ml,index,arr) => {
		// 			meta_json.push({
		// 			api: ml.api,
		// 			document_type_id: ml.document_type_id,
		// 			error: ml.error,
		// 			id: ml.id,
		// 			key: ml.key,
		// 			label: ml.label,
		// 			mandatroy: ml.mandatroy,
		// 			type: ml.type,
		// 			value:ml.value,
		// 			}) 
		// 		})
		// 		  for(var x = 0; x<this.state.selectedFile.length; x++) {					
		// 			const formData = new FormData();					
		// 			 formData.append('file', this.state.selectedFile[x])
		// 			 formData.append('groupid', groupid);
		// 			 formData.append('document_type', parseInt(this.state.docTypeDropSelected.value))
		// 			 formData.append('field_json', JSON.stringify(this.state.meta_json))
		// 			// console.log(formData.values())					
		// 			// formData.append('Country', this.state.selectedOptioncountry.value);
		// 			document.getElementById("loader").classList.add("d-block");
		// 				   fetch(process.env.REACT_APP_ENV+'/api/Document/UploadDocs', {  
		// 					headers: {
		// 							   'Authorization':'bearer '+cookies.get("auth")						   
		// 							 },     
		// 	   method: 'POST',
		// 	  body: formData
		// 	})
		// 	.then((response) => response.json())
		// 	.then((responseJson) => {
		// 	  toast.success('Upload success');
			 
		// 	  this.setState({
		// 		selectedFile: null,
		// 		// mfgNameen:'',
		// 		// selectedOptioncountry:'',
		// 		selectedOptiongroup:''
		// 	 })
		// 	 document.getElementById('selectedFile').value = null;
		// 	 document.getElementById("loader").classList.remove("d-block");
		// 	})
		// 	.catch(error =>{
		// 	  console.log(error);
		// 	  toast.error('upload fail');
		// 	  document.getElementById("loader").classList.remove("d-block");
		// 	  this.setState({
		// 		selectedFile: null,
		// 		//mfgNameen:'',
		// 		//selectedOptioncountry:'',
		// 		selectedOptiongroup:''
		// 	 })
		// 	 document.getElementById('selectedFile').value = null;
		// 	})
		// 		// 	 for (var value of formData.values()) {
		// 		// 	  console.log(value); 
		// 		//    }
		// 		   }
        //           }else{
        //              toast.error('Error ocured. Please Try Again');
        //              document.getElementById('loader').classList.remove('d-block'); 
        //         }
              
              
        //      })
        //      .catch(error =>{
        //        console.log(error); 
        //      })
		// 		}
				
				
				
        }
			}
		
			
	//	return false;
	
	
		
      }
   
      
    keyUpHandler(refName, e) {
     // console.log(refName);
      // prints either LoginInput or PwdInput
  }
  goBack() {
    window.history.back();
  }

  updateValueGroup(newValue) {	  
//	console.log(newValue.value)
	this.setState({
		meta_json:[]
		 })
	const groupdata = this.state.groupListAll.filter(group => group.id == newValue.value);
	let meta_json = groupdata[0].field_json;
	let mmeta_json = [];
	if(meta_json){
		mmeta_json = JSON.parse(meta_json);
		let ArrMetaList = [];
		mmeta_json.forEach((ml,index,arr) => { 
                if(ml.type === '1'){
                  ArrMetaList.push({
                    api: ml.api,
                    document_type_id: ml.document_type_id,
                    error: ml.error,
                    id: ml.id,
                    key: ml.key,
                    label: ml.label,
                    mandatroy: ml.mandatroy,
                    type: ml.type,
                    value:ml.value,
                    option:[],
                    selectedOptiongDrop:[],
                    valueLabel:''
                  })
                  fetch(process.env.REACT_APP_ENV+ml.api,{
                    method: 'POST',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization':'bearer '+cookies.get("auth")
                      
                    },
                    body: JSON.stringify({              
                      MfgName:'',
                      mfgId:ml.value
                   })
                    })
                  .then((response) => response.json())
                  .then((responseJson) => {
                    let tempData = responseJson; 
                   // console.log(tempData.data[0].companyName)   
                     if(tempData.status.statusMessage === 'Success'){
                      const timestamp = new Date().getTime();
                      const expireauth = timestamp + 1800000;
                      const exdate =  new Date(expireauth);
                      cookies.set('auth', tempData.status.authtoken, { path: '/'});   
                       let gindex = index;
					   this.state.meta_json[gindex].valueLabel = tempData.data[0].mnfName;
					   if(ml.key == 'mfgId'){
						this.state.mfgNameen = tempData.data[0].mnfName;
						this.state.mfgID = ml.value;
                       this.setState({
						meta_json:this.state.meta_json,
						mfgNameen:this.state.mfgNameen,
						mfgID:this.state.mfgID
						 })
						 }else{
							this.setState({
								meta_json:this.state.meta_json
						 })
						 }
						 
                       }else{
                          toast.success('Error ocured. Please Try Again')
                     }
                     
                   
                  })
                  .catch(error =>{
                    console.log(error); 
                  })
                 
                 
                }
                if(ml.type === '2'){
                  ArrMetaList.push({
                    api: ml.api,
                    document_type_id: ml.document_type_id,
                    error: ml.error,
                    id: ml.id,
                    key: ml.key,
                    label: ml.label,
                    mandatroy: ml.mandatroy,
                    type: ml.type,
                    value:ml.value,
                    option:[],
                    selectedOptiongDrop:'',
                    valueLabel:''
                  })  
                 document.getElementById('loader').classList.add('d-block'); 
                 fetch(process.env.REACT_APP_ENV+ml.api,{
                      method: 'POST',
                      headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':'bearer '+cookies.get("auth")
                        
                      },
                      body: JSON.stringify({ 
                     })
                      })
                    .then((response) => response.json())
                    .then((responseJson) => {
                      let tempData = responseJson;  
                    //  console.log(tempData)   
                      if(tempData){
                           // let exdate = new Date();
                           // cookies.set('auth', tempData.status.authtoken, { path: '/',expires: new Date(Date.now()+2592000) }); 
                          // console.log(tempData.replace(/'/g,"'");
                           let options = tempData.data;
                           let gindex = index;
                           let val = ml.value;
                           this.state.meta_json[gindex].option = options;
						   this.state.meta_json[gindex].selectedOptiongDrop = options.filter(option => option.value === val);
						   let valulabel = options.filter(option => option.value === val);
						   this.state.meta_json[gindex].valueLabel = valulabel[0].label;
						   if(ml.key == 'langId'){
							let langindex =  this.state.langlist.findIndex(option => option.value === ml.value); 
							this.state.selectedOptionglang = this.state.langlist[langindex]
							this.setState({
								selectedOptionglang: this.state.selectedOptionglang
							   })
						   }
						   if(ml.key == 'countrycode'){
							this.state.countryID = ml.value;
							this.setState({
								countryID: this.state.countryID
							   })
						   }
							this.setState({
								meta_json:this.state.meta_json
							   })
						  
                           
                                          
                           document.getElementById('loader').classList.remove('d-block'); 
                      }else{
                           toast.error('Error ocured. Please Try Again');
                           document.getElementById('loader').classList.remove('d-block'); 
                      }
                       
                     
                    })
                    .catch(error =>{
                      console.log(error); 
                      document.getElementById('loader').classList.remove('d-block'); 
                    }) 
                 
                }
                
              })
              this.setState({
                meta_json:ArrMetaList
                 })
	}
	
	this.setState({
		selectedOptiongroup: newValue,
		
  });
 
 }
//  updateValueCountry(newValue) {
//    this.setState({
// 	selectedOptioncountry: newValue
//  });
// }
validateName=(name)=> {
	var re = /^[\w,\s-]+\.[A-Za-z]{3}$/g;
	return re.test(name);
  }
updateValuelang(newValue) {
	//console.log(newValue);
	this.state.selectedOptionglang = newValue
	this.setState({
		selectedOptionglang: this.state.selectedOptionglang
  });
  console.log(this.state.selectedOptionglang.value)
  //	this.addFromCs()

 }
 selectNFG(e,id,name){    
    this.state.mfgID = id;
    this.state.mfgNameen = name;
    this.state.mfgId = id;
    this.setState({
      mfgID: this.state.mfgID,
      mfgNameen:this.state.mfgNameen,
      mfgId:this.state.mfgId
  })
  document.getElementById('mfglist').classList.add('d-none')
  console.log(this.state.mfgNameen+this.state.mfgID)
  //this.addFromCs()
  }
  addFromCs = () =>{		 
	this.setState({
		data: [],
	 })
	document.getElementById('loader').classList.add('d-block'); 
	fetch(process.env.REACT_APP_ENV+'/api/common/GetCSDocList',{
	   method: 'POST',
	   headers: {
		 'Accept': 'application/json',
		 'Content-Type': 'application/json',
		 'Authorization':'bearer '+cookies.get("auth")
		 
	   },
	   body: JSON.stringify({  
		langId : this.state.selectedOptionglang.value,
		mfgId:this.state.mfgID.toString(),
		startIssueDate:this.state.stDIssue,
		endIssueDate:this.state.enDIssue,
		countryID:this.state.countryID
	  })
	   })
	 .then((response) => response.json())
	 .then((responseJson) => {
	   let tempData = responseJson; 
	  // console.log(tempData)   
	  let exdate = new Date();
	   cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
	   if(tempData.status.statusMessage == 'Success'){
		if(!tempData.data.length){
			toast.error('No Record Found.')
				
			  // console.log(this.state.grouplist)
			 }else{		
			   let storedata = [];
			   for (var i = 0; i < tempData.data.length; i++) {
				 storedata.push({
				   id: tempData.data[i].id, 
				   is_GHS:  tempData.data[i].is_GHS,
				   msdS_FileName:tempData.data[i].msdS_FileName,
				   msdS_ID:tempData.data[i].msdS_ID,
				   msdS_Issue_Date:tempData.data[i].msdS_Issue_Date,
				   msdS_Product_Name:tempData.data[i].msdS_Product_Name,
				   msdS_S3_Path:tempData.data[i].msdS_S3_Path,
				   is_checked:false
			   })	
		   }
			  this.setState({
			   data: storedata,
			})
			//console.log(this.state.data)
		   }
		   document.getElementById('loader').classList.remove('d-block'); 
	   }else{
		toast.error('Error ocured.Please try again later.')
		document.getElementById('loader').classList.remove('d-block'); 
	   }
	   
	 
	  
	 })
	 .catch(error =>{
	   console.log(error); 
	   document.getElementById('loader').classList.remove('d-block'); 
	 })
  }
  addFileToQueueListFinal = () =>{
	let storedata = [];
	let meta = [];
	this.state.meta_json.forEach((ml,index) => { 
		let tempMeta = ml;
		tempMeta.option = [];
		meta.push(tempMeta)
	})
	
			for (var i = 0; i < this.state.data.length; i++) {
				if(this.state.data[i].is_checked == true){
					storedata.push({
						DocID: 0, 
			FileName: this.state.data[i].msdS_FileName, 
			field_json:meta,
			// MFGID:parseInt(this.state.mfgID),
			// LangID:this.state.selectedOptionglang.value,
			// Country:this.state.selectedOptioncountry.value,
			groupId:parseInt(this.state.selectedOptiongroup.value),
			FileLocation: this.state.data[i].msdS_S3_Path,
			category:this.state.docTypeDropSelected.value
					})
				}
			}
	document.getElementById('loader').classList.add('d-block'); 
	fetch(process.env.REACT_APP_ENV+'/api/tagv2/AddDocFromCS',{
	   method: 'POST',
	   headers: {
		 'Accept': 'application/json',
		 'Content-Type': 'application/json',
		 'Authorization':'bearer '+cookies.get("auth")		 
	   },
	   body: JSON.stringify(storedata)
	   })
	 .then((response) => response.json())
	 .then((responseJson) => {
	   let tempData = responseJson; 
	  // console.log(tempData)   
	  let exdate = new Date();
	   if(responseJson.status){
		if(responseJson.status.statusMessage === 'Success'){
			toast.success('File added Successfully')
		   cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
		   }
	   }else{
		toast.error('Error ocured.Please try again later')
	   }
	   
		  document.getElementById('loader').classList.remove('d-block'); 
	  
	 })
	 .catch(error =>{
	   console.log(error); 
	   toast.error(error)
	   document.getElementById('loader').classList.remove('d-block'); 
	 })
	// this.setState({
    //     modal: !this.state.modal
    // });
  }
  addFileToQueueList = () =>{
	this.setState({
		modal:true,
		fade:true
	})
  }
  fileSelect = (e,index) =>{
	if(this.state.selectAllfile === false){	
			this.state.data[index].is_checked =  !this.state.data[index].is_checked;			
			this.setState({
				data:this.state.data
			  })
	}else{
		this.state.data[index].is_checked =  !this.state.data[index].is_checked
		this.setState({
			selectAllfile:false,
			data:this.state.data
		  })
	}
	this.state.selectfile = true;
	for (var i = 0; i < this.state.data.length; i++) {
		if(this.state.data[i].is_checked == true){
			this.state.selectfile = false
		}
	}
	this.setState({
		selectfile:this.state.selectfile,
	})
  }
  selectAllfile = (e) =>{	
		  if(this.state.selectAllfile === false){
			for (var i = 0; i < this.state.data.length; i++) {
				this.state.data[i].is_checked = true 
			}
			this.setState({
				selectAllfile:true,
				data:this.state.data
			  })
		  }else{
			for (var i = 0; i < this.state.data.length; i++) {
				this.state.data[i].is_checked = false
			}
			this.setState({
				selectAllfile:false,
				data:this.state.data
			  })
		  }
		  this.state.selectfile = true;
	for (var i = 0; i < this.state.data.length; i++) {
		if(this.state.data[i].is_checked == true){
			this.state.selectfile = false
		}
	}
	this.setState({
		selectfile:this.state.selectfile,
	})
  }
  render() {
	  let invalidFIle = false
	  let ifFile = false;
	  let ifuploadEnable = false;
	  if(this.state.filesAccess.length){
		ifFile = true
		this.state.filesAccess.forEach ((s,index) => {
			if(s.validate===false){
				invalidFIle = true
			}
		  })
	  }
	  if(ifFile && !invalidFIle){
		ifuploadEnable = true
	  }
	  let detectCloudsds = '';
	  let activatetabUD = false;
	  let activatetabCS = false;
	  let doctype = this.state.docTypeDropSelected.value;
	  if(cookies.get("cid") === '1'){
		detectCloudsds = 'cloudsdsactive'
	  }
	  if(doctype === 1 && cookies.get("cid") === '1'){
		activatetabCS = true
	  }
	  if(doctype !== 1 && cookies.get("cid") === '1' || cookies.get("cid") !== '1'){
		activatetabUD = true
	  }
   let mfgname = this.state.mfgNameen;
    let mfgNameLists = []; 
   this.state.mfgNameList.forEach ((s,index) => {   
   //  console.log(s.companyName)
    mfgNameLists.push(
            <li onClick={(e) => this.selectNFG(e,s.mnfID,s.mnfName)}  key={index} >{s.mnfName+' ('+s.mnfID+')'}</li>
      )
   })
   const divStyle = {
	height: this.state.tbheight + 'px',
};
let filelist = [];
      this.state.data.forEach ((s,index) => {   
		let ghslbl = '';
		  if(s.is_GHS){
			let ghs = s.is_GHS.trim();
			if(ghs == 'true'){
				ghslbl = 'Yes'
			}else{
				ghslbl = 'No'
			}
		  }
		  
		  let idate = s.msdS_Issue_Date.split("T")[0];
		//  console.log(drawindex)
		filelist.push(
			<Row key={index}>                   
                <Cell><input checked={s.is_checked}  onChange={(e) =>this.fileSelect(e,index)} type='checkbox' name='fileSelect' /></Cell>
                {/* <Cell><button className='btn btn-primary btn-sm'>View</button></Cell> */}
				<Cell>{s.msdS_Product_Name}</Cell>
			<Cell>{idate}</Cell>
			{/* <Cell>{ghs}</Cell>  */}
			<Cell>{s.msdS_FileName}</Cell>
    </Row>
		
	)
	  })

let groupListMeta = [];
let groupListMetaModal = [];
this.state.meta_json.forEach ((gl,index) => {
	let label = gl.label.replace("Search", "").replace("Select", "");
	groupListMeta.push(
		<div key={index} className=''><label><b>{label} :</b> {gl.valueLabel}</label> </div>
	)
	groupListMetaModal.push(
		<div key={index} className=''><label><b>{label} :</b> {gl.valueLabel}</label> </div>
	)
	
})

let metalists = [];
   this.state.metalist.forEach((m,index) => {  
    if(m.type == '1'){
      let options = [];
      var itemsProcessed = 0;
      if(m.option.length){
        m.option.forEach((t,gindex,array) => { 
          //  console.log(t.companyName)
          itemsProcessed++;          
           options.push(
            <li onClick={(e) => this.selectNFGAto(e,t.mnfID,t.mnfName,index)}  key={gindex} >{t.mnfName+' ('+t.mnfID+')'}</li>
           )           
           })
      }
        metalists.push(
          <div key={index} className="col-md-6">
             <div className="form-group ">
             <label>{m.label}</label>
             <input autoComplete="off" type="text" name={m.key}   value={m.valueLabel} onChange={(e) => this.onChangeAutoSearch(e,index)} className="form-control" />
             <div >
             <div className="form-group position-relative">                
                   <ul id={m.key}  className='manudrop d-none'>
                   {options}
                   </ul>
                   </div>
                   </div>
                  
               </div>
          </div>
     )
      
      
     
    }
    if(m.type == '2'){
     metalists.push(
       <div key={index} className="col-md-6">
          <div className="form-group ">
          <label>{m.label}</label>
          <div >
          <Select name={index}        
                 value={m.selectedOptiongDrop}
                 onChange={e => this.updateValuedrop(index,e)}
                 options={m.option}
               />
           
            
            </div>
          
            </div>
       </div>
  )
    }
    
})

    return (     
          
        <div className='h100percent'>
        <div className='main-header'>
        <div className='row'>
	<div className='col-md-6'><h2 className="page-header">Upload File For Training</h2> </div>
            <div className='col-md-6 text-right'>             
              {/* <NavLink  className='btn btn-primary mr-2 mt-2'  title='Tag Queue'  activeClassName={"active"} to="/tag-queue">Tag Queue</NavLink>
               */}
              </div>
          </div>
        </div>
        <div className={detectCloudsds == 'cloudsdsactive'?'cloudsdsactive main-panel':'cloudsdsInactive main-panel'}>
           <div className='content '>
           <div className='container-fluid h100percent'>
          
        <ToastContainer />
        <div className='h100percent text-selection-none bg-white  round-corner-all'>
			<div className='row'>
			<div className='col-md-3' >
        <div className="form-group ">
                 <div >
                 <label>Document Type</label>
         <Select    
         className ='orgindex'            
        value={this.state.docTypeDropSelected}
        onChange={this.updatedocTypeDrop}
        options={this.state.docTypeDrop}
        defaultValue={{ value: '', label: '----' }}
      />
                 </div>
                 </div> 
               </div>
			  {this.state.docTypeDropSelected.value === 1 && activatetabCS &&
			   <div className='col-md-3'>
			   <label>Select Group</label> 
			   <Select
				 className ='orgindexplus' 
				  value={this.state.selectedOptiongroup}
				  onChange={this.updateValueGroup}
				  options={this.state.groupList}
				  defaultValue={{ value: '', label: '----' }}
				/>
				 </div>
				 
				}
				{this.state.docTypeDropSelected.value === 1 && this.state.selectedOptiongroup.value && groupListMeta.length > 0 && activatetabCS && 
			<div className='col-md-4'>
           <div className="form-group ">
        <label>Group Information</label>
        <div className={this.state.ginfo ?'viewgInfo-parent open':'viewgInfo-parent'}>
          <div className='oc'>
            <span onBlur={ this.collapse } onClick={this.openginfo} tabIndex="0" className='tools openinfo'><i class="fas fa-info-circle"></i></span>
            
          </div>
        <div className='bg-light viewgInfo'>{groupListMeta}</div>
        </div>
        
          
        </div>     
        </div> 
}

				{this.state.docTypeDropSelected.value === 1 && activatetabCS && 
				<div className='col-md-2 text-right'>
					<label className='vhidden'>add</label>
					<div>
						{!this.state.selectfile && this.state.selectedOptiongroup.value && 
						<button  onClick={this.addFileToQueueListFinal}  type="button" className='btn btn-primary'><i className="fas fa-plus"></i> Add File</button>
						}
					
					</div>
				</div>
				}
     {this.state.docTypeDropSelected.value && 
				<div className='col-md-12'>
				<Nav tabs>
				{activatetabUD && <NavItem>
            <NavLink
			  className="active"
			  id='uploadfile'>
              Upload file
            </NavLink>
          </NavItem>
  }
           {activatetabCS && <NavItem>
            <NavLink
			id='uploadfile-cloudsds'
              className="active">
              Add file from CS library
            </NavLink>
          </NavItem> }
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane className='d-block' tabId="1">           
		  {activatetabUD && 
		   <div>
		  <div className="row mt-3">
			 <div className="col-md-12">		   
			   {this.state.docTypeDropSelected.value === 1 && 
			   <div className="form-group row ">
				 
				 <div className='col-md-6'>
			   <label>Select Group</label> 
			   <Select				 
				  value={this.state.selectedOptiongroup}
				  onChange={this.updateValueGroup}
				  options={this.state.groupList}
				  defaultValue={{ value: '', label: '----' }}
				/>
				 </div>
				 
			{this.state.docTypeDropSelected.value === 1 && this.state.selectedOptiongroup.value && groupListMeta.length > 0 &&
			<div className='col-md-6'>
           <div className="form-group ">
        <label>Group Information</label>
        <div className={this.state.ginfo ?'viewgInfo-parent open':'viewgInfo-parent'}>
          <div className='oc'>
            <span onBlur={ this.collapse } onClick={this.openginfo} tabIndex="0" className='tools openinfo'><i class="fas fa-info-circle"></i></span>
            
          </div>
        <div className='bg-light viewgInfo'>{groupListMeta}</div>
        </div>
        
          
        </div>     
        </div> 
}
    
				</div>
			   }
			   
		</div>
		</div>
		<div className="row">
		 
		 {this.state.docTypeDropSelected.value !== 1 && metalists}
		 </div>
		 <div className="row">
			 
		 <div className="col-md-6 "> 
		 <div className="form-group files">
				 <input type="file" className="form-control" id='selectedFile' multiple    onChange={this.onChangeHandler}/>
			   </div> 
			   <button disabled={ifuploadEnable?false:true} type="button" className="btn btn-primary btn-lg " onClick={this.onClickHandlerg}>Upload</button>  
			   </div>
			   <div className='col-md-6 h-100 overflow-y-auto'>
				{invalidFIle &&
				<div>
				<h4>Invalid File Name</h4>
				<p>Accepeted characters - A-Z,a-z,0-9, ,.,_,-</p>
				</div>
				}
						{this.state.filesAccess.map((file,index) => (
        <div key={index} className="fileerror">
			{/* {file.validate &&
			<div className='errorfilelist'>{file.name}</div>
			} */}
			{!file.validate &&
			<div className='errorfilelistfalse'>{file.name}</div>
			}
			</div>
      ))}
					
			   </div>

	   
	   
	   </div>
	   </div>
			}
          </TabPane>
          {activatetabCS && <TabPane className='d-block' tabId="2">
           
			<div className="row mt-3">
				
				<div className="col-md-4">
				<div className="form-group "> 
            <label>Search Manufacturer </label>
                <input autoComplete="off" type="text" name='mfgNameen'   value={mfgname} onChange={this.onChange} className="form-control" />
              </div>
              <div className="form-group position-relative">                
                <ul id='mfglist'  className='manudrop d-none'>
                {mfgNameLists}
                </ul>
                </div>
               
						
				</div>
				<div className="col-md-3">					
					<div className="form-group "><label>Select Language</label> <Select	
					className="orgindex"
					//value = {this.state.langlist.filter(option => option.value === 'EN')}			 
		 value={this.state.selectedOptionglang}
		 onChange={this.updateValuelang}
		 options={this.state.langlist}
		 
	   /></div>			
					
						
					
				
				</div>
				
				<div className="col-md-2">
          <FormGroup>
          <Label >Issue Start Date</Label>
          <input type="date" name="stDIssue" rows='5'  value={this.state.stDIssue} onChange={this.onChangeSelect} className='form-control'     placeholder="Issue Start Date" />     
          </FormGroup>
     </div>
    			 <div className="col-md-2">
          <FormGroup>
          <Label >Issue End Date</Label>
          <input type="date" name="enDIssue" rows='5' value={this.state.enDIssue} onChange={this.onChangeSelect}  className='form-control'     placeholder="Issue Start Date" />     
          </FormGroup>
     </div> 
	 <div className="col-md-1">
	 <Label className='vhidden'>Search</Label>
				<Button color="primary" onClick={this.addFromCs}><i className="fas fa-search"></i></Button>
				</div>
				
				<div className='col-md-12'>
					<div  style={divStyle} >
					<div style={{width: '100%', height: '100%'}}>
          <StickyTable leftStickyColumnCount={0} >
            <Row>
			<Cell><input type='checkbox' onChange={this.selectAllfile} checked={this.state.selectAllfile}  name='fileSelectAll' /></Cell>
			{/* <Cell>View</Cell> */}
			  <Cell>Product Name</Cell>
              <Cell className='tagdate-width'>Issue Date</Cell>
              {/* <Cell>GHS</Cell> */}
              <Cell>Filename</Cell>
            </Row>
            {filelist}
            
          </StickyTable>
        </div>
		
	  </div>
				</div>			
							</div>
			
          </TabPane>}
        </TabContent>
				</div>
			}
			
			</div>
	     
	  </div>
    </div>
	</div>
	</div>
	
	
	</div>  
	                         
       
   
                        
    );
  }
};

export default UploadFilesForPrediction;