import React, { Component } from 'react';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import {NavLink} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class ViewDocTypeMeta extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      docType:[],
      cid:'',
      role:''
  };
  }
  componentDidMount(){
    let cid = cookies.get("cid");
      let role = cookies.get("roleid");
      this.setState({
        cid: cid,
        role:role
       })
   document.getElementById('loader').classList.add('d-block'); 
   let url = '/api/Document/GetDocumentTypeList';
   if(this.state.cid === '1' && this.state.role === '1'){
    url = '/api/Document/GetDocumentTypeGridList';
   }
   fetch(process.env.REACT_APP_ENV+url,{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
          
        },
        body: JSON.stringify({ 
        
       })
        })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;  
        console.log(tempData)   
        if(tempData.status.statusMessage === 'Success'){
             let exdate = new Date();
             cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
             this.state.docType =  tempData.data;
             this.setState({
               docType:this.state.docType
             })
        }else{
             toast.error('Error ocured. Please Try Again')
        }
       document.getElementById('loader').classList.remove('d-block'); 
       
      })
      .catch(error =>{
        console.log(error); 
      })
  }
  editgroupg = (e,id) => {
    const timestamp = new Date().getTime();
        const expireauth = timestamp + 1800000;
        const exdate =  new Date(expireauth);
         cookies.set('UpdDocID', id, { path: '/'});  
    this.props.history.push('/update-doc-type') 
    
}
editDocStatus= (e,id,status,index) => {
  console.log(status)
  document.getElementById('loader').classList.add('d-block'); 
  let gstatus = 0; 
              if(status === 1){
                gstatus = 0;
              }else{
                gstatus = 1;
              }
         fetch(process.env.REACT_APP_ENV+'/api/Document/EnableDisableDoctype',{
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'bearer '+cookies.get("auth")
                
              },
              body: JSON.stringify({ 
                Id : parseInt(id),
                status : parseInt(gstatus),
             })
              })
            .then((response) => response.json())
            .then((responseJson) => {
              let tempData = responseJson;  
            //  console.log(tempData)   
              if(tempData.status.statusMessage === 'Success'){
                toast.success('Document Type status successfully Updated')
                const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
              
             this.state.docType[index].status = gstatus;
              this.setState({
                docType:this.state.docType,
              })
             
              }else{
                toast.error('Error ocured.Please try again later.');
               
              }
              document.getElementById('loader').classList.remove('d-block'); 
             
            })
            .catch(error =>{
              console.log(error); 
            })
 // this.props.history.push('/update-doc-type') 
  
}
    goBack() {
        window.history.back();
      }
    render(){
      let userlist = [];
      if(this.state.docType.length){
         this.state.docType.forEach ((s,index) => {   
            let cdate = s.created_date.split("T")[0];
            let mdate = s.modified_date.split("T")[0];
            
            userlist.push(
                       <Row   key={index} >                        
                       <Cell>Manufacturer ID</Cell>
                 <Cell>Autosearch</Cell>
                 <Cell>*required</Cell>
                 <Cell>Yes</Cell>
                 <Cell>Safety Data Sheet</Cell>  
                 <Cell><div>2020-12-31</div></Cell>
                 <Cell><div>2020-12-31</div></Cell>
            
            <Cell>
                <button onClick={(e) => this.editMeta(e,s.id)}  type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-pencil-alt"></i></button>
                <button onClick={(e) => this.delMeta(e,s.id)}  type="button" className="btn btn-danger btn-sm mr-2"><i className="fas  fa-trash-alt"></i></button>
                     
               </Cell>
                       
                       </Row>
               )
            })
      }
     
        return(
         <div className='h100percent'>
         <div className='main-header'>
           
           <div className='row'>
             <div className='col-md-6'><h2 className="page-header">Document Type Meta Data</h2></div>
             <div className='col-md-6 text-right'>
             
                  
                  </div>
            
           </div>
           </div>
           <div className='main-panel'>
            <div className='content '>
            <ToastContainer />
            <div className='container-fluid h100percent'>
            <div className='h100percent text-selection-none '>
            <div className='row mb-3'>
     <div className='col-md-12 text-right'>             
     {this.state.cid == 1 && this.state.role == 1 && <NavLink  className='btn btn-primary  mt-2'  title='Add Meta Data'  activeClassName={"active"} to="/add-doc-meta-data">Add Meta Data</NavLink> }
               </div>
               </div>
     <div className='row viewGroupheight'>
            <div className="col-md-12  h-100">
            <div style={{width: '100%', height: '100%'}}>
              <StickyTable leftStickyColumnCount={0} >
         
              <Row>
                 <Cell>Label</Cell>
                 <Cell> Type</Cell>
                 <Cell> Key</Cell>
                 {/* <Cell>Validation</Cell> */}
                 <Cell>Mandatory</Cell>
                 <Cell>Document Type</Cell>  
                 <Cell>Created</Cell>
                 <Cell>Updated</Cell>
              
                 <Cell ></Cell>
              </Row>
              <Row  >                        
                       <Cell>Manufacturer Name (Type min 2 character for autosearch)</Cell>
                 <Cell>Autosearch</Cell>
                 <Cell>mnf_id</Cell>
                 {/* <Cell>*required</Cell> */}
                 <Cell>Yes</Cell>
                 <Cell>Safety Data Sheet</Cell>  
                 <Cell><div>2020-12-31</div></Cell>
                 <Cell><div>2020-12-31</div></Cell>
            
            <Cell>
                <button onClick={(e) => this.editMeta(e,s.id)}  type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-pencil-alt"></i></button>
                <button onClick={(e) => this.delMeta(e,s.id)}  type="button" className="btn btn-danger btn-sm mr-2"><i className="fas  fa-trash-alt"></i></button>
                     
               </Cell>
               </Row>
               <Row   >                        
                       <Cell>Select Language</Cell>
                 <Cell>
                     <div>Dropdown</div>
                     <div>/api/Controller/getLanguageList</div>
                 </Cell>
                 <Cell>coun_id</Cell>
                 {/* <Cell>*required</Cell> */}
                 <Cell>Yes</Cell>
                 <Cell>Safety Data Sheet</Cell>  
                 <Cell><div>2020-12-31</div></Cell>
                 <Cell><div>2020-12-31</div></Cell>
            
            <Cell>
                <button onClick={(e) => this.editMeta(e,s.id)}  type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-pencil-alt"></i></button>
                <button onClick={(e) => this.delMeta(e,s.id)}  type="button" className="btn btn-danger btn-sm mr-2"><i className="fas  fa-trash-alt"></i></button>
                     
               </Cell>
               </Row>
           
             {/* {userlist} */}
             
              
              
         
           </StickyTable>
           </div>
</div>

    </div>   
</div>
</div>
        </div>
        </div>
       </div>
     
        )
    }
}
export default ViewDocTypeMeta