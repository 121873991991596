import React, { Component } from 'react';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import {NavLink} from 'react-router-dom';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import classnames from 'classnames';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class ViewAccount extends Component { 
  constructor(props) {
    super(props);
    this.state = {
     useres:[],
     SelectedCompany:'',
      companyList:[],
      ifCloudsds:false,
 };
 this.updateValueCompany = this.updateValueCompany.bind(this);
 this.Getallusers = this.Getallusers.bind(this)
  }
  updateValueCompany(newValue) {
    this.state.SelectedCompany = newValue
  this.setState({
    SelectedCompany: this.state.SelectedCompany
  });
  this.Getallusers();
}
Getallusers(){
  console.log(this.state.SelectedCompany)
  document.getElementById('loader').classList.add('d-block'); 
  let cid = parseInt(this.state.SelectedCompany.value);
  if(!cid){
    cid = 0
  }
   fetch(process.env.REACT_APP_ENV+'/api/UserAuth/Getallusers',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
          
        },
        body: JSON.stringify({ 
          id:cid
       })
        })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;  
        console.log(tempData)   
        if(tempData.status.statusMessage === 'Success'){
             let exdate = new Date();
             cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
             this.state.useres =  tempData.data;
             this.setState({
               useres:this.state.useres
             })
        }else{
             toast.error('Error ocured. Please Try Again')
        }
       document.getElementById('loader').classList.remove('d-block'); 
       
      })
      .catch(error =>{
        console.log(error); 
      })
}
  componentDidMount(){
    if(cookies.get("cid") == '1' && cookies.get("roleid") == '1'){
      this.setState({
        ifCloudsds:true
       })
    }
    this.Getallusers();
      fetch(process.env.REACT_APP_ENV+'/api/UserAuth/GetCompanyList',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
          
        },
        body: JSON.stringify({ 
       //  Id : cookies.get("cid")  
       })
        })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;  
        console.log(tempData)   
        if(tempData.status.statusMessage === 'Success'){
          this.state.cname = tempData.data.cname;
          this.setState({
           cname:this.state.cname
          })
          let storedata = [];
             let tempDatas = responseJson.data; 
             for (var i = 0; i < tempDatas.length; i++) {
               storedata.push({
                 value: tempDatas[i].id, 
                 label:  tempDatas[i].cname,
               })
               
               }
              this.setState({
             companyList: [...this.state.companyList, ...storedata ],
            })
            
          const timestamp = new Date().getTime();
          const expireauth = timestamp + 1800000;
          const exdate =  new Date(expireauth);
          cookies.set('auth', tempData.status.authtoken, { path: '/'});   
          this.state.SelectedCompany = this.state.companyList[0];
          this.setState({
           SelectedCompany:this.state.SelectedCompany,
          })
         // console.log(this.state.companyList)
        }else{
             toast.success('Error ocured. Please Try Again')
        }
       document.getElementById('loader').classList.remove('d-block'); 
       
      })
      .catch(error =>{
        console.log(error); 
      })
  }
  updateAccount = (e,id,cid) => {
    const timestamp = new Date().getTime();
    const expireauth = timestamp + 1800000;
    const exdate =  new Date(expireauth);
   cookies.set('Uid', id, { path: '/'});
   if(cookies.get("cid") == '1' && cookies.get("roleid") == '1'){
     
    cookies.set('UpdCompID', cid, { path: '/'});
   }
    this.props.history.push('/update-account') 
    
}
deluser = (e,uid,ind) => {
  confirmAlert({
    title: 'Confirm to Proceed',
    message: 'Are you sure to do this.',
    buttons: [
      {
        label: 'Yes',
        onClick: () => {
          this.deluserCOnfirm('',uid,ind)
        }
      },
      {
        label: 'No',
        onClick: () => {}
      }
    ]
  });
}
deluserCOnfirm = (e,uid,ind) => {
   document.getElementById('loader').classList.add('d-block'); 
   fetch(process.env.REACT_APP_ENV+'/api/UserAuth/DeleteUser',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
          
        },
        body: JSON.stringify({ 
        id:uid
       })
        })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;  
        console.log(tempData)   
        if(tempData.status.statusMessage === 'Success'){
             let exdate = new Date();
             cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
             const user = this.state.useres.filter(user => user.id !== uid)
             this.setState({useres:user})
             toast.success('User account successfully deleted')
        }else{
             toast.error('Error ocured. Please Try Again')
        }
       document.getElementById('loader').classList.remove('d-block'); 
       
      })
      .catch(error =>{
        console.log(error); 
      })
   
}
  
    goBack() {
        window.history.back();
      }
    render(){
      let userlist = [];
      this.state.useres.forEach ((s,index) => {   
        //  console.log(s.companyName)
        let role = s.roleid;
        let status = s.status;
        if(role === 1){
         role = 'Super Administrator'
        }
        if(role === 2){
         role = 'Administrator'
        }
        if(role === 3){
         role = 'Taggers'
        }
        if(role === 4){
         role = 'Data Scientists'
        }
        if(status == 1){
          status = 'Active'
        }else{
          status = 'Inactive'
        }
        let cdate = s.created_date.split("T")[0];
        let mdate = s.modified_date.split("T")[0];
        userlist.push(
                   <Row   key={index} >                        
                   <Cell>{s.first_name}</Cell>
                  <Cell>{s.last_name}</Cell>
                   <Cell>{s.email_id}</Cell>
                   <Cell>{s.cname}</Cell>
                   <Cell>{s.mobile_no}</Cell>
                   <Cell>{role}</Cell>
                   <Cell>{status}</Cell>
                   <Cell>
                <div>{cdate}</div>
                <div>{s.created_by_name}</div>
                </Cell>
                <Cell>
                <div>{mdate !== '0001-01-01'?mdate:cdate}</div>
                <div>{s.modified_by_name.trim() !== '' ?s.modified_by_name:s.created_by_name}</div>
                </Cell>
                   <Cell style={{width:'100px'}}><button onClick={(e) => this.updateAccount(e,s.id,s.cid)}  type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-pencil-alt"></i></button>
           <button onClick={(e) => this.deluser(e,s.id,index)}  type="button" className="btn btn-danger btn-sm"><i className="fas fa-trash-alt"></i></button>
           </Cell>
                   </Row>
           )
        })
        return(
          <div className='h100percent'>
          <div className='main-header'>
          <div className='row'>
              <div className='col-md-6'><h2 className="page-header"><a onClick={this.goBack}   className="back-button cpointer" >
                <i className="fas fa-arrow-circle-left"></i></a> User Management</h2></div>
              <div className='col-md-6 text-right'>             
                
                </div>
            </div>
            </div>
              <ToastContainer />
              <div className='main-panel'>
             <div className='content '>
             <div className='container-fluid h100percent '>
             <div className='row mb-3'>
               <div className='col-md-3'>
                 <div className={this.state.ifCloudsds?'':' d-none'}>
                 <label>Filter By Organization</label>
         <Select    
         className ='orgindex'            
        value={this.state.SelectedCompany}
        onChange={this.updateValueCompany}
        options={this.state.companyList}
        defaultValue={{ value: '', label: '----' }}
      />
                 </div>
               
               </div>
     <div className='col-md-9 text-right'>             
     <NavLink  className='btn btn-primary  mt-2'  title='Add User'  activeClassName={"active"} to="/add-account">Add User</NavLink> 
               </div>
               </div>
              <div id="tabledata" className=" position-relative  viewGroupheight">
              <div style={{width: '100%', height: '100%'}}>
              <StickyTable leftStickyColumnCount={0} >
         
              <Row>
                 
                 <Cell>First Name</Cell>
                 <Cell>Last Name</Cell>                 
                 <Cell>Email</Cell>
                 <Cell>Organization</Cell>
                 <Cell>Phone</Cell>
                 <Cell>User Role</Cell>
                 <Cell>Status</Cell>
                 <Cell>Created</Cell>
              <Cell>Updated</Cell>
                 <Cell ></Cell>
              </Row>
           
           
              {userlist}
             
              
              
         
           </StickyTable>
        </div>
     </div>
     </div>
     </div>
     </div>
     
      </div>
        )
    }
}
export default ViewAccount