import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { Resizable } from "re-resizable";
import Draggable from 'react-draggable';
import Select from 'react-select';
import img from '../images/pdf.png'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CSVLink, CSVDownload } from "react-csv";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class Tagdoc extends Component {
  constructor(props) {
    super(props);
    let today = new Date();
    let date = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate();
    this.state = {
    tasks: [
        // {name:"input",category:"input"},
        // {name:"textarea", category:"textarea"},        
      ],
      draw: [],
      dragx:'',
      dragy:'',
      xmin:'',
      ymin:'',
      xmax:'',
      ymax:'',
      modal: false,
      selectedelm:'',
      tagtype : '',
      setdesc : 1,
      selectedOption: { value:'',label:'Select Key/Value'},
      showhide:'hide',
      currentPage:cookies.get("TaggedFilePage"),
      tagkeyList:[],
      tagvalueList:[],
      keyOptions:[],
      selectedImage:'',
      cDate:date,
      selectedTagId:'',
      sectioninfo:[
        { value:'',label:'Select Section'},
        { value:'1',label:'Section 1'},
        { value:'2',label:'Section 2'},
        { value:'3',label:'Section 3'},
        { value:'4',label:'Section 4'},
        { value:'5',label:'Section 5'},
        { value:'6',label:'Section 6'},
        { value:'7',label:'Section 7'},
        { value:'8',label:'Section 8'},
        { value:'9',label:'Section 9'},
        { value:'10',label:'Section 10'},
        { value:'11',label:'Section 11'},
        { value:'12',label:'Section 12'},
        { value:'13',label:'Section 13'},
        { value:'14',label:'Section 14'},
        { value:'15',label:'Section 15'},
        { value:'16',label:'Section 16'},
      ],
      selectedSection: { value:'',label:'Select Section'},
      expandWindow:false,
      isSubmitted:false,
      assignModal:false,
      taggerList:[],
      selectedTagger:'',
     
}
this.toggle = this.toggle.bind(this);
this.getjson = this.getjson.bind(this)
this.submitFile = this.submitFile.bind(this);
this.changeTable = this.changeTable.bind(this);
this.tablechange = this.tablechange.bind(this);
this.updateValuesec = this.updateValuesec.bind(this)
this.handleClickcontextMenu = this.handleClickcontextMenu.bind(this)
this.expandwindowshow = this.expandwindowshow.bind(this)
}
handleClickcontextMenu(e, data) {
  let dropdiv = document.getElementById("droparea");  
  let conposl = document.getElementsByClassName('react-contextmenu--visible')[0].style.left;
  let conpost = document.getElementsByClassName('react-contextmenu--visible')[0].style.top;
  conposl = conposl.replace('px','')
  conpost = conpost.replace('px','')
  conposl = parseInt(conposl);
  conpost = parseInt(conpost)
  let xaxis = conposl - (dropdiv.getBoundingClientRect().left); 
  if(xaxis > 824){
    xaxis = 824
  }
  let yaxis = conpost - (dropdiv.getBoundingClientRect().top);
  let genid = Math.floor(Math.random() * 100000); 
let pageno = this.state.currentPage;
let dropwidth = document.getElementById('droparea').offsetWidth;
let dropheight = document.getElementById('droparea').offsetHeight;
  // let dobottom = dropheight - (30 + yaxis);
  // let doright = dropwidth - (200 + xaxis);
  let dobottom = 30 + yaxis;
  let doright = 200 + xaxis;
  console.log('right'+doright)
    let draws =  this.state.draw.concat(
      {
        ClientID:0,
        Left:xaxis,
        Top:yaxis, 
        Right:doright,
        Bottom:dobottom,
        Width:200,
        Height:30,
        tagType:'',
        desc:'',
        descd:'',
        page:pageno,
        TagID:'',
        ImageName:cookies.get("TaggedFile").replace('.pdf','')+'_'+pageno,
        DocID:parseInt(cookies.get("docID")),
        Version:1,
        Status:0,
        LastUpdatedBy:parseInt(cookies.get("userid")), 
        LastUpdatedDate:this.state.cDate,
        id:genid,
        dataType:0,
        tbl:0,
        imgWidth:document.getElementById('dropIMG').clientWidth,
        isCheckbox:false,
        checked:false
      }
      );
      setTimeout (()=>this.setState({
      draw :draws,
      dragx:xaxis,
      dragy:yaxis
    }), 100);
    //console.log(doright+'/'+dobottom)
}
updateValuesec(newValue) {
 /// alert(newValue.value)
  this.setState({
    selectedSection: newValue
});
let tagtype = this.state.tagtype;
this.state.keyOptions = []
const tagkeyList = this.state.tagkeyList;
if(tagtype !== ''){
  if(tagtype === 'Key_Tag'){  
     if(newValue.value !== ''){
      this.state.keyOptions = tagkeyList.filter(item => item.type === 'K' && item.section === newValue.value);
      
     }else{
      this.state.keyOptions = tagkeyList.filter(item => item.type === 'K');
      
     }
     
   
  }else if(tagtype === 'Value_Tag'){
   
    if(newValue.value !== ''){
     
      this.state.keyOptions = tagkeyList.filter(item => item.type === 'V' && item.section === newValue.value);
    }else{
      this.state.keyOptions = tagkeyList.filter(item => item.type === 'V');
     
    }
   
    

  }
  this.setState({keyOptions:this.state.keyOptions});
}
  
}
submitFile(e){
  confirmAlert({
    title: 'Confirm to submit',
    message: 'Are you sure to do this.',
    buttons: [
      {
        label: 'Yes',
        onClick: () => {
          document.getElementById('loader').classList.add('d-block')
  fetch(process.env.REACT_APP_ENV+'/api/TagV2/SubmitTaggedDocument',{
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization':'bearer '+cookies.get("auth")

    },
    body: JSON.stringify({ 
      docId: parseInt(cookies.get("docID")),
      groupId:  parseInt(cookies.get("groupid"))
   })
    })
  .then((response) => response.json())
  .then((responseJson) => {
    let tempData = responseJson;    
    if(tempData){     
    const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', tempData.status.authtoken, { path: '/'});  
        
    }else{
      toast.warn('Error ocured. Please try again');
    }
     
    document.getElementById('loader').classList.remove('d-block')
  })
  .catch(error =>{
    console.log(error);     
    document.getElementById('loader').classList.remove('d-block')     
   
  })

        }
      },
      {
        label: 'No',
        onClick: () => {
          
        }
      }
    ]
  });
}
getPageNext(e){
  let currentpage = this.state.currentPage;
  let nextpage = parseInt(currentpage)+1;
  //geting image from server and assign to state
  document.getElementById('loader').classList.add('d-block')
  fetch(process.env.REACT_APP_ENV+'/api/TagV2/ViewTagImage',{
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization':'bearer '+cookies.get("auth")

    },
    body: JSON.stringify({ 
      PageNo: nextpage,
      // FileName:  localStorage.getItem("TaggedFile"),
      FileName:  cookies.get("TaggedFile"),
      category:cookies.get("TagCategory")
   })
    })
  .then((response) => response.json())
  .then((responseJson) => {
    let tempData = responseJson;    
    if(tempData){
      this.setState({
        selectedImage: tempData,
        currentPage:nextpage
    })
    const timestamp = new Date().getTime();
const expire = timestamp + 86400000;
const expireDate = new Date(expire);
    cookies.set('TaggedFilePage', nextpage, { path: '/'});
    //cookies.set('auth', tempData.status.authtoken, { path: '/',expires: new Date(Date.now()+2592000) });  
                    
   // localStorage.setItem('TaggedFilePage',nextpage)
    }else{
      toast.warn("Next page doesn't exist");
    }
     
    document.getElementById('loader').classList.remove('d-block')
  })
  .catch(error =>{
    console.log(error);     
    document.getElementById('loader').classList.remove('d-block')     
   
  })

}
getPagePrev(e){
  let currentpage = this.state.currentPage;  
  if(currentpage==1){
    toast.warn("Previuos page doesn't exist");     
  }else{
    document.getElementById('loader').classList.add('d-block')
    let prepage = parseInt(currentpage) - 1;
  fetch(process.env.REACT_APP_ENV+'/api/TagV2/ViewTagImage ',{
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization':'bearer '+cookies.get("auth")

    },
    body: JSON.stringify({ 
      PageNo: prepage,
      // FileName:  localStorage.getItem("TaggedFile"),
      FileName:  cookies.get("TaggedFile"),
      category:cookies.get("TagCategory")
      
   })
    })
  .then((response) => response.json())
  .then((responseJson) => {
    let tempData = responseJson; 
    if(tempData){
      this.setState({
        selectedImage: tempData,
        currentPage:prepage
    })
   // localStorage.setItem('TaggedFilePage',prepage)
   const timestamp = new Date().getTime();
   const expire = timestamp + 86400000;
   const expireDate = new Date(expire);
    cookies.set('TaggedFilePage', prepage, { path: '/'});  
  //  cookies.set('auth', tempData.status.authtoken, { path: '/',expires: new Date(Date.now()+2592000) });  
                    
                    
  }
    
    document.getElementById('loader').classList.remove('d-block')
 
  })
  .catch(error =>{
    console.log(error); 
  })
    
}
  
}
getPageno(e){
this.state.currentPage = no;
this.setState({
  currentPage: this.state.currentPage,
})
let items = document.querySelectorAll("#pages li");
let nav = document.querySelectorAll("#pagination li");
let count = 0;
let counter = 0;

for(var k = 0; k < nav.length; k++)
{
  nav[k].classList.remove('active');
 // console.log(nav[k])
}
e.target.parentElement.classList.add('active');


for(var j = 0; j < items.length; j++)
{
  items[j].classList.remove('active')
}


for(var i = 0; i < items.length; i++)
{
  counter = count++;
  counter = counter+1;
  if(counter == no){
    items[i].classList.add('active')
  }
 
}

return false
}
handleChangeset = (e) => {
  this.state.tagtype = e.target.value;  
  //isCheckbox
  //this.setState({tagtype : this.state.tagtype, selectedOption: null})
  this.state.draw[this.state.selectedelm].tagType =  this.state.tagtype;
  this.state.draw[this.state.selectedelm].desc =  '';
  this.state.draw[this.state.selectedelm].TagID =  '';
  if(this.state.tagtype === 'Cbx_Tag'){
    this.state.draw[this.state.selectedelm].isCheckbox = true
  }else{
    this.state.draw[this.state.selectedelm].isCheckbox = false
  }
  this.setState({
    tagtype : this.state.tagtype, 
    selectedOption: null,
    draw:this.state.draw
  });
 // console.log( e.target.value);
  const tagkeyList = this.state.tagkeyList;
  if(e.target.value == 'Key_Tag'){    
  //  console.log(tagkeyList.filter(item => item.type === 'K '))
    if(this.state.selectedSection.value !== '' ){
      this.state.keyOptions = tagkeyList.filter(item => item.type === 'K' && item.section === this.state.selectedSection.value);
    }else{
      this.state.keyOptions = tagkeyList.filter(item => item.type === 'K');
    }
   
  }else if(e.target.value == 'Value_Tag'){    
    if(this.state.selectedSection.value !== '' ){
      this.state.keyOptions = tagkeyList.filter(item => item.type === 'V' && item.section === this.state.selectedSection.value);
    }else{
      this.state.keyOptions = tagkeyList.filter(item => item.type === 'V');
    }
    console.log(tagkeyList.filter(item => item.type === 'V'))
    

  }else{        
      this.state.keyOptions = tagkeyList.filter(item => item.type === 'C');
   
    console.log(tagkeyList.filter(item => item.type === 'C'))
    

  }
  //console.log(this.state.keyOptions)
  this.setState({keyOptions:this.state.keyOptions});
}
opensetting = (e,elm, index,keyType,table,id) => {   
 // console.log(this.state.draw)
 // this.state.selectedTagId = this.state.draw[index].TagID;
 if(this.state.draw[index].TagID){
  let tagkeyindex = this.state.tagkeyList.findIndex(section => section.value === this.state.draw[index].TagID);
 let sectionid = this.state.tagkeyList[tagkeyindex].section;
 let sectionindex = this.state.sectioninfo.findIndex(section => section.value === sectionid);
 let sectionval = this.state.sectioninfo[sectionindex].label;
this.state.selectedSection = { value:sectionid,label:sectionval};

 }else{
  this.state.selectedSection = { value:'',label:'Select Section'};
  
 }
 
 
  let tagtype = this.state.draw[index].tagType;
  
  if(tagtype !==''){
    document.querySelector("input[name="+keyType+"][value="+this.state.draw[index].tagType+"]").checked = true;
    const tagkeyList = this.state.tagkeyList;
    if(this.state.draw[index].tagType == 'Key_Tag'){
      this.state.keyOptions = tagkeyList.filter(item => item.type === 'K');
    }else{
      this.state.keyOptions = tagkeyList.filter(item => item.type === 'V');
    }
  }else{
    this.state.keyOptions = []
  }
  
  let parent = document.getElementById(elm).parentElement;
  let allzindex = document.querySelectorAll('.react-draggable');
  let j;  
  for (let j = 0; j < allzindex.length; j++) {
    allzindex[j].classList.remove('zindex100');
   
}
  parent.parentElement.classList.add('zindex100');
  let allseting = document.querySelectorAll('.innerpopup');
  let i;  
  for (let i = 0; i < allseting.length; i++) {
    allseting[i].classList.remove('true');
}
//console.log('selected : '+this.state.selectedOption)
if(this.state.draw[index].desc){
  const selectedOption = {value: this.state.draw[index].desc, label: this.state.draw[index].descd };
this.state.selectedOption = selectedOption;
setTimeout (()=>this.setState({
selectedOption:this.state.selectedOption
}), 100); 
//console.log(selectedOption)
}else{
  this.state.selectedOption = { value:'',label:'Select Key/Value'};
setTimeout (()=>this.setState({
selectedOption:this.state.selectedOption
}), 100); 
}

 // this.setState({draw:this.state.draw, selectedOption:this.state.selectedOption});
  document.getElementById(elm).parentElement.nextSibling.classList.add("true");
  this.state.selectedelm = index;   
  setTimeout (()=>this.setState({  
    selectedelm: this.state.selectedelm,
    selectedTagId: this.state.draw[index].TagID,
    draw:this.state.draw,    
   keyOptions:this.state.keyOptions,  
   tagtype:this.state.draw[index].tagType
  }), 100); 
//console.log('selected : '+this.state.selectedelm)

}
closesetting = (elm,id) => {
 // console.log(this.state.draw)
  let drawindex = this.state.draw.findIndex(draws => draws.id === id);
  let dataType = this.state.draw[drawindex].dataType;  
  if(dataType === 1){    
    if(this.state.selectedTagId !== this.state.draw[drawindex].TagID){     
      let cbstate = -1;
      if (this.state.draw[drawindex].isCheckbox === true) {
        cbstate = 0;
        if (this.state.draw[drawindex].checked === true) {
        cbstate = 1;
        }
      }
      document.getElementById("loader").classList.add("d-block");
      fetch(process.env.REACT_APP_ENV+'/api/TagV2/UpdateTagData',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
    
        },
        body:  JSON.stringify({ 
          id:this.state.draw[drawindex].id,
          cid : cookies.get("cid"),
          left: this.state.draw[drawindex].Left, 
          top:  this.state.draw[drawindex].Top,
          right: this.state.draw[drawindex].Right, 
          bottom:  this.state.draw[drawindex].Bottom,
          width: this.state.draw[drawindex].Width, 
          height:  this.state.draw[drawindex].Height,
          imageName: this.state.draw[drawindex].ImageName, 
          docID:  this.state.draw[drawindex].DocID,
          version: this.state.draw[drawindex].Version, 
          status:  1,
          clientID:  this.state.draw[drawindex].ClientID,
          tagID:  this.state.draw[drawindex].TagID,
          tbl:this.state.draw[drawindex].tbl,
          modified_by:  cookies.get("userid"),
          modified_date:  new Date(),
          pageno:this.state.currentPage,
          img_width: document.getElementById('dropIMG').clientWidth,
          cb_state:cbstate
       })
        })
      .then((response) => response.json())
      .then((responseJson) => {
     // console.log(responseJson)
      const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', responseJson.status.authtoken, { path: '/'});  
                    
      document.getElementById("loader").classList.remove("d-block");
      })
      .catch(error =>{
        console.log(error);          
        document.getElementById("loader").classList.remove("d-block");
      })
    }
    
  }
  else
  {
  let tagId = this.state.draw[drawindex].TagID;
  if(tagId){
    document.getElementById("loader").classList.add("show");
   let fImgWIdth = this.state.draw[drawindex].imgWidth; 
   if(fImgWIdth === 0){
     console.log(fImgWIdth)
    fImgWIdth =  document.getElementById('dropIMG').clientWidth;    
   }
   let pagenumber = this.state.currentPage;
   let cpagenumber = cookies.get("TaggedFilePage");
   if(pagenumber !== cpagenumber){
    pagenumber =  cookies.get("TaggedFilePage");
    this.state.draw[drawindex].img_width=pagenumber;  
    setTimeout(()=>this.setState({
      currentPage:this.state.currentPage
  }), 100); 

   }
   let rightp = this.state.draw[drawindex].Right;
   if(rightp <= this.state.draw[drawindex].Left){
    rightp = parseInt(this.state.draw[drawindex].Left) + parseInt(this.state.draw[drawindex].Width);
    console.log(rightp)
   }
   let bottomp = this.state.draw[drawindex].Bottom;
   if(bottomp <= this.state.draw[drawindex].Top){
    bottomp = parseInt(this.state.draw[drawindex].Top) + parseInt(this.state.draw[drawindex].Height);
    console.log(bottomp)
   }
   let cbstate = -1;
      if (this.state.draw[drawindex].isCheckbox === true) {
        cbstate = 0;
        if (this.state.draw[drawindex].checked === true) {
        cbstate = 1;
        }
      }
  fetch(process.env.REACT_APP_ENV+'/api/TagV2/CreateTagData',{
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization':'bearer '+cookies.get("auth")

    },
    body:  JSON.stringify([{ 
        cid : cookies.get("cid"),
        Left: this.state.draw[drawindex].Left, 
        Top:  this.state.draw[drawindex].Top,
        Right: rightp, 
        Bottom:  bottomp,
        Width: this.state.draw[drawindex].Width, 
        Height:  this.state.draw[drawindex].Height,
        ImageName: this.state.draw[drawindex].ImageName, 
        DocID:  this.state.draw[drawindex].DocID,
        Version: this.state.draw[drawindex].Version, 
        Status:  1,
        ClientID:  this.state.draw[drawindex].ClientID,
        TagID:  this.state.draw[drawindex].TagID,
        tbl:this.state.draw[drawindex].tbl,
        created_by:  cookies.get("userid"),
        created_date:  new Date(),
        pageno:pagenumber,
        img_width:fImgWIdth,
        cb_state:cbstate
   }])
    })
  .then((response) => response.json())
  .then((responseJson) => {
    this.state.draw[drawindex].dataType=1;  
    setTimeout(()=>this.setState({
    draw:this.state.draw
  }), 100); 
  const timestamp = new Date().getTime();
  const expireauth = timestamp + 1800000;
  const exdate =  new Date(expireauth);
  cookies.set('auth', responseJson.status.authtoken, { path: '/'});  
  document.getElementById("loader").classList.remove("show");
  let node = document.createElement("div");        
  node.setAttribute("id", drawindex);         // Create a <li> node
  let textnode = document.createTextNode('Index:'+drawindex+', Left:'+this.state.draw[drawindex].Left+', Right:'+this.state.draw[drawindex].Right+', Top:'+this.state.draw[drawindex].Top+', Bottom:'+this.state.draw[drawindex].Bottom);         // Create a text node
node.appendChild(textnode);                              // Append the text to <li>
document.getElementById("debugdiv").appendChild(node); 
  })
  .catch(error =>{
    console.log(error);          
    document.getElementById("loader").classList.remove("show");
  })
  }
    

  }
  document.getElementById(elm).parentElement.nextSibling.classList.remove("true");
  document.getElementById(elm).parentElement.parentElement.classList.remove("zindex100");
  
}
savesetting = (e) =>{
  e.preventDefault();
  console.log(this.state.selectedelm);
  const { tagtype, setdesc} = this.state;
  this.state.draw[this.state.selectedelm].tagType = tagtype;
  this.state.draw[this.state.selectedelm].desc = setdesc;
  this.setState({draw:this.state.draw});
  this.setState({
    tagtype: 'Key_Tag',
    setdesc: 1
});
  //console.log(this.state.draw);
}
toggle() {
  this.setState({
    modal: !this.state.modal,
  });

}  
onStart = () => {
  
};

onStop = (e, index) => { 
  let id = this.state.draw[index].id; 
  console.log(this.state.draw)
  let elemwidth = document.getElementById(id).offsetWidth;
  let elemheight = document.getElementById(id).offsetHeight;
  let dropwidth = document.getElementById('droparea').offsetWidth;
  let dropheight = document.getElementById('droparea').offsetHeight;
  // let dobottom = dropheight - (elemheight + this.state.dragy);
  // let doright = dropwidth - (elemwidth + this.state.dragx);
  let dobottom = elemheight + this.state.dragy;
  let doright = elemwidth + this.state.dragx;
  this.state.draw[index].Left = this.state.dragx;
  this.state.draw[index].Top = this.state.dragy;
  this.state.draw[index].Right = doright;
  this.state.draw[index].Bottom = dobottom;
  this.state.draw[index].Width = elemwidth;
  this.state.draw[index].Height = elemheight;
  this.setState({draw:this.state.draw});
  // document.getElementById("loader").classList.add("show");
  // fetch('https://api.cloudsds.com/deepdatum/api/Tag/CreateTagData',{
  //   method: 'POST',
  //   headers: {
  //     'Accept': 'application/json',
  //     'Content-Type': 'application/json',

  //   },
  //   body:  JSON.stringify([{ 
  //       Left: this.state.draw[index].Left, 
  //       Top:  this.state.draw[index].Top,
  //       Right: this.state.draw[index].Right, 
  //       Bottom:  this.state.draw[index].Bottom,
  //       Width: this.state.draw[index].Width, 
  //       Height:  this.state.draw[index].Height,
  //       ImageName: this.state.draw[index].ImageName, 
  //       DocID:  this.state.draw[index].DocID,
  //       Version: this.state.draw[index].Version, 
  //       Status:  this.state.draw[index].Status,
  //       ClientID:  this.state.draw[index].ClientID,
  //       TagID:  this.state.draw[index].TagID,
  //       LastUpdatedBy:  this.state.draw[index].LastUpdatedBy,
  //       LastUpdatedDate:  this.state.draw[index].LastUpdatedDate,
  //  }])
  //   })
  // .then((response) => response.json())
  // .then((responseJson) => {
    
  // console.log(responseJson)
  // document.getElementById("loader").classList.remove("show");
  // })
  // .catch(error =>{
  //   console.log(error);          
  //   document.getElementById("loader").classList.remove("show");
  // })
  

  // console.log('dragged object width X height: ' + elemwidth+'X'+elemheight);
  // console.log('sds width X height: ' + dropwidth+'X'+dropheight);
  // console.log('draged x: ' + this.state.dragx);
  // console.log('draged y: ' + this.state.dragx)
  // console.log('draged left: ' + this.state.draw[index].xpos);
  // console.log('draged top: ' + this.state.draw[index].ypos);
  // console.log('draged bottom: ' + dobottom);
  // console.log('draged right: ' + doright);
 //console.log(this.state.draw);
  //document.getElementById('output').innerHTML = JSON.stringify(this.state.draw)
};
removeItem(e,index,id,tagid,docid,ver) {  
//  console.log('ID - '+id+', Tagid - '+tagid+', Docid - '+docid+', version - '+ver);
  document.getElementById("loader").classList.add("show");

  fetch(process.env.REACT_APP_ENV+'/api/TagV2/DeleteTagData',{
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization':'bearer '+cookies.get("auth")
    },
    body: JSON.stringify({
      id: id,
      })
    })
         .then((response) => response.json()) 
         .then((responseJson) => { 
          // console.log(responseJson.data);
           if(responseJson.status.statusMessage == 'Success'){
             const draw = this.state.draw;
              if (index !== -1) {
                draw.splice(index, 1);
                this.setState({draw: draw});
              }
              toast.error('Tag deleted successfully')
           }
              const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', responseJson.status.authtoken, { path: '/'});  
                    
           document.getElementById("loader").classList.remove("show");
         })
         .catch(error =>{
           console.log(error);          
           document.getElementById("loader").classList.remove("show");
         })
    // const draw = this.state.draw;
    // if (index !== -1) {
    //   draw.splice(index, 1);
    //   this.setState({draw: draw});
    // }
  //this.setState({draw:item});
//  console.log(this.state.draw);

}


handleDrag = (e, ui) => {
  let xp =  ui.x ;
  let yp = ui.y;   
  this.state.dragx = xp;
  this.state.dragy = yp;
  this.setState({
    dragx:this.state.dragx,
    dragy:this.state.dragy
  })
  
};
onresize = (e, index, id) => {
  let elemwidth = document.getElementById(id).offsetWidth;
  let elemheight = document.getElementById(id).offsetHeight;
  let dropwidth = document.getElementById('droparea').offsetWidth;
  let dropheight = document.getElementById('droparea').offsetHeight;
  let dobottom = elemheight + this.state.dragy;
  let doright = elemwidth + this.state.dragx;
  this.state.draw[index].Right = doright;
  this.state.draw[index].Bottom = dobottom;
  this.state.draw[index].Width = elemwidth;
  this.state.draw[index].Height = elemheight;
  this.setState({draw:this.state.draw});
  //console.log(dobottom+'/'+doright);
  
};
onChange(e, index){
  console.log(index);
  this.state.draw[index].value = e.target.value;
  this.setState({draw:this.state.draw})
}
onDragStart = (ev, name, cat) => {
   // console.log('dragstart:',name);
    ev.dataTransfer.setData("id", name);
    ev.dataTransfer.setData("data-cat", cat);
}

onDragOver = (ev) => {
    ev.preventDefault();
}

onDrop = (ev) => {
  ev.preventDefault();
   let id = ev.dataTransfer.getData("id");  
   if(id !== ''){
    let cat = ev.dataTransfer.getData("data-cat");
    let genid = Math.floor(Math.random() * 100000);  
    let dropdiv = document.getElementById("droparea");  
    let xaxis = ev.clientX - (dropdiv.getBoundingClientRect().left + 45);    
 //   console.log('x:'+ dropdiv.getBoundingClientRect().left);
    let yaxis = ev.clientY - (dropdiv.getBoundingClientRect().top + 26);
  //  console.log('y:'+ dropdiv.getBoundingClientRect().top);
    let pageno = this.state.currentPage;
    //console.log({id:genid,category:cat,xpos:xaxis,ypos:yaxis})
    
  let dropwidth = document.getElementById('droparea').offsetWidth;
  let dropheight = document.getElementById('droparea').offsetHeight;
  // let dobottom = dropheight - (30 + yaxis);
  // let doright = dropwidth - (200 + xaxis);
  let dobottom = 30 + yaxis;
  let doright = 200 + xaxis;
    let draws =  this.state.draw.concat(
      {
        ClientID:0,
        Left:xaxis,
        Top:yaxis, 
        Right:doright,
        Bottom:dobottom,
        Width:200,
        Height:30,
        tagType:'',
        desc:'',
        descd:'',
        page:pageno,
        TagID:'',
        ImageName:cookies.get("TaggedFile").replace('.pdf','')+'_'+pageno,
        DocID:parseInt(cookies.get("docID")),
        Version:1,
        Status:0,
        LastUpdatedBy:parseInt(cookies.get("userid")), 
        LastUpdatedDate:this.state.cDate,
        id:genid,
        dataType:0,
        tbl:0
      }
      );
    this.setState({
      draw :draws
    });
   }
   
   
}
// handlecsv(e){
//   let count = this.state.draw.length;
//   let draw = this.state.draw;
//   if(draw){
//     let storedata = [];
//     for (var i = 0; i < draw.length; i++) {
//       storedata.push({
//         Left: draw[i].Left, 
//         Top:  draw[i].Top,
//         Right: draw[i].Right, 
//         Bottom:  draw[i].Bottom,
//         Width: draw[i].Width, 
//         Height:  draw[i].Height,
//         ImageName: draw[i].ImageName, 
//         DocID:  draw[i].DocID,
//         Version: draw[i].Version, 
//         Status:  draw[i].Status,
//         ClientID:  draw[i].ClientID,
//         TagID:  draw[i].TagID,
//         LastUpdatedBy:  draw[i].LastUpdatedBy,
//         LastUpdatedDate:  draw[i].LastUpdatedDate,
//     })
      
//     }   
//     console.log(JSON.stringify(storedata));
//   document.getElementById("loader").classList.add("show");
//   fetch('https://api.cloudsds.com/deepdatum/api/Tag/CreateTagData',{
//     method: 'POST',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json',
//       'Authorization':'bearer '+cookies.get("auth")

//     },
//     body: JSON.stringify(storedata)
//     })
//   .then((response) => response.json())
//   .then((responseJson) => {
//     const timestamp = new Date().getTime();
//               const expireauth = timestamp + 1800000;
//               const exdate =  new Date(expireauth);
//               cookies.set('auth', tempData.status.authtoken, { path: '/',expires: exdate });  
     
//   console.log(responseJson)
//   document.getElementById("loader").classList.remove("show");
//   })
//   .catch(error =>{
//     console.log(error);          
//     document.getElementById("loader").classList.remove("show");
//   })
//   }
  
 
// }


handleChange(selectedOption){
  this.state.selectedOption = selectedOption;
  this.setState({ selectedOption: this.state.selectedOption })  
  this.state.draw[this.state.selectedelm].desc =  this.state.selectedOption.value;  
  this.state.draw[this.state.selectedelm].descd =  this.state.selectedOption.label;
  this.state.draw[this.state.selectedelm].TagID =  this.state.selectedOption.value;  
  this.setState({draw:this.state.draw});
 // console.log('index:', this.state.draw)
};
getjson(docid){ 
  document.getElementById("loader").classList.add("d-block");

  fetch(process.env.REACT_APP_ENV+'/api/TagV2/GetTagData',{
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization':'bearer '+cookies.get("auth")
    },
    body: JSON.stringify({ 
      DocID: docid,
      status : 1
   })
    })
         .then((response) => response.json())
         .then((responseJson) => {
          // console.log(responseJson.data);
           if(responseJson.data){
             let storedata = [];
             for (var i = 0; i < responseJson.data.length; i++) {
            //  console.log(this.state.tagkeyList)
              let tagindex = this.state.tagkeyList.findIndex(tagkey => tagkey.value === responseJson.data[i].tagID);
             // tag_type
             // let ttype = this.state.tagkeyList[tagindex].type;
             
               let ttype = responseJson.data[i].tag_type;
               let ifCheckbox = false;
               let ifchecked = false;
               if (responseJson.data[i].cb_state === 1) {
                ifchecked = true
               }
              if(ttype === 'K'){
                ttype = "Key_Tag";
              }
              if(ttype === 'V'){
                ttype = "Value_Tag";
              }
              if(ttype === 'C'){
                ttype = "Checkbox";
                ifCheckbox = true
               }
               
              let pageno = responseJson.data[i].imageName.split("_").pop();
              storedata.push({
                id:responseJson.data[i].id, 
                Left: responseJson.data[i].left, 
                Top:  responseJson.data[i].top,
                Right: responseJson.data[i].right, 
                Bottom:  responseJson.data[i].bottom,
                Width: responseJson.data[i].width, 
                Height:  responseJson.data[i].height,
                ImageName: responseJson.data[i].imageName, 
                DocID:  responseJson.data[i].docID,
                Version: responseJson.data[i].version, 
                Status:  responseJson.data[i].status,
                ClientID:  responseJson.data[i].clientID,
                TagID:  responseJson.data[i].tagID,
                LastUpdatedBy:  responseJson.data[i].lastUpdatedBy,
                LastUpdatedDate:  responseJson.data[i].lastUpdatedDate,
                tagType:ttype,
                desc:responseJson.data[i].tagID,
                descd:this.state.tagkeyList[tagindex].label,
                page:pageno,
                dataType:1,
                tbl:responseJson.data[i].tbl,
                imgWidth:responseJson.data[i].img_width,
                isCheckbox:ifCheckbox,
                checked:ifchecked
            })
              
            }  
            this.setState({
              draw: storedata,
         })
         //console.log(this.state.draw) 
           }
           const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', responseJson.status.authtoken, { path: '/'});  
                    
           document.getElementById("loader").classList.remove("d-block");
         })
         .catch(error =>{
           console.log(error);          
           document.getElementById("loader").classList.remove("d-block");
         })
}
handleClickOutside = (event,id) => { 
//   const domNode = ReactDOM.findDOMNode(this);
//   let ids = event.target.getAttribute("data-id");
//   if(id){
//   document.getElementById(id).classList.add('true');
//   }else{
//     let allseting = document.querySelectorAll('.innerpopup');
//   let i;  
//   for (let i = 0; i < allseting.length; i++) {
//     allseting[i].classList.remove('true');
// }
//   }
  
}
componentWillUnmount() {
  document.removeEventListener('click', this.handleClickOutside, true);
}


componentDidMount(){  
  if(this.props.submitted){
    this.state.isSubmitted = this.props.submitted
    this.setState({
      isSubmitted:this.state.isSubmitted
    })
  }
 
  document.addEventListener('click', this.handleClickOutside, true);
  document.getElementById('loader').classList.add('d-block') 
  fetch(process.env.REACT_APP_ENV+'/api/TagV2/GetTagKeyValList',{
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization':'bearer '+cookies.get("auth")

    },
    body: JSON.stringify({ 
      category:cookies.get("TagCategory"),
      status:1
   })
    }) 
  .then((response) => response.json())
  .then((responseJson) => {
    let tempData = responseJson.data;  
      if(tempData){
        let storedata = [];
        for (var i = 0; i < tempData.length; i++) {
          storedata.push({
            value: tempData[i].id, 
            label:  tempData[i].desc,
            type:tempData[i].type,
            section:tempData[i].section,
        })
          // console.log(tempData[i].val)
        }
        this.state.tagkeyList = [...this.state.tagkeyList, ...storedata ]
         this.setState({
           tagkeyList: this.state.tagkeyList,
      })
     // console.log(this.state.tagkeyList)
     const timestamp = new Date().getTime();
     const expireauth = timestamp + 1800000;
     const exdate =  new Date(expireauth);
     cookies.set('auth', responseJson.status.authtoken, { path: '/'});  
      }
     
      
  })
  .catch(error =>{
    console.log(error);  
   // document.getElementById('loader').classList.remove('d-block')        
   
  })
  fetch(process.env.REACT_APP_ENV+'/api/TagV2/ViewTagImage',{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'bearer '+cookies.get("auth")

    },
    body: JSON.stringify({ 
      PageNo:cookies.get("TaggedFilePage"),
      // FileName:  localStorage.getItem("TaggedFile"),
      FileName: cookies.get("TaggedFile"),
       category:cookies.get("TagCategory")
   })
    })
  .then((response) => response.json())
  .then((responseJson) => {
    let tempData = responseJson;    
    if(tempData){
      
      this.setState({
        selectedImage: tempData,
    })

   
    }
    this.getjson(parseInt(cookies.get("docID")))
  //document.getElementById('loader').classList.remove('d-block')
  })
  .catch(error =>{
    console.log(error);          
    //document.getElementById('loader').classList.remove('d-block')
  })
}
changeTable(e,tid,id,type,typeid,index){
  this.state.draw[index].tbl = type;  
  this.setState({
    draw:this.state.draw
  })
 // console.log(this.state.draw)
  
  
}
isCheckbox = (e,id,index) =>{
  this.state.draw[index].isCheckbox = !this.state.draw[index].isCheckbox;
  this.setState({
    draw:this.state.draw
  })
}
isCheckboxCheked= (e,id,index) =>{
  this.state.draw[index].checked = !this.state.draw[index].checked;
  this.setState({
    draw:this.state.draw
  })
}
tablechange(e,id,index){
  let cid = e.target.id;
  let drawindex = this.state.draw.findIndex(draws => draws.id === id);
  var checkBox = document.getElementById(cid);
  if (checkBox.checked == true){
    document.getElementById('table-'+id).classList.remove('d-none');
    this.state.draw[index].tbl = 2;
   // document.getElementById('thead-'+id).classList.add('bg-primary')
  } else {
    this.state.draw[index].tbl = 0;
    document.getElementById('table-'+id).classList.add('d-none');
  //   document.getElementById('chead-'+id).classList.remove('bg-primary')
  // document.getElementById('thead-'+id).classList.remove('bg-primary')
  // document.getElementById('cell-'+id).classList.remove('bg-primary')
  // document.getElementById('chead-'+id).classList.add('bg-dark')
  // document.getElementById('thead-'+id).classList.add('bg-dark')
  // document.getElementById('cell-'+id).classList.add('bg-dark')
  }
  this.setState({
    draw:this.state.draw
  })
}
expandwindowshow = (e) =>{
  document.getElementById('modal-tagDesigner-readonly').classList.toggle("max");
  document.getElementById('expandwindowIcon').classList.toggle("fa-compress");
  
}
reAssign = (e) =>{
  this.Getallusers()
  this.toggleAssignModal()
}
toggleAssignModal = () =>{
  this.setState({
    assignModal: !this.state.assignModal
  });
}
Getallusers = () =>{
  document.getElementById('loader').classList.add('d-block'); 
  let cid = parseInt(cookies.get('cid'));
 
   fetch(process.env.REACT_APP_ENV+'/api/UserAuth/Getallusers',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
          
        },
        body: JSON.stringify({ 
          id:cid
       })
        })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;  
        console.log(tempData)   
        if(tempData.status.statusMessage === 'Success'){
        //  taggerList
             cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
             let taggers = []
             tempData.data.forEach((s,index)=>{
               if(s.roleid === 3){
                taggers.push({id:s.id,value:s.first_name+' '+s.last_name})
               }
              
             })
             this.state.taggerList =  taggers;
             this.setState({
              taggerList:this.state.taggerList
             })
             console.log(this.state.taggerList)
             if(this.state.taggerList.length){
              let selectedTagger = this.state.taggerList[0].id;
              this.state.selectedTagger = selectedTagger;
              this.setState({
                selectedTagger:this.state.selectedTagger
              }) 
             }
             
        }else{
             toast.error('Error ocured. Please Try Again')
        }
       document.getElementById('loader').classList.remove('d-block');  
       
      })
      .catch(error =>{
        console.log(error); 
        document.getElementById('loader').classList.remove('d-block'); 
        toast.error('Error ocured. Please Try Again')
      })
}
assignListChange=(e)=>{
  this.setState({[e.target.name]: e.target.value})
}
assignTagger=(e)=>{  
  document.getElementById('loader').classList.add('d-block'); 
  fetch(process.env.REACT_APP_ENV+'/api/Document/AssignDocument',{
       method: 'POST',
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'Authorization':'bearer '+cookies.get("auth")         
       },
       body: JSON.stringify({              
        assigned_user_id:this.state.selectedTagger,
        group_id : cookies.get("groupid"),
        doc_id : cookies.get("docID"),
        cid : cookies.get("cid"),
        status : 1,
        category:cookies.get("TagCategory")
      })
       })
     .then((response) => response.json())
     .then((responseJson) => {
       let tempData = responseJson;  
      // console.log(tempData.data[0].id)   
       let exdate = new Date();
        cookies.set('auth', tempData.status.authtoken, { path: '/'});  
             
        if(tempData.status.statusMessage === 'Success'){                         
         toast.success('File re-assigned for tagging');  
         this.props.isReassigned(true)
          }else{      
           toast.error('Error occured.Please try again')    
        }
      document.getElementById('loader').classList.remove('d-block'); 
      
     })
     .catch(error =>{
       console.log(error); 
     })

  this.toggleAssignModal()
}
  render() {  
    let taggerList = [];
          this.state.taggerList.forEach((t,index)=>{
            taggerList.push(
              <option key={index} value={t.id}>{t.value}</option>
            )
          })
    const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
    const docType = cookies.get("TagCategory");
    const {deltaPosition, controlledPosition} = this.state;
    const { selectedOption } = this.state;
    const headers = [
      { label: "Tag ID", key: "TagID" },
      { label: "Left", key: "Left" },
      { label: "Top", key: "Top" },
      { label: "Right", key: "Right" },
      { label: "Bottom", key: "Bottom" },
      { label: "Width", key: "Width" },
      { label: "Height", key: "Height" },
      { label: "ClientID", key: "ClientID" },
      { label: "ImageName", key: "ImageName" },
      { label: "Doc Id", key: "DocID" },
      { label: "Version", key: "Version" },
      { label: "Status", key: "Status" },
      { label: "Last Updated By", key: "LastUpdatedBy" },      
      { label: "LastUpdatedDate", key: "LastUpdatedDate" },
      { label: "Table", key: "tbl" },
      { label: "Image Nme", key: "ImageName" },
      { label: "Image Width", key: "ImageName" },
    ];   
    const style = {
      display: "block",
      border: "solid 1px #ddd",
    };
  
    var draw = [];

console.log(this.state.draw)
  this.state.draw.forEach ((s,index) => {   
    let done = false;
    let reStatus=true
    if(s.tagType !=='' && s.desc !==''){
      done = true;
      reStatus=false;
    }
    
    if(s.page == this.state.currentPage){   
      let showDesc = s.descd;
      if(showDesc){
        showDesc = <span className="showonHover">{s.descd}</span>;
      }
      let rightSPaceLeft = parseInt(s.imgWidth)-parseInt(s.Left);
      let isright = false;
      if(rightSPaceLeft < 300){
        isright = true
      }
      console.log(rightSPaceLeft)
      draw.push(             
          <Draggable disabled={done} zIndex={100} handle=".keyval_holder"   defaultPosition={{x: s.Left, y: s.Top}}  key={s.id}   onStop={(e) => this.onStop(e,index)}  onDrag={this.handleDrag} defaultPosition={{x: s.Left, y:  s.Top}} enableUserSelectHack={false}>
           
             <Resizable 
              minHeight='10 '   
              enable= {{top: reStatus, right: reStatus,bottom: reStatus,left: reStatus,topRight: reStatus, bottomRight: reStatus,bottomLeft: reStatus,topLeft: reStatus,}} 
              style={style} 
              onResizeStop={(e) => this.onresize(e,index,s.id)}     
              defaultSize={{   width: s.Width,  height: s.Height,  }}  >
            
             
             <div id={s.id} className={s.isCheckbox?'keyval_holder '+ s.tagType+' tagCheckbox':'keyval_holder '+ s.tagType}>
               {s.isCheckbox &&
                  
               <input   checked={s.checked === true ? true : false} type='checkbox' />

               }
             {showDesc}
            
               </div>
            {/* <input   onChange={(e) => this.onChange(e,index)} value={s.value}  name={s.id} type='text' /> */}
            </Resizable>
          </Draggable>
        
    );
  } 
  
    // if(s.category == 'textarea'){
    //   draw.push(      
      
    //       <Draggable id={s.id}  key={index}   onStop={(e) => this.onStop(e,index)}  onDrag={this.handleDrag} defaultPosition={{x: s.xpos, y:  s.ypos}} enableUserSelectHack={false}>
    //        <textarea  onChange={(e) => this.onChange(e,index)} value={s.value}   name={s.id}></textarea></Draggable>
       
    // );
    // }    
    
});
  return (
   
   
     
       
         <div className='container-fluid h100percent'>
    <div className='h100percent text-selection-none'>
    <ToastContainer />
      <div className='row h100percent justify-content-center'>
      <div className='col-md-5 text-right'>
          <ul className='dragtool'> 
          {this.state.isSubmitted &&
          <li onClick={(e)=>this.reAssign(e)}>
             <span className="cpointer ">Re-assign</span>
              </li> 
              }
               <li className='cursor-pointer'  onClick={(e)=> this.expandwindowshow(e)}>
               <i id='expandwindowIcon' className="fa fa-expand"></i>
              </li>
             
              {/* <li>
              <i className="fas fa-angle-double-left"></i>
              </li>
              <li>
              <i className="fas fa-angle-double-right"></i>
              </li> */}
              
            </ul>
           
         
        </div>
        <div className='col-md-2 text-center my-auto'>
          Current page {this.state.currentPage}
        </div>
        
        <div className='col-md-5 my-auto'>
      
  <ul id='pagination' className="pagination m-0 ">
    <li className="page-item">
      <button onClick={(e)=>this.getPagePrev(e)} className="page-link "><span aria-hidden="true">&laquo;</span>
        <span className="sr-only">Previous</span></button>
     
    </li>
    <li className="page-item active">
            <button className="page-link cpointer ">{this.state.currentPage}</button>
    
      </li>
    {/* <li className="page-item"><a className="page-link cpointer" onClick={(e)=>this.getPageno(e,2)}>2</a></li>
    <li className="page-item"><a className="page-link cpointer" onClick={(e)=>this.getPageno(e,3)} >3</a></li>
    <li className="page-item"><a className="page-link cpointer" onClick={(e)=>this.getPageno(e,4)}>4</a></li> */}
    <li className="page-item">
      <button onClick={(e)=>this.getPageNext(e)} className="page-link">
      <span aria-hidden="true">&raquo;</span>
        <span className="sr-only">Next</span>
      </button>
     
    </li>
   
  </ul>

        </div>
        <div className='col-md-12 h100percentdropare '> 
        <div className='dropio h100percent ' >
          <div className='droparea' id='droparea'  onDragOver={(e)=>this.onDragOver(e)} onDrop={(e)=>{this.onDrop(e)}}>
            
            {draw}
           
             <ul id='pages' className='pages'>
              <li className='active'> <img className='paheimg' id='dropIMG' src={"data:image/png;base64,"+this.state.selectedImage} /></li>
             

            </ul>
            
        
        
          </div>
          </div>
        </div>
      </div>
    <div id='output' style={{display:'none'}}></div>
    
    </div>
    <Modal isOpen={this.state.assignModal} centered={true} toggle={this.toggleAssignModal} className="modal modalViewGroup reassignmodal">
        <ModalHeader toggle={this.toggleAssignModal}>Re-assign to tagger</ModalHeader>
        <ModalBody>
         <div className='form-group'>
           <label>Select Tagger</label>
           <select name='selectedTagger' onChange={(e)=>this.assignListChange(e)} value={this.state.selectedTagger} className='form-control'>
             {taggerList}
           </select>
         </div>
       
        
        </ModalBody>
        <ModalFooter>
          <button onClick={(e)=>this.assignTagger(e)} className='btn btn-primary'>Re-assign</button>
        </ModalFooter>
        
      </Modal> 
   </div>
  
  
  )
    
  }
};

export default Tagdoc;