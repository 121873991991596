import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


class Review extends Component {  
  constructor(props) {
    super(props);
     this.state = {
      mfgNameen:'',
      mfgNameList:[],
      mfgID:'',
      fileList:[],
      trainModlist:[],
      modal:false,
      expand:false,
      seletedFilename:''
     }
     this.getPagePrev = this.getPagePrev.bind(this)
     this.getPageNext = this.getPageNext.bind(this)
     this.openReviewModal = this.openReviewModal.bind(this)
}
onChange=event=> {
  this.state.mfgNameen = event.target.value;
  this.setState({mfgNameen: this.state.mfgNameen})
 // console.log(this.state.mfgNameen.length);
  if(this.state.mfgNameen.length>1){
    this.setState({mfgNameList:[]})
    document.getElementById("loader").classList.add("show");
        fetch('https://api.cloudsds.com/csplatform/api/Manufacturer/SearchManf', {       
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
   
          },
          body: JSON.stringify({
            MFGName: this.state.mfgNameen,
            MFGID:this.state.mfgID
         })
       })
       .then((response) => response.json())
       .then((responseJson) => {
         document.getElementById("loader").classList.remove("show");
         let tempData = responseJson.data;
     
          this.setState({
            mfgNameList: [...this.state.mfgNameList, ...tempData ],
        })
      document.getElementById('mfglist').classList.remove('d-none')
        console.log(this.state.mfgNameList)
       
       })
       .catch(error =>{
         console.log(error);          
         document.getElementById("loader").classList.remove("show");
       })
      
  }
}
selectNFG(e,id,name){    
  this.state.mfgID = id;
  this.state.mfgNameen = name;
  this.setState({
    mfgID: this.state.mfgID,
    mfgNameen:this.state.mfgNameen,
    fileList:[]
})
document.getElementById('mfglist').classList.add('d-none');
document.getElementById('tabledata').classList.add('d-none');
console.log(this.state.mfgNameen+this.state.mfgID);

document.getElementById("loader").classList.add("show");
        fetch('https://api.cloudsds.com/csplatform/api/Tag/ListSDS', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
   
          },
          body: JSON.stringify({
            MFGName: this.state.mfgNameen,
            MFGID:this.state.mfgID
         })
       })
       .then((response) => response.json())
       .then((responseJson) => {
         document.getElementById("loader").classList.remove("show");
         let tempDataf = responseJson.data;
     
          this.setState({
            fileList: [...this.state.fileList, ...tempDataf ],
        })
        document.getElementById('tabledata').classList.remove('d-none');
        console.log(this.state.fileList)
       
       })
       .catch(error =>{
         console.log(error);          
         document.getElementById("loader").classList.remove("show");
       })
}
getPagePrev(e){

}
getPageNext(e){

}
getPageno(e,no){

}
openReviewModal(e,id,filename){
    this.setState(prevState => {
        return {
            modal : !prevState.modal,
            seletedFilename:filename
        };
      });
}
closeReviewModal(e){
    this.setState(prevState => {
        return {
            modal : !prevState.modal
        };
      });
}
reviewExpand(e){
    this.setState(prevState => {
        return {
            expand : !prevState.expand
        };
      });
}
  render() {  
    let mfgname = this.state.mfgNameen;
    let mfgNameLists = [];
    let fileLists = [];
   this.state.mfgNameList.forEach ((s,index) => {   
   //  console.log(s.companyName)
    mfgNameLists.push(
            <li onClick={(e) => this.selectNFG(e,s.companyID,s.companyName)}  key={index} >{s.companyName+' ('+s.companyID+')'}</li>
      )
   })
   this.state.fileList.forEach ((s,index) => {   
    //  console.log(s.companyName)
    fileLists.push(
               <tr   key={index} >
                 <td><button onClick={(e)=>this.openReviewModal(true,s.id,s.fileName)} className='btn btn-primary'><i className="fas fa-eye"></i></button></td>
               <td>{s.fileName}</td>
               <td>{s.mfgName}</td>
               <td>{s.country}</td>
               <td>{s.uploadTS}</td>
               
               
              
              
               </tr>
       )
    })
  return (
    <div id='review' className='h100percent'>
        <div className='main-header'>
        <div className='row'>
            <div className='col-md-6'><h2 className="page-header">Review</h2></div>
            <div className='col-md-6 text-right'>
               </div>
          </div>
          </div>
        <div className='main-panel'>
           <div className='content '>
            <div className='container-fluid h100percent'>
    <div className='h100percent text-selection-none '>
      <div className='row'>
      <div className="col-md-6">
          <div className="form-group ">
            <label>Manufacturer Name (Type min 2 character for autosearch)</label>
                <input type="text" name='mfgNameen'   value={mfgname} onChange={this.onChange} className="form-control" />
              </div>
              <div className="form-group position-relative">
                
                <ul id='mfglist'  className='manudrop d-none'>
                {mfgNameLists}
                </ul>
               
                </div>
        </div>
        </div>
        <div className='row manudetailheight'>
        <div className='col-md-12 h-100'>
        <div id='tabledata' className="form-group position-relative d-none h-100 overflowy">
                  <table className='table bg-white mb-0'>
                    <thead>
                      <tr>
                      <th width='30'></th>
                        <th>FileName</th>
                        <th>Manufacturer</th>
                        <th>Country</th>
                        <th>Upload Date</th>
                      </tr>
                    </thead>
                    <tbody>
                    
                  {fileLists}
                  </tbody>
                  </table>
                
                </div>
               
        </div>
    
      </div>
         </div>
    
    </div>
    </div>
           </div>
           {this.state.modal===true && <div className="modal fade show d-block" id="preview">
    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
            <div className="modal-header">
               
                <h5 className="modal-title">Review - {this.state.seletedFilename}</h5>
                  
                    
               
                <button onClick={(e)=>this.reviewExpand()} className='review-ex btn btn-sm'>{this.state.expand===false && <i className="fas fa-expand"></i>}{this.state.expand===true && <i className="fas fa-compress-arrows-alt"></i>}</button>
                <button onClick={(e)=>this.closeReviewModal(e)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="modal-body overflow-hidden">
                <div className='row h-100'>
                    
                  
                    <div className={this.state.expand===false ? 'col-md-6 h-100 ':'col-md-12 h-100'} >
                    <div className='h-100 rview-overflow'>
                    <div className="card ">
                        <div className="card-header">
                        SECTION 1. IDENTIFICATION
                        </div>
                        <div className="card-body">
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Product name</h5>
                                <p className="card-text">Hydrochloric acid</p>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Product Number</h5>
                                <p className="card-text">320331</p>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Brand</h5>
                                <p className="card-text">SIGALD</p>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Index-No.</h5>
                                <p className="card-text">017-002-01-X</p>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">REACH No.</h5>
                                <p className="card-text">01-2119484862-27-XXXX</p>
                            </div>
                            
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">CAS-No. </h5>
                                <p className="card-text">7647-01-0</p>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Identified uses</h5>
                                <p className="card-text">Laboratory chemicals, Manufacture of substances</p>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Company</h5>
                                <p className="card-text">Sigma-Aldrich Chemical Pvt Limited,Industrial Area, Anekal Taluka, Plot No 12,
12 Bommasandra - Jigani Link Road,560100 BANGALORE, INDIA</p>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Emergency Phone #</h5>
                                <p className="card-text">+91 98802 05043</p>
                            </div>
                        </div>
                    </div>
                    <div className="card ">
                        <div className="card-header">
                        SECTION 2: Hazards identification
                        </div>
                        <div className="card-body">
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Classification of the substance or mixture</h5>
                                <p className="card-text">
                                    Corrosive to metals (Category 1), H290<br/>
                                    Skin corrosion (Category 1B), H314<br/>
                                    Serious eye damage (Category 1), H318<br/>
                                    Specific target organ toxicity - single exposure (Category 3), Respiratory system, H335<br/>
                                    For the full text of the H-Statements mentioned in this Section, see Section 16.</p>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Pictogram</h5>
                                <p className="card-text">GHS05,GHS07</p>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Signal word</h5>
                                <p className="card-text">Danger</p>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Hazard statements</h5>
                                <div className="card-text">
                                <ol className='m-0 pl-3'>
                                    <li>H290 - May be corrosive to metals.</li>
                                    <li>H314 - Causes severe skin burns and eye damage.</li>
                                    <li>H335 - May cause respiratory irritation.</li>
                                </ol>                                
                                
                                </div>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Precautionary statements</h5>
                                <div className="card-text">
                                    <ol className='m-0 pl-3'>
                                        <li>P260 - Do not breathe dust/ fume/ gas/ mist/ vapours/ spray.</li>
                                        <li>P280 - Wear protective gloves/ protective clothing/ eye protection/ face protection.</li>
                                        <li>P303 + P361 + P353 - IF ON SKIN (or hair): Take off immediately all contaminated
clothing. Rinse skin with water/shower.</li>
                                        <li>P304 + P340 + P310 - IF INHALED: Remove person to fresh air and keep comfortable
for breathing. Immediately call a POISON CENTER/doctor.</li>
                                        <li>P305 + P351 + P338 + P310 - IF IN EYES: Rinse cautiously with water for several minutes.
Remove contact lenses, if present and easy to do. Continue
rinsing. Immediately call a POISON CENTER/doctor.</li>
                                       
                                    
                                    </ol>
                                

                                </div>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Other hazards</h5>
                                <p className="card-text">This substance/mixture contains no components considered to be either persistent,
bioaccumulative and toxic (PBT), or very persistent and very bioaccumulative (vPvB) at
levels of 0.1% or higher.</p>
                            </div>
                            
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                        SECTION 3: Composition/information on ingredients
                        </div>
                        <div className="card-body">
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Formula</h5>
                                <p className="card-text">HCl</p>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Molecular weight</h5>
                                <p className="card-text">36,46 g/mol</p>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Component</h5>
                                <p className="card-text">
                                CAS-No. - 7647-01-0<br/>
                                EC-No. - 231-595-7<br/>
                                Index-No. - 017-002-01-X<br/>
                                Registration number - 01-2119484862-27-XXXX<br/>
                                </p>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Classification</h5>
                                <p className="card-text">
                                Met. Corr. 1; Skin Corr.1B; Eye Dam. 1; STOT SE3; H290, H314, H318,
H335<br/>
Concentration limits:<br/>
>= 0,1 %: Met. Corr. 1,H290; >= 10%: SkinCorr. 1B, H314; 10 &minus; &lt; 25%: Eye Irrit. 2, H319; >=10 %: STOT SE 3, H335; 
                                
                                </p>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Concentration</h5>
                                <p className="card-text">
                                    >= 30 &minus; &lt; 50%
                                  
                                

                                </p>
                            </div>
                            <div className='mb-2 data-holder-bg'>
                                <h5 className="card-title mb-1">Other hazards</h5>
                                <p className="card-text">This substance/mixture contains no components considered to be either persistent,
bioaccumulative and toxic (PBT), or very persistent and very bioaccumulative (vPvB) at
levels of 0.1% or higher.</p>
                            </div>
                            
                        </div>
                    </div>
                    </div>
                    </div>
                    {this.state.expand===false && <div className='col-md-6 h-100'>
                       <iframe frameBorder='0' className='w-100 h-100' src='https://cloudsds.com/cs-new-design/super-site-admin/assets/sds/PrintMSDSAction.pdf'></iframe>
                        
                    </div>}
                </div>
            </div>
            
        </div>
    </div>
</div>
}

           
          
           </div>
  );
}
}


export default Review;