import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Select from 'react-select';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class editAccount extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      fname: '',
      lname:'',
      email: '',
      phone: '',
      password: '',
      cpassword: '',
      role:'',
      cname:'',
      docType:'',
      ifCloudsds:false,
      SelectedCompany:'',
      companyList:[],
      selectedOptiongdoc:[],
      status:'1',
      documnent_type:[
        { value:'1',label:'Safety Data Sheet'},
        { value:'2',label:'Invoice'},
        { value:'3',label:'Technical Data Sheet'},
      ],
 };
 this.onSubmit = this.onSubmit.bind(this);
    this.updateValueCompany = this.updateValueCompany.bind(this);
    this.updateValueDoc = this.updateValueDoc.bind(this)
  }
  updateValueCompany(newValue) {
    this.state.SelectedCompany = newValue
  this.setState({
    SelectedCompany: this.state.SelectedCompany
  });
  console.log(this.state.SelectedCompany)
  
 }
 updateValueDoc(newValue) {
  this.state.selectedOptiongdoc = newValue
this.setState({
  selectedOptiongdoc: this.state.selectedOptiongdoc
});

}
  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
  onSubmit = (e) => {
    e.preventDefault();  
    let error = false;
    let emilPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    var phonePattern = new RegExp(/^[0-9\b]+$/);
    const { fname, lname, email, phone,password,cpassword,role} = this.state;
    if(fname === ''){
         toast.error('Please enter first name.')
         error = true;
         document.getElementsByName('fname')[0].focus()
         return false;
    }
    if(lname === ''){
         toast.error('Please enter last name.');         
         error = true;
         document.getElementsByName('lname')[0].focus();
         return false;
    }
    // if(email === ''){
    //      toast.error('Please enter email.')
    //      error = true
    //      document.getElementsByName('email')[0].focus();
    //      return false;
    // }else{
    //   if (!emilPattern.test(email)) {
    //     toast.error('Please enter valid email.')
    //     error = true
    //     document.getElementsByName('email')[0].focus();
    //     return false;
    //   }
    // }
    if(phone === ''){
         toast.error('Please enter phone number.')
         error = true
         document.getElementsByName('phone')[0].focus();
         return false;
    }else{
      if (!phonePattern.test(phone)) {

        toast.error('Please enter only phone number.')
         error = true
         document.getElementsByName('phone')[0].focus();
         return false;
      }else if(phone.length != 10){
    
        toast.error('Please enter valid phone number.')
         error = true
         document.getElementsByName('phone')[0].focus();
         return false;
      }
    }
    if(role === ''){
      toast.error('Please select role.')
      error = true
      document.getElementsByName('role')[0].focus();
      return false;
 }
//     if(password){
//       if(cpassword === ''){
//         toast.error('Please enter confirm password.')
//         error = true
//         document.getElementsByName('cpassword')[0].focus();
//         return false;
//       }
//       if(password !== cpassword){
//         toast.error('Wrong confirm password entered.')
//         error = true
//         document.getElementsByName('cpassword')[0].focus();
//         return false;
//       }
//  }
 
   // console.log('name : ' + tgname+', desc: ' +tgdesc+',tagType:'+tagType+',docType'+docType);
    if(error !== true){
     
         document.getElementById('loader').classList.add('d-block'); 
         fetch(process.env.REACT_APP_ENV+'/api/UserAuth/UpdateUser',{
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'bearer '+cookies.get("auth")
                
              },
              body: JSON.stringify({ 
                id:parseInt(cookies.get("Uid")),
                email_id : email,
                mobile_no :  phone,
                password : password,
                first_name : fname,
                last_name : lname,
                cid : this.state.SelectedCompany.value, 
                cname : this.state.cname,
                profilephoto:null,
                utype:role,
                roleid:role,
                status:1,
                created_by : cookies.get("userid")
           
             })
              })
            .then((response) => response.json())
            .then((responseJson) => {
              let tempData = responseJson;  
              console.log(tempData)   
              if(tempData.status.statusMessage === 'Success'){
                   toast.success('Successfully user info updated.');
                   const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', tempData.status.authtoken, { path: '/'});  
              setTimeout(function(){ 
                window.history.back();
                }, 1000);
              }else{
                   toast.success('Error ocured. Please Try Again')
                   document.getElementById('loader').classList.remove('d-block'); 
              }
             
             
            })
            .catch(error =>{
              console.log(error); 
            })
             
            
           
             
           
    }
    
    
  }
  componentDidMount(){
    document.getElementById('loader').classList.add('d-block'); 
    if(cookies.get("cid") == '1' && cookies.get("roleid") == '1'){
      this.setState({
        ifCloudsds:true
       })
    }
    fetch(process.env.REACT_APP_ENV+'/api/UserAuth/GetCompanyList',{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':'bearer '+cookies.get("auth")
        
      },
      body: JSON.stringify({ 
     //  Id : cookies.get("cid")  
     })
      })
    .then((response) => response.json())
    .then((responseJson) => {
      let tempData = responseJson;  
      console.log(tempData)   
      if(tempData.status.statusMessage === 'Success'){
        this.state.cname = tempData.data.cname;
        this.setState({
         cname:this.state.cname
        })
        let storedata = [];
           let tempDatas = responseJson.data; 
           for (var i = 0; i < tempDatas.length; i++) {
             storedata.push({
               value: tempDatas[i].id, 
               label:  tempDatas[i].cname,
             })
             
             }
            this.setState({
           companyList: [...this.state.companyList, ...storedata],
          })
          
        const timestamp = new Date().getTime();
        const expireauth = timestamp + 1800000;
        const exdate =  new Date(expireauth);
        cookies.set('auth', tempData.status.authtoken, { path: '/'});   
      //  this.state.SelectedCompany = this.state.companyList[0];
        this.state.selectedOptiongdoc = this.state.documnent_type[0];
        this.setState({
        // SelectedCompany:this.state.SelectedCompany,
         selectedOptiongdoc:this.state.selectedOptiongdoc,
        })
      }else{
           toast.success('Error ocured. Please Try Again')
      }
     document.getElementById('loader').classList.remove('d-block'); 
     
    })
    .catch(error =>{
      console.log(error); 
    })
    let UpdCompId = parseInt(cookies.get("UpdCompID"));
    
    let cid = 0;
    if(cookies.get("cid") == '1' && cookies.get("roleid") == '1'){
      cid = UpdCompId
    }else{
      cid = parseInt(cookies.get("cid"));
    }
    fetch(process.env.REACT_APP_ENV+'/api/UserAuth/GetUserByParam',{
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'Authorization':'bearer '+cookies.get("auth")
           
         },
         body: JSON.stringify({ 
          id : parseInt(cookies.get("Uid")),
          cid:cid
          
        })
         })
       .then((response) => response.json())
       .then((responseJson) => {
         let tempData = responseJson;  
         console.log(tempData)   
         if(tempData.status.statusMessage === 'Success'){
          const timestamp = new Date().getTime();
          const expireauth = timestamp + 1800000;
          const exdate =  new Date(expireauth);
          cookies.set('auth', tempData.status.authtoken, { path: '/'});   
              this.state.fname =  tempData.data[0].first_name;
              this.state.lname =  tempData.data[0].last_name;
              this.state.email =  tempData.data[0].email_id;
              this.state.phone =  tempData.data[0].mobile_no;
              this.state.cid = tempData.data[0].cid;
              let cid = tempData.data[0].cid;
              console.log(cid)
              let companyIndex = this.state.companyList.findIndex(companyList => companyList.value === cid);
              this.state.SelectedCompany = this.state.companyList[companyIndex];
              console.log( this.state.companyList[companyIndex])
             // this.state.password =  tempData.data[0].password;
              this.state.role =  tempData.data[0].roleid;
             // this.state.cname =  tempData.data[0].cname;
              this.setState({
                useres:this.state.useres,
                SelectedCompany:this.state.SelectedCompany
              })
         }else{
              toast.error('Error ocured. Please Try Again')
         }
        document.getElementById('loader').classList.remove('d-block'); 
        
       })
       .catch(error =>{
         console.log(error); 
       })
  }
    goBack() {
        window.history.back();
      }
    render(){
        return(
            <div className="modal fade show d-block"     >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header px-4 d-block">
              
               <div className="row">
              	<div className="col-md-10">
              		 <h4 className="modal-title" ><a onClick={this.goBack}   className="back-button cpointer" >
                <i className="fas fa-arrow-circle-left"></i></a> Update Account</h4>
              	</div>
              	<div className="col-md-2 text-right">
              	</div>
              </div>
            
            </div>
            <div className="modal-body p-4">
              
            
            <ToastContainer />
            <Form onSubmit={this.onSubmit}>
            
              <div className='row'>
                <div className='col-md-6'>
                <div className="form-row">
              <div className="form-group col-md-6">
                <label>First Name <span className='text-danger'>*</span></label>
                <input type='text' onChange={this.onChange} value={this.state.fname} name='fname'  className="form-control" />
                
              </div>
              <div className="form-group col-md-6">
              <label>Last Name <span className='text-danger'>*</span></label>
                <input type='text' onChange={this.onChange} value={this.state.lname}  name='lname'   className="form-control" />
                
              </div>
              <div className="form-group col-md-6">
              <label>Email <span className='text-danger'>*</span></label>
                <input readOnly type='text' onChange={this.onChange} value={this.state.email}  name='email'    className="form-control" />                
              </div>
              <div className="form-group col-md-6">
              <label>Phone </label>
                <input type='text' onChange={this.onChange} value={this.state.phone} name='phone'    className="form-control" />                
              </div>
              <div className="form-group col-md-6">
              <label>Role <span className='text-danger'>*</span></label>
              <select onChange={this.onChange} name='role' value={this.state.role} className="form-control">
                <option value='2'>Administrator</option>
                <option value='5'>System Administrator</option>
                <option value='3'>Tagger</option>
                <option value='4'>Data Scientists</option>
              </select>
                             
              </div>
              <div className="form-group col-md-6 d-none"> 
              <label>Document Type </label>             
          <Select                
        value={this.state.selectedOptiongdoc}
        onChange={this.updateValueDoc}
        isMulti
        options={this.state.documnent_type}
        defaultValue={{ value: '', label: '----' }}
      />
              </div>
             
              <div className="form-group col-md-6" className={this.state.ifCloudsds?'form-group col-md-6':'form-group col-md-6 d-none'}>
              <label>Organization <span className='text-danger'>*</span></label>
              
              <Select                
        value={this.state.SelectedCompany}
        onChange={this.updateValueCompany}
        options={this.state.companyList}
        defaultValue={{ value: '', label: '----' }}
      />
              
        
              </div>
              <div className="form-group col-md-6">
              <label>Status <span className='text-danger'>*</span></label>
              <select onChange={this.onChange} name='status' value={this.state.status} className="form-control">
                <option value='1'>Active</option>
                <option value='0'>Inactive</option>
              </select>
                             
              </div>
              {/* <div className="form-group col-md-6">
              <label>Password </label>
                <input type='password'  onChange={this.onChange} value={this.state.password} name='password'   className="form-control" />                
              </div>
              <div className="form-group col-md-6">
              <label>Confirm Password </label>
                <input type='password'  onChange={this.onChange} value={this.state.cpassword} name='cpassword'   className="form-control" />                
              </div> */}
              
              </div>
              <button type="submit" className="btn btn-primary">Save</button>
                </div>
                
              </div>
             
             </Form>
              
            
           
</div>
                                  
       
   
          </div>
        
        </div>
      </div>
        )
    }
}
export default editAccount