import React, { Component } from 'react';
class Predict extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      mfgNameen:'',
      mfgNameList:[],
      mfgID:'',
      fileList:[],
      csvList:[],  
      expand:false,     
      errorlistHeight:0,    
     }
  this.closeModal = this.closeModal.bind(this);
  this.openModal = this.openModal.bind(this);
  this.accuracyPreview = this.accuracyPreview.bind(this);
  this.tabOpen = this.tabOpen.bind(this);
  this.tabClose = this.tabClose.bind(this);
  this.openModelFiles = this.openModelFiles.bind(this)
  }
  onChange=event=> {
    this.state.mfgNameen = event.target.value;
    this.setState({mfgNameen: this.state.mfgNameen})
   // console.log(this.state.mfgNameen.length);
    if(this.state.mfgNameen.length>1){
      this.setState({mfgNameList:[]})
      document.getElementById("loader").classList.add("show");
          fetch('https://api.cloudsds.com/csplatform/api/Manufacturer/SearchManf', {       
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
     
            },
            body: JSON.stringify({
              MfgName: this.state.mfgNameen,
           })
         })
         .then((response) => response.json())
         .then((responseJson) => {
           document.getElementById("loader").classList.remove("show");
           let tempData = responseJson.data;
       
            this.setState({
              mfgNameList: [...this.state.mfgNameList, ...tempData ],
          })
        document.getElementById('mfglist').classList.remove('d-none')
          console.log(this.state.mfgNameList)
         
         })
         .catch(error =>{
           console.log(error);          
           document.getElementById("loader").classList.remove("show");
         })
        
    }
  }
  selectNFG(e,id,name){    
    this.state.mfgID = id;
    this.state.mfgNameen = name;
    this.setState({
      mfgID: this.state.mfgID,
      mfgNameen:this.state.mfgNameen
  })
  document.getElementById('mfglist').classList.add('d-none');
  document.getElementById('tabledata').classList.add('d-none');
  console.log(this.state.mfgNameen+this.state.mfgID);
   this.setState({
     fileList: [],
   })
  document.getElementById("loader").classList.add("show");
          fetch('https://api.cloudsds.com/csplatform/api/TagV2/ListSDS', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
     
            },
            body: JSON.stringify({
              MFGName: this.state.mfgNameen,
              MFGID:this.state.mfgID
           })
         })
         .then((response) => response.json())
         .then((responseJson) => {
           document.getElementById("loader").classList.remove("show");
           let tempDataf = responseJson.data;
           console.log(tempDataf)
       
            this.setState({
              fileList: [...this.state.fileList, ...tempDataf ],
          })
          document.getElementById('tabledata').classList.remove('d-none');
          console.log(this.state.fileList)
         
         })
         .catch(error =>{
           console.log(error);          
           document.getElementById("loader").classList.remove("show");
         })
  }
  closeModal = (modalname) =>{    
    document.getElementById(modalname).classList.remove('show');
 }        
openModal=(modalname) =>{          
    document.getElementById(modalname).classList.add('show');
 }
 accuracyPreview(e){
   
    this.openModal('accuracy-preview');
    let errHeight = document.getElementById('errorlist').clientHeight;
    this.state.errorlistHeight = errHeight;
    this.setState({
      errorlistHeight: this.state.errorlistHeight
  })
   //console.log(errHeight)
 }
 reviewExpand(e){
  this.setState(prevState => {
      return {
          expand : !prevState.expand
      };
    });
}
tabOpen(id){
document.getElementById(id).classList.add('card-collapsed')
}
tabClose(id){
  document.getElementById(id).classList.remove('card-collapsed')
  }
  openModelFiles(id){
    document.querySelector('#'+id+' .model-files').classList.toggle('d-none')
  }
  goBack() {
    window.history.back();
  }
  render() {  
    const topmargin = {
      marginTop: this.state.errorlistHeight+'px',
    };   
    let mfgname = this.state.mfgNameen;
      let mfgNameLists = [];
      let fileLists = [];
     this.state.mfgNameList.forEach ((s,index) => {   
     //  console.log(s.companyName)
      mfgNameLists.push(
              <li onClick={(e) => this.selectNFG(e,s.companyID,s.companyName)}  key={index} >{s.companyName+' ('+s.companyID+')'}</li>
        )
     })
  return (
<div className='h100percent modelperform'>
        <div className='main-header'>
        <div className='row'>
            <div className='col-md-6'><h2 className="page-header">Model Performance</h2></div>
            <div className='col-md-6 text-right'>           
              {/* <button onClick={(e)=>this.AllpublishModel(e)} className='btn btn-primary mt-2'>All Published Model</button>
          */}
             </div>
          </div>
          </div>
          <div className='main-panel'>
        <div className='content '>
            <div className='container-fluid h100percent'>
    <div className='h100percent text-selection-none bg-white round-corner-all'>
    <div className="row">
    <div className="col-md-3">
          <div className="form-group ">
            <label>Filter By Group</label>
               
              <select className='form-control'>
                <option>--</option>
                <option>Hikma Pharmaceuticals USA Inc.</option>
                <option>Sigma Alridge</option>
              </select>
              </div>
               
             
            </div>
           
      	   <div className="col-md-3">
          <div className="form-group ">
            <label>Filter By Manufacturer</label>
               
              <select className='form-control'>
                <option>--</option>
                <option>Hikma Pharmaceuticals USA Inc.</option>
                <option>Sigma Alridge</option>
              </select>
              </div>
               
             
            </div>
            <div className='col-md-2 '>
            <div className="form-group ">
            <label>Filter By Accuracy</label>
            <select className='form-control'>
                <option>--</option>
                <option>0 - 1 Percent</option>
                <option>1 - 10 Percent</option>
                <option>10 - 25 Percent</option>
                <option>25 - 50 Percent</option>
                <option>50 - 100 Percent</option>
            </select>
            </div>
            </div>
            <div className='col-md-2 '>
            <div className="form-group ">
            <label>Filter By Section</label>
            <select className='form-control'>
                <option>--</option>
                <option>Section 1</option>
                <option>Section 2</option>
                <option>Section 3</option>
                <option>Section 4</option>
                <option>Section 5</option>
                <option>Section 6</option>
                <option>Section 7</option>
                <option>Section 8</option>
                <option>Section 9</option>
                <option>Section 10</option>
                <option>Section 11</option>
                <option>Section 12</option>
                <option>Section 13</option>
                <option>Section 14</option>
                <option>Section 15</option>
                <option>Section 16</option>
            </select>
            </div>
            </div>
            
            <div className='col-md-2'>
            <nav aria-label="...">
  <ul className="pagination psixsec-pag-top-margin d-flex">
    <li className="page-item  flex-fill">
      <span className="page-link">Prev</span>
    </li>   
    <li className="page-item active flex-fill">
      <select className='form-control'>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>  
      </select>
    </li>   
    <li className="page-item flex-fill">
    <span className="page-link">Next</span>
    </li>
  </ul>
</nav>
            </div>
            
            </div>
            
    <div className='row  justify-content-center accuracy-table-height'>
      <div className='col-md-12 h-100 manu-modal'> 
      <div className='h-100 model-detail-overflow'>
         <div id='manu-12345' className="card ">
  <div className="card-header d-block">
    <div className='error-details'>Accuracy - 25%</div>
    <div className='row'>
      <div className='col-md-2'><h6 className='m-0'>Group Name </h6></div> 
      <div className='col-md-4'><h6 className='m-0'>Manufacturer -  Pharmaceuticals USA Inc.</h6></div> 
      <div className='col-md-3'><h6 className='m-0'>Model Status - Active </h6></div> 
      <div className='col-md-3 text-right'>
        <span onClick={(e)=>this.tabOpen('manu-12345')} className='toggle-manu-details tabopen'><i className="fas fa-chevron-up"></i></span>
        <span onClick={(e)=>this.tabClose('manu-12345')} className='toggle-manu-details tabclose'><i className="fas fa-chevron-down"></i></span>
        </div>
    </div>
  
  </div>
  <div className="card-body ">
    
    <div className='row'>
      <div className='col-md-3'>
        <div className='g-pie'>
          <div className='info'>25%</div>
        </div>
        <h5 className='text-center mb-0'>Accuracy</h5>
      </div>
      <div className='col-md-9'>
      <ul className="nav">
  <li className="nav-item">
    <a className="nav-link active" >2020-03-07</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" >2020-02-07</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" >2020-01-07</a>
  </li>
  
</ul>
        <table className='table mb-0 graph-table'>
          <tbody>
            <tr>
              <td>
                <div className='g-bar'><div style={{height:'8%'}} className='error-percentage'><span>8%</span></div></div>
              </td>
              <td><div className='g-bar'><div style={{height:'10%'}} className='error-percentage'><span>10%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'1%'}} className='error-percentage'><span>1%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'3%'}} className='error-percentage'><span>3%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'13%'}} className='error-percentage'><span>13%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'4%'}} className='error-percentage'><span>4%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'5%'}} className='error-percentage'><span>5%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'2%'}} className='error-percentage'><span>2%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'9%'}} className='error-percentage'><span>9%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'63%'}} className='error-percentage'><span>63%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'1%'}} className='error-percentage'><span>1%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'5%'}} className='error-percentage'><span>5%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'3%'}} className='error-percentage'><span>3%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'7%'}} className='error-percentage'><span>7%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'8%'}} className='error-percentage'><span>8%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'9%'}} className='error-percentage'><span>9%</span></div></div></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th className='text-center'>1</th>
              <th className='text-center'>2</th>
              <th className='text-center'>3</th>
              <th className='text-center'>4</th>
              <th className='text-center'>5</th>
              <th className='text-center'>6</th>
              <th className='text-center'>7</th>
              <th className='text-center'>8</th>
              <th className='text-center'>9</th>
              <th className='text-center'>10</th>
              <th className='text-center'>11</th>
              <th className='text-center'>12</th>
              <th className='text-center'>13</th>
              <th className='text-center'>14</th>
              <th className='text-center'>15</th>
              <th className='text-center'>16</th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div  className='col-md-12 mt-2 model-files d-none'>
        
      <table className="table table-striped">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col">File Name</th>
      <th scope="col">Accuracy</th>
      <th scope="col">Tagged Date</th>
      <th scope="col">Train Date</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td >1</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >2</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >3</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >4</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >5</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >6</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >7</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >8</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >9</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >10</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
   
  </tbody>
</table>
      </div>
    </div>
  
  </div>
  <div className="card-footer ">
    <div className='row'>
      <div className='col-md-4'>
        <div><strong>Model Train Date</strong></div>
        <div>2020-02-07</div>
      </div>
      <div className='col-md-4'>
        <div><strong> Model Publish Date</strong></div>
        <div>2020-02-07</div>
      </div>
      <div className='col-md-4 text-right'>
        <button onClick={(e)=>this.openModelFiles('manu-12345')} className='btn btn-primary mt-1'>View Model Files</button>
      </div>
    </div>
  </div>

</div>
         <div id='manu-123456' className="card card-collapsed">
         <div className="card-header d-block">
    <div className='error-details'>Accuracy - 25%</div>
    <div className='row'>
      <div className='col-md-2'><h6 className='m-0'>Group Name </h6></div> 
      <div className='col-md-4'><h6 className='m-0'>Manufacturer -  Pharmaceuticals USA Inc.</h6></div> 
      <div className='col-md-3'><h6 className='m-0'>Model Status - Retired </h6></div>  
      <div className='col-md-3 text-right'>
        <span onClick={(e)=>this.tabOpen('manu-123456')} className='toggle-manu-details tabopen'><i className="fas fa-chevron-up"></i></span>
        <span onClick={(e)=>this.tabClose('manu-123456')} className='toggle-manu-details tabclose'><i className="fas fa-chevron-down"></i></span>
        </div>
    </div>
  
  </div>
  <div className="card-body ">
    
    <div className='row'>
      <div className='col-md-3'>
        <div className='g-pie'>
          <div className='info'>25%</div>
        </div>
        <h5 className='text-center mb-0'>Accuracy</h5>
      </div>
      <div className='col-md-9'>
      <ul className="nav">
  <li className="nav-item">
    <a className="nav-link active" >2020-03-07</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" >2020-02-07</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" >2020-01-07</a>
  </li>
  
</ul>
        <table className='table mb-0 graph-table'>
          <tbody>
            <tr>
              <td>
                <div className='g-bar'><div style={{height:'8%'}} className='error-percentage'><span>8%</span></div></div>
              </td>
              <td><div className='g-bar'><div style={{height:'10%'}} className='error-percentage'><span>10%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'1%'}} className='error-percentage'><span>1%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'3%'}} className='error-percentage'><span>3%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'13%'}} className='error-percentage'><span>13%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'4%'}} className='error-percentage'><span>4%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'5%'}} className='error-percentage'><span>5%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'2%'}} className='error-percentage'><span>2%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'9%'}} className='error-percentage'><span>9%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'63%'}} className='error-percentage'><span>63%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'1%'}} className='error-percentage'><span>1%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'5%'}} className='error-percentage'><span>5%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'3%'}} className='error-percentage'><span>3%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'7%'}} className='error-percentage'><span>7%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'8%'}} className='error-percentage'><span>8%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'9%'}} className='error-percentage'><span>9%</span></div></div></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th className='text-center'>1</th>
              <th className='text-center'>2</th>
              <th className='text-center'>3</th>
              <th className='text-center'>4</th>
              <th className='text-center'>5</th>
              <th className='text-center'>6</th>
              <th className='text-center'>7</th>
              <th className='text-center'>8</th>
              <th className='text-center'>9</th>
              <th className='text-center'>10</th>
              <th className='text-center'>11</th>
              <th className='text-center'>12</th>
              <th className='text-center'>13</th>
              <th className='text-center'>14</th>
              <th className='text-center'>15</th>
              <th className='text-center'>16</th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div  className='col-md-12 mt-2 model-files d-none'>
        
      <table className="table table-striped">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col">File Name</th>
      <th scope="col">Accuracy</th>
      <th scope="col">Tagged Date</th>
      <th scope="col">Train Date</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td >1</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >2</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >3</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >4</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >5</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >6</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >7</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >8</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >9</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >10</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
   
  </tbody>
</table>
      </div>
    </div>
  
  </div>
  <div className="card-footer ">
    <div className='row'>
      <div className='col-md-4'>
        <div><strong>Model Train Date</strong></div>
        <div>2020-02-07</div>
      </div>
      <div className='col-md-4'>
        <div><strong> Model Publish Date</strong></div>
        <div>2020-02-07</div>
      </div>
      <div className='col-md-4 text-right'>
        <button onClick={(e)=>this.openModelFiles('manu-123456')} className='btn btn-primary mt-1'>View Model Files</button>
      </div>
    </div>
  </div>

</div>
          <div id='manu-1234567' className="card card-collapsed">
          <div className="card-header d-block">
    <div className='error-details'>Accuracy - 25%</div>
    <div className='row'>
      <div className='col-md-2'><h6 className='m-0'>Group Name </h6></div> 
      <div className='col-md-4'><h6 className='m-0'>Manufacturer -  Pharmaceuticals USA Inc.</h6></div> 
      <div className='col-md-3'><h6 className='m-0'>Model Status - Active </h6></div> 
      <div className='col-md-3 text-right'>
        <span onClick={(e)=>this.tabOpen('manu-1234567')} className='toggle-manu-details tabopen'><i className="fas fa-chevron-up"></i></span>
        <span onClick={(e)=>this.tabClose('manu-1234567')} className='toggle-manu-details tabclose'><i className="fas fa-chevron-down"></i></span>
        </div>
    </div>
  
  </div>
  <div className="card-body ">
    
    <div className='row'>
      <div className='col-md-3'>
        <div className='g-pie'>
          <div className='info'>25%</div>
        </div>
        <h5 className='text-center mb-0'>Accuracy</h5>
      </div>
      <div className='col-md-9'>
      <ul className="nav">
  <li className="nav-item">
    <a className="nav-link active" >2020-03-07</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" >2020-02-07</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" >2020-01-07</a>
  </li>
  
</ul>
        <table className='table mb-0 graph-table'>
          <tbody>
            <tr>
              <td>
                <div className='g-bar'><div style={{height:'8%'}} className='error-percentage'><span>8%</span></div></div>
              </td>
              <td><div className='g-bar'><div style={{height:'10%'}} className='error-percentage'><span>10%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'1%'}} className='error-percentage'><span>1%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'3%'}} className='error-percentage'><span>3%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'13%'}} className='error-percentage'><span>13%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'4%'}} className='error-percentage'><span>4%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'5%'}} className='error-percentage'><span>5%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'2%'}} className='error-percentage'><span>2%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'9%'}} className='error-percentage'><span>9%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'63%'}} className='error-percentage'><span>63%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'1%'}} className='error-percentage'><span>1%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'5%'}} className='error-percentage'><span>5%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'3%'}} className='error-percentage'><span>3%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'7%'}} className='error-percentage'><span>7%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'8%'}} className='error-percentage'><span>8%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'9%'}} className='error-percentage'><span>9%</span></div></div></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th className='text-center'>1</th>
              <th className='text-center'>2</th>
              <th className='text-center'>3</th>
              <th className='text-center'>4</th>
              <th className='text-center'>5</th>
              <th className='text-center'>6</th>
              <th className='text-center'>7</th>
              <th className='text-center'>8</th>
              <th className='text-center'>9</th>
              <th className='text-center'>10</th>
              <th className='text-center'>11</th>
              <th className='text-center'>12</th>
              <th className='text-center'>13</th>
              <th className='text-center'>14</th>
              <th className='text-center'>15</th>
              <th className='text-center'>16</th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div  className='col-md-12 mt-2 model-files d-none'>
        
      <table className="table table-striped">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col">File Name</th>
      <th scope="col">Accuracy</th>
      <th scope="col">Tagged Date</th>
      <th scope="col">Train Date</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td >1</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >2</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >3</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >4</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >5</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >6</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >7</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >8</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >9</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >10</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
   
  </tbody>
</table>
      </div>
    </div>
  
  </div>
  <div className="card-footer ">
    <div className='row'>
      <div className='col-md-4'>
        <div><strong>Model Train Date</strong></div>
        <div>2020-02-07</div>
      </div>
      <div className='col-md-4'>
        <div><strong> Model Publish Date</strong></div>
        <div>2020-02-07</div>
      </div>
      <div className='col-md-4 text-right'>
        <button onClick={(e)=>this.openModelFiles('manu-1234567')} className='btn btn-primary mt-1'>View Model Files</button>
      </div>
    </div>
  </div>

</div>
          <div id='manu-12345678' className="card card-collapsed">
          <div className="card-header d-block">
    <div className='error-details'>Accuracy - 25%</div>
    <div className='row'>
      <div className='col-md-2'><h6 className='m-0'>Group Name </h6></div> 
      <div className='col-md-4'><h6 className='m-0'>Manufacturer -  Pharmaceuticals USA Inc.</h6></div> 
      <div className='col-md-3'><h6 className='m-0'>Model Status - Active </h6></div> 
      <div className='col-md-3 text-right'>
        <span onClick={(e)=>this.tabOpen('manu-12345678')} className='toggle-manu-details tabopen'><i className="fas fa-chevron-up"></i></span>
        <span onClick={(e)=>this.tabClose('manu-12345678')} className='toggle-manu-details tabclose'><i className="fas fa-chevron-down"></i></span>
        </div>
    </div>
  
  </div>
  <div className="card-body ">
    
    <div className='row'>
      <div className='col-md-3'>
        <div className='g-pie'>
          <div className='info'>25%</div>
        </div>
        <h5 className='text-center mb-0'>Accuracy</h5>
      </div>
      <div className='col-md-9'>
      <ul className="nav">
  <li className="nav-item">
    <a className="nav-link active" >2020-03-07</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" >2020-02-07</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" >2020-01-07</a>
  </li>
  
</ul>
        <table className='table mb-0 graph-table'>
          <tbody>
            <tr>
              <td>
                <div className='g-bar'><div style={{height:'8%'}} className='error-percentage'><span>8%</span></div></div>
              </td>
              <td><div className='g-bar'><div style={{height:'10%'}} className='error-percentage'><span>10%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'1%'}} className='error-percentage'><span>1%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'3%'}} className='error-percentage'><span>3%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'13%'}} className='error-percentage'><span>13%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'4%'}} className='error-percentage'><span>4%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'5%'}} className='error-percentage'><span>5%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'2%'}} className='error-percentage'><span>2%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'9%'}} className='error-percentage'><span>9%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'63%'}} className='error-percentage'><span>63%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'1%'}} className='error-percentage'><span>1%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'5%'}} className='error-percentage'><span>5%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'3%'}} className='error-percentage'><span>3%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'7%'}} className='error-percentage'><span>7%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'8%'}} className='error-percentage'><span>8%</span></div></div></td>
              <td><div className='g-bar'><div style={{height:'9%'}} className='error-percentage'><span>9%</span></div></div></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th className='text-center'>1</th>
              <th className='text-center'>2</th>
              <th className='text-center'>3</th>
              <th className='text-center'>4</th>
              <th className='text-center'>5</th>
              <th className='text-center'>6</th>
              <th className='text-center'>7</th>
              <th className='text-center'>8</th>
              <th className='text-center'>9</th>
              <th className='text-center'>10</th>
              <th className='text-center'>11</th>
              <th className='text-center'>12</th>
              <th className='text-center'>13</th>
              <th className='text-center'>14</th>
              <th className='text-center'>15</th>
              <th className='text-center'>16</th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div  className='col-md-12 mt-2 model-files d-none'>
        
      <table className="table table-striped">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col">File Name</th>
      <th scope="col">Accuracy</th>
      <th scope="col">Tagged Date</th>
      <th scope="col">Train Date</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td >1</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >2</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >3</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >4</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >5</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >6</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >7</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >8</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >9</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
    <tr>
      <td >10</td>
      <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf	</td>
      <td>10%</td>
      <td>2020-02-07</td>
      <td>2020-02-07</td>
      <td><button className='btn btn-sm btn-primary'><i className="fas fa-file-alt"></i></button></td>
    </tr>
   
  </tbody>
</table>
      </div>
    </div>
  
  </div>
  <div className="card-footer ">
    <div className='row'>
      <div className='col-md-4'>
        <div><strong>Model Train Date</strong></div>
        <div>2020-02-07</div>
      </div>
      <div className='col-md-4'>
        <div><strong> Model Publish Date</strong></div>
        <div>2020-02-07</div>
      </div>
      <div className='col-md-4 text-right'>
        <button onClick={(e)=>this.openModelFiles('manu-12345678')} className='btn btn-primary mt-1'>View Model Files</button>
      </div>
    </div>
  </div>

</div>
     </div>
  
      </div>
 
       </div>
       </div>
  </div>
  
            </div>

            
            </div>
    </div>
    
          
    
  )
    
  }
};

export default Predict;