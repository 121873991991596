import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import Logo from '../images/logo.png';
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {   
      uname:'',
      upass:'',
    }
    this.login = this.login.bind(this);
    this.onChange = this.onChange.bind(this)
  }
  onChange= (e) =>{
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }
  login = (e) => {    
    e.preventDefault();  
    let uname = this.state.uname;
    let upass = this.state.upass;
    this.props.handleIsLoggedin(true,uname,upass)
  }

  render() {    
    return (
      <div className="wrapper">
        <div className='fusion-header'>
          <div className='fusion-row'>
          <div className="fusion-logo">
              <a className="fusion-logo-link" href="https://deepdatum.ai/">					
                <img src="https://deepdatum.ai/wp-content/uploads/2020/09/logo.png"  className="fusion-standard-logo" />  
              </a>
		</div>
          </div>
        </div>
        <Form onSubmit={this.login}>
      <div className="login">
      
          
          <div className="form">
             
              <div className="index"><h2>D2STUDIO SIGN IN</h2></div>  
               <div className='form-group index'>
                  <label htmlFor="exampleInputEmail1">Username</label>
                  <input onChange={this.onChange} type="text" value={this.state.uname} className="form-control" name='uname'  placeholder="Enter Username" />
                </div>
                <div className='form-group index'>
                  <label htmlFor="exampleInputPassword1">Password</label>
                  <input onChange={this.onChange} type="password" value={this.state.upass} className="form-control" name='upass' placeholder="Password" />
                </div>
               
                <button  type='submit'   className="btn mt-2 btn-primary btn-lg index">Sign In</button>
              
          </div>
         
      </div>
      </Form>       
<div className='fusion-footer-copyright-area'>
  <div className="fusion-row">
    <div className="fusion-copyright-content">
      <div className='fusion-copyright-notice'>
          <div>
        © Copyright 2023  DeepDatum.  All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</div>
  </div>
    )
  }


}


export default Login