import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import {NavLink} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
class SystemAdmin extends Component {  
     constructor(props) {
          super(props);
          this.state = {
                     
           }
        this.addtoqueue = this.addtoqueue.bind(this)
        this.taggfile = this.taggfile.bind(this)
        }
        addtoqueue = (e) => {
          toast.success('File added to queue for tagging');
          
      }
     // taggfile(e,fileName,mfgName,mfgid,docID){
     taggfile(e){
      localStorage.setItem("TaggedFile", 'Phenytoin Sodium Injection, USP.pdf');
      localStorage.setItem("TaggedFilePage", 1);
      localStorage.setItem("mfgid", '17164');
      localStorage.setItem("docID", '1803');
      this.props.history.push('/tagging') 
      console.log(docID)
    }      
     render() {    
     
  return (
    
   
    <div className='h100percent'>
        <div className='main-header'>
        <div className='row'>
            <div className='col-md-6'><h2 className="page-header">System Admin Area</h2></div>
            <div className='col-md-6 text-right'>             
              </div>
          </div>
          </div>
        <div className='main-panel'>
           <div className='content '>
           <div className='container-fluid h100percent'>
           <ToastContainer />
 <div className='h100percent text-selection-none adminarea'>
 <div className='row mb-3'>
     <div className='col-md-12 text-right'>             
     <NavLink  className='btn btn-primary mr-2 mt-2'  title='Accounts'  activeClassName={"active"} to="/view-client-account">Account</NavLink>
                 
               </div>
               </div>
     <div className='row adminheight  overflow-auto'>
         <div className='col-md-12'>
         <div className='card'>
         <div className="card-header d-block">
             <h4 className='mb-0'>Storage </h4>
         </div>
         <div className="card-body ">
         <div className='row'>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-hdd fa-2x"></i>
                <h2 className="timer count-title count-number" data-to="100" data-speed="1500">20 <span>GB</span></h2>
                <p className="count-text ">File Upload</p>
            </div>
         </div>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-hdd fa-2x"></i>
                <h2 className="timer count-title count-number" data-to="100" data-speed="1500">20 <span>GB</span></h2>
                <p className="count-text ">Tagged File</p>
            </div>
         </div>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-hdd fa-2x"></i>
                <h2 className="timer count-title count-number" data-to="100" data-speed="1500">20 <span>GB</span></h2>
                <p className="count-text ">Train Model</p>
            </div>
         </div>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-hdd fa-2x"></i>
                <h2 className="timer count-title count-number" data-to="100" data-speed="1500">20 <span>GB</span></h2>
                <p className="count-text ">Publish Model</p>
            </div>
         </div>

         </div>
         </div>
        
         </div>
         </div>
         <div className='col-md-12'>
         <div className='card'>
         <div className="card-header d-block">
             <h4 className='mb-0'>Time Spent</h4>
         </div>
         <div className="card-body ">
         <div className='row'>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-clock fa-2x"></i>
                <h2 className="timer count-title count-number" data-to="100" data-speed="1500">20:12:45 <span>Hour</span></h2>
                <p className="count-text ">File Upload</p>
            </div>
         </div>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-clock fa-2x"></i>
                <h2 className="timer count-title count-number" data-to="100" data-speed="1500">20:12:45 <span>Hour</span></h2>
                <p className="count-text ">Tagged File</p>
            </div>
         </div>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-clock fa-2x"></i>
                <h2 className="timer count-title count-number" data-to="100" data-speed="1500">20:12:45 <span>Hour</span></h2>
                <p className="count-text ">Train Model</p>
            </div>
         </div>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-clock fa-2x"></i>
                <h2 className="timer count-title count-number" data-to="100" data-speed="1500">20:12:45 <span>Hour</span></h2>
                <p className="count-text ">Publish Model</p>
            </div>
         </div>

         </div>
         </div>
        
         </div>
         </div>
         <div className='col-md-12'>
         <div className='card mb-0'>
         <div className="card-header d-block">
             <h4 className='mb-0'>File Count </h4>
         </div>
         <div className="card-body ">
         <div className='row'>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-file-alt fa-2x"></i>
                <h2 className="timer count-title count-number" data-to="100" data-speed="1500">1000</h2>
                <p className="count-text ">File Upload</p>
            </div>
         </div>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-file-alt fa-2x"></i>
                <h2 className="timer count-title count-number" data-to="100" data-speed="1500">800 </h2>
                <p className="count-text ">Tagged File</p>
            </div>
         </div>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-file-alt fa-2x"></i>
                <h2 className="timer count-title count-number" data-to="100" data-speed="1500">50 </h2>
                <p className="count-text ">Train Model</p>
            </div>
         </div>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-file-alt fa-2x"></i>
                <h2 className="timer count-title count-number" data-to="100" data-speed="1500">45</h2>
                <p className="count-text ">Publish Model</p>
            </div>
         </div>

         </div>
         </div>
        
         </div>
         </div>
    
     </div>
      </div>
    </div>
    
    </div>
</div>
</div>
  )};
};

export default SystemAdmin;