import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import {NavLink} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class Admin extends Component {  
     constructor(props) {
          super(props);
          this.state = {
                   archived:[],
                   processed:[],
                   published:[],
                   tagged:[] ,
                   taxonomy:[],
                   trained:[],
                   uploaded:[] 
           }
       
        }
      
   
     
    componentDidMount(){
        fetch(process.env.REACT_APP_ENV+'/api/common/DashboardListing',{
             method: 'POST',
             headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json',
               'Authorization':'bearer '+cookies.get("auth")               
             },
             body: JSON.stringify({ 
             
            })
             })
           .then((response) => response.json())
           .then((responseJson) => {
             let tempData = responseJson;  
           //  console.log(tempData)   
             if(tempData.status.statusMessage === 'Success'){
                  cookies.set('auth', tempData.status.authtoken, { path: '/'});     
                  this.state.archived=tempData.data.archived,
                  this.state.processed=tempData.data.processed,
                  this.state.published=tempData.data.published,
                  this.state.tagged=tempData.data.tagged ,
                  this.state.taxonomy=tempData.data.taxonomy,
                  this.state.trained=tempData.data.trained,
                  this.state.uploaded=tempData.data.uploaded              
                  this.setState({
                    archived:this.state.archived,
                    processed:this.state.processed,
                    published:this.state.published,
                    tagged:this.state.tagged ,
                    taxonomy:this.state.taxonomy,
                    trained:this.state.trained,
                    uploaded:this.state.uploaded 
                  })
                  console.log(this.state.uploaded)
                 
             }else{
                  toast.error('Error ocured. Please Try Again');
                 
             }
            
            
           })
           .catch(error =>{
             console.log(error); 
           
           }) 
    }
     render() {    
     
  return (
    
   
    <div className='h100percent'>
        <div className='main-header'>
        <div className='row'>
            <div className='col-md-6'><h2 className="page-header">Dashboard</h2></div>
            <div className='col-md-6 text-right'>             
             
              </div>
          </div>
          </div>
        <div className='main-panel'>
           <div className='content '>
           <div className='container-fluid h100percent '>
           <ToastContainer />
 <div className='h100percent text-selection-none adminarea'>
  <div className='row h-100'>
          <div className='col-md-12 h-100'>
              <div  className='overflow-y-auto h-100'>
      <div className='row overflow-auto '> 
         <div className='col-md-12'>
        
         
             <h4 className='mb-3'>Storage </h4>
        
        
         <div className='row'>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-hdd fa-2x"></i>
                
                    {this.state.uploaded.length<1 && 
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                   } 
                
                    {this.state.uploaded && 
                    <h2 className="timer count-title count-number" data-to="100" data-speed="1500">
                      {this.state.uploaded.size_in_bytes}
                      </h2>
                    }
                    
                <p className="count-text ">Uploaded Files</p>
            </div>
         </div>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-hdd fa-2x"></i>
                {this.state.tagged.length<1 && 
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                   } 
                   {this.state.tagged && 
                    <h2 className="timer count-title count-number" data-to="100" data-speed="1500">{this.state.tagged.size_in_bytes}</h2>
     }
                
                <p className="count-text ">Tagged Files</p>
            </div>
         </div>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-hdd fa-2x"></i>
                {this.state.trained.length<1 && 
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                   } 
                   {this.state.trained && 
                    <h2 className="timer count-title count-number" data-to="100" data-speed="1500">{this.state.trained.size_in_bytes} </h2>
     }
                
                <p className="count-text ">Trained Models</p>
            </div>
         </div>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-hdd fa-2x"></i>
                {this.state.published.length<1 && 
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                   } 
                   {this.state.published && 
                    <h2 className="timer count-title count-number" data-to="100" data-speed="1500">{this.state.published.size_in_bytes} </h2>
                   }
                
                <p className="count-text ">Published Models</p>
            </div>
         </div>

         </div>
        
        
       
         </div>
         
         <div className='col-md-12 mt-5'>
       
        
             <h4 className='mb-3'>File Count </h4>
        
      
         <div className='row'>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-file-alt fa-2x"></i>
                {this.state.uploaded.length<1 && 
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                   } 
                
                    {this.state.uploaded && 
                    <h2 className="timer count-title count-number" data-to="100" data-speed="1500">
                      {this.state.uploaded.no_of_files}
                      </h2>
                    }
               
                <p className="count-text ">Uploaded Files</p>
            </div>
         </div>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-file-alt fa-2x"></i>
                {this.state.tagged.length<1 && 
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                   } 
                
                    {this.state.tagged && 
                    <h2 className="timer count-title count-number" data-to="100" data-speed="1500">
                      {this.state.tagged.no_of_files}
                      </h2>
                    }
                <p className="count-text ">Tagged Files</p>
            </div>
         </div>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-file-alt fa-2x"></i>
                {this.state.trained.length<1 && 
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                   } 
                
                    {this.state.trained && 
                    <h2 className="timer count-title count-number" data-to="100" data-speed="1500">
                      {this.state.trained.no_of_files}
                      </h2>
                    }
               
                <p className="count-text ">Trained Models</p>
            </div>
         </div>
         <div className='col-md-3'>
            <div className="counter">
                <i className="fa fa-file-alt fa-2x"></i>
                {this.state.published.length<1 && 
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                   } 
                
                    {this.state.published && 
                    <h2 className="timer count-title count-number" data-to="100" data-speed="1500">
                      {this.state.published.no_of_files}
                      </h2>
                    }
                <p className="count-text ">Published Models</p>
            </div>
         </div>

         </div>
         </div>
         <div className='col-md-12 mt-5 opacity-2'> 
        
        <h4 className='mb-3'>Time Spent</h4>
   
  
    <div className='row'>
    <div className='col-md-3'>
       <div className="counter">
           <i className="fa fa-clock fa-2x"></i>
           <h2 className="timer count-title count-number" data-to="100" data-speed="1500"> <span>NA</span></h2>
           <p className="count-text ">File Upload</p>
       </div>
    </div>
    <div className='col-md-3'>
       <div className="counter">
           <i className="fa fa-clock fa-2x"></i>
           <h2 className="timer count-title count-number" data-to="100" data-speed="1500"><span>NA</span></h2>
           <p className="count-text ">Tagged File</p>
       </div>
    </div>
    <div className='col-md-3'>
       <div className="counter">
           <i className="fa fa-clock fa-2x"></i>
           <h2 className="timer count-title count-number" data-to="100" data-speed="1500"> <span>NA</span></h2>
           <p className="count-text ">Train Model</p>
       </div>
    </div>
    <div className='col-md-3'>
       <div className="counter">
           <i className="fa fa-clock fa-2x"></i>
           <h2 className="timer count-title count-number" data-to="100" data-speed="1500"> <span>NA</span></h2>
           <p className="count-text ">Publish Model</p>
       </div>
    </div>

    </div>
   
    </div>
  
        
       
         </div>
     </div>
     </div>
          </div>         
     
      </div>
    </div>
    
    </div>
</div>
</div>
  )};
};

export default Admin;