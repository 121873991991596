import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class AddDocType extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      doc:'',
      SelectedCompany:'',
      companyList:[],
      status:'1',
      fieldNo:0,
      allFields:[],
      apilist:[
        {label:'Manufacturer Auto-Search', value:'/api/common/getAllManufacturerName'},
        {label:'Country List', value:'/api/common/getAllContries'},
        {label:'Language List', value:'/api/common/getAllLanguages'}
      ]
  };
  //this.updateValueCompany = this.updateValueCompany.bind(this);
  }
  componentDidMount(){
    // fetch(process.env.REACT_APP_ENV+'/api/common/GetApiList',{
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     'Authorization':'bearer '+cookies.get("auth")
        
    //   },
    //   body: JSON.stringify({ 
    //  //  Id : cookies.get("cid")  
    //  })
    //   })
    // .then((response) => response.json())
    // .then((responseJson) => {
    //   let tempData = responseJson;  
    //   console.log(tempData)   
    //   if(tempData.status.statusMessage === 'Success'){
    //         this.setState({
    //           apilist: tempData.data,
    //       })
          
    //     const timestamp = new Date().getTime();
    //     const expireauth = timestamp + 1800000;
    //     const exdate =  new Date(expireauth);
    //     cookies.set('auth', tempData.status.authtoken, { path: '/',expires: exdate });     
    //     document.getElementById('loader').classList.remove('d-block');       
    //   }else{
    //        toast.success('Error ocured. Please Try Again');
    //        document.getElementById('loader').classList.remove('d-block'); 
    //   }
    
     
    // })
    // .catch(error =>{
    //   console.log(error); 
    //   document.getElementById('loader').classList.remove('d-block'); 
    // })
  }
onChangefield = (e,index) => {  
  console.log([e.target.value])
  let breakfield = e.target.name.split('-');
  let getname = breakfield[1];
  this.state.allFields[index][getname] = e.target.value;
  this.setState({
    allFields:this.state.allFields
  }
    );
    console.log(this.state.allFields)
}
previewApi = (e,index) => {

}
  onChange = (e) => {
    if([e.target.name] == 'fieldNo'){
      let fields = [];
      let fieldnumber = parseInt(e.target.value);
      if(fieldnumber === 0){
        this.state.allFields = [];
        this.setState({
          [e.target.name]: e.target.value,
          allFields:this.state.allFields
        })
      }
      if(fieldnumber > 0 && fieldnumber !== ''){
        let existfield = this.state.allFields.length; 
        if(existfield < fieldnumber){
          let getexactfield = fieldnumber - existfield;
          let innmber = 0;
          for (var i = 0; i < getexactfield; i++) {
            innmber++;
            let fieldno = innmber+existfield;
            fields.push({document_type_id:0,type:'1',label:'',api:'1',mandatroy:true,key:'field_'+fieldno})
          }
          this.state.allFields = [...this.state.allFields, ...fields];
          this.setState({
            [e.target.name]: e.target.value,
            allFields:this.state.allFields
          }
            );
        }
         if(existfield > fieldnumber){
          let getexactfield = existfield - fieldnumber;
          var array = [...this.state.allFields];
          let innmber = 0;
          for (var i = 0; i < getexactfield; i++) {
            innmber++;
            var index = (innmber+fieldnumber) - 1;
            if (index !== -1) {
              array.splice(index, 1);
            }
          }
          this.state.allFields = array;
          this.setState({allFields: this.state.allFields, [e.target.name]: e.target.value});
        }       
      }
      
    }else{
      this.setState({[e.target.name]: e.target.value})
    }
    
  }
  onSubmit = (e) => {
    e.preventDefault();  
   
    let error = false;
    const { doc} = this.state;
    if(doc === ''){
         toast.error('Please enter Doc Type.')
         error = true;
         document.getElementsByName('doc')[0].focus()
         return false;
    }
    if(error !== true){
     
         document.getElementById('loader').classList.add('d-block'); 
         fetch(process.env.REACT_APP_ENV+'/api/Document/AddDocumentType',{
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'bearer '+cookies.get("auth")
                
              },
              body: JSON.stringify({ 
                doctype_name : this.state.doc,
                status:this.state.status,
              //  metaData:this.state.allFields
             })
              })
            .then((response) => response.json())
            .then((responseJson) => {
              let tempData = responseJson;  
            //  console.log(tempData)   
              if(tempData.status.statusMessage === 'Success'){
                toast.success('Document Type successfully Created')
                const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', tempData.status.authtoken, { path: '/'});  
              window.history.back();
             
              }else{
                toast.error('Error ocured.Please try again later.');
               
              }
              document.getElementById('loader').classList.remove('d-block'); 
             
            })
            .catch(error =>{
              console.log(error); 
            })
    }
    
    
  }
    goBack() {
        window.history.back();
      }
    render(){
      
      let filedno = [];      
      let apis = [];
      this.state.apilist.forEach ((t,index) => {  
        apis.push(
          <option key={index} valus={t.value}>{t.label}</option>
        )
      })
      this.state.allFields.forEach ((s,index) => {    
      
        filedno.push(
          <div key={index} className='card'>
          <div className='card-body'>
            <div className='row'>
            <div className='col-md-1 my-auto'>
              <span className='round-number'>{index+1}</span>
            </div>
            <div className='col-md-3'>
            <label>Field Type </label>
            <select value={s.type}  name={s.key+'-type'} onChange={(e) => this.onChangefield(e,index)} className="form-control" >
              <option value='1'>Dropdown</option>
              <option value='2'>Auto-search</option>
            </select>                                         
            </div>
            <div className='col-md-5'>
            <label>Label </label>
            <input onChange={(e) => this.onChangefield(e,index)}  value={s.label} name={s.key+'-label'} className="form-control" type='text' />                                       
            </div>
            <div className='col-md-3'>
            <label>API </label>
            <select value={s.api}  name={s.key+'-api'} onChange={(e) => this.onChangefield(e,index)} className="form-control" >
              {apis}
            </select>   
                                                 
            </div>
            <div className='col-md-2 d-none'>
              <div> <label className='vhidden'>action </label></div>           
           <button onChange={(e) => this.previewApi(e,index)} className='btn btn-primary'>Preview Values</button>                                    
            </div>
            </div>
          </div>
          </div>
        
        )
      })
        return(
          <Form onSubmit={this.onSubmit}>
            <div className="modal fade show d-block"     >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header px-4 d-block">
            
              
               <div className="row">
              	<div className="col-md-10">
              		 <h4 className="modal-title" ><a onClick={this.goBack}   className="back-button cpointer" >
                <i className="fas fa-arrow-circle-left"></i></a> Add Document Type</h4>
              	</div>
              	<div className="col-md-2 text-right">
              	</div>
              </div>
            
            </div>
           
            <ToastContainer />
            <div className="modal-body p-4">
            
            
            
              <div className='row'>
                <div className='col-md-6'>
                <div className="form-row">
              
              {/* <div className="form-group col-md-6">
              <label>Select Organization</label>
				 <Select                
        value={this.state.SelectedCompany}
        onChange={this.updateValueCompany}
        options={this.state.companyList}
        defaultValue={{ value: '', label: '----' }}
      />
              </div> */}
              <div className="form-group col-md-6">
                <label>Document Type </label>
                <input type='text' value={this.state.doc} onChange={this.onChange} name='doc'  className="form-control" />
                
              </div>
              <div className="form-group col-md-6">
                <label>Status </label>
                <select className="form-control" value={this.state.status} onChange={this.onChange} name='status'>
                  <option value='1'>Active</option>
                  <option value='0'>Inactive</option>
                </select>
               
                
              </div>
             <div className='col-md-12'>
             <button type="submit" className="btn btn-primary">Save</button>
             </div>
              {/* <div className="form-group col-md-4">
                <label>No. of Meta-Data fields </label>
                <input type="number" className="form-control" onInput={this.onChange} value={this.state.fieldNo} name="fieldNo" onChange={this.onChange}  />
                {/* <select className="form-control" value={this.state.status} onChange={this.onChange} name='status'>
                  <option value='1'>Active</option>
                  <option value='0'>Inactive</option>
                </select> 
               
                
              </div>
               */}
              </div>
              
                </div>
                {/* {this.state.allFields.length > 0 && <div className='col-md-12'>
                <fieldset className='metadata'>
                <legend className='metadata'>Meta Data Fields</legend>
              
                  {filedno}
               
              
                  
               </fieldset>
                </div>
                } */}
             
              </div>
            
             
              
           
</div>
                                  
       


          </div>
        
        </div>
      </div>
      </Form>
        )
    }
}
export default AddDocType