import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class Addgroup extends Component {  
  constructor(props) {
    super(props);
          this.state = {
            mfgNameen:'',
            mfgID:'',
            mfgNameList:[],
            mfglang:'', 
            groupname : "",
            mfgId : 0,
            langId: "", 
            cid : cookies.get("cid"),
            userid : cookies.get("userid"), 
            status : 1, 
            workflow_status : 0,
            created_by : cookies.get("created_by"),
            created_date : new Date(),
            selectedOptiongdoc:'1',
            documnent_type:[],
            docType:'',
            selectedOptionglang:'',
            metalist:[],
            langlist:[
              { value:'',label:'----'},
              { value:'AF',label:'Afrikaans'},
              { value:'SQ',label:'Albanian'},
              { value:'AR',label:'Arabic'},
              { value:'HY',label:'Armenian'},
              { value:'EU',label:'Basque'},
              { value:'BN',label:'Bengali'},
              { value:'BG',label:'Bulgarian'},
              { value:'CA',label:'Catalan'},
              { value:'KM',label:'Cambodian'},
              { value:'ZH',label:'Chinese (Mandarin)'},
              { value:'HR',label:'Croatian'},
              { value:'CS',label:'Czech'},
              { value:'DA',label:'Danish'},
              { value:'NL',label:'Dutch'},
              { value:'EN',label:'English'},
              { value:'ET',label:'Estonian'},
              { value:'FJ',label:'Fiji'},
              { value:'FI',label:'Finnish'},
              { value:'FR',label:'French'},
              { value:'KA',label:'Georgian'},
              { value:'DE',label:'German'},
              { value:'EL',label:'Greek'},
              { value:'GU',label:'Gujarati'},
              { value:'HE',label:'Hebrew'},
              { value:'HI',label:'Hindi'},
              { value:'HU',label:'Hungarian'},
              { value:'IS',label:'Icelandic'},
              { value:'ID',label:'Indonesian'},
              { value:'GA',label:'Irish'},
              { value:'IT',label:'Italian'},
              { value:'JA',label:'Japanese'},
              { value:'JW',label:'Javanese'},
              { value:'KO',label:'Korean'},
              { value:'LA',label:'Latin'},
              { value:'LV',label:'Latvian'},
              { value:'LT',label:'Lithuanian'},
              { value:'MK',label:'Macedonian'},
              { value:'MS',label:'Malay'},
              { value:'ML',label:'Malayalam'},
              { value:'MT',label:'Maltese'},
              { value:'MI',label:'Maori'},
              { value:'MR',label:'Marathi'},
              { value:'MN',label:'Mongolian'},
              { value:'NE',label:'Nepali'},
              { value:'NO',label:'Norwegian'},
              { value:'FA',label:'Persian'},
              { value:'PL',label:'Polish'},
              { value:'PT',label:'Portuguese'},
              { value:'PA',label:'Punjabi'},
              { value:'QU',label:'Quechua'},
              { value:'RO',label:'Romanian'},
              { value:'RU',label:'Russian'},
              { value:'SM',label:'Samoan'},
              { value:'SR',label:'Serbian'},
              { value:'SK',label:'Slovak'},
              { value:'SL',label:'Slovenian'},
              { value:'ES',label:'Spanish'},
              { value:'SW',label:'Swahili'},
              { value:'SV',label:'Swedish '},
              { value:'TA',label:'Tamil'},
              { value:'TT',label:'Tatar'},
              { value:'TE',label:'Telugu'},
              { value:'TH',label:'Thai'},
              { value:'BO',label:'Tibetan'},
              { value:'TO',label:'Tonga'},
              { value:'TR',label:'Turkish'},
              { value:'UK',label:'Ukrainian'},
              { value:'UR',label:'Urdu'},
              { value:'UZ',label:'Uzbek'},
              { value:'VI',label:'Vietnamese'},
              { value:'CY',label:'Welsh'},
              { value:'XH',label:'Xhosa'}
        ],
          };
          this.updateValuedoc = this.updateValuedoc.bind(this)
        //  this.updateValuelang = this.updateValuelang.bind(this)
         // this.updateValuedrop = this.updateValuedrop.bind(this)
  }
  componentDidMount(){
    document.getElementById('loader').classList.add('d-block'); 
    fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocumentTypeList',{
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'Authorization':'bearer '+cookies.get("auth")
           
         },
         body: JSON.stringify({ 
         
        })
         })
       .then((response) => response.json())
       .then((responseJson) => {
         let tempData = responseJson;  
       //  console.log(tempData)   
         if(tempData.status.statusMessage === 'Success'){
              let exdate = new Date();
              cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
              this.state.documnent_type =  tempData.data;
              this.setState({
                documnent_type:this.state.documnent_type
              })
              document.getElementById('loader').classList.remove('d-block'); 
         }else{
              toast.error('Error ocured. Please Try Again');
              document.getElementById('loader').classList.remove('d-block'); 
         }
       
        
       })
       .catch(error =>{
         console.log(error); 
         document.getElementById('loader').classList.remove('d-block'); 
       }) 

    //    document.getElementById('loader').classList.add('d-block'); 
    // fetch(process.env.REACT_APP_ENV+'/api/Document/GetMetaDatalistForDocumentType',{
    //      method: 'POST',
    //      headers: {
    //        'Accept': 'application/json',
    //        'Content-Type': 'application/json',
    //        'Authorization':'bearer '+cookies.get("auth")
           
    //      },
    //      body: JSON.stringify({ 
    //       doctype_id:1
    //     })
    //      })
    //    .then((response) => response.json())
    //    .then((responseJson) => {
    //      let tempData = responseJson;  
    //    //  console.log(tempData)   
    //      if(tempData.status.statusMessage === 'Success'){
    //           let exdate = new Date();
    //           cookies.set('auth', tempData.status.authtoken, { path: '/',expires: new Date(Date.now()+2592000) }); 
    //           this.state.metalist =  tempData;
    //           this.setState({
    //             metalist:this.state.metalist
    //           })
    //           console.log(metalist)
    //           document.getElementById('loader').classList.remove('d-block'); 
    //      }else{
    //           toast.error('Error ocured. Please Try Again');
    //           document.getElementById('loader').classList.remove('d-block'); 
    //      }
       
        
    //    })
    //    .catch(error =>{
    //      console.log(error); 
    //      document.getElementById('loader').classList.remove('d-block'); 
    //    }) 
  }
  // updateValuelang(newValue) {
  //    this.setState({
  //     selectedOptionglang: newValue
  //  });
  // }
  updateValuedrop = (selectName,selectedOption) =>{
    let gindex = parseInt(this.name);
   
    this.state.metalist[selectName].selectedOptiongDrop = selectedOption; 
    this.state.metalist[selectName].valueLabel = selectedOption.label;
    this.state.metalist[selectName].value = selectedOption.value;
    this.setState({
      metalist: this.state.metalist
  })
  }
  updateValuedoc(newValue) {
    this.setState({
      selectedOptiongdoc: newValue,
     
  });
  
console.log(this.state.metalist)
 }
  // selectNFG(e,id,name){    
  //   this.state.mfgID = id;
  //   this.state.mfgNameen = name;
  //   this.state.mfgId = id;
  //   this.setState({
  //     mfgID: this.state.mfgID,
  //     mfgNameen:this.state.mfgNameen,
  //     mfgId:this.state.mfgId
  // })
  // document.getElementById('mfglist').classList.add('d-none')
  // console.log(this.state.mfgNameen+this.state.mfgID)
  // }
  selectNFGAto(e,id,name,index){    
    this.state.metalist[index].value = id;
    this.state.metalist[index].valueLabel = name;
    this.state.mfgID = id;
    this.state.mfgNameen = name;
    this.state.mfgId = id;
    this.setState({
      metalist: this.state.metalist
  })
  let getid = this.state.metalist[index].key
  document.getElementById(getid).classList.add('d-none');
  console.log(this.state.metalist)
  }
  onChanges = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
  onChangesDT= (e) => {
    this.state.docType =  e.target.value
    this.setState({
      docType:  this.state.docType
    }
      )
      let getDocMetaList  = this.state.documnent_type.filter(item => item.id == e.target.value);
      let DocMetaList = JSON.parse(getDocMetaList[0].field_json)
      console.log(DocMetaList)
     // this.state.metalist = DocMetaList;
     // this.setState({metalist:this.state.metalist})
     let ArrMetaList = [];
     DocMetaList.forEach((ml,index,arr) => { 
       if(ml.type === '1'){
        ArrMetaList.push({
          api: ml.api,
          document_type_id: ml.document_type_id,
          error: ml.error,
          id: ml.id,
          key: ml.key,
          label: ml.label,
          mandatroy: ml.mandatroy,
          type: ml.type,
          value:'',
          option:[],
          selectedOptiongDrop:[],
          valueLabel:''
        })
        
       }
       if(ml.type === '2'){
        ArrMetaList.push({
          api: ml.api,
          document_type_id: ml.document_type_id,
          error: ml.error,
          id: ml.id,
          key: ml.key,
          label: ml.label,
          mandatroy: ml.mandatroy,
          type: ml.type,
          value:'',
          option:[],
          selectedOptiongDrop:[],
          valueLabel:''
        })
        document.getElementById('loader').classList.add('d-block'); 
        fetch(process.env.REACT_APP_ENV+ml.api,{
             method: 'POST',
             headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json',
               'Authorization':'bearer '+cookies.get("auth")
               
             },
             body: JSON.stringify({ 
            })
             })
           .then((response) => response.json())
           .then((responseJson) => {
             let tempData = responseJson;  
           //  console.log(tempData)   
             if(tempData){
                  // let exdate = new Date();
                  // cookies.set('auth', tempData.status.authtoken, { path: '/',expires: new Date(Date.now()+2592000) }); 
                 // console.log(tempData.replace(/'/g,"'");
                 let optionsold = tempData.data;
                 let options = [{'value':'','label':'----'}, ...optionsold]
                // options.unshift()
                console.log(options)
                 let gindex = index;
                 let val = ml.value;
                 this.state.metalist[gindex].option = options;
                 this.state.metalist[gindex].selectedOptiongDrop = options.filter(option => option.value === val);
                 this.setState({
                      metalist:this.state.metalist
                 })
                                 
                  document.getElementById('loader').classList.remove('d-block'); 
             }else{
                  toast.error('Error ocured. Please Try Again');
                  document.getElementById('loader').classList.remove('d-block'); 
             }
              
            
           })
           .catch(error =>{
             console.log(error); 
             document.getElementById('loader').classList.remove('d-block'); 
           }) 
        
       }
       
     })
     this.setState({
      metalist:ArrMetaList
       })
    console.log(this.state.docType)
  }
  onChangeDF= (e,index) => {
    this.state.metalist[index].value = e.target.value
    this.setState({
      metalist: this.state.metalist
    })
    console.log(this.state.metalist)
  }
  onChangeAutoSearch = (e,index) => {
    this.state.metalist[index].valueLabel = e.target.value;
    this.state.metalist[index].option = [];
    this.setState({metalist: this.state.metalist});
   // console.log(this.state.mfgNameen.length);
   let apiurl = this.state.metalist[index].api;
    if(this.state.metalist[index].valueLabel.length>1){   
      document.getElementById("loader").classList.add("d-block");
          fetch(process.env.REACT_APP_ENV+apiurl, {       
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization':'bearer '+cookies.get("auth")
     
            },
            body: JSON.stringify({
             MfgName: e.target.value,
             mfgId:0
           })
         })
         .then((response) => response.json())
         .then((responseJson) => {
           document.getElementById("loader").classList.remove("d-block");
           let tempData = responseJson.data;
           const timestamp = new Date().getTime();
           const expireauth = timestamp + 1800000;
           const exdate =  new Date(expireauth);
           cookies.set('auth', responseJson.status.authtoken, { path: '/'});  
           this.state.metalist[index].option = tempData
            this.setState({
              metalist: this.state.metalist,
          })
          let getid = this.state.metalist[index].key;
        document.getElementById(getid).classList.remove('d-none')
       //   console.log(this.state.metalist[index].option)
         
         })
         .catch(error =>{
           console.log(error);          
           document.getElementById("loader").classList.remove("d-block");
         })
        
    }
  }
  onChange=event=> {
    this.state.mfgNameen = event.target.value;
    this.setState({mfgNameen: this.state.mfgNameen})
   // console.log(this.state.mfgNameen.length);
    if(this.state.mfgNameen.length>1){
      this.setState({mfgNameList:[]})
      document.getElementById("loader").classList.add("d-block");
          fetch(process.env.REACT_APP_ENV+'/api/Common/SearchManufacturer', {       
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization':'bearer '+cookies.get("auth")
     
            },
            body: JSON.stringify({
              MfgName: this.state.mfgNameen,
           })
         })
         .then((response) => response.json())
         .then((responseJson) => {
           document.getElementById("loader").classList.remove("d-block");
           let tempData = responseJson.data;
           const timestamp = new Date().getTime();
           const expireauth = timestamp + 1800000;
           const exdate =  new Date(expireauth);
           cookies.set('auth', responseJson.status.authtoken, { path: '/'});  
            this.setState({
              mfgNameList: [...this.state.mfgNameList, ...tempData ],
          })
        document.getElementById('mfglist').classList.remove('d-none')
          console.log(this.state.mfgNameList)
         
         })
         .catch(error =>{
           console.log(error);          
           document.getElementById("loader").classList.remove("d-block");
         })
        
    }
  }
    goBack() {
        window.history.back();
      }
      onSubmit = (e) => {
        let error = false;
        // let mfgNameen = this.state.mfgNameen;
        // if(mfgNameen === ''){
        //   toast.error('Please enter manufacturer name.')
        //        error = true
        // }
        let groupname = this.state.groupname;
        if(groupname === ''){
          toast.error('Please enter group name.')
               error = true
        }
        let selectedOptiongdoc = this.state.docType;
        if(selectedOptiongdoc === ''){
          toast.error('Please select document type.')
               error = true
        }
        this.state.metalist.forEach((m,index,arr) => {
          if(!m.value){
            toast.error(m.error)
               error = true
          }
        })
        // let selectedOptionglang = this.state.selectedOptionglang;
        // if(selectedOptionglang === ''){
        //   toast.error('Please select language.')
        //        error = true
        // }
        e.preventDefault();
        if(error === false){
          let meta_json = [];
          this.state.metalist.forEach((ml,index,arr) => {
            meta_json.push({
              api: ml.api,
              document_type_id: ml.document_type_id,
              error: ml.error,
              id: ml.id,
              key: ml.key,
              label: ml.label,
              mandatroy: ml.mandatroy,
              type: ml.type,
              value:ml.value,
            }) 
          })
          let meta = JSON.stringify(meta_json);
          document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/Document/AddGroup',{
               method: 'POST',
               headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 'Authorization':'bearer '+cookies.get("auth")
                 
               },
               body: JSON.stringify({              
                groupname : this.state.groupname,
                category:this.state.selectedOptiongdoc,
                field_json: meta,
                mfgId : 84,
                langId:"EN",                 
                cid : cookies.get("cid"),
                userid : cookies.get("userid"), 
                status : 1, 
                workflow_status : 0,
                created_by : cookies.get("created_by"),
                created_date : new Date()
              })
               })
             .then((response) => response.json())
             .then((responseJson) => {
               let tempData = responseJson; 
               //console.log(tempData.data[0].id)   
                if(tempData.status.statusMessage === 'Success'){
                  const timestamp = new Date().getTime();
                  const expireauth = timestamp + 1800000;
                  const exdate =  new Date(expireauth);
                  cookies.set('auth', tempData.status.authtoken, { path: '/'});    
                     toast.success('Successfully Created Group.');
                    //  setTimeout(function(){ 
                    //   this.props.history.push('/view-group') 
                    //   }, 1000);
                    this.props.closeAddGroup(true)
                  }else{
                     toast.error('Error ocured. Please Try Again');
                     this.props.closeAddGroup(false)
                    
                }
                document.getElementById('loader').classList.remove('d-block'); 
              
             })
             .catch(error =>{
               console.log(error); 
               document.getElementById('loader').classList.remove('d-block'); 
               this.props.closeAddGroup(false)
             })
        }
        
        
      }
    render(){
      let mfgname = this.state.mfgNameen;
    let mfgNameLists = []; 
   this.state.mfgNameList.forEach ((s,index) => {   
   //  console.log(s.companyName)
    mfgNameLists.push(
            <li onClick={(e) => this.selectNFG(e,s.mnfID,s.mnfName)}  key={index} >{s.mnfName+' ('+s.mnfID+')'}</li>
      )
   })
   let docTypeList = [];
   docTypeList.push(<option key='9999'  value=''>---</option>)
   this.state.documnent_type.forEach ((s,index) => {   
       docTypeList.push(
            <option key={index} value={s.id}>{s.doctype_name}</option>
       )
   })
   let metalists = [];
   this.state.metalist.forEach((m,index) => {  
    if(m.type == '1'){
      let options = [];
      var itemsProcessed = 0;
      if(m.option.length){
        m.option.forEach((t,gindex,array) => { 
          //  console.log(t.companyName)
          itemsProcessed++;          
           options.push(
            <li onClick={(e) => this.selectNFGAto(e,t.mnfID,t.mnfName,index)}  key={gindex} >{t.mnfName+' ('+t.mnfID+')'}</li>
           )           
           })
      }
        metalists.push(
          <div key={index} className="col-md-6">
             <div className="form-group ">
             <label>{m.label} <span className='text-danger'>*</span></label>
             <input autoComplete="off" type="text" name={m.key}   value={m.valueLabel} onChange={(e) => this.onChangeAutoSearch(e,index)} className="form-control" />
             <div >
             <div className="form-group position-relative">                
                   <ul id={m.key}  className='manudrop d-none'>
                   {options}
                   </ul>
                   </div>
                   </div>
                  
               </div>
          </div>
     )
      
      
     
    }
    if(m.type == '2'){
     metalists.push(
       <div key={index} className="col-md-6">
          <div className="form-group ">
          <label>{m.label} <span className='text-danger'>*</span></label>
          <div >
          <Select name={index}        
                 value={m.selectedOptiongDrop}
                 onChange={e => this.updateValuedrop(index,e)}
                 options={m.option}
               />
           
            
            </div>
          
            </div>
       </div>
  )
    }
    
})
        return(
           
            <div className="modal-body p-4">
            {/* <ToastContainer /> */}
           
           <Form onSubmit={this.onSubmit}>
            
              <div className='row'>
                <div className='col-md-6'>
               
              <div className="form-group ">
                <label>Group Name <span className='text-danger'>*</span></label>
                <input type='text' onChange={this.onChanges} name='groupname'  className="form-control" />
                
              </div> 
             
             
              
                </div>
                <div className='col-md-6'>
                <div className="form-group">
                <label >Document Type <span className='text-danger'>*</span></label>
                <select className="form-control" name='docType' value={this.state.docType} onChange={this.onChangesDT}>
                  {docTypeList}
                </select>
                {/* <Select
                
                 value={this.state.selectedOptiongdoc}
                 onChange={this.updateValuedoc}
                 options={this.state.documnent_type}
                 
               /> */}
                
              </div>
              
                </div>
               
                {/* <div className='col-md-6'>
                <div className="form-group ">
            <label>Manufacturer Name (Type min 2 character for autosearch)</label>
                <input autoComplete="off" type="text" name='mfgNameen'   value={mfgname} onChange={this.onChange} className="form-control" />
              </div>
              <div className="form-group position-relative">                
                <ul id='mfglist'  className='manudrop d-none'>
                {mfgNameLists}
                </ul>
                </div>
                </div>
                <div className='col-md-6'>
                <div className="form-group">
                <label>Select Language</label>
                <Select                
                 value={this.state.selectedOptionglang}
                 onChange={this.updateValuelang}
                 options={this.state.langlist}
               />
                </div>
                </div>
              */}
              {metalists}
              <div className='col-md-12'>
              <button type="submit" className="btn btn-primary">Save</button>
              </div>
              </div>
             
             
              </Form>
          
           
</div>
                                  
       
   
         
        )
    }
}
export default Addgroup