import React, { Component } from 'react';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import {NavLink} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Select from 'react-select';
import Cookies from 'universal-cookie';
import Tagdoc from "./TagDocReadOnly.jsx";
import JsonDownload from "./JsonDownload.jsx"
import ViewFile from "./ViewFile.jsx"
const cookies = new Cookies();
class UploadedFile extends Component {  
     constructor(props) {
          super(props);
          this.state = {
                     tagfilelist:[],
                     groupList:[],
                     groupListAll:[],                     
               selectedgroup:'',
               selectedManu : '',
               selectFilter : '',
               docTypeDrop:[],
               docTypeDropSelected:'',
               selectedlang:'',
               langlist:[
                { value:'',label:'----'},
                { value:'AF',label:'Afrikaans'},
                { value:'SQ',label:'Albanian'},
                { value:'AR',label:'Arabic'},
                { value:'HY',label:'Armenian'},
                { value:'EU',label:'Basque'},
                { value:'BN',label:'Bengali'},
                { value:'BG',label:'Bulgarian'},
                { value:'CA',label:'Catalan'},
                { value:'KM',label:'Cambodian'},
                { value:'ZH',label:'Chinese (Mandarin)'},
                { value:'HR',label:'Croatian'},
                { value:'CS',label:'Czech'},
                { value:'DA',label:'Danish'},
                { value:'NL',label:'Dutch'},
                { value:'EN',label:'English'},
                { value:'ET',label:'Estonian'},
                { value:'FJ',label:'Fiji'},
                { value:'FI',label:'Finnish'},
                { value:'FR',label:'French'},
                { value:'KA',label:'Georgian'},
                { value:'DE',label:'German'},
                { value:'EL',label:'Greek'},
                { value:'GU',label:'Gujarati'},
                { value:'HE',label:'Hebrew'},
                { value:'HI',label:'Hindi'},
                { value:'HU',label:'Hungarian'},
                { value:'IS',label:'Icelandic'},
                { value:'ID',label:'Indonesian'},
                { value:'GA',label:'Irish'},
                { value:'IT',label:'Italian'},
                { value:'JA',label:'Japanese'},
                { value:'JW',label:'Javanese'},
                { value:'KO',label:'Korean'},
                { value:'LA',label:'Latin'},
                { value:'LV',label:'Latvian'},
                { value:'LT',label:'Lithuanian'},
                { value:'MK',label:'Macedonian'},
                { value:'MS',label:'Malay'},
                { value:'ML',label:'Malayalam'},
                { value:'MT',label:'Maltese'},
                { value:'MI',label:'Maori'},
                { value:'MR',label:'Marathi'},
                { value:'MN',label:'Mongolian'},
                { value:'NE',label:'Nepali'},
                { value:'NO',label:'Norwegian'},
                { value:'FA',label:'Persian'},
                { value:'PL',label:'Polish'},
                { value:'PT',label:'Portuguese'},
                { value:'PA',label:'Punjabi'},
                { value:'QU',label:'Quechua'},
                { value:'RO',label:'Romanian'},
                { value:'RU',label:'Russian'},
                { value:'SM',label:'Samoan'},
                { value:'SR',label:'Serbian'},
                { value:'SK',label:'Slovak'},
                { value:'SL',label:'Slovenian'},
                { value:'ES',label:'Spanish'},
                { value:'SW',label:'Swahili'},
                { value:'SV',label:'Swedish '},
                { value:'TA',label:'Tamil'},
                { value:'TT',label:'Tatar'},
                { value:'TE',label:'Telugu'},
                { value:'TH',label:'Thai'},
                { value:'BO',label:'Tibetan'},
                { value:'TO',label:'Tonga'},
                { value:'TR',label:'Turkish'},
                { value:'UK',label:'Ukrainian'},
                { value:'UR',label:'Urdu'},
                { value:'UZ',label:'Uzbek'},
                { value:'VI',label:'Vietnamese'},
                { value:'CY',label:'Welsh'},
                { value:'XH',label:'Xhosa'}
          ],
          meta_json:[],
	        field_json:[],
           metalist:[],
           ginfo:false,
           TaggedFilePage:1,
           selectedImage:'',
           imageView:false,
           modal: false,
        fade: false,
        selectedFilename:'',
        viewFilter:'',
        filelistunAssign:[],
        ifDesigner:false,
        isTagger:false,
        isSubmitted:false,
        assigneduserid:null,
        ifDownload:false
           }
        this.addtoqueue = this.addtoqueue.bind(this)
        this.taggfile = this.taggfile.bind(this)
        this.getdocbygroupById = this.getdocbygroupById.bind(this)
        this.updateValueGroup = this.updateValueGroup.bind(this)
        this.updatedocTypeDrop = this.updatedocTypeDrop.bind(this)
        this.toggleModal = this.toggleModal.bind(this);
        this.isReassigned = this.isReassigned.bind(this);
        }
        toggleModal(){
          this.setState({
              modal: !this.state.modal,
              imageView: !this.state.imageView
          });
         // console.log( 'after setState: ', this.state );
      }
        updatedocTypeDrop(newValue){
          this.state.docTypeDropSelected = newValue
          this.setState({
            docTypeDropSelected: this.state.docTypeDropSelected
        });
       this.getTagData()
    }
    getTagData =(e)=>{
      if(this.state.docTypeDropSelected.value){
        if(this.state.docTypeDropSelected.value === 1){
          document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/Document/GetGroupList_UnTrained',{
             method: 'POST',
             headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'Authorization':'bearer '+cookies.get("auth")
             
             },
             body: JSON.stringify({  
              cid : parseInt(cookies.get("cid")),
              category:parseInt(this.state.docTypeDropSelected.value)
            })
             })
           .then((response) => response.json())
           .then((responseJson) => {
             let tempData = responseJson; 
            // console.log(tempData)   
            let exdate = new Date();
             cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
             if(tempData.data === null){
             toast.error('Error ocured. Please Try Again')
               
               // console.log(this.state.grouplist)
              }else if(tempData.data.length === 0){

              }else{
              let storedata = [];
              let tempDatas = responseJson.data; 
              for (var i = 0; i < tempDatas.length; i++) {
                storedata.push({
                  value: tempDatas[i].id, 
                  label:  tempDatas[i].groupname,
                })
                
                }
                this.state.groupList = storedata;
                this.state.groupListAll = tempDatas;
               this.setState({
              groupList:this.state.groupList,
              groupListAll:this.state.groupListAll
             })
             
             
             
             

            console.log(this.state.groupList[0].value)
           if(cookies.get('SeleQGroup')){
             this.state.selectedgroup = cookies.get('SeleQGroup');
             let selgroup =  cookies.get('SeleQGroup');
             let field_json = [];
            let fj = this.state.groupListAll.filter(groupList => groupList.id == selgroup.value);
            field_json = JSON.parse(fj[0].field_json);   
           this.state.field_json = field_json;
            this.setState({
              selectedgroup:this.state.selectedgroup,
              field_json:this.state.field_json
             })
             
             this.getdocbygroupById(this.state.selectedgroup.value)
             this.getMetaListall();
           }else{
            this.state.selectedgroup = this.state.groupList[0];
            let field_json = [];
            let fj = this.state.groupListAll.filter(groupList => groupList.id == this.state.groupList[0].value);
            field_json = JSON.parse(fj[0].field_json);   
           this.state.field_json = field_json;
            this.setState({
              selectedgroup:this.state.selectedgroup,
              field_json:this.state.field_json
             })
            this.getdocbygroupById(this.state.groupList[0].value)
            this.getMetaListall();
           }
             
            }
            document.getElementById('loader').classList.remove('d-block'); 
            
           })
           .catch(error =>{
             console.log(error); 
           })
        }else{
            this.getdocbygroupById()
      
          }
      }else{
        this.state.tagfilelist = [] 
        this.setState({
          tagfilelist: this.state.tagfilelist
      });
        
      }
    }
        addtoqueue = (e) => {
          toast.success('File added to queue for tagging');
          
      }
      onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
      }
      retagGroup= (e,gid) => {
        console.log(this.state.selectedgroup.value)
        let groupid = '';
        if(gid){
          groupid = gid
        }else{
          groupid = this.state.selectedgroup.value
        }
        document.getElementById('loader').classList.add('d-block'); 
                fetch(process.env.REACT_APP_ENV+'/api/tagv2/SetReTagging',{
           method: 'POST',
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'Authorization':'bearer '+cookies.get("auth")
             
           },
           body: JSON.stringify({   
            groupId:groupid
          })
           })
         .then((response) => response.json())
         .then((responseJson) => {
           console.log(responseJson)
           let exdate = new Date();
           cookies.set('auth', responseJson.status.authtoken, { path: '/'}); 
           let language = '';        
         document.getElementById('loader').classList.remove('d-block'); 
         if(responseJson.status.statusMessage === 'Success'){
          toast.success('Group added for re-tagging');
          this.getdocbygroupById();
         }else{
          toast.success('Error occurred. Please try later');
         }
         
		})
         .catch(error =>{
           console.log(error); 
         })
        
    }
    collapse = (e) =>{
      this.setState({ginfo: false})
    }
    openginfo = (e) =>{
      this.setState({ginfo: !this.state.ginfo})
    }
    viewImage = (e,filename) =>{
      this.state.selectedFilename = filename
      this.state.TaggedFilePage = 1;
      this.setState({
        imageView: !this.state.imageView,
        selectedFilename: this.state.selectedFilename,
    });
    }
    closefile =(status)=>{
      if(status){
        this.state.imageView = false;
        this.setState({
          imageView:this.state.imageView
        })
      }
      
    }
    getMetaListall = (e) =>{
      let ArrMetaList = [];
      if(this.state.field_json && this.state.field_json.length > 0 ){
	this.state.field_json.forEach((ml,index,arr) => { 
    if(ml.type === '1'){
      ArrMetaList.push({
        api: ml.api,
        document_type_id: ml.document_type_id,
        error: ml.error,
        id: ml.id,
        key: ml.key,
        label: ml.label,
        mandatroy: ml.mandatroy,
        type: ml.type,
        value:ml.value,
        option:[],
        selectedOptiongDrop:[],
        valueLabel:''
      })
      fetch(process.env.REACT_APP_ENV+ml.api,{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
          
        },
        body: JSON.stringify({              
          MfgName:'',
          mfgId:ml.value
       })
        })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson; 
        console.log(tempData.data[0].mnfName)   
         if(tempData.status.statusMessage === 'Success'){
          const timestamp = new Date().getTime();
          const expireauth = timestamp + 1800000;
          const exdate =  new Date(expireauth);
          cookies.set('auth', tempData.status.authtoken, { path: '/'});   
           let gindex = index;
           this.state.metalist[gindex].valueLabel = tempData.data[0].mnfName;
           this.setState({
            metalist:this.state.metalist
             })
                  
           }else{
              toast.success('Error ocured. Please Try Again')
         }
         
       
      })
      .catch(error =>{
        console.log(error); 
      })
     
     
    }
    if(ml.type === '2'){
      ArrMetaList.push({
        api: ml.api,
        document_type_id: ml.document_type_id,
        error: ml.error,
        id: ml.id,
        key: ml.key,
        label: ml.label,
        mandatroy: ml.mandatroy,
        type: ml.type,
        value:ml.value,
        option:[],
        selectedOptiongDrop:'',
        valueLabel:''
      })  
     document.getElementById('loader').classList.add('d-block'); 
     fetch(process.env.REACT_APP_ENV+ml.api,{
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'bearer '+cookies.get("auth")
            
          },
          body: JSON.stringify({ 
         })
          })
        .then((response) => response.json())
        .then((responseJson) => {
          let tempData = responseJson;  
        //  console.log(tempData)   
          if(tempData){
               // let exdate = new Date();
               // cookies.set('auth', tempData.status.authtoken, { path: '/',expires: new Date(Date.now()+2592000) }); 
              // console.log(tempData.replace(/'/g,"'");
              // let optionsold = tempData.data;
              // let attachoption = [{'value':'','label':'----'}];
              // let options = [attachoption, ...optionsold]
              let options = tempData.data;
               let gindex = index;
               let val = ml.value;
               console.log(options)
               this.state.metalist[gindex].option = options;
              this.state.metalist[gindex].selectedOptiongDrop = options.filter(option => option.value === val);
              let valulabel = options.filter(option => option.value === val);
              this.state.metalist[gindex].valueLabel = valulabel[0].label;
               this.setState({
                metalist:this.state.metalist
               })
                              
               document.getElementById('loader').classList.remove('d-block'); 
          }else{
               toast.error('Error ocured. Please Try Again');
               document.getElementById('loader').classList.remove('d-block'); 
          }
           
         
        })
        .catch(error =>{
          console.log(error); 
          document.getElementById('loader').classList.remove('d-block'); 
        }) 
     
    }
    
  })
}
     this.state.metalist = ArrMetaList;
     this.setState({
      metalist:this.state.metalist 
       })
     
    }
      updateValueGroup(newValue) {
        this.state.selectedgroup = newValue;
        let field_json = [];
        let fj = this.state.groupListAll.filter(groupList => groupList.id == newValue.value);
        field_json = JSON.parse(fj[0].field_json);   
        this.state.field_json = field_json;
      this.setState({
        selectedgroup: this.state.selectedgroup,
        field_json:this.state.field_json
      });
     
      this.getdocbygroupById();
      this.getMetaListall();
      let exdate = new Date();
      cookies.set('SeleQGroup', newValue, { path: '/'});  
     }
     updateFilter= (e) =>{
       this.state.selectFilter =  e.target.value;
      this.setState({selectFilter:this.state.selectFilter});
      
      this.getdocbygroupById('');
     }
     updateViewFilter = (e) =>{
      this.state.viewFilter =  e.target.value;
     this.setState({viewFilter:this.state.viewFilter});
    }
     getdocbygroupById(){
      this.setState({
        tagfilelist: [],
        filelistunAssign:[]
    })
   
    
    let parms = '';
    if(this.state.docTypeDropSelected.value){
      let parmsUnAssign = '';
      if(this.state.docTypeDropSelected.value === 1){
        parmsUnAssign = JSON.stringify({              
          group_id:this.state.selectedgroup.value,
          category:parseInt(this.state.docTypeDropSelected.value),
          cid : cookies.get("cid"),
          workflow_status:100, 
        })
      }else{
        parmsUnAssign = JSON.stringify({  
          category:parseInt(this.state.docTypeDropSelected.value),
          cid : cookies.get("cid"),
          workflow_status:100
        })
      }
      fetch(process.env.REACT_APP_ENV+'/api/Document/GetUnAssignDocument',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
          
        },
        body: parmsUnAssign
        })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson; 
       // console.log(tempData.data[0].id)   
        let exdate = new Date();
         cookies.set('auth', tempData.status.authtoken, { path: '/'});  
              
         if(!tempData.data.length){             
           }else{  
             let preListunAssign = [];  
             tempData.data.forEach((s,index) =>{
              preListunAssign.push({
                 categoryId: s.categoryId,
                 cid: s.cid,
countrycode: s.countrycode,
created_by_name: s.created_by_name,
docpath: s.docpath,
doctype: s.doctype,
field_json: s.field_json,
filename: s.filename,
groupId: s.groupId,
groupname: s.groupname,
id: s.id,
langId: s.langId,
mfgId: s.mfgId,
modified_by_name: s.modified_by_name,
status: s.status,
uploadtime: s.uploadtime,
checked:false
               })
             })
             this.state.filelistunAssign = preListunAssign;
            this.setState({
              filelistunAssign: this.state.filelistunAssign,
          })
        
         }
      
       
      })
      .catch(error =>{
        console.log(error); 
        
      })
    }
    if(this.state.docTypeDropSelected.value === 1){
      parms = JSON.stringify({  
        category:parseInt(this.state.docTypeDropSelected.value),
        assigned_user_id:this.state.assigneduserid,
        status : 1,
        group_id:this.state.selectedgroup.value.toString(),
        sortColumn:this.state.selectFilter,
        cid : parseInt(cookies.get("cid")),
      })
    }else{
      parms = JSON.stringify({  
        category:parseInt(this.state.docTypeDropSelected.value),
        assigned_user_id:this.state.assigneduserid,
        status : 1,
        group_id:0,
        sortColumn:this.state.selectFilter,
        cid : parseInt(cookies.get("cid")),
      })
    }
      document.getElementById('loader').classList.add('d-block'); 
      fetch(process.env.REACT_APP_ENV+'/api/Document/GetAssignDocument',{
           method: 'POST',
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'Authorization':'bearer '+cookies.get("auth")
             
           },
           body:parms
           })
         .then((response) => response.json())
         .then((responseJson) => {
           let tempData = responseJson; 
          // console.log(tempData.data[0].id)   
           let exdate = new Date();
            cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
            document.getElementById('loader').classList.remove('d-block'); 
            if(!tempData.data.length){
               toast.error('No records found..');
                             
              }else{    
                this.state.tagfilelist =   tempData.data;
                this.setState({
                  tagfilelist: this.state.tagfilelist
               })
               
               

            
            }
         
          
         })
         .catch(error =>{
           console.log(error); 
           document.getElementById('loader').classList.remove('d-block'); 
         })
    }
      componentDidMount(){
        document.getElementById('loader').classList.add('d-block'); 
	 fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocumentTypeList',{
		  method: 'POST',
		  headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization':'bearer '+cookies.get("auth")
			
		  },
		  body: JSON.stringify({ 
		  
		 })
		  })
		.then((response) => response.json())
		.then((responseJson) => {
		  let tempData = responseJson;  
		//  console.log(tempData)   
		  if(tempData.status.statusMessage === 'Success'){
			   let exdate = new Date();
			   cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
			   this.state.docType =  tempData.data;
         let storedata = [];
         storedata.push({value:'',label:'Select'});
			   for (var i = 0; i < tempData.data.length; i++) {
						   storedata.push({
							 value: tempData.data[i].id, 
							 label:  tempData.data[i].doctype_name,
						   })
						  
				}
        let isTagger = false;
        if(cookies.get("roleid")){
          let getrole = cookies.get("roleid");
          if(getrole ==='3'){
            isTagger = true
          }
        }
			   this.setState({
				 docTypeDrop:storedata,
				 docTypeDropSelected:storedata[0],
         isTagger:isTagger
			   })
			   
			   console.log(this.state.docTypeDropSelected)
			   document.getElementById('loader').classList.remove('d-block'); 
		  }else{
			   toast.error('Error ocured. Please Try Again');
			   document.getElementById('loader').classList.remove('d-block'); 
		  }
		 
		 
		})
		.catch(error =>{
		  console.log(error); 
		  document.getElementById('loader').classList.remove('d-block'); 
		}) 
        
       }  
     
     // taggfile(e,fileName,page,mfgid,docID){
      closeTagDesigner = () =>{
        this.setState({
          ifDesigner:!this.state.ifDesigner
        })
      }
      closeIfDownload = (status) =>{
        this.setState({
          ifDownload:!this.state.ifDownload
        })
      }
     taggfile(e,file,page,mfgid,docid,gid,isSubmitted){
      let exdate = new Date();
      cookies.set('TaggedFile', file, { path: '/'});
      cookies.set('TaggedFilePage', page, { path: '/'});
      cookies.set('mfgid', mfgid, { path: '/' });
      cookies.set('docID', docid, { path: '/' });
      cookies.set('groupid', gid, { path: '/'});
      cookies.set('TagCategory', this.state.docTypeDropSelected.value, { path: '/'});
      
      let submitstatus = false;
      if(parseInt(isSubmitted) === 2){
        submitstatus=true
      }
      this.state.isSubmitted = submitstatus
      this.setState({
        ifDesigner:!this.state.ifDesigner,
        isSubmitted:this.state.isSubmitted
      })
      // localStorage.setItem("TaggedFile", 'Phenytoin Sodium Injection, USP.pdf');
      // localStorage.setItem("TaggedFilePage", 1);
      // localStorage.setItem("mfgid", '17164');
      // localStorage.setItem("docID", '1803');
    //  this.props.history.push('/tagging') 
     // console.log(docID)
    } 
    taggfileJsonDownload(e,file,page,mfgid,docid,gid,isSubmitted){
      cookies.set('TaggedFile', file, { path: '/'});
      cookies.set('TaggedFilePage', page, { path: '/'});
      cookies.set('mfgid', mfgid, { path: '/' });
      cookies.set('docID', docid, { path: '/' });
      cookies.set('groupid', gid, { path: '/'});
      cookies.set('TagCategory', this.state.docTypeDropSelected.value, { path: '/'});
      
      let submitstatus = false;
      if(parseInt(isSubmitted) === 2){
        submitstatus=true
      }
      this.state.isSubmitted = submitstatus
      this.setState({
        ifDownload:!this.state.ifDownload,
        isSubmitted:this.state.isSubmitted
      })
     
    } 
    removeFromQ(e,docid,gid,index){
      document.getElementById('loader').classList.add('d-block'); 
      fetch(process.env.REACT_APP_ENV+'/api/Document/AssignDocument',{
 method: 'POST',
 headers: {
   'Accept': 'application/json',
   'Content-Type': 'application/json',
   'Authorization':'bearer '+cookies.get("auth")
   
 },
 body: JSON.stringify({   
  assigned_user_id:cookies.get("userid"),
 group_id : gid,
 doc_id : docid,
 cid : cookies.get("cid"),
 status : 0,
 category:this.state.docTypeDropSelected.value
})
 })
.then((response) => response.json())
.then((responseJson) => {
 console.log(responseJson)
 let exdate = new Date();
 cookies.set('auth', responseJson.status.authtoken, { path: '/'}); 
 var array = this.state.tagfilelist;
 if (index !== -1) {
  array.splice(index, 1);
  this.setState({tagfilelist: array});
}
document.getElementById('loader').classList.remove('d-block'); 
toast.success('File removed from Queue');
})
.catch(error =>{
 console.log(error); 
})
     
    }  
    selectRow =(e,id) =>{
      var elements = document.getElementsByClassName("tableRowDetect");
      for (var i = 0, len = elements.length; i < len; i++) {
        elements[i].classList.remove('selected')
      }
      document.getElementById(id).classList.add('selected')
    } 
    goUnassignfile =()=>{
      let category = this.state.docTypeDropSelected.value;
      if(this.state.docTypeDropSelected.value === 1){
        let group = this.state.selectedgroup.value;
        cookies.set('unassigncgroup', group, { path: '/'});  
      }
      
      cookies.set('unassigncat', category, { path: '/'});  
     if(this.state.isTagger){
      this.props.history.push('/') 
     }else{
      this.props.history.push('/uploaded-file-list') 
     }
      
    } 
    markDuplicate =(e,id,groupid,index)=>{
      document.getElementById('loader').classList.add('d-block'); 
      fetch(process.env.REACT_APP_ENV+'/api/Document/MarkUploadedFileDuplicate',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")           
        },
        body: JSON.stringify({   
         doc_id:id,
        category : this.state.docTypeDropSelected.value
       })
        })
       .then((response) => response.json())
       .then((responseJson) => {
         if(responseJson.status.statusMessage == 'Success'){
           cookies.set('auth', responseJson.status.authtoken, { path: '/'}); 
           var array = this.state.tagfilelist;
               if (index !== -1) {
                array.splice(index, 1);
                this.setState({tagfilelist: array});
              }
              document.getElementById('loader').classList.remove('d-block'); 
              toast.success('File marked duplicate');
//            document.getElementById('loader').classList.add('d-block'); 
//            fetch(process.env.REACT_APP_ENV+'/api/Document/AssignDocument',{
//             method: 'POST',
//             headers: {
//               'Accept': 'application/json',
//               'Content-Type': 'application/json',
//               'Authorization':'bearer '+cookies.get("auth")           
//             },
//             body: JSON.stringify({   
//               assigned_user_id:cookies.get("userid"),
//  group_id : groupid,
//  doc_id : id,
//  cid : cookies.get("cid"),
//  status : 0
//            })
//             })
//            .then((response) => response.json())
//            .then((responseJson) => {
//              if(responseJson.status.statusMessage == 'Success'){
//                cookies.set('auth', responseJson.status.authtoken, { path: '/'}); 
//                var array = this.state.tagfilelist;
//                if (index !== -1) {
//                 array.splice(index, 1);
//                 this.setState({tagfilelist: array});
//               }
//               document.getElementById('loader').classList.remove('d-block'); 
//               toast.success('File marked duplicate');
//              }else{
//                toast.success('Error occurred.');
//              }
//              document.getElementById('loader').classList.remove('d-block'); 
//            })
//            .catch(error =>{
//             console.log(error); 
//             document.getElementById('loader').classList.remove('d-block'); 
//             toast.error('Error occurred. Please try again later.');
//            })  
         }else{
           toast.success('Error occurred.');
         }
         document.getElementById('loader').classList.remove('d-block'); 
       })
       .catch(error =>{
        console.log(error); 
        document.getElementById('loader').classList.remove('d-block'); 
        toast.error('Error occurred. Please try again later.');
       })  
    } 
    isReassigned=(reassigned)=>{
      if(reassigned){
        this.getTagData()
      }
    }
     render() {    
       let countLeftFiles = 0;
       if(this.state.filelistunAssign.length > 0){
        countLeftFiles = this.state.filelistunAssign.length
       }
      let filelists = [];
      let filelistsO = [];
      let retagstatus = true;
      let tagfilecount = this.state.tagfilelist.length;
       this.state.tagfilelist.forEach ((s,index) => {   
        let lang = s.langId;

        let language = '';
        if(lang !== null){
          let langindex = this.state.langlist.findIndex(tagkey => tagkey.value === lang);   
          if(langindex !== -1){
            language = this.state.langlist[langindex].label;
          }
        }
        let status = s.submit_status;
        let btnset = '';
        if(status == 2){
          status = 'Submitted';
          btnset
        }else{
          status = 'Not submitted';
          retagstatus = false
        }
        
        let mdate = s.modified_dt.split("T")[0];
        let latestmdate = mdate.split('-');
        let formatedmdate = latestmdate[1]+'-'+latestmdate[2]+'-'+latestmdate[0];
        let assigned_user_name ='';
        if(s.assigned_user_name !==null || s.assigned_user_name !==undefined){
          assigned_user_name = s.assigned_user_name
        }
        let assigned = s.assigned_dt.split("T")[0];
        let latestassigned = assigned.split('-');
        let formatedassigned = latestassigned[1]+'-'+latestassigned[2]+'-'+latestassigned[0];

        if(this.state.docTypeDropSelected.value === 1){
   if(this.state.viewFilter){
     if(parseInt(this.state.viewFilter) === s.submit_status){
      filelists.push(
        <Row className='tableRowDetect' onClick={(e) =>this.selectRow(e,'rowID-'+s.id)} id={'rowID-'+s.id} key={index}>
          <Cell>
          <button onClick={(e) => this.viewImage(e,s.filename)} className='btn btn-primary btn-sm mr-1'>View File</button>
            <button title='Tag Details' onClick={(e)=> this.taggfile(e,s.filename,1,s.mfgId,s.doc_id,s.group_id,s.submit_status)} className='btn btn-primary btn-sm mr-1'><i className='fas fa-tags'></i> Tag Details</button>
            {/* <button title='Tag Details' onClick={(e)=> this.taggfileJsonDownload(e,s.filename,1,s.mfgId,s.doc_id,s.group_id,s.submit_status)} className='btn btn-primary btn-sm mr-1'><i className='fas fa-tags'></i> Json File</button> */}
        </Cell>
        <Cell>{s.filename}</Cell>
        <Cell>{formatedassigned}</Cell>
        <Cell>{mdate !== '0001-01-01' ?formatedmdate:formatedassigned}</Cell>
        <Cell>{status}</Cell>
        <Cell>{assigned_user_name}</Cell>
       
        
      </Row>
                 
          )
     }
   }else{
    filelists.push(
      <Row className='tableRowDetect' onClick={(e) =>this.selectRow(e,'rowID-'+s.id)} id={'rowID-'+s.id}  key={index}>
        <Cell>
        <button onClick={(e) => this.viewImage(e,s.filename)} className='btn btn-primary btn-sm mr-1'>View File</button>
          <button title='Tag Details' onClick={(e)=> this.taggfile(e,s.filename,1,s.mfgId,s.doc_id,s.group_id,s.submit_status)} className='btn btn-primary btn-sm mr-1'><i className='fas fa-tags'></i> Tag Details</button>
          {/* <button title='Tag Details' onClick={(e)=> this.taggfileJsonDownload(e,s.filename,1,s.mfgId,s.doc_id,s.group_id,s.submit_status)} className='btn btn-primary btn-sm mr-1'><i className='fas fa-tags'></i> Json File</button> */}
        </Cell>
      <Cell>{s.filename}</Cell>
      <Cell>{formatedassigned}</Cell>
      <Cell>{mdate !== '0001-01-01' ?formatedmdate:formatedassigned}</Cell>
      <Cell>{status}</Cell>
      <Cell>{assigned_user_name}</Cell>
     
      
    </Row>
               
        )
   }
          
        }else{
          if(this.state.viewFilter){
            if(parseInt(this.state.viewFilter) === s.submit_status){
          filelists.push(
            <Row className='tableRowDetect' onClick={(e) =>this.selectRow(e,'rowID-'+s.id)} id={'rowID-'+s.id} key={index}>
              <Cell>
                <button onClick={(e) => this.viewImage(e,s.filename)} className='btn btn-primary btn-sm mr-1'>View File</button>
                <button title='Tag Details' onClick={(e)=> this.taggfile(e,s.filename,1,s.mfgId,s.doc_id,s.group_id,s.submit_status)} className='btn btn-primary btn-sm mr-1'><i className='fas fa-tags'></i> Tag Details</button>
            
            {/* {s.submit_status === 2 && 
            <button  onClick={(e) => this.retagGroup(e,s.group_id)}  className='btn btn-primary btn-sm ml-1'>Re-tag</button> 
          } */}
              </Cell>
            <Cell>{s.filename}</Cell> 
            <Cell>{assigned}</Cell>
            <Cell>{mdate !== '0001-01-01' ?mdate:assigned}</Cell>
            <Cell>{status}</Cell>
            <Cell>{assigned_user_name}</Cell>
           
            
          </Row>
                     
              )
        }
        }else{
          filelists.push(
            <Row className='tableRowDetect' onClick={(e) =>this.selectRow(e,'rowID-'+s.id)} id={'rowID-'+s.id} key={index}>
              <Cell>
                <button onClick={(e) => this.viewImage(e,s.filename)} className='btn btn-primary btn-sm mr-1'>View File</button>
                <button title='Tag Details' onClick={(e)=> this.taggfile(e,s.filename,1,s.mfgId,s.doc_id,s.group_id,s.submit_status)} className='btn btn-primary btn-sm mr-1'><i className='fas fa-tags'></i> Tag Details</button>
                
           
            {/* {s.submit_status === 2 && 
             <button  onClick={(e) => this.retagGroup(e,s.group_id)}  className='btn btn-primary btn-sm ml-1'>Re-tag</button> 
          } */}
              </Cell>
            <Cell>{s.filename}</Cell>
            <Cell>{assigned}</Cell>
            <Cell>{mdate !== '0001-01-01' ?mdate:assigned}</Cell>
            <Cell>{status}</Cell>
            <Cell>{assigned_user_name}</Cell>
           
            
          </Row>
                     
              )
        }
        }
         
         })
         if(!this.state.tagfilelist.length){
          retagstatus = false
        }
        let groupListMeta = [];
        console.log(this.state.metalist)
this.state.metalist.forEach ((gl,index) => {
  let label = gl.label.replace("Search", "").replace("Select", "");
	groupListMeta.push(
		<div key={index} className=''> 
                 <label><b>{label} :</b> {gl.valueLabel}</label>
    </div>
	)
	
}) 

  return (   
    <div className='h100percent'>
        <div className='main-header'>
        <div className='row'>
            <div className='col-md-6'><h2 className="page-header">Manage Tagged Files <span className='invisible'>{tagfilecount}</span></h2></div>
            <div className='col-md-6 text-right'>             
              {/* <NavLink  className='btn btn-primary mr-2 mt-2'  title='Tag Queue'  activeClassName={"active"} to="/tag-queue">Tag Queue</NavLink>
               */}
              </div>
          </div>
          </div>
        <div className='main-panel'>
           <div className='content '>
           <div className='container-fluid h100percent'>
           <ToastContainer />
 <div className='h100percent text-selection-none bg-white  round-corner-all'>
     <div className='row'>
     <div className='col-md-3' >
        <div className="form-group ">
                 <div >
                 <label>Document Type</label>
         <Select    
         className ='orgindex'            
        value={this.state.docTypeDropSelected}
        onChange={this.updatedocTypeDrop}
        options={this.state.docTypeDrop}
        defaultValue={{ value: '', label: '----' }}
      />
                 </div>
                 </div> 
               </div>
               {this.state.docTypeDropSelected.value === 1 &&  
     <div className='col-md-2'>
        <div className="form-group ">
        <label>Select  Group</label>
        <Select       
        className ='orgindex'             
        value={this.state.selectedgroup}
        onChange={this.updateValueGroup}
        options={this.state.groupList}
        defaultValue={{ value: '', label: '----' }}
      />
          </div>
        
        </div>
     }
       
        {this.state.docTypeDropSelected.value === 1 && groupListMeta.length > 0 &&  
        <div className='col-md-3'>
           <div className="form-group ">
        <label>Group Information</label>
        <div className={this.state.ginfo ?'viewgInfo-parent open':'viewgInfo-parent'}>
          <div className='oc'>
            <span onBlur={ this.collapse } onClick={this.openginfo} tabIndex="0" className='tools openinfo'><i className="fas fa-info-circle"></i></span>
            
          </div>
        <div className='bg-light viewgInfo'>{groupListMeta}</div>
        </div>
        
          
        </div>     
        </div> 
    
    }
     
    {this.state.docTypeDropSelected.value === 1 &&  groupListMeta.length == 0 &&
    <div className='col-md-3'></div>
    }
    {this.state.docTypeDropSelected.value &&
    <div className='col-md-2'>
        <div className="form-group ">
            <label>Filter By   </label>
                <select
                name='viewFilter'
                value={this.state.viewFilter}
                onChange={this.updateViewFilter}
                className="form-control">
                    <option value=''>All</option>
                    <option value='2'>Submitted</option>
                    <option value='1'>Not Submitted</option>
                </select>
              </div>
        </div>
     }
     {this.state.docTypeDropSelected.value &&
      <div className='col-md-2'>
        <div className="form-group ">
            <label>Sort By  </label>
                <select
                name='selectFilter'
                value={this.state.selectFilter}
                onChange={this.updateFilter}
                className="form-control">
                    <option value=''>------</option>
                    {/* <option value=''>Manufacturer Name (ASC)</option>
                    <option value=''>Manufacturer Name (DESC)</option> */}
                    <option value='assigned_dt asc'>Assigned date (ASC)</option>
                    <option value='assigned_dt desc'>Assigned date (DESC)</option>
                    <option value='modified_dt asc'>Updated Date (ASC)</option>
                    <option value='modified_dt desc'>Updated Date (DESC)</option>
                </select>
              </div>
        </div>
     
                  }
     {/* {this.state.docTypeDropSelected.value === 1 &&  
      <div  className='col-md-2 text-right'>
      <div className="form-group ">
        <label className='vhidden'>retag</label>
        <div>
        <button disabled={!retagstatus} onClick={(e) => this.retagGroup(e,'')}  className='btn btn-primary'>Re-tag Group</button>
        </div>
        
               </div>
      </div> 
     } */}
   </div>
        <div className="manudetailheight row" >
        <div className="col-md-12  h-100">
         
        <div style={{width: '100%', height: '100%'}}>
        {filelists.length> 0 &&   
          <StickyTable leftStickyColumnCount={0} >
            <Row>              
             
            <Cell className='tagfile-tool-width'></Cell>
            
              <Cell>File Name</Cell>
              <Cell>Assign Date</Cell>
              <Cell>Last Updated Date</Cell>
              <Cell>Status</Cell>
              <Cell>Assigned User</Cell>
              
            </Row>
            {filelists}
            
          </StickyTable>
     }
     {/* {this.state.docTypeDropSelected.value && this.state.docTypeDropSelected.value !== 1 &&  
        <StickyTable leftStickyColumnCount={0} >
        <Row>
          <Cell>File Name</Cell>
          <Cell>Assign Date</Cell>
          <Cell>Last Updated Date</Cell>
          <Cell>Status</Cell>
          <Cell>Assigned User</Cell>
          <Cell></Cell>
        </Row>
        <Row>
          <Cell>document - Copy (3).pdf</Cell>
          <Cell>2020-07-28</Cell>
          <Cell>2020-07-28</Cell>
          <Cell>Not submitted</Cell>
          <Cell>Super Admin</Cell>
          <Cell><button title="Tag file" className="btn btn-primary btn-sm mr-2"><i className="fas fa-tags"></i> Tag File</button>
          <button title="Remove from Queue" className="btn btn-danger btn-sm mr-2"><i className="fas fa-trash"></i> Remove</button></Cell>
        </Row>
       
        
      </StickyTable>
     } */}
        </div>
   

</div>
        </div>
      </div>
    </div>
    
    </div>
</div>
{this.state.imageView && 
  <ViewFile 
   file={this.state.selectedFilename} 
   category={this.state.docTypeDropSelected.value} 
   close={this.closefile} 
   />   
      }


{this.state.ifDesigner &&
 <div className="modal fade show d-block" >
 <div id='modal-tagDesigner-readonly' className="modal-dialog modal-lg" role="document">
 <div className="modal-content">
 <div className="modal-header px-4 d-block">
         
           
         <div className="row">
             <div className="col-md-10">
                   <h4 className="modal-title" ><a onClick={this.closeTagDesigner}   className="back-button cpointer" >
          <i className="fas fa-arrow-circle-left"></i></a> Tag Details</h4>
             </div>
             <div className="col-md-2 text-right"> 
             <button type="button" className="close" onClick={this.closeTagDesigner}>
          <span aria-hidden="true">&times;</span>
        </button>
             </div>
        </div>
      
      </div>
      <div className="modal-body p-1">   
  <Tagdoc submitted={this.state.isSubmitted} isReassigned={this.isReassigned} />
  </div>
  </div>
  </div>
  </div>
}
{this.state.ifDownload && 
  <JsonDownload closedownload={this.closeIfDownload} submitted={this.state.isSubmitted} isReassigned={this.isReassigned} /> 
}

</div>
  )};
};

export default UploadedFile;