import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import {NavLink} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class AdminSettings extends Component {  
     constructor(props) {
          super(props);
          this.state = {
            cid:'',
            role:''    
           }
        this.addtoqueue = this.addtoqueue.bind(this)
        this.taggfile = this.taggfile.bind(this)
        }
        addtoqueue = (e) => {
          toast.success('File added to queue for tagging');
          
      }
     // taggfile(e,fileName,mfgName,mfgid,docID){
     taggfile(e){
      localStorage.setItem("TaggedFile", 'Phenytoin Sodium Injection, USP.pdf');
      localStorage.setItem("TaggedFilePage", 1);
      localStorage.setItem("mfgid", '17164');
      localStorage.setItem("docID", '1803');
      this.props.history.push('/tagging') 
      console.log(docID)
    }  
    componentDidMount(){
      let cid = cookies.get("cid");
      let role = cookies.get("roleid");
      this.setState({
        cid: cid,
        role:role
       })
    }    
     render() {    
     
  return (
    
   
    <div className='h100percent'>
        <div className='main-header'>
        <div className='row'>
            <div className='col-md-6'><h2 className="page-header">Settings</h2></div>
            <div className='col-md-6 text-right'>             
             
              </div>
          </div>
          </div>
        <div className='main-panel'>
           <div className='content '>
           <div className='container-fluid h100percent '>
           <ToastContainer />
 <div className='h100percent text-selection-none adminarea'>

      <div className='row adminheight'>
          <div className='col-md-12 h-100'>
              <div  className='overflow-y-auto h-100'>
      <div className='row '> 
       
       
         <div className='col-md-4 mb-3'>
         <NavLink    title='User Management' to="/view-account">
            <div className="counter text-center">
              <div className='p-4'>
              <i className="fa fa-users-cog fa-3x"></i>
              </div>
                
                <p className="count-text ">User Management</p>
            </div>
            </NavLink>
         </div>
         
        
        <div className='col-md-4 mb-3'>
         <NavLink    title='Document Type' to="/view-doc-type">
            <div className="counter text-center">
            <div className='p-4'><i className="fa fa-file-alt fa-3x"></i></div>
                <p className="count-text ">Document Type Management</p>
            </div>
            </NavLink>
         </div>
         {this.state.cid ==='1' && this.state.role === '1' &&
          <div className='col-md-4 mb-3'>
          <NavLink    title='Client Management' to="/view-client-account">
             <div className="counter text-center">
             <div className='p-4'> <i className="fa fa-hdd fa-3x"></i></div>
                
                 <p className="count-text ">Client Management</p>
             </div>
             </NavLink>
          </div>
         }
         <div className='col-md-4 mb-3'>
          <NavLink    title='Client Management' to="/view-group">
             <div className="counter text-center">
             <div className='p-4'> <i className="fa fa-layer-group fa-3x"></i></div>
                
                 <p className="count-text ">Group Management</p>
             </div>
             </NavLink>
          </div>
          <div className='col-md-4 mb-3'>
          <NavLink    title='Client Management' to="/view-api-key">
             <div className="counter text-center">
             <div className='p-4'> <i className="fa fa-key fa-3x"></i></div>
                
                 <p className="count-text ">API Key</p>
             </div>
             </NavLink>
          </div>
          <div className='col-md-4 mb-3'>
          <NavLink    title='Client Management' to="/add-uri">
             <div className="counter text-center">
             <div className='p-4'> <i className="fa fa-link fa-3x"></i></div>                
                 <p className="count-text ">Callback API URL</p>
             </div>
             </NavLink>
          </div>
          
         
         <div className='col-md-4 d-none'>
         <NavLink    title='Document Type' to="/view-doc-type-meta">
            <div className="counter text-center">
            <div className='p-4'><i className="fa fa-file-alt fa-3x"></i></div>
                <p className="count-text ">Document Type Meta Data</p>
            </div>
            </NavLink>
         </div>
        
        </div>
        <div className='row mt-4'> 
        
         
       
        
      
        
         </div>
     </div>
     </div>
          </div>         
     
      </div>
    </div>
    
    </div>
</div>
</div>
  )};
};

export default AdminSettings;