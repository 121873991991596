import React, { Component } from 'react';
import axios from 'axios';
import {Progress, Card, CardBody,TabContent, TabPane, Nav, NavItem, NavLink,  Button, CardTitle, CardText } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
class UploadFilesForPrediction extends Component {  
  constructor(props) {
    super(props);
      this.state = {
        selectedFile: null,
        mfgNameen:'',
        mfgNameList:[],
        mfgID:'',
      }
   
  }
  onChange=event=> {
    this.state.mfgNameen = event.target.value;
    this.setState({mfgNameen: this.state.mfgNameen})
   // console.log(this.state.mfgNameen.length);
    if(this.state.mfgNameen.length>1){
      this.setState({mfgNameList:[]})
      document.getElementById("loader").classList.add("show");
          fetch('https://api.cloudsds.com/csplatform/api/Manufacturer/SearchManf', {       
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
     
            },
            body: JSON.stringify({
              MfgName: this.state.mfgNameen,
           })
         })
         .then((response) => response.json())
         .then((responseJson) => {
           document.getElementById("loader").classList.remove("show");
           let tempData = responseJson.data;
       
            this.setState({
              mfgNameList: [...this.state.mfgNameList, ...tempData ],
          })
        document.getElementById('mfglist').classList.remove('d-none')
          console.log(this.state.mfgNameList)
         
         })
         .catch(error =>{
           console.log(error);          
           document.getElementById("loader").classList.remove("show");
         })
        
    }
  }
  checkMimeType=(event)=>{
    
    //getting file object
    let files = event.target.files;
    //define message container
    let err = []
    // list allow mime type
   const types = ['application/pdf']
    // loop access array
    for(var x = 0; x<files.length; x++) {   
     // compare file type find doesn't matach
         if (types.every(type => files[x].type !== type)) {
         // create error message and assign to container   
         err[x] = files[x].type+' is not a supported format\n';
       }
     };
     for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
         // discard selected file
        toast.error(err[z])
        event.target.value = null
    }
   return true;
  }
  maxSelectFile=(event)=>{
    let files = event.target.files;
        if (files.length > 3) { 
           const msg = 'Only 3 file can be uploaded at a time'
           event.target.value = null
           toast.warn(msg)
           return false;
      }
    return true;
 }
 checkFileSize=(event)=>{
  let files = event.target.files;
  let size = 200000000 
  let err = []; 
  for(var x = 0; x<files.length; x++) {
  if (files[x].size > size) {
   err[x] = files[x].type+'is too large, please pick a smaller file\n';
 }
};
for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
  // discard selected file
 toast.error(err[z])
 event.target.value = null
}
return true;
}
onChangeHandler=event=>{
  var files = event.target.files[0]
  if(this.maxSelectFile(event) && this.checkMimeType(event) &&    this.checkFileSize(event)){ 
  // if return true allow to setState
     this.setState({
     selectedFile: files,
  })
} 
}

 
    
    onClickHandlerg = () => {
      // const data = new FormData() 
      // for(var x = 0; x<this.state.selectedFile.length; x++) {
      //   data.append('file', this.state.selectedFile[x])
      // }
      
      document.getElementById("loader").classList.add("show");
      const formData = new FormData();
      
      formData.append('file', this.state.selectedFile);
      formData.append('MFGName', this.state.mfgNameen);
     // formData.append('MFGName', '17054');
      formData.append('Lang', 'EN');
      formData.append('Country', 'USA');

      // try {
      //   const response =  fetch('https://api.cloudsds.com/csplatform/api/DocumentControl/UploadSDS', {
      //     mode: 'no-cors', 
      //     method: 'POST',
      //     body: formData
      //   });
      //   const result =  response.json();
      //   console.log('Success:', JSON.stringify(result));
      // } catch (error) {
      //   console.error('Error:', error);
      // }
      fetch('https://api.cloudsds.com/csplatform/api/DocumentControl/UploadSDS', {       
   method: 'POST',
  body: formData
})
.then((response) => response.json())
.then((responseJson) => {
  toast.success('Upload success');
  document.getElementById("loader").classList.remove("show");
  this.setState({
    selectedFile: null,
    mfgNameen:''
 })
 document.getElementById('selectedFile').value = null;
 console.log(responseJson);
})
.catch(error =>{
  console.log(error);
  toast.error('upload fail');
  document.getElementById("loader").classList.remove("show");
  this.setState({
    selectedFile: null,
    mfgNameen:''
 })
 document.getElementById('selectedFile').value = null;
})
      }
    
    selectNFG(e,id,name){
    
      this.state.mfgID = id;
      this.state.mfgNameen = name;
      this.setState({
        mfgID: this.state.mfgID,
        mfgNameen:this.state.mfgNameen
    })
    document.getElementById('mfglist').classList.add('d-none');
   
   
    }
  render() {
    let mfgname = this.state.mfgNameen;
    let mfgNameLists = [];
    let fileLists = [];
   this.state.mfgNameList.forEach ((s,index) => {   
   //  console.log(s.companyName)
    mfgNameLists.push(
            <li onClick={(e) => this.selectNFG(e,s.companyID,s.companyName)}  key={index} >{s.companyName+' ('+s.companyID+')'}</li>
      )
   })
    return (
      <div className='h100percent'>
        <div className='main-header'><h2 className="page-header">Upload tagged file</h2></div>
      <div className='main-panel'>
           <div className='content '>
            <div className='container-fluid h100percent'>
      <div className='h100percent text-selection-none'>
      <div className='row h100percent justify-content-center'>
        <div className='col-md-12'>
        <ToastContainer />
        
       
	      <div className="row">
      	  <div className="col-md-6">
          <div className="form-group ">
            <label>Manufacturer Name</label>
                <input type="text" name='mfgNameen' value={mfgname} onChange={this.onChange} className="form-control" />
              </div>
              <div className="form-group position-relative">
                
                <ul id='mfglist'  className='manudrop d-none'>
                {mfgNameLists}
                </ul>
               
                </div>   
               <div className="form-group files">
                <input type="file" className="form-control" id='selectedFile'    onChange={this.onChangeHandler}/>
              </div>  
              <div className="form-group">
             
            
        
              </div> 
              
              <button type="button" className="btn btn-primary btn-lg" onClick={this.onClickHandlerg}>Upload</button>

	      </div>
      </div>
     
        </div>
         </div>
   
         </div>
           </div>
    </div>
    </div>
    </div>
    );
  }
};

export default UploadFilesForPrediction;