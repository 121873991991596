import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class CreateTags extends Component {  
     constructor(props) {
          super(props);
          this.state = {
               tgname: '',
               tgdesc:'',
               tagType: 'K',
               docType: '1',
               docTypes: [],
               tagList:[
                    {
                         'val':'',
                         'type':'',
                         'desc':'',
                         'tag_common_name':'',
                         'granular':1, 
                         'section':'',
                         'subsection':'',
                         'note':'',
                         'cid':cookies.get("cid"),
                         'category':this.props.documentType,
                         'created_by': cookies.get("created_by"),
                         'created_date' : new Date(),
                         'status' :1,
                         'checkbox':0
                    }
               ],
               subsection:[
{section:'1',subsection:'1.1'},
{section:'1',subsection:'1.2'},
{section:'1',subsection:'1.3'},
{section:'1',subsection:'1.4'},
{section:'2',subsection:'2.1'},
{section:'2',subsection:'2.2'},
{section:'2',subsection:'2.3'},
{section:'3',subsection:'3.1'},
{section:'4',subsection:'4.1'},
{section:'4',subsection:'4.2'},
{section:'4',subsection:'4.3'},
{section:'5',subsection:'5.1'},
{section:'5',subsection:'5.2'},
{section:'5',subsection:'5.3'},
{section:'5',subsection:'5.4'},
{section:'6',subsection:'6.1'},
{section:'6',subsection:'6.2'},
{section:'6',subsection:'6.3'},
{section:'6',subsection:'6.4'},
{section:'7',subsection:'7.1'},
{section:'7',subsection:'7.2'},
{section:'7',subsection:'7.3'},
{section:'8',subsection:'8.1'},
{section:'8',subsection:'8.2'},
{section:'9',subsection:'9.1'},
{section:'9',subsection:'9.2'},
{section:'10',subsection:'10.1'},
{section:'10',subsection:'10.2'},
{section:'10',subsection:'10.3'},
{section:'10',subsection:'10.4'},
{section:'10',subsection:'10.5'},
{section:'10',subsection:'10.6'},
{section:'11',subsection:'11.1'},
{section:'12',subsection:'12.1'},
{section:'12',subsection:'12.2'},
{section:'12',subsection:'12.3'},
{section:'12',subsection:'12.4'},
{section:'12',subsection:'12.5'},
{section:'12',subsection:'12.6'},
{section:'13',subsection:'13.1'},
{section:'14',subsection:'14.1'},
{section:'15',subsection:'15.1'},
{section:'15',subsection:'15.2'},
{section:'16',subsection:'16.1'}
               ]
          };
          this.onSubmit = this.onSubmit.bind(this)
        }
        onChange = (e) => {
          this.setState({[e.target.name]: e.target.value})
        }
        changeSection= (e,index) => {         
          this.state.tagList[index].section = e.target.value;
          this.setState({
               tagList:this.state.tagList
          })
        }
        changeSubSection= (e,index) => {         
          this.state.tagList[index].subsection = e.target.value;
          this.setState({
               tagList:this.state.tagList
          })
        }
        addTagRow = (e) =>{
          let tags = [];
          tags.push({
                         'val':'',
                         'type':'',
                         'desc':'',
                         'tag_common_name':'',
                         'granular':1, 
                         'section':'',
                         'subsection':'',
                         'note':'',
                         'cid':cookies.get("cid"),
                         'category':this.state.docType,
                         'created_by': cookies.get("created_by"),
                         'created_date' : new Date(),
                         'status' :1,
                         'checkbox':0
                         
          });
          this.state.tagList = [...this.state.tagList, ...tags];
          this.setState({
               tagList:this.state.tagList
             }
               );
        }
        delTag = (e,index) =>{
          let freshtag = this.state.tagList
          if (index > -1) {
               freshtag.splice(index, 1);
             }
             this.state.tagList = freshtag;
             this.setState({
               tagList:this.state.tagList
             }
             )
               
        }
        onChangefield = (e,index) => {  
          let breakfield = e.target.name.split('*');
          let getname = breakfield[0];
          this.state.tagList[index][getname] = e.target.value;
          this.setState({
               tagList:this.state.tagList
          }
            );
            
        }
        onChangeCheckbox= (e,index) => {  
          let breakfield = e.target.name.split('*');
          let getname = breakfield[0];
          if(this.state.tagList[index][getname] === 1){
               this.state.tagList[index][getname] = 0;
          }else{
               this.state.tagList[index][getname] = 1;
          }
          
          this.setState({
               tagList:this.state.tagList
          }
            );
            
        }
        onSubmit = (e) => {
          let error = false;
          this.state.tagList.forEach ((s,index) => {  
               let val = s.val;
               let desc = s.desc;
               let tagCommonName = s.tag_common_name;
               let section = s.section;
               if(!val){
                    error = true;
               }
               if(!desc){
                    error = true;
               }
               if(!tagCommonName){
                    error = true;
               }
               if(this.state.docType === 1){
                    if(!section){
                         error = true;
                    }
               }
               
               
          })
          if(error){
               toast.error('Please enter all required field.')
          }else{
              
              // console.log(this.state.tagList)
              // return false
               document.getElementById('loader').classList.add('d-block'); 
               fetch(process.env.REACT_APP_ENV+'/api/TagV2/CreateTags',{
                    method: 'POST',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization':'bearer '+cookies.get("auth")
                      
                    },
                    body: JSON.stringify({ 
                     tags : this.state.tagList
                   })
                    })
                  .then((response) => response.json())
                  .then((responseJson) => {
                    let tempData = responseJson;  
                    console.log(tempData)   
                    if(tempData.status.statusMessage === 'Success'){
                         toast.success('Successfully Created Tag(s).');
                         const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', tempData.status.authtoken, { path: '/'});                
              this.props.closeAddTag(true)
                    }else{
                         toast.success('Error ocured. Please Try Again');
                         document.getElementById('loader').classList.remove('d-block'); 
                         this.props.closeAddTag(false)
                    }
                   
                   
                  })
                  .catch(error =>{
                    toast.success(error);
                    this.props.closeAddTag(false)
                   // console.log(error); 
                  })
          }
       //   console.log(this.state.tagList)
         // return false;
          
          // const { tgname, tgdesc, tagType, docType} = this.state;
          // if(tgname === ''){
          //      toast.error('Please enter tag name.')
          //      error = true
          // }
          // if(tgdesc === ''){
          //      toast.error('Please enter tag description.')
          //      error = true
          // }
          // if(tagType === ''){
          //      toast.error('Please select tag type.')
          //      error = true
          // }
          // if(docType === ''){
          //      toast.error('Please select document type.')
          //      error = true
          // }
          // console.log('name : ' + tgname+', desc: ' +tgdesc+',tagType:'+tagType+',docType'+docType);
         // return false;
         
          
         e.preventDefault();  
        }
        componentDidMount(){
          // document.getElementById('loader').classList.add('d-block'); 
          // fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocumentTypeList',{
          //      method: 'POST',
          //      headers: {
          //        'Accept': 'application/json',
          //        'Content-Type': 'application/json',
          //        'Authorization':'bearer '+cookies.get("auth")
                 
          //      },
          //      body: JSON.stringify({ 
               
          //     })
          //      })
          //    .then((response) => response.json())
          //    .then((responseJson) => {
          //      let tempData = responseJson;  
          //    //  console.log(tempData)   
          //      if(tempData.status.statusMessage === 'Success'){
          //           let exdate = new Date();
          //           cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
          //           this.state.docTypes =  tempData.data;
          //           this.setState({
          //                docTypes:this.state.docTypes
          //           })
                   
          //      }else{
          //           toast.error('Error ocured. Please Try Again')
                   
          //      }
          //     document.getElementById('loader').classList.remove('d-block'); 
              
          //    })
          //    .catch(error =>{
          //     // console.log(error); 
          //      toast.error(error)
          //      document.getElementById('loader').classList.remove('d-block'); 
              
          //    }) 
          this.state.docType = this.props.documentType;
          this.setState({
               docType:this.state.docType
          })
         
        }
        
        goBack() {
          window.history.back();
        }
      
     render() {
          const { tgname, tgdesc} = this.state;
          let docTypeList = [];
      this.state.docTypes.forEach ((s,index) => {   
          docTypeList.push(
               <option key={index} value={s.id}>{s.doctype_name}</option>
          )
      })
      
      const TagType = this.state.tagType;
      let taglist = [];
      this.state.tagList.forEach ((s,index) => {  
           let delitem ='';
           let subsection =[];
      let checkSubsection = [];
      subsection.push(<option value=''>---</option>)
      this.state.subsection.forEach ((d,index) => {   
           if(d.subsection !== '' && d.section === s.section){
               checkSubsection.push({subsection:s.subsection})
               subsection.push(
                    <option key={d.index} value={d.subsection}>{d.subsection}</option>
               )
           }
          
      })
          if(index > 0) {
               delitem = <button onClick={(e) => this.delTag(e,index)}  type="button" className="btn btn-danger"><i className="fas fa-trash-alt"></i></button>
           } 
          taglist.push(
               <div key={index} className='row'>
                    {/* <div className='col-md-2 border p-2 '>
                         <select className='form-control' disabled onChange={(e) => this.onChangefield(e,index)}  value={s.category} name={'category*'+index} >
                              {docTypeList}
                         </select>      
                                     
                    </div> */}
                    {/* <div className='col-md-2 border p-2 border-left-0 pt-3'>
                    <div className="form-check-inline">
                         <label className="form-check-label">
                              <input type="radio" className="form-check-input" checked={s.type === "K"}  name={'type*'+index}  onChange={(e) => this.onChangefield(e,index)} value="K" />Key Tag
                         </label>
                         </div>
                         <div className="form-check-inline">
                         <label className="form-check-label">
                               <input type="radio" className="form-check-input"  checked={s.type === "V"}  name={'type*'+index}  onChange={(e) => this.onChangefield(e,index)} value='V' />Value Tag
                         </label>
</div>
         
                    </div>
                    */}
                    {this.state.docType === 1 &&
                         <div className='col-md-1 border p-2 '>
                              <input  onChange={(e) => this.changeSection(e,index)} className={s.section?'form-control':'form-control border-danger'}   value={s.section} name={'section*'+index} type='text' />
                         {/* <select className={s.section?'form-control':'form-control border-danger'}   onChange={(e) => this.changeSection(e,index)}   value={s.section} name={'section*'+index} >
                             <option value=''>---</option>
                             <option value='1'>1</option>
                             <option value='2'>2</option>
                             <option value='3'>3</option>
                             <option value='4'>4</option>
                             <option value='5'>5</option>
                             <option value='6'>6</option>
                             <option value='7'>7</option>
                             <option value='8'>8</option>
                             <option value='9'>9</option>
                             <option value='10'>10</option>
                             <option value='11'>11</option>
                             <option value='12'>12</option>
                             <option value='13'>13</option>
                             <option value='14'>14</option>
                             <option value='15'>15</option>
                             <option value='16'>16</option>
                         </select>       */}
                                     
                    </div> 
                    }
                     {this.state.docType === 1 && 
                         <div className='col-md-1 border p-2 '>
                              <input  onChange={(e) => this.changeSubSection(e,index)} className='form-control'    value={s.subsection} name={'subsection*'+index} type='text' />
                         {/* {!checkSubsection.length &&
                         <select className='form-control' disabled={true}    value='' name={'subsection*'+index} >
                              <option value=''>---</option>
                         </select>   
                         }
                        {checkSubsection.length > 1  &&
                         <select className='form-control'   onChange={(e) => this.changeSubSection(e,index)}  value={s.subsection} name={'subsection*'+index} >
                         {subsection}
                      </select>   
                         }     */}
                                     
                    </div> 
               }
                    
                    <div className={this.state.docType === 1?'col-md-2 border p-2 border-left-0':'col-md-3 border p-2 ' } ><input className={s.val?'form-control':'form-control border-danger'} onChange={(e) => this.onChangefield(e,index)}  type='text' value={s.val} name={'val*'+index} /> </div>
                    
                    <div className={this.state.docType === 1?'col-md-3 border p-2 border-left-0':'col-md-4 border p-2 border-left-0' }><input className={s.desc?'form-control':'form-control border-danger'} onChange={(e) => this.onChangefield(e,index)}  type='text' value={s.desc} name={'desc*'+index} /> </div>
                   
                    <div className={this.state.docType === 1?'col-md-3 border p-2 border-left-0':'col-md-3 border p-2 border-left-0' } ><input className={s.tag_common_name?'form-control':'form-control border-danger'}  onChange={(e) => this.onChangefield(e,index)}  type='text' value={s.tag_common_name} name={'tag_common_name*'+index} /> </div>
                    <div className='col-md-1 border p-2  border-left-0'>
                    <div className="custom-control d-inline-block custom-checkbox mt-2">
                  <input type="checkbox" className="custom-control-input" checked={s.checkbox === 1 ? true : false} onChange={(e) => this.onChangeCheckbox(e,index)} id={'checkbox*'+index}  name={'checkbox*'+index} />
                    <label className="custom-control-label" htmlFor={'checkbox*'+index}></label></div>
                    </div>
                    <div className='col-md-1 border p-2  border-left-0'> 
                    
                  <button disabled={s.val && s.desc && s.tag_common_name ?false:true } type='button' onClick={this.addTagRow} className="btn btn-primary mr-1"><i className="fa fa-plus"></i></button>{delitem}
                     </div>
               </div>
          )
      })
  return (
     <div className='modal-data'>
       
         <div className="modal-body p-4">       
           
           <div className='row'>
           {/* <ToastContainer /> */}
           {this.state.docType === 1 && 
           <div className='col-md-1 border p-2 '>
                    <label className='mb-0'>Section <span className='text-danger'>*</span></label>                        
                    </div>
     }
     {this.state.docType === 1 && 
                    <div className='col-md-1 border p-2 border-left-0'>
                    <label className='mb-0'>Sub-section </label> 
                    </div>
     }
                    <div className={this.state.docType === 1?'col-md-2 border p-2 border-left-0':'col-md-3 border p-2 ' } >
                    <label className='mb-0'>Tag Name <span className='text-danger'>*</span></label>      
                     </div>
                    
                    <div className={this.state.docType ===1?'col-md-3 border p-2 border-left-0':'col-md-4 border p-2 ' } ><label className='mb-0'>Tag Description <span className='text-danger'>*</span></label>  </div>
                    
                    <div className={this.state.docType === 1?'col-md-3 border p-2 border-left-0':'col-md-3 border p-2 ' } ><label className='mb-0'>Human Readable <span className='text-danger'>*</span></label> </div>
                    <div className='col-md-1 border p-2  border'> If Checkbox</div>
                    <div className='col-md-1 border p-2  border-left-0 text-center'> </div>
               </div>
                    {taglist}              
          
             
</div>
<div className='modal-footer d-block'>
     <button onClick={this.onSubmit} className='btn btn-primary' type='submit'>Save</button>
</div>
</div>
  )};
};

export default CreateTags;