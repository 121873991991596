import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class EditDocType extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      doc:'',
      status:'',
      id:''
  };

  }
  componentDidMount(){
    document.getElementById('loader').classList.add('d-block'); 
    fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocumentTypeGridList',{
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'Authorization':'bearer '+cookies.get("auth")
           
         },
         body: JSON.stringify({ 
          id : parseInt(cookies.get("UpdDocID"))
        })
         })
       .then((response) => response.json())
       .then((responseJson) => {
         let tempData = responseJson;  
         console.log(tempData)   
         if(tempData.status.statusMessage === 'Success'){
          const timestamp = new Date().getTime();
          const expireauth = timestamp + 1800000;
          const exdate =  new Date(expireauth);
          cookies.set('auth', tempData.status.authtoken, { path: '/'});   
              this.state.doc =  tempData.data[0].doctype_name;
              this.state.status = tempData.data[0].status;
              this.state.id = tempData.data[0].id;
              this.setState({
                doc:this.state.doc,
                status:this.state.status,
                id:this.state.id
              })
         }else{
              toast.error('Error ocured. Please Try Again')
         }
        document.getElementById('loader').classList.remove('d-block'); 
        
       })
       .catch(error =>{
         console.log(error); 
       })
  }
  onSubmit = (e) => {
    e.preventDefault();  
   
    let error = false;
    const { doc} = this.state;
    if(doc === ''){
         toast.error('Please enter Doc Type.')
         error = true;
         document.getElementsByName('doc')[0].focus()
         return false;
    }
    if(error !== true){
     
         document.getElementById('loader').classList.add('d-block'); 
         fetch(process.env.REACT_APP_ENV+'/api/Document/UpdateDocumentType',{
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'bearer '+cookies.get("auth")
                
              },
              body: JSON.stringify({ 
                doctype_name : this.state.doc,
                Id : this.state.id,
                status : parseInt(this.state.status),
             })
              })
            .then((response) => response.json())
            .then((responseJson) => {
              let tempData = responseJson;  
            //  console.log(tempData)   
              if(tempData.status.statusMessage === 'Success'){
                toast.success('Document Type successfully Updated')
                const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', tempData.status.authtoken, { path: '/'});  
              window.history.back();
             
              }else{
                toast.error('Error ocured.Please try again later.');
               
              }
              document.getElementById('loader').classList.remove('d-block'); 
             
            })
            .catch(error =>{
              console.log(error); 
            })
    }
    
    
  }
  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
    goBack() {
        window.history.back();
      }
    render(){
        return(
            <div className="modal fade show d-block"     >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header px-4 d-block">
            
              
               <div className="row">
              	<div className="col-md-10">
              		 <h4 className="modal-title" ><a onClick={this.goBack}   className="back-button cpointer" >
                <i className="fas fa-arrow-circle-left"></i></a> Update Document Type</h4>
              	</div>
              	<div className="col-md-2 text-right">
              	</div>
              </div>
            
            </div>
            <div className="modal-body p-4">
            <ToastContainer />
            <Form onSubmit={this.onSubmit}> 
              <div className='row'>
                <div className='col-md-6'>
                <div className="form-row">
              <div className="form-group col-md-6">
                <label>Document Type </label>
                <input type='text' value={this.state.doc} onChange={this.onChange} name='doc'  className="form-control" />
                
              </div>
              <div className="form-group col-md-6">
                <label>Status </label>
                <select className="form-control" value={this.state.status} onChange={this.onChange} name='status'>
                  <option value='1'>Active</option>
                  <option value='0'>Inactive</option>
                </select>
               
                
              </div>
              </div>
              <button type="submit" className="btn btn-primary">Save</button>
                </div>
                
              </div>
             
             
           </Form>   
           
</div>
                                  
       
   
          </div>
        
        </div>
      </div>
        )
    }
}
export default EditDocType