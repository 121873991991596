import React, { Component } from 'react';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import {NavLink} from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class ApiKey extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      docType:[],
      cid:'',
      role:'',
      ifCloudsds:false,
  };
  }
  componentDidMount(){
   
   document.getElementById('loader').classList.add('d-block'); 
   let url = '/client';
   
   fetch(process.env.REACT_APP_ENV+url,{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
          
        },
        body: JSON.stringify({ 
        
       })
        })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;  
        console.log(tempData)   
        if(tempData.status.statusMessage === 'Success'){
             let exdate = new Date();
             cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
             let keyList = [];
             let klist = tempData.data;
        for ( let key in klist ) {
          let item = klist[key];
          keyList.push({
            keyName:key,
            value:item,
            copied:false
          })
          
 }
    console.log(keyList)        
           //  this.state.docType =  keyList;
           this.state.docType =  tempData.data;
             this.setState({
               docType:this.state.docType
             })
             console.log( this.state.docType)
        }else{
             toast.error('Error ocured. Please Try Again')
        }
       document.getElementById('loader').classList.remove('d-block'); 
       
      })
      .catch(error =>{
        console.log(error); 
      })
  }
    goBack() {
        window.history.back();
      }
      copyClip =()=>{       
        toast.success('Key Copied')
      }
    render(){
      let userlist = [];
      
    //   if(this.state.docType){
    //     this.state.docType.forEach((s,index) =>{  
    //       userlist.push(
    //         <tr   key={index} >                        
    //         <td>{s.keyName}</td>                       
    //         <td className='breakword'>
    //           <div>{s.value}</div>
    //           {/* {s.copied ? <span style={{color: 'red'}}>Copied.</span> : null} */}
    //      </td>         
    //      <td>
    //      <CopyToClipboard text={s.value}
    //       onCopy={(e) => this.copyClip(e,index,s.keyName)}>
    //       <button className='btn btn-primary'>Copy to clipboard</button>
    //     </CopyToClipboard>
              
    //      </td>                  
    //         </tr>
    // )
    //     })
        
    //     }
         
     
        return(
         <div className='h100percent'>
         <div className='main-header'>
           
           <div className='row'>
             <div className='col-md-6'><h2 className="page-header"><a onClick={this.goBack}   className="back-button cpointer" >
                <i className="fas fa-arrow-circle-left"></i></a> API Key</h2></div>
             <div className='col-md-6 text-right'>
             
                  
                  </div>
            
           </div>
           </div>
           <div className='main-panel'>
            <div className='content '>
            <ToastContainer />
            <div className='container-fluid h100percent'>
            <div className='h100percent text-selection-none '>
         
     <div className='row viewGroupheight'>
            <div className="col-md-12  h-100">
            <div id='tabledata' className="form-group position-relative  h-100 overflowy">
                  <table className='table bg-white mb-0'>
                    <thead>
                      <tr>
                        <th className='key-width'>Document Type</th>
                        <th>Key</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                    <td>{this.state.docType.Categories}</td>
                        <td>{this.state.docType.Token}</td>
                        <td>
                        <CopyToClipboard text={this.state.docType.Token}
           onCopy={this.copyClip}>
           <button className='btn btn-primary'>Copy to clipboard</button>
         </CopyToClipboard>
                        </td>
                      </tr>
                  {userlist}
                  </tbody>
                  </table>
                
                </div>
           
            {/* <div style={{width: '100%', height: '100%'}}>
              <StickyTable leftStickyColumnCount={0} >         
              <Row>                 
                 <Cell className='key-width'>Document Type</Cell>
                 
                 <Cell>Key</Cell>
                 <Cell></Cell>
              </Row>
           
           
              {userlist}
             
              
              
         
           </StickyTable>
           </div> */}


</div>

    </div>   
</div>
</div>
        </div>
        </div>
       </div>
     
        )
    }
}
export default ApiKey