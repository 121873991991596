import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class editgroup extends Component { 
  constructor(props) {
    super(props);
          this.state = {
            mfgNameen:'',
            mfgID:'',
            mfgNameList:[],
            mfglang:'', 
            groupname : "",
            category:'',
            id:'',
            mfgId : 0,
            langId: "", 
            cid : cookies.get("cid"),
            userid : cookies.get("userid"), 
            status : 1, 
            workflow_status : 0,
            created_by : cookies.get("created_by"),
            created_date : new Date(),
            modified_by : '',
            modified_date : '',
            selectedOptiongdoc:'',
            documnent_type:[],
            selectedOptionglang:'',
            metalist:[],
            langlist:[
              { value:'',label:'----'},
              { value:'AF',label:'Afrikaans'},
              { value:'SQ',label:'Albanian'},
              { value:'AR',label:'Arabic'},
              { value:'HY',label:'Armenian'},
              { value:'EU',label:'Basque'},
              { value:'BN',label:'Bengali'},
              { value:'BG',label:'Bulgarian'},
              { value:'CA',label:'Catalan'},
              { value:'KM',label:'Cambodian'},
              { value:'ZH',label:'Chinese (Mandarin)'},
              { value:'HR',label:'Croatian'},
              { value:'CS',label:'Czech'},
              { value:'DA',label:'Danish'},
              { value:'NL',label:'Dutch'},
              { value:'EN',label:'English'},
              { value:'ET',label:'Estonian'},
              { value:'FJ',label:'Fiji'},
              { value:'FI',label:'Finnish'},
              { value:'FR',label:'French'},
              { value:'KA',label:'Georgian'},
              { value:'DE',label:'German'},
              { value:'EL',label:'Greek'},
              { value:'GU',label:'Gujarati'},
              { value:'HE',label:'Hebrew'},
              { value:'HI',label:'Hindi'},
              { value:'HU',label:'Hungarian'},
              { value:'IS',label:'Icelandic'},
              { value:'ID',label:'Indonesian'},
              { value:'GA',label:'Irish'},
              { value:'IT',label:'Italian'},
              { value:'JA',label:'Japanese'},
              { value:'JW',label:'Javanese'},
              { value:'KO',label:'Korean'},
              { value:'LA',label:'Latin'},
              { value:'LV',label:'Latvian'},
              { value:'LT',label:'Lithuanian'},
              { value:'MK',label:'Macedonian'},
              { value:'MS',label:'Malay'},
              { value:'ML',label:'Malayalam'},
              { value:'MT',label:'Maltese'},
              { value:'MI',label:'Maori'},
              { value:'MR',label:'Marathi'},
              { value:'MN',label:'Mongolian'},
              { value:'NE',label:'Nepali'},
              { value:'NO',label:'Norwegian'},
              { value:'FA',label:'Persian'},
              { value:'PL',label:'Polish'},
              { value:'PT',label:'Portuguese'},
              { value:'PA',label:'Punjabi'},
              { value:'QU',label:'Quechua'},
              { value:'RO',label:'Romanian'},
              { value:'RU',label:'Russian'},
              { value:'SM',label:'Samoan'},
              { value:'SR',label:'Serbian'},
              { value:'SK',label:'Slovak'},
              { value:'SL',label:'Slovenian'},
              { value:'ES',label:'Spanish'},
              { value:'SW',label:'Swahili'},
              { value:'SV',label:'Swedish '},
              { value:'TA',label:'Tamil'},
              { value:'TT',label:'Tatar'},
              { value:'TE',label:'Telugu'},
              { value:'TH',label:'Thai'},
              { value:'BO',label:'Tibetan'},
              { value:'TO',label:'Tonga'},
              { value:'TR',label:'Turkish'},
              { value:'UK',label:'Ukrainian'},
              { value:'UR',label:'Urdu'},
              { value:'UZ',label:'Uzbek'},
              { value:'VI',label:'Vietnamese'},
              { value:'CY',label:'Welsh'},
              { value:'XH',label:'Xhosa'}
        ],
          }; 
          this.updateValuedoc = this.updateValuedoc.bind(this)
          // this.updateValuelang = this.updateValuelang.bind(this)
  }
  updateValuedrop = (selectName,selectedOption) =>{
    let gindex = parseInt(this.name);   
    this.state.metalist[selectName].selectedOptiongDrop = selectedOption; 
    this.state.metalist[selectName].valueLabel = selectedOption.label;
    this.state.metalist[selectName].value = selectedOption.value;
    this.setState({
      metalist: this.state.metalist
  })
  }
  onChangeAutoSearch = (e,index) => {
    this.state.metalist[index].valueLabel = e.target.value;
    this.state.metalist[index].option = [];
    this.setState({metalist: this.state.metalist});
   // console.log(this.state.mfgNameen.length);
   let apiurl = this.state.metalist[index].api;
    if(this.state.metalist[index].valueLabel.length>1){   
      document.getElementById("loader").classList.add("d-block");
          fetch(process.env.REACT_APP_ENV+apiurl, {       
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization':'bearer '+cookies.get("auth")
     
            },
            body: JSON.stringify({
              MfgName: e.target.value,
           })
         })
         .then((response) => response.json())
         .then((responseJson) => {
           document.getElementById("loader").classList.remove("d-block");
           let tempData = responseJson.data;
           const timestamp = new Date().getTime();
           const expireauth = timestamp + 1800000;
           const exdate =  new Date(expireauth);
           cookies.set('auth', responseJson.status.authtoken, { path: '/'});  
           this.state.metalist[index].option = tempData
            this.setState({
              metalist: this.state.metalist,
          })
          let getid = this.state.metalist[index].key;
        document.getElementById(getid).classList.remove('d-none')
       //   console.log(this.state.metalist[index].option)
         
         })
         .catch(error =>{
           console.log(error);          
           document.getElementById("loader").classList.remove("d-block");
         })
        
    }
  }
  selectNFGAto(e,id,name,index){    
    this.state.metalist[index].value = id;
    this.state.metalist[index].valueLabel = name;
    this.state.mfgID = id;
    this.state.mfgNameen = name;
    this.state.mfgId = id;
    this.setState({
      metalist: this.state.metalist
  })
  let getid = this.state.metalist[index].key
  document.getElementById(getid).classList.add('d-none');
  console.log(this.state.metalist)
  }
  onChangesDT= (e) => {
    this.state.docType =  e.target.value
    this.setState({
      docType:  this.state.docType
    }
      )
      let getDocMetaList  = this.state.documnent_type.filter(item => item.id == e.target.value);
      let DocMetaList = JSON.parse(getDocMetaList[0].field_json)
      console.log(DocMetaList)
     // this.state.metalist = DocMetaList;
     // this.setState({metalist:this.state.metalist})
     let ArrMetaList = [];
     DocMetaList.forEach((ml,index,arr) => { 
       if(ml.type === '1'){
        ArrMetaList.push({
          api: ml.api,
          document_type_id: ml.document_type_id,
          error: ml.error,
          id: ml.id,
          key: ml.key,
          label: ml.label,
          mandatroy: ml.mandatroy,
          type: ml.type,
          value:'',
          option:[],
          selectedOptiongDrop:[],
          valueLabel:''
        })
        
       }
       if(ml.type === '2'){
        ArrMetaList.push({
          api: ml.api,
          document_type_id: ml.document_type_id,
          error: ml.error,
          id: ml.id,
          key: ml.key,
          label: ml.label,
          mandatroy: ml.mandatroy,
          type: ml.type,
          value:'',
          option:[],
          selectedOptiongDrop:[],
          valueLabel:''
        })
        document.getElementById('loader').classList.add('d-block'); 
        fetch(process.env.REACT_APP_ENV+ml.api,{
             method: 'POST',
             headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json',
               'Authorization':'bearer '+cookies.get("auth")
               
             },
             body: JSON.stringify({ 
            })
             })
           .then((response) => response.json())
           .then((responseJson) => {
             let tempData = responseJson;  
           //  console.log(tempData)   
             if(tempData){
                  // let exdate = new Date();
                  // cookies.set('auth', tempData.status.authtoken, { path: '/',expires: new Date(Date.now()+2592000) }); 
                 // console.log(tempData.replace(/'/g,"'");
                 let optionsold = tempData.data;
                 let options = [{'value':'','label':'----'}, ...optionsold]
                // options.unshift()
                console.log(options)
                 let gindex = index;
                 let val = ml.value;
                 this.state.metalist[gindex].option = options;
                 this.state.metalist[gindex].selectedOptiongDrop = options.filter(option => option.value === val);
                 this.setState({
                      metalist:this.state.metalist
                 })
                                 
                  document.getElementById('loader').classList.remove('d-block'); 
             }else{
                  toast.error('Error ocured. Please Try Again');
                  document.getElementById('loader').classList.remove('d-block'); 
             }
              
            
           })
           .catch(error =>{
             console.log(error); 
             document.getElementById('loader').classList.remove('d-block'); 
           }) 
        
       }
       
     })
     this.setState({
      metalist:ArrMetaList
       })
    console.log(this.state.docType)
  }
   componentDidMount(){

      
    document.getElementById('loader').classList.add('d-block'); 
    fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocumentTypeList',{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':'bearer '+cookies.get("auth")
        
      },
      body: JSON.stringify({ 
      
     })
      })
    .then((response) => response.json())
    .then((responseJson) => {
      let tempData = responseJson;  
    //  console.log(tempData)   
      if(tempData.status.statusMessage === 'Success'){
           let exdate = new Date();
           cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
           this.state.documnent_type =  tempData.data;
           this.setState({
            documnent_type:this.state.documnent_type
           })
           
      }else{
           toast.error('Error ocured. Please Try Again')
      }
     document.getElementById('loader').classList.remove('d-block'); 
     
    })
    .catch(error =>{
      console.log(error); 
    }) 
    let groupID = Number(cookies.get("groupID"));
    document.getElementById('loader').classList.add('d-block'); 
    fetch(process.env.REACT_APP_ENV+'/api/Document/GetGroupList',{
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'Authorization':'bearer '+cookies.get("auth")
           
         },
         body: JSON.stringify({              
              Id : groupID
        })
         })
       .then((response) => response.json())
       .then((responseJson) => {
         let tempData = responseJson; 
         console.log(tempData.data[0].id)   
          if(tempData.status.statusMessage === 'Success'){
               let exdate = new Date();
               cookies.set('auth', tempData.status.authtoken, { path: '/'});  
               this.setState({
                   id : tempData.data[0].id,
                   category : tempData.data[0].category.toString(),
                   groupname :  tempData.data[0].groupname,
                   mfgId: tempData.data[0].mfgId,
                   selectedOptionglang : tempData.data[0].langId,
                   langId:tempData.data[0].langId,
                   modified_by : tempData.data[0].modified_by,
                   modified_date : new Date(),
                   userid : tempData.data[0].userid,  
                   cid : tempData.data[0].cid,
                   selectedOptiongdoc : tempData.data[0].category.toString(), 
                   created_by : tempData.data[0].created_by,
                   created_date : tempData.data[0].created_date,
                   status : 	tempData.data[0].status,
                   workflow_status:tempData.data[0].workflow_status
              })
              
              let ArrMetaList = [];
              JSON.parse(tempData.data[0].field_json).forEach((ml,index,arr) => { 
                if(ml.type === '1'){
                  ArrMetaList.push({
                    api: ml.api,
                    document_type_id: ml.document_type_id,
                    error: ml.error,
                    id: ml.id,
                    key: ml.key,
                    label: ml.label,
                    mandatroy: ml.mandatroy,
                    type: ml.type,
                    value:ml.value,
                    option:[],
                    selectedOptiongDrop:[],
                    valueLabel:''
                  })
                  fetch(process.env.REACT_APP_ENV+ml.api,{
                    method: 'POST',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization':'bearer '+cookies.get("auth")
                      
                    },
                    body: JSON.stringify({              
                      MfgName:'',
                      mfgId:ml.value
                   })
                    })
                  .then((response) => response.json())
                  .then((responseJson) => {
                    let tempData = responseJson; 
                    console.log(tempData.data[0].mnfName)   
                     if(tempData.status.statusMessage === 'Success'){
                      const timestamp = new Date().getTime();
                      const expireauth = timestamp + 1800000;
                      const exdate =  new Date(expireauth);
                      cookies.set('auth', tempData.status.authtoken, { path: '/'});   
                       let gindex = index;
                       this.state.metalist[gindex].valueLabel = tempData.data[0].mnfName;
                       this.setState({
                        metalist:this.state.metalist
                         })
                              
                       }else{
                          toast.success('Error ocured. Please Try Again')
                     }
                     
                   
                  })
                  .catch(error =>{
                    console.log(error); 
                  })
                 
                 
                }
                if(ml.type === '2'){
                  ArrMetaList.push({
                    api: ml.api,
                    document_type_id: ml.document_type_id,
                    error: ml.error,
                    id: ml.id,
                    key: ml.key,
                    label: ml.label,
                    mandatroy: ml.mandatroy,
                    type: ml.type,
                    value:ml.value,
                    option:[],
                    selectedOptiongDrop:'',
                    valueLabel:''
                  })  
                 document.getElementById('loader').classList.add('d-block'); 
                 fetch(process.env.REACT_APP_ENV+ml.api,{
                      method: 'POST',
                      headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':'bearer '+cookies.get("auth")
                        
                      },
                      body: JSON.stringify({ 
                     })
                      })
                    .then((response) => response.json())
                    .then((responseJson) => {
                      let tempData = responseJson;  
                    //  console.log(tempData)   
                      if(tempData){
                           // let exdate = new Date();
                           // cookies.set('auth', tempData.status.authtoken, { path: '/',expires: new Date(Date.now()+2592000) }); 
                          // console.log(tempData.replace(/'/g,"'");
                          let optionsold = tempData.data;
                          let options = [{'value':'','label':'----'}, ...optionsold]
                           let gindex = index;
                           let val = ml.value;
                           this.state.metalist[gindex].option = options;
                           this.state.metalist[gindex].selectedOptiongDrop = options.filter(option => option.value === val);
                           this.setState({
                                metalist:this.state.metalist
                           })
                                          
                           document.getElementById('loader').classList.remove('d-block'); 
                      }else{
                           toast.error('Error ocured. Please Try Again');
                           document.getElementById('loader').classList.remove('d-block'); 
                      }
                       
                     
                    })
                    .catch(error =>{
                      console.log(error); 
                      document.getElementById('loader').classList.remove('d-block'); 
                    }) 
                 
                }
                
              })
              this.setState({
                metalist:ArrMetaList
                 })
             
            }else{
               toast.success('Error ocured. Please Try Again');
          }
        document.getElementById('loader').classList.remove('d-block'); 
        
       })
       .catch(error =>{
         console.log(error); 
       })
  }
//   updateValuelang(newValue) { 
//     this.setState({
//      selectedOptionglang: newValue,
//      langId:newValue.value
//   });
//  }
 updateValuedoc(newValue) {
   this.setState({
     selectedOptiongdoc: newValue,
     category:newValue.value
 });
}
//  selectNFG(e,id,name){    
//    this.state.mfgID = id;
//    this.state.mfgNameen = name;
//    this.state.mfgId = id;
//    this.setState({
//      mfgID: this.state.mfgID,
//      mfgNameen:this.state.mfgNameen,
//      mfgId:this.state.mfgId
//  })
//  document.getElementById('mfglist').classList.add('d-none')
//  console.log(this.state.mfgNameen+this.state.mfgID)
//  }
 onChanges = (e) => {
   this.setState({[e.target.name]: e.target.value})
 }
 onChange=event=> {
   this.state.mfgNameen = event.target.value;
   this.setState({mfgNameen: this.state.mfgNameen})
  // console.log(this.state.mfgNameen.length);
   if(this.state.mfgNameen.length>1){
     this.setState({mfgNameList:[]})
     document.getElementById("loader").classList.add("d-block");
         fetch(process.env.REACT_APP_ENV+'/api/Common/SearchManufacturer', {       
           method: 'POST',
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'Authorization':'bearer '+cookies.get("auth")
    
           },
           body: JSON.stringify({
             MfgName: this.state.mfgNameen,
          })
        })
        .then((response) => response.json())
        .then((responseJson) => {
          document.getElementById("loader").classList.remove("d-block");
          let tempData = responseJson.data;
          const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', tempData.status.authtoken, { path: '/'});  
              this.state.mfgNameList = tempData;
           this.setState({
             mfgNameList: this.state.mfgNameList,
         })
       document.getElementById('mfglist').classList.remove('d-none')
         console.log(this.state.mfgNameList)
        
        })
        .catch(error =>{
          console.log(error);          
          document.getElementById("loader").classList.remove("d-block");
        })
       
   }
 }
   goBack() {
       window.history.back();
     }
     onSubmit = (e) => {
      e.preventDefault();
       let error = false;
      //  let mfgNameen = this.state.mfgNameen;
      //  if(mfgNameen === ''){
      //    toast.error('Please enter manufacturer name.')
      //         error = true
      //  }
       let groupname = this.state.groupname;
       if(groupname === ''){
         toast.error('Please enter group name.')
              error = true
       }
       let selectedOptiongdoc = this.state.selectedOptiongdoc;
       if(selectedOptiongdoc === ''){
         toast.error('Please select document type.')
              error = true
       }
      //  let selectedOptionglang = this.state.selectedOptionglang;
      //  if(selectedOptionglang === ''){
      //    toast.error('Please select language.')
      //         error = true
      //  }
      this.state.metalist.forEach((m,index,arr) => {
        if(!m.value){
          toast.error(m.error)
             error = true
        }
      })
       if(error === false){
        let meta_json = [];
        this.state.metalist.forEach((ml,index,arr) => {
          meta_json.push({
            api: ml.api,
            document_type_id: ml.document_type_id,
            error: ml.error,
            id: ml.id,
            key: ml.key,
            label: ml.label,
            mandatroy: ml.mandatroy,
            type: ml.type,
            value:ml.value,
          }) 
        })
        let meta = JSON.stringify(meta_json);
         document.getElementById('loader').classList.add('d-block'); 
         fetch(process.env.REACT_APP_ENV+'/api/Document/UpdateGroup',{
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'bearer '+cookies.get("auth")
                
              },
              body: JSON.stringify({    
                Id : this.state.id,
groupname : this.state.groupname,
mfgId : 0,
langId : "",  
field_json: meta,
cid : cookies.get("cid"),
userid : cookies.get("userid"),  
status : this.state.status, 
category:this.state.category.toString(),
workflow_status : this.state.workflow_status
             })
              })
            .then((response) => response.json())
            .then((responseJson) => {
              let tempData = responseJson; 
              //console.log(tempData.data[0].id)   
               if(tempData.status.statusMessage === 'Success'){
                const timestamp = new Date().getTime();
                const expireauth = timestamp + 1800000;
                const exdate =  new Date(expireauth);
                cookies.set('auth', tempData.status.authtoken, { path: '/'});    
                    toast.success('Successfully Updated Group.');                   
                    this.props.closeEditGroup(true)
                 }else{
                    toast.error('Error ocured. Please Try Again');
                    this.props.closeEditGroup(false)
                   
               }
               document.getElementById('loader').classList.remove('d-block'); 
             
            })
            .catch(error =>{
              console.log(error); 
              document.getElementById('loader').classList.remove('d-block'); 
              this.props.closeEditGroup(false)
            })
       }
       
       
     }
    render(){
      let mfgname = this.state.mfgNameen;
    let mfgNameLists = []; 
   this.state.mfgNameList.forEach ((s,index) => {   
   //  console.log(s.companyName)
    mfgNameLists.push(
            <li onClick={(e) => this.selectNFG(e,s.mnfID,s.mnfName)}  key={index} >{s.mnfName+' ('+s.mnfID+')'}</li>
            
      )
   })
   let docTypeList = [];
   this.state.documnent_type.forEach ((s,index) => {   
       docTypeList.push(
            <option key={index} value={s.id}>{s.doctype_name}</option>
       )
   })
   let metalists = [];
   this.state.metalist.forEach((m,index) => {  
    if(m.type == '1'){
      let options = [];
      var itemsProcessed = 0;
      if(m.option.length){
        m.option.forEach((t,gindex,array) => { 
          //  console.log(t.companyName)
          itemsProcessed++;          
           options.push(
            <li onClick={(e) => this.selectNFGAto(e,t.mnfID,t.mnfName,index)}  key={gindex} >{t.mnfName+' ('+t.mnfID+')'}</li>
           )           
           })
      }
        metalists.push(
          <div key={index} className="col-md-6">
             <div className="form-group ">
             <label>{m.label}</label>
             <input autoComplete="off" type="text" name={m.key}   value={m.valueLabel} onChange={(e) => this.onChangeAutoSearch(e,index)} className="form-control" />
             <div >
             <div className="form-group position-relative">                
                   <ul id={m.key}  className='manudrop d-none'>
                   {options}
                   </ul>
                   </div>
                   </div>
                  
               </div>
          </div>
     )
      
      
     
    }
    if(m.type == '2'){   
     
     metalists.push(
       <div key={index} className="col-md-6">
          <div className="form-group ">
          <label>{m.label}</label>
          <div >
          <Select name={index}        
                 value={m.selectedOptiongDrop}
                 onChange={e => this.updateValuedrop(index,e)}
                 options={m.option}
               />
           
            
            </div>
          
            </div>
       </div>
  )
    }
    
})
        return(
          
            <div className="modal-body p-4">
            {/* <ToastContainer /> */}
            <Form onSubmit={this.onSubmit}>
            
              <div className='row'>
                <div className='col-md-6'>
               
              <div className="form-group ">
                <label>Group Name</label>
                <input type='text' onChange={this.onChanges} name='groupname'  value={this.state.groupname} className="form-control" />
                
              </div>
             
             
              
                </div>
                <div className='col-md-6'>
                <div className="form-group">
                <label >Document Type</label>
                <select className="form-control" disabled name='docType' onChange={this.onChangesDT} value={this.state.docType} >
                  {docTypeList}
                </select>
                {/* <Select
                
                 value={this.state.selectedOptiongdoc}
                 onChange={this.updateValuedoc}
                 options={this.state.documnent_type}
                 
               /> */}
                
              </div>
              
                </div>
               
                {/* <div className='col-md-6'>
                <div className="form-group ">
            <label>Manufacturer Name (Type min 2 character for autosearch)</label>
                <input autoComplete="off" type="text" name='mfgNameen'   value={mfgname} onChange={this.onChange} className="form-control" />
              </div>
              <div className="form-group position-relative">                
                <ul id='mfglist'  className='manudrop d-none'>
                {mfgNameLists}
                </ul>
                </div>
                </div>
                <div className='col-md-6'>
                <div className="form-group">
                <label>Select Language</label>
                <Select                
                 value={this.state.selectedOptionglang}
                 onChange={this.updateValuelang}
                 options={this.state.langlist}
               />
                </div>
                </div>
              */}
              {metalists}
              <div className='col-md-12'>
              <button type="submit" className="btn btn-primary">Save</button>
              </div>
              </div>
             
             
              </Form>
          
            {/* <Form onSubmit={this.onSubmit}>
            
            <div className='row'>
              <div className='col-md-6'>
             
            <div className="form-group ">
              <label>Group Name</label>
              <input type='text' onChange={this.onChanges} name='groupname'  value={this.state.groupname} className="form-control" />
              
            </div>
              </div>
              <div className='col-md-6'>
              <div className="form-group">
              <label >Document Type</label>
              <select disabled className="form-control" name='docType' value={this.state.docType} onChange={this.onChanges}>
                  {docTypeList}
                </select>             
              
            </div>
            
              </div>
              <div className='col-md-6'>
              <div className="form-group ">
          <label>Manufacturer Name (Type min 2 character for autosearch)</label>
              <input autoComplete="off" type="text" name='mfgNameen'   value={mfgname} onChange={this.onChange} className="form-control" />
            </div>
            <div className="form-group position-relative">                
              <ul id='mfglist'  className='manudrop d-none'>
              {mfgNameLists}
              </ul>
              </div>
              </div>
              <div className='col-md-6'>
              <div className="form-group">
              <label>Select Language</label>
              <Select
              value={this.state.langlist.find(obj => obj.value === this.state.selectedOptionglang)} // set selected value
             
             //  value={this.state.selectedOptionglang}
               onChange={this.updateValuelang}
               options={this.state.langlist}
             />
              </div>
              </div>
            <div className='col-md-12'>
            <button type="submit" className="btn btn-primary">Save</button>
            </div>
            </div>
           
           
            </Form>
         */}
         
</div>
                                  
       
        )
    }
}
export default editgroup