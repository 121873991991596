import React, { Component } from 'react';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';

import {NavLink} from 'react-router-dom';
const cookies = new Cookies();
class ViewAccountClient extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      useres:[],
      countres:[
        { value:'',label:'----'},
        { value:"AFG",label:"Afghanistan" },
	{ value:"ALA",label:"Åland Islands" },
	{ value:"ALB",label:"Albania" },
	{ value:"DZA",label:"Algeria" },
	{ value:"ASM",label:"American Samoa" },
	{ value:"AND",label:"Andorra" },
	{ value:"AGO",label:"Angola" },
	{ value:"AIA",label:"Anguilla" },
	{ value:"ATA",label:"Antarctica" },
	{ value:"ATG",label:"Antigua and Barbuda" },
	{ value:"ARG",label:"Argentina" },
	{ value:"ARM",label:"Armenia" },
	{ value:"ABW",label:"Aruba" },
	{ value:"AUS",label:"Australia" },
	{ value:"AUT",label:"Austria" },
	{ value:"AZE",label:"Azerbaijan" },
	{ value:"BHS",label:"Bahamas" },
	{ value:"BHR",label:"Bahrain" },
	{ value:"BGD",label:"Bangladesh" },
	{ value:"BRB",label:"Barbados" },
	{ value:"BLR",label:"Belarus" },
	{ value:"BEL",label:"Belgium" },
	{ value:"BLZ",label:"Belize" },
	{ value:"BEN",label:"Benin" },
	{ value:"BMU",label:"Bermuda" },
	{ value:"BTN",label:"Bhutan" },
	{ value:"BOL",label:"Bolivia, Plurinational State of" },
	{ value:"BES",label:"Bonaire, Sint Eustatius and Saba" },
	{ value:"BIH",label:"Bosnia and Herzegovina" },
	{ value:"BWA",label:"Botswana" },
	{ value:"BVT",label:"Bouvet Island" },
	{ value:"BRA",label:"Brazil" },
	{ value:"IOT",label:"British Indian Ocean Territory" },
	{ value:"BRN",label:"Brunei Darussalam" },
	{ value:"BGR",label:"Bulgaria" },
	{ value:"BFA",label:"Burkina Faso" },
	{ value:"BDI",label:"Burundi" },
	{ value:"KHM",label:"Cambodia" },
	{ value:"CMR",label:"Cameroon" },
	{ value:"CAN",label:"Canada" },
	{ value:"CPV",label:"Cape Verde" },
	{ value:"CYM",label:"Cayman Islands" },
	{ value:"CAF",label:"Central African Republic" },
	{ value:"TCD",label:"Chad" },
	{ value:"CHL",label:"Chile" },
	{ value:"CHN",label:"China" },
	{ value:"CXR",label:"Christmas Island" },
	{ value:"CCK",label:"Cocos (Keeling) Islands" },
	{ value:"COL",label:"Colombia" },
	{ value:"COM",label:"Comoros" },
	{ value:"COG",label:"Congo" },
	{ value:"COD",label:"Congo, the Democratic Republic of the" },
	{ value:"COK",label:"Cook Islands" },
	{ value:"CRI",label:"Costa Rica" },
	{ value:"CIV",label:"Côte d'Ivoire" },
	{ value:"HRV",label:"Croatia" },
	{ value:"CUB",label:"Cuba" },
	{ value:"CUW",label:"Curaçao" },
	{ value:"CYP",label:"Cyprus" },
	{ value:"CZE",label:"Czech Republic" },
	{ value:"DNK",label:"Denmark" },
	{ value:"DJI",label:"Djibouti" },
	{ value:"DMA",label:"Dominica" },
	{ value:"DOM",label:"Dominican Republic" },
	{ value:"ECU",label:"Ecuador" },
	{ value:"EGY",label:"Egypt" },
	{ value:"SLV",label:"El Salvador" },
	{ value:"GNQ",label:"Equatorial Guinea" },
	{ value:"ERI",label:"Eritrea" },
	{ value:"EST",label:"Estonia" },
	{ value:"ETH",label:"Ethiopia" },
	{ value:"FLK",label:"Falkland Islands (Malvinas)" },
	{ value:"FRO",label:"Faroe Islands" },
	{ value:"FJI",label:"Fiji" },
	{ value:"FIN",label:"Finland" },
	{ value:"FRA",label:"France" },
	{ value:"GUF",label:"French Guiana" },
	{ value:"PYF",label:"French Polynesia" },
	{ value:"ATF",label:"French Southern Territories" },
	{ value:"GAB",label:"Gabon" },
	{ value:"GMB",label:"Gambia" },
	{ value:"GEO",label:"Georgia" },
	{ value:"DEU",label:"Germany" },
	{ value:"GHA",label:"Ghana" },
	{ value:"GIB",label:"Gibraltar" },
	{ value:"GRC",label:"Greece" },
	{ value:"GRL",label:"Greenland" },
	{ value:"GRD",label:"Grenada" },
	{ value:"GLP",label:"Guadeloupe" },
	{ value:"GUM",label:"Guam" },
	{ value:"GTM",label:"Guatemala" },
	{ value:"GGY",label:"Guernsey" },
	{ value:"GIN",label:"Guinea" },
	{ value:"GNB",label:"Guinea-Bissau" },
	{ value:"GUY",label:"Guyana" },
	{ value:"HTI",label:"Haiti" },
	{ value:"HMD",label:"Heard Island and McDonald Islands" },
	{ value:"VAT",label:"Holy See (Vatican City State)" },
	{ value:"HND",label:"Honduras" },
	{ value:"HKG",label:"Hong Kong" },
	{ value:"HUN",label:"Hungary" },
	{ value:"ISL",label:"Iceland" },
	{ value:"IND",label:"India" },
	{ value:"IDN",label:"Indonesia" },
	{ value:"IRN",label:"Iran, Islamic Republic of" },
	{ value:"IRQ",label:"Iraq" },
	{ value:"IRL",label:"Ireland" },
	{ value:"IMN",label:"Isle of Man" },
	{ value:"ISR",label:"Israel" },
	{ value:"ITA",label:"Italy" },
	{ value:"JAM",label:"Jamaica" },
	{ value:"JPN",label:"Japan" },
	{ value:"JEY",label:"Jersey" },
	{ value:"JOR",label:"Jordan" },
	{ value:"KAZ",label:"Kazakhstan" },
	{ value:"KEN",label:"Kenya" },
	{ value:"KIR",label:"Kiribati" },
	{ value:"PRK",label:"Korea, Democratic People's Republic of" },
	{ value:"KOR",label:"Korea, Republic of" },
	{ value:"KWT",label:"Kuwait" },
	{ value:"KGZ",label:"Kyrgyzstan" },
	{ value:"LAO",label:"Lao People's Democratic Republic" },
	{ value:"LVA",label:"Latvia" },
	{ value:"LBN",label:"Lebanon" },
	{ value:"LSO",label:"Lesotho" },
	{ value:"LBR",label:"Liberia" },
	{ value:"LBY",label:"Libya" },
	{ value:"LIE",label:"Liechtenstein" },
	{ value:"LTU",label:"Lithuania" },
	{ value:"LUX",label:"Luxembourg" },
	{ value:"MAC",label:"Macao" },
	{ value:"MKD",label:"Macedonia, the former Yugoslav Republic of" },
	{ value:"MDG",label:"Madagascar" },
	{ value:"MWI",label:"Malawi" },
	{ value:"MYS",label:"Malaysia" },
	{ value:"MDV",label:"Maldives" },
	{ value:"MLI",label:"Mali" },
	{ value:"MLT",label:"Malta" },
	{ value:"MHL",label:"Marshall Islands" },
	{ value:"MTQ",label:"Martinique" },
	{ value:"MRT",label:"Mauritania" },
	{ value:"MUS",label:"Mauritius" },
	{ value:"MYT",label:"Mayotte" },
	{ value:"MEX",label:"Mexico" },
	{ value:"FSM",label:"Micronesia, Federated States of" },
	{ value:"MDA",label:"Moldova, Republic of" },
	{ value:"MCO",label:"Monaco" },
	{ value:"MNG",label:"Mongolia" },
	{ value:"MNE",label:"Montenegro" },
	{ value:"MSR",label:"Montserrat" },
	{ value:"MAR",label:"Morocco" },
	{ value:"MOZ",label:"Mozambique" },
	{ value:"MMR",label:"Myanmar" },
	{ value:"NAM",label:"Namibia" },
	{ value:"NRU",label:"Nauru" },
	{ value:"NPL",label:"Nepal" },
	{ value:"NLD",label:"Netherlands" },
	{ value:"NCL",label:"New Caledonia" },
	{ value:"NZL",label:"New Zealand" },
	{ value:"NIC",label:"Nicaragua" },
	{ value:"NER",label:"Niger" },
	{ value:"NGA",label:"Nigeria" },
	{ value:"NIU",label:"Niue" },
	{ value:"NFK",label:"Norfolk Island" },
	{ value:"MNP",label:"Northern Mariana Islands" },
	{ value:"NOR",label:"Norway" },
	{ value:"OMN",label:"Oman" },
	{ value:"PAK",label:"Pakistan" },
	{ value:"PLW",label:"Palau" },
	{ value:"PSE",label:"Palestinian Territory, Occupied" },
	{ value:"PAN",label:"Panama" },
	{ value:"PNG",label:"Papua New Guinea" },
	{ value:"PRY",label:"Paraguay" },
	{ value:"PER",label:"Peru" },
	{ value:"PHL",label:"Philippines" },
	{ value:"PCN",label:"Pitcairn" },
	{ value:"POL",label:"Poland" },
	{ value:"PRT",label:"Portugal" },
	{ value:"PRI",label:"Puerto Rico" },
	{ value:"QAT",label:"Qatar" },
	{ value:"REU",label:"Réunion" },
	{ value:"ROU",label:"Romania" },
	{ value:"RUS",label:"Russian Federation" },
	{ value:"RWA",label:"Rwanda" },
	{ value:"BLM",label:"Saint Barthélemy" },
	{ value:"SHN",label:"Saint Helena, Ascension and Tristan da Cunha" },
	{ value:"KNA",label:"Saint Kitts and Nevis" },
	{ value:"LCA",label:"Saint Lucia" },
	{ value:"MAF",label:"Saint Martin (French part)" },
	{ value:"SPM",label:"Saint Pierre and Miquelon" },
	{ value:"VCT",label:"Saint Vincent and the Grenadines" },
	{ value:"WSM",label:"Samoa" },
	{ value:"SMR",label:"San Marino" },
	{ value:"STP",label:"Sao Tome and Principe" },
	{ value:"SAU",label:"Saudi Arabia" },
	{ value:"SEN",label:"Senegal" },
	{ value:"SRB",label:"Serbia" },
	{ value:"SYC",label:"Seychelles" },
	{ value:"SLE",label:"Sierra Leone" },
	{ value:"SGP",label:"Singapore" },
	{ value:"SXM",label:"Sint Maarten (Dutch part)" },
	{ value:"SVK",label:"Slovakia" },
	{ value:"SVN",label:"Slovenia" },
	{ value:"SLB",label:"Solomon Islands" },
	{ value:"SOM",label:"Somalia" },
	{ value:"ZAF",label:"South Africa" },
	{ value:"SGS",label:"South Georgia and the South Sandwich Islands" },
	{ value:"SSD",label:"South Sudan" },
	{ value:"ESP",label:"Spain" },
	{ value:"LKA",label:"Sri Lanka" },
	{ value:"SDN",label:"Sudan" },
	{ value:"SUR",label:"Suriname" },
	{ value:"SJM",label:"Svalbard and Jan Mayen" },
	{ value:"SWZ",label:"Swaziland" },
	{ value:"SWE",label:"Sweden" },
	{ value:"CHE",label:"Switzerland" },
	{ value:"SYR",label:"Syrian Arab Republic" },
	{ value:"TWN",label:"Taiwan, Province of China" },
	{ value:"TJK",label:"Tajikistan" },
	{ value:"TZA",label:"Tanzania, United Republic of" },
	{ value:"THA",label:"Thailand" },
	{ value:"TLS",label:"Timor-Leste" },
	{ value:"TGO",label:"Togo" },
	{ value:"TKL",label:"Tokelau" },
	{ value:"TON",label:"Tonga" },
	{ value:"TTO",label:"Trinidad and Tobago" },
	{ value:"TUN",label:"Tunisia" },
	{ value:"TUR",label:"Turkey" },
	{ value:"TKM",label:"Turkmenistan" },
	{ value:"TCA",label:"Turks and Caicos Islands" },
	{ value:"TUV",label:"Tuvalu" },
	{ value:"UGA",label:"Uganda" },
	{ value:"UKR",label:"Ukraine" },
	{ value:"ARE",label:"United Arab Emirates" },
	{ value:"GBR",label:"United Kingdom" },
	{ value:"USA",label:"United States" },
	{ value:"UMI",label:"United States Minor Outlying Islands" },
	{ value:"URY",label:"Uruguay" },
	{ value:"UZB",label:"Uzbekistan" },
	{ value:"VUT",label:"Vanuatu" },
	{ value:"VEN",label:"Venezuela, Bolivarian Republic of" },
	{ value:"VNM",label:"Viet Nam" },
	{ value:"VGB",label:"Virgin Islands, British" },
	{ value:"VIR",label:"Virgin Islands, U.S." },
	{ value:"WLF",label:"Wallis and Futuna" },
	{ value:"ESH",label:"Western Sahara" },
	{ value:"YEM",label:"Yemen" },
	{ value:"ZMB",label:"Zambia" },
	{ value:"ZWE",label:"Zimbabwe" }
      ],
  };
    this.updateAccount = this.updateAccount.bind(this)
  }
  componentDidMount(){
   document.getElementById('loader').classList.add('d-block'); 
   fetch(process.env.REACT_APP_ENV+'/api/UserAuth/GetCompanyList',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
          
        },
        body: JSON.stringify({ 
        
       })
        })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;  
        console.log(tempData)   
        if(tempData.status.statusMessage === 'Success'){
             let exdate = new Date();
             cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
             this.state.useres =  tempData.data;
             this.setState({
               useres:this.state.useres
             })
        }else{
             toast.error('Error ocured. Please Try Again')
        }
       document.getElementById('loader').classList.remove('d-block'); 
       
      })
      .catch(error =>{
        console.log(error); 
      })
  }
  updateAccount = (e,id) => {
   const timestamp = new Date().getTime();
   const expireauth = timestamp + 1800000;
   const exdate =  new Date(expireauth);
  cookies.set('CLid', id, { path: '/'});
    this.props.history.push('/update-client-account') 
    
}
detailAccount = (e) => {
    this.props.history.push('/client-details') 
    
}
  
    goBack() {
        window.history.back();
      }
    render(){
      let userlist = [];
      this.state.useres.forEach ((s,index) => {          
            let country = '';
            if(s.country_code !== null){
              let cindex = this.state.countres.findIndex(country => country.value === s.country_code);              
              if(cindex !== -1){
                country = this.state.countres[cindex].label;
              }
            }
        //  console.log(s.companyName)
        let role = s.roleid;
        if(role === 1){
         role = 'Super Admin'
        }
        if(role === 2){
         role = 'Admin'
        }
        if(role === 3){
         role = 'Taggers'
        }
        if(role === 4){
         role = 'Data Scientists'
        }
        let cdate = s.created_date.split("T")[0];
        let mdate = s.modified_date.split("T")[0];
       
        userlist.push( 
          <Row key={index}>                     
                   <Cell>{s.cname}</Cell>
                  <Cell><div>{s.address_1}</div><div>{s.address_2}</div></Cell>
                   <Cell>{country}</Cell>
                   <Cell>{s.state}</Cell>
                   <Cell>{s.city}</Cell>
                   <Cell>{s.zip}</Cell>
                   <Cell>
                <div>{cdate}</div>
                <div>{s.created_by_name}</div>
                </Cell>
                <Cell>
                <div>{mdate}</div>
                <div>{s.modified_by_name.trim() !== ''?s.modified_by_name:s.created_by_name}</div>
                </Cell>
                   <Cell><button onClick={(e) => this.updateAccount(e,s.id)}  type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-pencil-alt"></i></button>
           
           </Cell>
                   </Row>
           )
        })
        return(
          <div className='h100percent'>
          <div className='main-header'>
          <div className='row'>
              <div className='col-md-6'><h2 className="page-header">Client Management</h2></div>
              <div className='col-md-6 text-right'>             
                
                </div>
            </div>
            </div>
           
            <ToastContainer />
              <div className='main-panel'>
             <div className='content '>
             <div className='container-fluid h100percent '>
             <div className='row mb-3'>
     <div className='col-md-12 text-right'>             
     <NavLink  className='btn btn-primary  mt-2'  title='Add Account'  activeClassName={"active"} to="/add-client-account">Add Client</NavLink>
               </div>
               </div>
              
            
            <div id="tabledata" className=" position-relative  viewGroupheight">
            <div style={{width: '100%', height: '100%'}}>
          <StickyTable leftStickyColumnCount={0} >
            <Row>
              <Cell>Company Name</Cell>
              <Cell>Address</Cell>
              <Cell>Country</Cell>
              <Cell>State</Cell>
              <Cell>City</Cell>
              <Cell>Zip</Cell>
              <Cell>Created</Cell>
              <Cell>Updated</Cell>
              <Cell></Cell>
            </Row>
            {userlist}
            
          </StickyTable>
        
   </div>
   
   </div>

   </div>
     </div>
     </div>
     
      </div>
        )
    }
}
export default ViewAccountClient