import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
class ProcessedFiles extends Component {  
     constructor(props) {
          super(props);
          this.state = {
              
          };
        }
       
      
     render() {
          const { tgname, tgdesc} = this.state;
  return (
     <div className='h-100'>
     <div className='main-header'>
       
       <div className='row'>
         <div className='col-md-6'><h2 className="page-header">Processed Files</h2></div>
        
       </div>
       </div>
 <div className='main-panel'>
        <div className='content '>
         <div className='container-fluid h100percent'>
 <div className='h100percent text-selection-none '>
     <div className='row'>
         <div className='col-md-12 mb-3'>
         <div className="custom-control d-inline-block custom-radio mr-5">
    <input checked type="radio" className="custom-control-input" id="filesByUser" name="process-file" />
    <label className="custom-control-label" htmlFor="filesByUser">Files By User</label>
  </div>
  <div className="custom-control d-inline-block custom-radio">
    <input type="radio" className="custom-control-input" id="filesByManufacturer" name="process-file" />
    <label className="custom-control-label" htmlFor="filesByManufacturer">Files By Manufacturer</label>
  </div>
         </div>
     </div>
        <div className='row pfileheight'>
        <div className="col-md-12  h-100">
   <div id="tabledata" className=" position-relative overflowy h-100">
      <table className="table bg-white mb-0">
         <thead>
            <tr>
               
               <th>File Name</th>
               <th>Manufacturer Name</th>
               <th>Process Date</th>
            </tr>
         </thead>
         <tbody>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            <tr>    
                <td>84_Sigma-Aldrich_132191670629220443_289275685.pdf</td>          
                <td>Sigma-Aldrich</td>
                <td>2019-11-25T14:51:03.073</td>
            </tr>
            
            
         </tbody>
      </table>
   </div>
</div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
  )};
};

export default ProcessedFiles;