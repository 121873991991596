import React, { Component } from 'react';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
import Select from 'react-select';
import TagdocReadOnly from "./TagDocReadOnly.jsx"
import JsonDownload from "./JsonDownload.jsx"
import { saveAs } from 'file-saver';
const cookies = new Cookies();
class TrainModel extends Component {  
  constructor(props) {
    super(props);
     this.state = {
         trainlist:[],
        docType:[],
        selectedTrain:'1',
        docTypeDrop:[],
        docTypeDropSelected:'',
        trainlistSent:[],
        isSetTrainListModal:false,
        modalList:false,
      ifDesigner:false,
      selectedGid:'',
      selectedMNFid:'',
      tagFileList:[],
      ifDownload:false,
      isSubmitted:true,
      tagkeyList:[]
     }
     this.trainStatus = this.trainStatus.bind(this)
     this.evaulateStatus = this.evaulateStatus.bind(this)
     this.publishStatus = this.publishStatus.bind(this)
     this.train = this.train.bind(this)
     this.evaulate = this.evaulate.bind(this)
    // this.publish = this.publish.bind(this)
     this.updatedocTypeDrop = this.updatedocTypeDrop.bind(this)
}
closeTagDesigner = () =>{
  this.setState({
    ifDesigner:!this.state.ifDesigner
  })
}
togglemodalList = () => {
  this.setState({
    modalList:!this.state.modalList
  })
}
retagGroup= (e,gid) => {
  let groupid = gid;  
  document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/tagv2/SetReTagging',{
     method: 'POST',
     headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/json',
       'Authorization':'bearer '+cookies.get("auth")
       
     },
     body: JSON.stringify({   
      groupId:groupid,
      category:this.state.docTypeDropSelected.value
    })
     })
   .then((response) => response.json())
   .then((responseJson) => {
     console.log(responseJson)
     let exdate = new Date();
     cookies.set('auth', responseJson.status.authtoken, { path: '/'}); 
     let language = '';        
   document.getElementById('loader').classList.remove('d-block'); 
   if(responseJson.status.statusMessage === 'Success'){
    toast.success('Group added for re-tagging');
    //this.getdocbygroupById();
   }else{
    toast.success('Error occurred. Please try later');
   }
   
})
   .catch(error =>{
     console.log(error); 
   })
  
}
updatedocTypeDrop(newValue){
  this.state.docTypeDropSelected = newValue
  this.state.trainlist = [];
  this.state.trainlistSent = []
  this.setState({
    docTypeDropSelected: this.state.docTypeDropSelected,
    trainlist:this.state.trainlist,
    trainlistSent:this.state.trainlistSent
});
if(this.state.docTypeDropSelected.value){
//  if(this.state.docTypeDropSelected.value !==1){
    document.getElementById('loader').classList.add('d-block'); 
   fetch(process.env.REACT_APP_ENV+'/api/Document/GetGroupList',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
          
        },
        body: JSON.stringify({              
         cid : cookies.get("cid"),
         workflow_status:200,
         category:this.state.docTypeDropSelected.value
       })
        })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson; 
        //console.log(tempData.data[0].id)   
         if(tempData.status.statusMessage === 'Success'){
           if(tempData.data.length > 0){
            this.setState({
              trainlistSent: [],
         })
      const timestamp = new Date().getTime();
      const expireauth = timestamp + 1800000;
      const exdate =  new Date(expireauth);
      cookies.set('auth', tempData.status.authtoken, { path: '/'});   
          this.state.trainlistSent = tempData.data;
           this.setState({
              trainlistSent: this.state.trainlistSent,
         })
           }else{
           // toast.success('No records found..');
            this.setState({
              trainlistSent: [],
         })
           }
           
           // console.log(this.state.id)
           }else{
              toast.success('Error ocured. Please Try Again')
         }
       document.getElementById('loader').classList.remove('d-block'); 
       
      })
      .catch(error =>{
        console.log(error); 
      }) 
 // }
this.gettrained(this.state.selectedTrain);

}else{
  this.setState({
    trainlist: [],
})
}
}
componentDidMount(){   
  document.getElementById('loader').classList.add('d-block'); 
  fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocumentTypeList',{
       method: 'POST',
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'Authorization':'bearer '+cookies.get("auth")
         
       },
       body: JSON.stringify({ 
       
      })
       })
     .then((response) => response.json())
     .then((responseJson) => {
       let tempData = responseJson;  
     //  console.log(tempData)   
       if(tempData.status.statusMessage === 'Success'){
            let exdate = new Date();
            cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
            this.state.docType =  tempData.data;
            let storedata = [];
         storedata.push({value:'',label:'Select'});
			   for (var i = 0; i < tempData.data.length; i++) {
						   storedata.push({
							 value: tempData.data[i].id, 
							 label:  tempData.data[i].doctype_name,
						   })
						  
				}
            this.setState({
              docType:this.state.docType,
              docTypeDrop:storedata,
				      docTypeDropSelected:storedata[0],
            })
            document.getElementById('loader').classList.remove('d-block');  
       }else{
            toast.error('Error ocured. Please Try Again');
            document.getElementById('loader').classList.remove('d-block'); 
       }
      
      
     })
     .catch(error =>{
       console.log(error); 
     })  
   this.getTagKey()
 }    
 gettrained = (type) =>{
   let workflowstatus = 150
   if(type == 2){
    workflowstatus = 200
   }
   
  document.getElementById('loader').classList.add('d-block'); 
   fetch(process.env.REACT_APP_ENV+'/api/Document/GetGroupList',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
          
        },
        body: JSON.stringify({              
         cid : cookies.get("cid"),
         workflow_status:workflowstatus,
         category:this.state.docTypeDropSelected.value
       })
        })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson; 
        //console.log(tempData.data[0].id)   
         if(tempData.status.statusMessage === 'Success'){
           if(tempData.data.length > 0){
            this.setState({
              trainlist: [],
         })
      const timestamp = new Date().getTime();
      const expireauth = timestamp + 1800000;
      const exdate =  new Date(expireauth);
      cookies.set('auth', tempData.status.authtoken, { path: '/'});   
          this.state.trainlist = tempData.data;
           this.setState({
              trainlist: this.state.trainlist,
         })
           }else{
            toast.success('No records found..');
            this.setState({
              trainlist: [],
         })
           }
           
           // console.log(this.state.id)
           }else{
              toast.success('Error ocured. Please Try Again')
         }
       document.getElementById('loader').classList.remove('d-block'); 
       
      })
      .catch(error =>{
        console.log(error); 
      }) 
 }
 changeTraine = (e) =>{
  this.state.selectedTrain =  e.target.value;
 this.setState({selectedTrain:this.state.selectedTrain});
 this.gettrained(this.state.selectedTrain)
}
AllpublishModel = (e) => {
   this.props.history.push('/published-models') 
  
}
trainStatus = (e) => {
            this.props.history.push('/train-status') 
           
}
evaulateStatus = (e) => {
   this.props.history.push('/evaluate-status') 
   
}
publishStatus = (e) => {
   this.props.history.push('/publish-status') 
   
}
trainCheckStatus  = (e,gid) => {
  toast.success('Check Train Status.')
}
train = (e,gid) => {
  document.getElementById('loader').classList.add('d-block'); 
  fetch(process.env.REACT_APP_ENV+'/api/TagV2/SendForTraining',{
       method: 'POST',
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'Authorization':'bearer '+cookies.get("auth")
         
       },
       body: JSON.stringify({   
        GroupId:gid,
        category:this.state.docTypeDropSelected.value
      })
       })
     .then((response) => response.json())
     .then((responseJson) => {
       let tempData = responseJson; 
       //console.log(tempData.data[0].id)   
        if(tempData.status.statusMessage === 'Success'){
         const timestamp = new Date().getTime();
         const expireauth = timestamp + 1800000;
         const exdate =  new Date(expireauth);
         cookies.set('auth', tempData.status.authtoken, { path: '/'});   
            //  this.state.trainlist =   [...this.state.trainlist, ...tempData.data]
            //   this.setState({
            //      trainlist: this.state.trainlist,
            // })
          // console.log(this.state.id)
          toast.error('Train request successfully send.');
          let filteredArray = this.state.trainlist.filter(item => item.id !== gid);
          this.state.trainlist = filteredArray;
        this.setState({trainlist: this.state.trainlist});
          }else{
             toast.error('Error ocured. Please Try Again')
        }
      document.getElementById('loader').classList.remove('d-block'); 
      
     })
     .catch(error =>{
       console.log(error); 
       toast.error('Error ocured. Please Try Again')
       document.getElementById('loader').classList.remove('d-block'); 
     }) 
   
}
evaulate = (e) => {
  // this.props.history.push('/edit-tag') 
   
}
goSentForTrain = (e) => {
   this.setState({
     isSetTrainListModal:!this.state.isSetTrainListModal
   })
}
toggleFileCount= (e,id) =>{
  document.getElementById(id).classList.toggle("fivefileSHow");
}
selectRowt =(e,id) =>{
  var elements = document.getElementsByClassName("tableRowDetectt");
  for (var i = 0, len = elements.length; i < len; i++) {
    elements[i].classList.remove('selected')
  }
  document.getElementById(id).classList.add('selected')
}
viewModelFiles =(e,gid,mnfid,index) =>{
  this.state.selectedGid = gid;
  this.state.selectedMNFid=mnfid;
  // let files = this.state.trainlist[index].files;
  // console.log(this.state.trainlist[index].files)
  // this.state.tagFileList = files;
  document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/Document/GetFilesForGroup',{
     method: 'POST',
     headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/json',
       'Authorization':'bearer '+cookies.get("auth")
       
     },
     body: JSON.stringify({   
      groupid:parseInt(gid),
      category:parseInt(this.state.docTypeDropSelected.value)
    })
     })
   .then((response) => response.json())
   .then((responseJson) => {
     cookies.set('auth', responseJson.status.authtoken, { path: '/'}); 
   document.getElementById('loader').classList.remove('d-block'); 
   if(responseJson.status.statusMessage === 'Success'){
    this.state.tagFileList = responseJson.data;
    this.setState({
      tagFileList:this.state.tagFileList
    })
   // toast.success('Group added for re-tagging');
   }else{
    toast.success('Error occurred. Please try later');
    document.getElementById('loader').classList.remove('d-block'); 
   }
   
})
   .catch(error =>{
     console.log(error); 
   })
  // this.setState({
  //   selectedGid:this.state.selectedGid,
  //   selectedMNFid:this.state.selectedMNFid,
  //   tagFileList:this.state.tagFileList
  // })
  this.togglemodalList()
}
taggfile(e,file,page,docid){
  let exdate = new Date();
  cookies.set('TaggedFile', file, { path: '/'});
  cookies.set('TaggedFilePage', page, { path: '/'});
  cookies.set('mfgid', this.state.selectedMNFid, { path: '/' });
  cookies.set('docID', docid, { path: '/' });
  cookies.set('groupid', this.state.selectedGid, { path: '/'});
  cookies.set('TagCategory', this.state.docTypeDropSelected.value, { path: '/'});
  this.state.tagFilename = file
  this.setState({
    ifDesigner:!this.state.ifDesigner,
    tagFilename:this.state.tagFilename
  })
} 

 getTagKey = ()=>{
  fetch(process.env.REACT_APP_ENV+'/api/TagV2/GetTagKeyValList',{
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization':'bearer '+cookies.get("auth")

    },
    body: JSON.stringify({ 
      category:this.state.docTypeDropSelected.value,
      status:1
   })
    }) 
  .then((response) => response.json())
  .then((responseJson) => {
    let tempData = responseJson.data;  
      if(tempData){
        let storedata = [];
        for (var i = 0; i < tempData.length; i++) {
          storedata.push({
            value: tempData[i].id, 
            label:  tempData[i].desc,
            type:tempData[i].type,
            section:tempData[i].section,
            name:tempData[i].val,
        })
          // console.log(tempData[i].val)
        }
        this.state.tagkeyList = [...this.state.tagkeyList, ...storedata ]
         this.setState({
           tagkeyList: this.state.tagkeyList,
      })
     // console.log(this.state.tagkeyList)
     const timestamp = new Date().getTime();
     const expireauth = timestamp + 1800000;
     const exdate =  new Date(expireauth);
     cookies.set('auth', responseJson.status.authtoken, { path: '/'});  
      }
     
      
  })
  .catch(error =>{
    console.log(error);  
   // document.getElementById('loader').classList.remove('d-block')        
   
  })
 }
taggfileJsonDownload(e,file,page,mfgid,docid,gid,isSubmitted){
  cookies.set('TaggedFile', file, { path: '/'});
  cookies.set('TaggedFilePage', page, { path: '/'});
  cookies.set('mfgid', mfgid, { path: '/' });
  cookies.set('docID', docid, { path: '/' });
  cookies.set('groupid', gid, { path: '/'});
  cookies.set('TagCategory', this.state.docTypeDropSelected.value, { path: '/'});
  
  let submitstatus = false;
  if(parseInt(isSubmitted) === 2){
    submitstatus=true
  }
  this.state.isSubmitted = submitstatus
  this.setState({
    ifDownload:!this.state.ifDownload,
    isSubmitted:this.state.isSubmitted
  })
 
} 
closeIfDownload = (status) =>{
  this.setState({
    ifDownload:!this.state.ifDownload
  })
}
  render() {  
    let taggedFileperModel = []
    this.state.tagFileList.forEach ((s,index) => {   
      taggedFileperModel.push(
        <Row className='tableRowDetectt' onClick={(e) =>this.selectRowt(e,'rowIDf-'+index)} id={'rowIDf-'+index} key={index}>          
        <Cell>{s.filename}</Cell>
        <Cell>
          {/* <button onClick={(e) => this.viewImage(e,s.filename)} className='btn btn-primary btn-sm mr-1'>View File</button> */}
            <button title='View Tag Information' onClick={(e)=> this.taggfile(e,s.filename,1,s.docid)} className='btn btn-primary btn-sm mr-1'><i className='fas fa-tags'></i> View Tag Information</button>
         {/* <button title='Tag Details' onClick={(e)=> this.taggfileJsonDownload(e,s.filename,1,s.mfgId,s.docid,s.groupid,2)} className='btn btn-primary btn-sm mr-1'><i className='fas fa-tags'></i> Json File</button> */}
        </Cell>
       
        
      </Row>
                 
          )
    })
    let ifTrainSent = false;
    let sentforCountFiles = 0;
    let trainlistSentD =[];
    let modelCount = this.state.trainlistSent.length;
    if(this.state.trainlistSent.length){
      
      this.state.trainlistSent.forEach ((s,index) => { 
        let files = s.files
        let getfiles = files.split(',');
        sentforCountFiles = sentforCountFiles+getfiles.length
        let groupName = s.groupname;
        let filename ='';
        if(s.files){
          filename = s.files.trim();
        }
        let cdate = s.modified_date.split("T")[0];  
                let ctime = s.modified_date.split("T")[1].toString();
                let output = ctime.split(/(?=(?:..)*$)/);          
                let datestring = cdate + "T" + ("0" + output[0]).slice(-2) + ":" + ("0" + output[1]).slice(-2)+ ":" + ("0" + output[2]).slice(-2);
                
              //  let latest_ms = new Date(datestring);
        if(s.category === 1){    
          trainlistSentD.push(
            <Row key={index}>
              
            <Cell>{groupName}</Cell>
            <Cell>
             
            {cdate}
            </Cell>
            {/* <Cell>{getDocTypeName}</Cell> */}
            
          </Row>
                     
              )
        }else{
          let files = [];
          let filenames =[];
          if(s.files){
            filenames = s.files.split(',');
          }
          
          if(filenames.length){
            filenames.forEach ((s,index) => { 
              let filename = s.split('#')
              files.push(
                  <span key={index}>{filename[2]}</span> 
              )
             })
          }
          
          trainlistSentD.push(
            <Row key={index}>
            <Cell><div id={'trainfile-'+s.id} className='trainFileName fivefileSHow'>
              {files}
              <div onClick={(e)=>this.toggleFileCount(e,'trainfile-'+s.id)}><button className='btn btn-sm btn-primary mt-2'></button></div>
              </div></Cell>
            <Cell>
             
            {cdate}
            </Cell>
            {/* <Cell>{getDocTypeName}</Cell> */}
            
          </Row>
                     
              )
        }
  
      })
      ifTrainSent = true;
    }
   let filelists = [];
       this.state.trainlist.forEach ((s,index) => {   
        let dTindex = this.state.docType.findIndex(docTypes => docTypes.id === s.category);
        console.log(this.state.docType[dTindex])
        let getDocTypeName = this.state.docType[dTindex].doctype_name;
        let groupName = s.groupname;
        let filename ='';
        if(s.files){
          filename = s.files.trim();
        }
        if(s.category === 1){    
          filelists.push(
            <Row key={index}>
              <Cell>
              {this.state.selectedTrain === '1' &&
              <div>
    <button   title='Train model' onClick={(e) => this.train(e,s.id)}  type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-book-reader"></i> Train model</button> 
    <button  onClick={(e) => this.viewModelFiles(e,s.id,s.mfgId,index)}  className='btn btn-primary btn-sm'>View Files</button> 
    {/* <button   title='Re-Tag' onClick={(e) => this.retagGroup(e,s.id)}  type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-book-reader"></i> Retag - {s.id}</button> */}
    </div>
        }
         {this.state.selectedTrain === '2' &&
    <button   title='Train model' onClick={(e) => this.train(e,s.id)}  type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-book-reader"></i> Re-train model</button> 
        }
            
            </Cell>
            <Cell>{groupName}</Cell>
            {/* <Cell>{getDocTypeName}</Cell> */}
            
          </Row>
                     
              )
        }else{
          let files = [];
          let filenames =[];
          if(s.files){
            filenames = s.files.split(',');
          }
          
          if(filenames.length){
            filenames.forEach ((s,index) => { 
              let filename = s.split('#')
              files.push(
                  <span key={index}>{filename[0]}</span> 
              )
             })
          }
          
          filelists.push(
            <Row key={index}>
              <Cell>
              {this.state.selectedTrain === '1' &&
              <div>
    <button   title='Train model' onClick={(e) => this.train(e,s.id)}  type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-book-reader"></i> Train model</button> 
    <button  onClick={(e) => this.viewModelFiles(e,s.id,s.mfgId,index)}  className='btn btn-primary btn-sm'>View Files</button> 
    </div>
      }
         {this.state.selectedTrain === '2' &&
    <button   title='Train model' onClick={(e) => this.train(e,s.id)}  type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-book-reader"></i> Re-train model</button> 
        }
            </Cell>
            {/* <Cell><div className='trainFileName'>{files}</div></Cell> */}
            {/* <Cell>{getDocTypeName}</Cell> */}
            
          </Row>
                     
              )
        }
         
         })
  return (
    <div className='h100percent'>
        <div className='main-header'>
        <div className='row'>
            <div className='col-md-6'><h2 className="page-header">Train Model</h2></div>
            <div className='col-md-6 text-right'>           
              {/* <button onClick={(e)=>this.AllpublishModel(e)} className='btn btn-primary mt-2'>All Published Model</button>
          */}
             </div>
          </div>
          </div>
          <div className='main-panel'>
        <div className='content '>
         <div className='container-fluid h100percent'>
         <ToastContainer />
 <div className='h100percent text-selection-none bg-white  round-corner-all'>
     <div className='row'>
     <div className='col-md-4' >
        <div className="form-group ">
                 <div >
                 <label>Document Type</label>
         <Select    
         className ='orgindex'            
        value={this.state.docTypeDropSelected}
        onChange={this.updatedocTypeDrop}
        options={this.state.docTypeDrop}
        defaultValue={{ value: '', label: '----' }}
      />
                 </div>
                 </div> 
               </div>
        
     {/* <div className='col-md-4'>
        <div className="form-group ">
            <label>Filter By  </label>
                <select  className="form-control">
                    <option value=''>------</option>
                    <option value=''>Group Name (ASC)</option>
                    <option value=''>Group Name (DESC)</option>
                    <option value=''>Manufacturer Name (ASC)</option>
                    <option value=''>Manufacturer Name (DESC)</option>
                    
                </select>
              </div>
        </div>
        */}
       {/* {this.state.docTypeDropSelected.value &&
       <div className='col-md-8'>
         <div className='trainradiopt'>
       <div className="custom-control custom-radio custom-control-inline">
    <input type="radio" onChange={this.changeTraine} className="custom-control-input" defaultChecked={this.state.selectedTrain == 1?'checked':''} id="customRadio"  name="trainmodelType" value="1" />
    <label className="custom-control-label" htmlFor="customRadio">Ready for Train</label>
  </div>
  <div className="custom-control custom-radio custom-control-inline">
    <input onChange={this.changeTraine} type="radio" className="custom-control-input" defaultChecked={this.state.selectedTrain == 2?'checked':''} id="customRadio2" name="trainmodelType" value="2" />
    <label className="custom-control-label" htmlFor="customRadio2">View Trained Model</label>
  </div>
  </div>
       </div> 
  } */}
        
        {/* <div className='col-md-4'>
        <div className="form-group ">
        <label>Select  Group</label>
        <select  className="form-control">
                    <option value=''>------</option>
                    <option value=''>Group Name</option>
                    <option value=''>Group Name</option>
                    <option value=''>Group Name</option>
                    <option value=''>Group Name</option>
                    <option value=''>Group Name</option>
                    <option value=''>Group Name</option>
                </select>
          </div>
        
        </div>
         */}
     </div>
        <div className='row  manudetailheight'>
        <div className="col-md-12  h-100">
        <div style={{width: '100%', height: '100%'}}>
        {this.state.docTypeDropSelected.value === 1 && filelists.length > 0 &&
          <StickyTable leftStickyColumnCount={0} >
            <Row>
            <Cell className='tagfile-tool-width'></Cell>
              <Cell>Group Name</Cell>
              {/* <Cell>Document Type</Cell> */}
            
            </Row>
            {filelists}
            
          </StickyTable>
  }
  {this.state.docTypeDropSelected.value && this.state.docTypeDropSelected.value !== 1 && filelists.length > 0 &&
  <div>
    
   
          <StickyTable leftStickyColumnCount={0} >
            <Row>
            <Cell className='tagfile-tool-width' ></Cell>
              {/* <Cell>File Name</Cell> */}
              
            </Row>
            {filelists}
            
          </StickyTable>
          
          </div>
  }
  {ifTrainSent &&  filelists.length === 0 &&
  <div class="alert alert-primary" >Model has been sent for training</div>
        // <button onClick={(e)=>this.goSentForTrain(e)} className='btn btn-primary'> 
        //      <span className="badge badge-primary">{modelCount}</span> model sent for training.
        //     </button>
  }
        </div>
   
</div>
        </div>
      </div>
    </div>
    
    </div>
    </div>
    {/* <Modal id='modal-tagDesigner-readonly' size="lg" isOpen={this.state.isSetTrainListModal} centered={true} toggle={this.closeTagDesigner} className="modal modal-tagDesigner-readonly">
        <ModalHeader toggle={this.closeTagDesigner}>Sent for training </ModalHeader>
        <ModalBody>
        <div style={{width: '100%', height: '100%'}}>
        {this.state.docTypeDropSelected.value === 1 &&
          <StickyTable leftStickyColumnCount={0} >
            <Row>
              <Cell>Group Name</Cell>
              <Cell>Date</Cell>
            </Row>
            {trainlistSentD}
            
          </StickyTable>
  }
  {this.state.docTypeDropSelected.value && this.state.docTypeDropSelected.value !== 1 &&
  <div>
    
   
          <StickyTable leftStickyColumnCount={0} >
            <Row>
              <Cell>File Name</Cell>
              <Cell>Date</Cell>
              
            </Row>
            {trainlistSentD}
            
          </StickyTable>
          
          </div>
  }
        </div>
   
        </ModalBody>
               
      </Modal> 
       */} 
      <Modal isOpen={this.state.modalList} centered={true} toggle={this.togglemodalList} className="modal modalViewGroup">
        <ModalHeader toggle={this.togglemodalList}>File Information</ModalHeader>
        <ModalBody>
       
        <div style={{width: '100%', height: '100%'}}>
          <StickyTable leftStickyColumnCount={0} >
            <Row>
              <Cell>
              File  Name 
              </Cell>
              <Cell>
                
              </Cell>
              
            </Row>
            {taggedFileperModel}
            
          </StickyTable>
        
   </div>

       
        
        </ModalBody>
       
        
      </Modal> 
     
      <Modal id='modal-tagDesigner-readonly' size="lg" isOpen={this.state.ifDesigner} centered={true} toggle={this.closeTagDesigner} className="modal modal-tagDesigner-readonly">
        <ModalHeader toggle={this.closeTagDesigner}>Tag Details - {this.state.tagFilename}</ModalHeader>
        <ModalBody>
        <TagdocReadOnly/>
        </ModalBody>
       
        
      </Modal> 
      {this.state.ifDownload && 
  <JsonDownload closedownload={this.closeIfDownload} submitted={this.state.isSubmitted}/> 
}
           </div>
  );
}
}


export default TrainModel;