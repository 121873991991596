import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { Resizable } from "re-resizable";
import Draggable from 'react-draggable';
import Select from 'react-select';
import img from '../images/pdf.png'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CSVLink, CSVDownload } from "react-csv";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { saveAs } from 'file-saver';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class Tagdoc extends Component {
  constructor(props) {
    super(props);
    let today = new Date();
    let date = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate();
    this.state = {
    tasks: [
        // {name:"input",category:"input"},
        // {name:"textarea", category:"textarea"},        
      ],
      draw: [],
      dragx:'',
      dragy:'',
      xmin:'',
      ymin:'',
      xmax:'',
      ymax:'',
      modal: false,
      selectedelm:'',
      tagtype : '',
      setdesc : 1,
      selectedOption: { value:'',label:'Select Key/Value'},
      showhide:'hide',
      currentPage:cookies.get("TaggedFilePage"),
      tagkeyList:[],
      tagvalueList:[],
      keyOptions:[],
      selectedImage:'',
      cDate:date,
      selectedTagId:'',
      sectioninfo:[
        { value:'',label:'Select Section'},
        { value:'1',label:'Section 1'},
        { value:'2',label:'Section 2'},
        { value:'3',label:'Section 3'},
        { value:'4',label:'Section 4'},
        { value:'5',label:'Section 5'},
        { value:'6',label:'Section 6'},
        { value:'7',label:'Section 7'},
        { value:'8',label:'Section 8'},
        { value:'9',label:'Section 9'},
        { value:'10',label:'Section 10'},
        { value:'11',label:'Section 11'},
        { value:'12',label:'Section 12'},
        { value:'13',label:'Section 13'},
        { value:'14',label:'Section 14'},
        { value:'15',label:'Section 15'},
        { value:'16',label:'Section 16'},
      ],
      selectedSection: { value:'',label:'Select Section'},
      expandWindow:false,
      isSubmitted:false,
      assignModal:false,
      taggerList:[],
      selectedTagger:'',
     
}
this.getjson = this.getjson.bind(this)
}

getjson(docid){ 
  document.getElementById("loader").classList.add("d-block");

  fetch(process.env.REACT_APP_ENV+'/api/TagV2/GetTagData',{
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization':'bearer '+cookies.get("auth")
    },
    body: JSON.stringify({ 
      DocID: docid,
      status : 1
   })
    })
         .then((response) => response.json())
         .then((responseJson) => {
          // console.log(responseJson.data);
           if(responseJson.data){
            let taggedAreas = [];
            let filename = responseJson.data[0].imageName;
            var startFileName = filename.slice(0, filename.lastIndexOf("_"));
            for (var i = 0; i < responseJson.data.length; i++) {
              let pageno = responseJson.data[i].imageName.split("_").pop();
              let tagindex = this.state.tagkeyList.findIndex(tagkey => tagkey.value === responseJson.data[i].tagID);
              taggedAreas.push({
                id: responseJson.data[i].id,
                left: responseJson.data[i].left,
                top: responseJson.data[i].top,
                right: responseJson.data[i].right,
                bottom: responseJson.data[i].bottom,
                width: responseJson.data[i].width,
                height: responseJson.data[i].height,
                tag_id: responseJson.data[i].tagID,
                tag_name: this.state.tagkeyList[tagindex].name,
                image_name: responseJson.data[i].imageName,
                doc_id: responseJson.data[i].docID,
                version: responseJson.data[i].version,
                status: responseJson.data[i].status,
                update_tag_data: 0,
                tbl: responseJson.data[i].tbl,
                pageno: pageno,
                img_width: responseJson.data[i].img_width,
                tag_type: responseJson.data[i].tag_type
              })              
            }
             let storedata = [
              {
                file: {
                  bucket: "clientuploadedfilesbyapi",
                  key_name: startFileName+".pdf",
                  file_type: "pdf"
                },
                manufacturer: "",
                tagged_areas: taggedAreas
              }
            ];         
            //console.log(storedata)
            let jsonfile = JSON.stringify(storedata,undefined,2);
            let fileToSave = new Blob([jsonfile], {type: 'application/json'});
            saveAs(fileToSave,startFileName+'.json'); 
           }
           const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', responseJson.status.authtoken, { path: '/'});  
                    
           document.getElementById("loader").classList.remove("d-block");
           this.props.closedownload(true);
         })
         .catch(error =>{
           console.log(error);          
           document.getElementById("loader").classList.remove("d-block");
         })
}



componentDidMount(){  
  if(this.props.submitted){
    this.state.isSubmitted = this.props.submitted
    this.setState({
      isSubmitted:this.state.isSubmitted
    })
  }
 
  document.addEventListener('click', this.handleClickOutside, true);
  document.getElementById('loader').classList.add('d-block') 
  fetch(process.env.REACT_APP_ENV+'/api/TagV2/GetTagKeyValList',{
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization':'bearer '+cookies.get("auth")

    },
    body: JSON.stringify({ 
      category:cookies.get("TagCategory"),
      status:1
   })
    }) 
  .then((response) => response.json())
  .then((responseJson) => {
    let tempData = responseJson.data;  
      if(tempData){
        let storedata = [];
        for (var i = 0; i < tempData.length; i++) {
          storedata.push({
            value: tempData[i].id, 
            label:  tempData[i].desc,
            type:tempData[i].type,
            section:tempData[i].section,
            name:tempData[i].val,
        })
          // console.log(tempData[i].val)
        }
        this.state.tagkeyList = [...this.state.tagkeyList, ...storedata ]
         this.setState({
           tagkeyList: this.state.tagkeyList,
      })
     // console.log(this.state.tagkeyList)
     const timestamp = new Date().getTime();
     const expireauth = timestamp + 1800000;
     const exdate =  new Date(expireauth);
     cookies.set('auth', responseJson.status.authtoken, { path: '/'});  
      }
     
      
  })
  .catch(error =>{
    console.log(error);  
   // document.getElementById('loader').classList.remove('d-block')        
   
  })
  fetch(process.env.REACT_APP_ENV+'/api/TagV2/ViewTagImage',{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'bearer '+cookies.get("auth")

    },
    body: JSON.stringify({ 
      PageNo:cookies.get("TaggedFilePage"),
      // FileName:  localStorage.getItem("TaggedFile"),
      FileName: cookies.get("TaggedFile"),
       category:cookies.get("TagCategory")
   })
    })
  .then((response) => response.json())
  .then((responseJson) => {
    let tempData = responseJson;    
    if(tempData){
      
      this.setState({
        selectedImage: tempData,
    })

   
    }
    this.getjson(parseInt(cookies.get("docID")))
  //document.getElementById('loader').classList.remove('d-block')
  })
  .catch(error =>{
    console.log(error);          
    //document.getElementById('loader').classList.remove('d-block')
  })
}

  render() {  
   return (
   
   
     
       
         <div className='container-fluid h100percent'>
    
   </div>
  
  
  )
    
  }
};

export default Tagdoc;