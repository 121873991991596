import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import {NavLink} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
import Select from 'react-select';
const cookies = new Cookies();
class TagQueue extends Component {  
   constructor(props) {
      super(props);
      this.state = {
           tgname: '',
           tgdesc:'',
           filelist:[],
           groupList:[],
           selectedgroup:''
      };
    this.addtoqueue = this.addtoqueue.bind(this)
      this.getdocbygroupById = this.getdocbygroupById.bind(this)
      this.updateValueGroup = this.updateValueGroup.bind(this)
    }
        updateValueGroup(newValue) {
         console.log(newValue)
          this.state.selectedgroup = newValue
        this.setState({
          selectedgroup: this.state.selectedgroup
        });
        this.getdocbygroupById(this.state.selectedgroup.value.toString())
      }
       componentDidMount(){
         document.getElementById('loader').classList.add('d-block'); 
         fetch(process.env.REACT_APP_ENV+'/api/Document/GetGroupList',{
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'bearer '+cookies.get("auth")
            
            },
            body: JSON.stringify({  
              cid : cookies.get("cid")
           })
            })
          .then((response) => response.json())
          .then((responseJson) => {
            let tempData = responseJson; 
           // console.log(tempData)   
           const timestamp = new Date().getTime();
           const expireauth = timestamp + 1800000;
           const exdate =  new Date(expireauth);
           cookies.set('auth', tempData.status.authtoken, { path: '/'});  
            if(tempData.data === null){
            toast.error('Error ocured. Please Try Again')
              
              // console.log(this.state.grouplist)
             }else{
             let storedata = [];
             let tempDatas = responseJson.data; 
             for (var i = 0; i < tempDatas.length; i++) {
               storedata.push({
                 value: tempDatas[i].id, 
                 label:  tempDatas[i].groupname,
               })
               
               }
              this.setState({
             groupList: [...this.state.groupList, ...storedata ],
            })
            this.setState({
             selectedgroup:this.state.groupList[0],
            })

            console.log(this.state.groupList[1].value)
            
            this.getdocbygroupById(this.state.groupList[0].value)
           }
          // document.getElementById('loader').classList.remove('d-block'); 
           
          })
          .catch(error =>{
            console.log(error); 
          })
      }  
      getdocbygroupById(id){
       this.setState({
         filelist: [],
     })
       document.getElementById('loader').classList.add('d-block'); 
       fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocList',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization':'bearer '+cookies.get("auth")
              
            },
            body: JSON.stringify({              
             groupId:id
           })
            })
          .then((response) => response.json())
          .then((responseJson) => {
            let tempData = responseJson; 
           // console.log(tempData.data[0].id)   
           const timestamp = new Date().getTime();
           const expireauth = timestamp + 1800000;
           const exdate =  new Date(expireauth);
           cookies.set('auth', tempData.status.authtoken, { path: '/',expires: exdate });    
                  
             if(tempData.data === null){
                toast.error('No records found..')                
               }else{      
                this.setState({
                  filelist: [...this.state.filelist, ...tempData.data ],
              })
             }
           document.getElementById('loader').classList.remove('d-block'); 
           
          })
          .catch(error =>{
            console.log(error); 
          })
     }     
        addtoqueue = (e,gid,did) => {
         document.getElementById('loader').classList.add('d-block'); 
         fetch(process.env.REACT_APP_ENV+'/api/Document/AssignDocument',{
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'bearer '+cookies.get("auth")
                
              },
              body: JSON.stringify({              
               assigned_user_id:cookies.get("userid"),
               group_id : gid,
               doc_id : did,
               cid : cookies.get("cid"),
               status : 1
             })
              })
            .then((response) => response.json())
            .then((responseJson) => {
              let tempData = responseJson; 
             // console.log(tempData.data[0].id)   
             const timestamp = new Date().getTime();
             const expireauth = timestamp + 1800000;
             const exdate =  new Date(expireauth);
             cookies.set('auth', tempData.status.authtoken, { path: '/',expires: exdate });    
                    
               if(tempData.status.statusMessage === 'Success'){                         
                toast.success('File added to queue for tagging');     
                 }else{      
                  toast.error('Error occured.Please try again')    
               }
             document.getElementById('loader').classList.remove('d-block'); 
             
            })
            .catch(error =>{
              console.log(error); 
            })
         
          
      }
      removeFromqueue= (e) => {
         toast.success('File removed from queue');
         
     }
      
     render() {    
      let filelists = [];
      this.state.filelist.forEach ((s,index) => {   
        filelists.push(
                   <tr   key={index} >                        
                   <td>{s.filename}</td>
                   <td>{s.groupname}</td>
                   {/* <td>{s.doctype}</td> */}
                   <td>{s.countrycode}</td>
                   <td>{s.langId}</td>
                   <td>{s.uploadtime}</td>
                   <td>
                   <button  title='Add to queue' onClick={(e) => this.addtoqueue(e,s.groupId,s.id)}  type="button" className="btn btn-primary btn-sm "><i className="fas fa-plus"></i></button> 
          
           </td>
                   
                  
                  
                   </tr>
           )
        })
  return (
    
   
    <div className='h100percent'>
        <div className='main-header'>
        <div className='row'>
            <div className='col-md-6'><h2 className="page-header">Tag Queue</h2></div>
            <div className='col-md-6 text-right'>             
              {/* <NavLink  className='btn btn-primary mr-2 mt-2'  title='Tag Queue'  activeClassName={"active"} to="/tag-queue">Tag Queue</NavLink>
               */}
              </div>
          </div>
          </div>
        <div className='main-panel'>
           <div className='content '>
           <div className='container-fluid h100percent'>
           <ToastContainer />
 <div className='h100percent text-selection-none '>
     <div className='row'>
     <div className='col-md-4'>
        <div className="form-group ">
        <label>Select  Group</label>
        <Select
                
        value={this.state.selectedgroup}
        onChange={this.updateValueGroup}
        options={this.state.groupList}
        defaultValue={{ value: '', label: '----' }}
      />
        
          </div>
        
        </div>
        
     <div className='col-md-4'>
        <div className="form-group ">
            <label>Filter By  </label>
                <select  className="form-control">
                    <option value=''>------</option>
                    <option value=''>Manufacturer Name (ASC)</option>
                    <option value=''>Manufacturer Name (DESC)</option>
                    <option value=''>Group Name (ASC)</option>
                    <option value=''>Group Name (DESC)</option>
                    <option value=''>Upload Date (ASC)</option>
                    <option value=''>Upload Date (DESC)</option>
                </select>
              </div>
        </div>
        
     </div>
        <div className='row  manudetailheight'>
        <div className="col-md-12  h-100">
   <div id="tabledata" className=" position-relative overflowy h-100">
      <table className="table bg-white mb-0">
         <thead>
            <tr>
               
            <th>File Name</th>               
               <th>Group Name</th>
               {/* <th>Manufacturer Name</th> */}
               {/* <th>Document Type</th> */}
               <th>Country</th>
               <th>Language</th>
               <th>Upload Date</th>
               <th className='gridtoolwidth_1half'></th>
            </tr>
         </thead>
         <tbody>
         {filelists}
         </tbody>
      </table>
   </div>
</div>
        </div>
      </div>
    </div>
    
    </div>
</div>
</div>
  )};
};

export default TagQueue;