import React, { Component } from "react";
import { HashRouter, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Menu from "./components/Menu.jsx";
import PerformExperiment from "./pages/Perform-Experiment.jsx";
import UploadFilesForPrediction from "./pages/Upload-Files-For-Prediction.jsx";
import TrainModel from "./pages/Train-Model.jsx";
import Predict from "./pages/Predict.jsx";
import Tagdoc from "./pages/Tagdoc.jsx";
import CreateTags from "./pages/Create-Tags.jsx";
import EditTags from "./pages/Edit-Tag.jsx";
import TagManagement from "./pages/Tag_management.jsx";
import UploadedFile from "./pages/Uploaded-File.jsx";
import Review from "./pages/Review.jsx";
import ManuDetails from "./pages/ManuDetails.jsx";
import TaggingDetails from './pages/TaggingDetails.jsx';
import ProcessedFiles from './pages/ProcessedFiles.jsx'
import Login from "./pages/Login.jsx";
import UploadedfileList from "./pages/UploadedFIleList.jsx";
import Addgroup from "./pages/Add-group.jsx";
import Editgroup from "./pages/EditGroup.jsx";
import viewgroup from "./pages/ViewGroup.jsx";
import Addtogroup from "./pages/AddToGroup.jsx";
import TagQueue from "./pages/TagQueue.jsx";
import TrainStatus from "./pages/TrainStatus.jsx";
import EvaluateStatus from "./pages/EvaluateStatus.jsx";
import PublishStatus from "./pages/PublishStatus.jsx";
import TagdocReadOnly from "./pages/TagDocReadOnly.jsx";
import PublishModel from "./pages/PublishModel.jsx";
import Admin from "./pages/Dashboard.jsx";
import AddAccount from "./pages/Add-Account.jsx";
import ViewAccount from "./pages/ViewAccount.jsx";
import editAccount from "./pages/Edit-Account.jsx";
import SystemAdmin from "./pages/SystemAdmin.jsx";
import AddAccountClient from "./pages/Add-Account-client.jsx";
import editAccountClient from "./pages/Edit-Account-client.jsx";
import ViewAccountClient from "./pages/ViewAccountCLient.jsx";
import ClientDetails from "./pages/Client-Details.jsx";
import ViewDocType from "./pages/ViewDocType.jsx";
import AddDocType from "./pages/AddDocType.jsx";
import EditDocType from "./pages/EditDocType.jsx";
import ProcessFile from "./pages/Process-File.jsx";
import AdminSettings from "./pages/AdminSettings.jsx"
import Profile from "./pages/Profile.jsx"
import ViewDocTypeMeta from "./pages/ViewDocMetaData.jsx"
import ApiKey from "./pages/ApiKey.jsx"
import AddMetaData from "./pages/AddMetaData.jsx"
import ProcessFiles from "./pages/ProcessFiles.jsx"
import ManageTaggesFIles from "./pages/ManageTaggesFIles.jsx"
import AddUriscreen from "./pages/Add-URI.jsx"
import JSONLoopDownload from "./pages/JSONLoopDownload.jsx"
import JsonLoopDownloadnonSUbmittedGroup  from "./pages/JsonLoopDownloadnonSUbmittedGroup.jsx"
import styles from './app.scss'; 
import { ToastContainer, toast } from 'react-toastify';
import AdminUploadedFIleList from "./pages/AdminUploadedFIleList.jsx"
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const history = createBrowserHistory();

class App extends Component {  
  constructor(props) {
    super(props);   
    this.state = {
      isLoggedIn:false, 
      user:[
        {
          'username':'',
          'password':'',
          'auth':'',
          'email':''
        }
      ]
    }
    this.handleIsLoggedin = this.handleIsLoggedin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
}
componentDidMount(){ 
          let auth = cookies.get("auth");
          if(auth){        
              this.state.user[0].email = cookies.get("email");
              this.state.user[0].username = cookies.get("fname")+' '+cookies.get("lname");
              this.state.user[0].auth = cookies.get("auth");
              this.setState({isLoggedIn:true,user:this.state.user});
            
          }else{
            
          }
         
}


handleIsLoggedin(logedin,uname,pass){
  if(logedin){
    document.getElementById('loader').classList.add('d-block')
    fetch(process.env.REACT_APP_ENV+'/api/UserAuth/Authenticate',{
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',

    },
    body: JSON.stringify({ 
      email_id: uname,
      password: pass
   })
    })
  .then((response) => response.json())
  .then((responseJson) => {
    let tempData = responseJson; 
    if(tempData.status.authtoken === null){    
      document.getElementById('loader').classList.remove('d-block')
      toast.error('Username or Password is incorrect.');
  }else{
    let exdate = new Date();
    const timestamp = new Date().getTime();
    const expire = timestamp + 86400000;
    const expireauth = timestamp + 1800000;
    const expireDate = new Date(expire);
    const expireauthorization =  new Date(expireauth);
        cookies.set('userid', tempData.data.id, { path: '/'});
        cookies.set('email', tempData.data.email_id, { path: '/' });
        cookies.set('fname', tempData.data.first_name, { path: '/' });
        cookies.set('lname', tempData.data.last_name, { path: '/' });
        cookies.set('auth', tempData.status.authtoken, { path: '/'});     
        cookies.set('cid', tempData.data.cid, { path: '/' });  
        cookies.set('created_by', tempData.data.created_by, { path: '/' }); 
        cookies.set('roleid', tempData.data.roleid, { path: '/' }); 
        this.state.isLoggedIn = logedin;
        this.state.user[0].email = tempData.data.email_id;
        this.state.user[0].username = tempData.data.first_name+' '+tempData.data.last_name;
        this.state.user[0].password = pass;
        this.state.user[0].auth = tempData.status.authtoken;
        this.setState({isLoggedIn:this.state.isLoggedIn,user:this.state.user});          
        document.getElementById('loader').classList.remove('d-block'); 
  }
  })
  .catch(error =>{
    console.log(error); 
  })
       
  }  
 }
 handleLogout(logstatus){
   if(logstatus==='logout'){
     this.state.isLoggedIn = false;
      this.state.user[0].username = '';
      this.state.user[0].password = '';
      this.state.user[0].auth = '';
      this.state.user[0].email = '';
      cookies.remove('email', { path: '/' });
      cookies.remove('fname', { path: '/' });
      cookies.remove('lname', { path: '/' });
      cookies.remove('auth', { path: '/' });     
      cookies.remove('cid', { path: '/' }); 
      cookies.remove('created_by', { path: '/' }); 
      cookies.remove('userid', { path: '/' }); 
      cookies.remove('roleid', { path: '/' }); 
      cookies.remove('groupid', { path: '/' });
      cookies.remove('TaggedFilePage', { path: '/' });
      cookies.remove('TaggedFile', { path: '/' });
      cookies.remove('mfgid', { path: '/' });
      cookies.remove('SeleQGroup', { path: '/' })
      cookies.remove('TMDOC', { path: '/' })
      cookies.remove('tagFilter', { path: '/' })
      this.setState({
        isLoggedIn:this.state.isLoggedIn,
        user:this.state.user
      })
     // alert(window.location.hostname)
      if(window.location.hostname === 'cloudsds.com'){
        // history.push('/api-search-demo/skilith-blue')
        window.location = '/api-search-demo/d2studio/';
        return false;
       }else if(window.location.hostname === 'api.cloudsds.com'){
         // history.push('/api-search-demo/skilith-blue')
         window.location = '/d2studio/';
         return false;
        }else if(window.location.hostname === 'files.cloudsds.com'){
         // history.push('/api-search-demo/skilith-blue')
         window.location = 'https://files.cloudsds.com/files/d2studio/';
         return false;
        }else if(window.location.hostname === '34.225.242.67'){
          // history.push('/api-search-demo/skilith-blue')
          window.location = 'http://34.225.242.67:8080/d2studio';
          return false;
         }else{
          window.location = '/';
         return false;
        }
   }    
  }
 
  checkauth = () => {  
    let auth = cookies.get("auth");
    // if(!auth){
    //   this.state.isLoggedIn = false;
    //   this.state.user[0].username = '';
    //   this.state.user[0].password = '';
    //   this.state.user[0].auth = '';
    //   this.state.user[0].email = '';
    //   cookies.remove('email', { path: '/' });
    //   cookies.remove('fname', { path: '/' });
    //   cookies.remove('lname', { path: '/' });
    //   cookies.remove('auth', { path: '/' });     
    //   cookies.remove('cid', { path: '/' }); 
    //   cookies.remove('created_by', { path: '/' }); 
    //   cookies.remove('userid', { path: '/' }); 
    //   cookies.remove('roleid', { path: '/' }); 
    //   cookies.remove('groupid', { path: '/' });
    //   cookies.remove('TaggedFilePage', { path: '/' });
    //   cookies.remove('TaggedFile', { path: '/' });
    //   cookies.remove('mfgid', { path: '/' });
    //   cookies.remove('SeleQGroup', { path: '/' })
    //   cookies.remove('TMDOC', { path: '/' })
    //   this.setState({
    //     isLoggedIn:this.state.isLoggedIn,
    //     user:this.state.user
    //   })
    //  // alert(window.location.hostname)
    //     if(window.location.hostname === 'cloudsds.com'){
    //       // history.push('/api-search-demo/skilith-blue')
    //       window.location = '/api-search-demo/d2studio/';
    //       return false;
    //      }else if(window.location.hostname === 'api.cloudsds.com'){
    //        // history.push('/api-search-demo/skilith-blue')
    //        window.location = '/d2studio/';
    //        return false;
    //       }else if(window.location.hostname === 'files.cloudsds.com'){
    //        // history.push('/api-search-demo/skilith-blue')
    //        window.location = 'https://files.cloudsds.com/files/d2studio/';
    //        return false;
    //       }else if(window.location.hostname === '34.225.242.67'){
    //         // history.push('/api-search-demo/skilith-blue')
    //         window.location = 'http://34.225.242.67:8080/d2studio';
    //         return false;
    //        }else{
    //         window.location = '/';
    //        return false;
    //       }
    // }
  }
  render() {
    const history = createBrowserHistory();
    const role =  parseInt(cookies.get('roleid'))
    const companyid = parseInt(cookies.get('cid'))
    let isLoggedIn = this.state.isLoggedIn;
    const renderAuth = ()=>{
      if(isLoggedIn){
      
        if(role === 3){
          if(companyid === 1){
            return (
              <HashRouter history={history}>
                <div onClick={this.checkauth}  id='collapsec' className='wrapper'>
                  <div id='loader' className="loaderholder"><div className="loader"></div></div>
                  <Menu 
                  username={this.state.user[0].username}
                  handleLogout={this.handleLogout}  
                  />                   
                   <Route exact   path="/" component={viewgroup} />
                    <Route   path="/add-group" component={Addgroup} />
                   <Route   path="/add-to-group" component={Addtogroup} />
                   <Route   path="/edit-group" component={Editgroup} /> 
                  <Route  path="/upload-file" component={UploadFilesForPrediction} />                   
                    <Route    path="/uploaded-file-list" component={UploadedfileList} />
                    <Route  path="/tagging" component={Tagdoc} />
                    <Route   path="/tagged-file" component={UploadedFile} /> 
                    <Route   path="/profile" component={Profile} />
                   
      
                </div>
                </HashRouter>
              );
          }else{
            return (
              <HashRouter history={history}>
                <div onClick={this.checkauth}  id='collapsec' className='wrapper'>
                  <div id='loader' className="loaderholder"><div className="loader"></div></div>
                  <Menu 
                  username={this.state.user[0].username}
                  handleLogout={this.handleLogout}  
                  />
                 
                   {/* <Route exact  path="/"  component={TagManagement} /> */}
                   {/* <Route   path="/add-group" component={Addgroup} />
                   <Route   path="/add-to-group" component={Addtogroup} />
                   <Route   path="/edit-group" component={Editgroup} />
                   <Route   path="/view-group" component={viewgroup} /> */}
                  {/* <Route  path="/upload-file" component={UploadFilesForPrediction} />
                    <Route   path="/uploaded-file-list" component={UploadedfileList} />
                    <Route  path="/upload-tagged-file" component={PerformExperiment} /> */}
                    <Route  exact  path="/" component={UploadedfileList} />
                    <Route  path="/tagging" component={Tagdoc} />
                    <Route   path="/tagged-file" component={UploadedFile} /> 
                    <Route   path="/profile" component={Profile} />
                    {/* <Route  path="/tag-queue" component={TagQueue} /> */}
                   
      
                </div>
                </HashRouter>
              );
          }
         
        }else if(role === 4){
          return (
            <HashRouter history={history}>
              <div onClick={this.checkauth} id='collapsec' className='wrapper'>
                <div id='loader' className="loaderholder"><div className="loader"></div></div>
                <Menu 
                username={this.state.user[0].username}
                handleLogout={this.handleLogout}  
                />
                <Route  exact path="/" component={Admin} />
                <Route   path="/tag-management"  component={TagManagement} />
                  <Route  path="/create-tag" component={CreateTags} />
                  <Route  path="/edit-tag"  component={EditTags} />
                 <Route   path="/add-group" component={Addgroup} />
                 <Route   path="/add-to-group" component={Addtogroup} />
                 <Route   path="/edit-group" component={Editgroup} />
                 <Route   path="/view-group" component={viewgroup} />
                  <Route  path="/upload-file" component={UploadFilesForPrediction} />
                  <Route   path="/uploaded-file-list" component={UploadedfileList} />
                  {/* <Route  path="/upload-tagged-file" component={PerformExperiment} />  */}
                  {/* <Route  path="/tagging" component={Tagdoc} /> */}
                  <Route  path="/train-model" component={TrainModel} />
                  <Route  path="/publish-model" component={PublishModel} />
                  <Route   path="/profile" component={Profile} />
                  <Route path="/all-uploaded-file" component={AdminUploadedFIleList} />
                  <Route path="/manage-tagged-files" component={ManageTaggesFIles} />
                  <Route path="/process-files" component={ProcessFiles} />
                  {/* <Route exact  path="/" component={UploadedFile} /> */}
                  {/* <Route  path="/manufacturer-details" component={ManuDetails} />
                  <Route  path="/tagging-details" component={TaggingDetails} />
                  <Route  path="/process-files" component={ProcessedFiles} /> */}
                  {/* <Route  path="/tag-queue" component={TagQueue} /> */}
                  {/* <Route  path="/train-status" component={TrainStatus} />
                  <Route  path="/publish-status" component={PublishStatus} />
                  <Route  path="/evaluate-status" component={EvaluateStatus} />
                  <Route  path="/tagged-file-read-only" component={TagdocReadOnly} />
                  <Route  path="/publish-model" component={PublishModel} /> */}
                 
    
              </div>
              </HashRouter>
            );
        }else if(role === 2){
          return (
            <HashRouter history={history}>
              <div onClick={this.checkauth} id='collapsec' className='wrapper'>
                <div id='loader' className="loaderholder"><div className="loader"></div></div>
                <Menu 
                username={this.state.user[0].username}
                handleLogout={this.handleLogout}  
                />
                 <Route  exact path="/" component={Admin} />
                 <Route   path="/tag-management"  component={TagManagement} />
                 <Route   path="/add-group" component={Addgroup} />
                 <Route   path="/add-to-group" component={Addtogroup} />
                 <Route   path="/edit-group" component={Editgroup} />
                 <Route   path="/view-group" component={viewgroup} />
                  <Route  path="/upload-file" component={UploadFilesForPrediction} />
                  <Route   path="/uploaded-file-list" component={UploadedfileList} />
                  <Route  path="/upload-tagged-file" component={PerformExperiment} />
                  <Route  path="/tagging" component={Tagdoc} />
                  <Route  path="/train-model" component={TrainModel} />
                  <Route  path="/published-models" component={Predict} />
                  <Route  path="/tagged-file" component={UploadedFile} />
                  <Route  path="/manufacturer-details" component={ManuDetails} />
                  <Route  path="/tagging-details" component={TaggingDetails} />
                  <Route  path="/create-tag" component={CreateTags} />
                  <Route  path="/edit-tag"  component={EditTags} />
                  <Route  path="/tag-queue" component={TagQueue} />
                  <Route  path="/train-status" component={TrainStatus} />
                  <Route  path="/publish-status" component={PublishStatus} />
                  <Route  path="/evaluate-status" component={EvaluateStatus} />
                  <Route  path="/tagged-file-read-only" component={TagdocReadOnly} />
                  <Route  path="/publish-model" component={PublishModel} />
                  <Route   path="/profile" component={Profile} />
                  <Route  path="/view-doc-type" component={ViewDocType} />                  
                  <Route  path="/view-account" component={ViewAccount} />
                  <Route  path="/add-account" component={AddAccount} />
                  <Route  path="/update-account" component={editAccount} />    
                  <Route  path="/process-file" component={ProcessFile} /> 
                  <Route path="/settings" component={AdminSettings} />
                  <Route path="/view-api-key" component={ApiKey} />
                  <Route path="/all-uploaded-file" component={AdminUploadedFIleList} />
                  <Route path="/manage-tagged-files" component={ManageTaggesFIles} />
                  <Route path="/process-files" component={ProcessFiles} />
                  <Route  path="/add-uri" component={AddUriscreen} />
                  <Route path="/json-download" component={JSONLoopDownload} />
                  <Route path="/json-download-nonsubmitted" component={JsonLoopDownloadnonSUbmittedGroup} />
              </div>
              </HashRouter>
            );
        }else{
          return (
            <HashRouter history={history}>
              <div onClick={this.checkauth} id='collapsec' className='wrapper'>              
                <div id='loader' className="loaderholder"><div className="loader"></div></div>
                <Menu 
                username={this.state.user[0].username}
                handleLogout={this.handleLogout}  
                />
                <Route exact  path="/" component={Admin} />
                 <Route   path="/tag-management"  component={TagManagement} />
                 <Route   path="/add-group" component={Addgroup} />
                 <Route   path="/add-to-group" component={Addtogroup} />
                 <Route   path="/edit-group" component={Editgroup} />
                 <Route   path="/view-group" component={viewgroup} />
                  <Route  path="/upload-file" component={UploadFilesForPrediction} />
                  <Route   path="/uploaded-file-list" component={UploadedfileList} />
                  <Route  path="/upload-tagged-file" component={PerformExperiment} />
                  <Route  path="/tagging" component={Tagdoc} />
                  <Route  path="/train-model" component={TrainModel} />
                  <Route  path="/published-models" component={Predict} />
                  <Route  path="/tagged-file" component={UploadedFile} />
                  <Route  path="/manufacturer-details" component={ManuDetails} />
                  <Route  path="/tagging-details" component={TaggingDetails} />
                  <Route  path="/create-tag" component={CreateTags} />
                  <Route  path="/edit-tag"  component={EditTags} />
                  <Route  path="/tag-queue" component={TagQueue} />
                  <Route  path="/train-status" component={TrainStatus} />
                  <Route  path="/publish-status" component={PublishStatus} />
                  <Route  path="/evaluate-status" component={EvaluateStatus} />
                  <Route  path="/tagged-file-read-only" component={TagdocReadOnly} />
                  <Route  path="/publish-model" component={PublishModel} />                 
                  <Route  path="/add-client-account" component={AddAccountClient} />
                  <Route  path="/update-client-account" component={editAccountClient} />
                  <Route  path="/view-client-account" component={ViewAccountClient} />
                  <Route  path="/system-admin" component={SystemAdmin} />
                  <Route  path="/client-details" component={ClientDetails} /> 
                  <Route  path="/view-doc-type" component={ViewDocType} />
                  <Route  path="/add-doc-type" component={AddDocType} />
                  <Route  path="/update-doc-type" component={EditDocType} />
                  
                  <Route  path="/view-account" component={ViewAccount} />
                  <Route  path="/add-account" component={AddAccount} />
                  <Route  path="/update-account" component={editAccount} /> 
                  <Route  path="/process-file" component={ProcessFile} /> 
                  <Route path="/settings" component={AdminSettings} />
                  <Route   path="/profile" component={Profile} />
                  <Route path='/view-doc-type-meta' component={ViewDocTypeMeta} />
                  <Route path='/add-doc-meta-data' component={AddMetaData} />
                  <Route path="/view-api-key" component={ApiKey} />
                  <Route path="/process-files" component={ProcessFiles} />
                  <Route path="/all-uploaded-file" component={AdminUploadedFIleList} />
                  <Route path="/manage-tagged-files" component={ManageTaggesFIles} />
                  <Route  path="/add-uri" component={AddUriscreen} />
                  
              </div>
              </HashRouter>
            );
        }
       
      } else{
        return <HashRouter >
        <div id='collapsec' className='wrapper collapsed'>
        <div id='loader' className="loaderholder"><div className="loader"></div></div>
          <div className="content-area">
          <ToastContainer />
          <Login 
          handleIsLoggedin={this.handleIsLoggedin}
           />
         </div>
        </div>
        </HashRouter>
      }
    }
    return (
      <div>
      {renderAuth()} 
      </div>
    );
  }
}

export default App; 
