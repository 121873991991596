import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Select from 'react-select';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class AddUriscreen extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      uri: '',
 }; 
   
  }
  
  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
  onSubmit = (e) => {   
    e.preventDefault();  
    let error = false;   
    const {uri} = this.state;
    if(uri === ''){
         toast.error('Enter callback API URL.')
         error = true;
         document.getElementsByName('uri')[0].focus()
         return false;
    }else{
      console.log(this.validURL(uri))
      if(!this.validURL(uri)){
        toast.error('Callback API URL is not valid.')
        error = true;
         document.getElementsByName('uri')[0].focus()
      }
      
    }
  
    
    if(error !== true){     
         document.getElementById('loader').classList.add('d-block'); 
         fetch(process.env.REACT_APP_ENV+'/client/SetCallbackAPIUrl',{
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'bearer '+cookies.get("auth")
                
              },
              body: JSON.stringify({ 
                callbackAPIUrl : this.state.uri
             })
              })
            .then((response) => response.json())
            .then((responseJson) => {
              let tempData = responseJson;  
            //  console.log(tempData)   
            if(tempData.status.statusMessage === 'Success'){
              cookies.set('auth', tempData.status.authtoken, { path: '/'});     
              toast.success('Callback API URL saved.')
              }
              document.getElementById('loader').classList.remove('d-block');
            })
            .catch(error =>{
              console.log(error); 
              document.getElementById('loader').classList.remove('d-block');
            })
    }
    
    
  }
  validURL=(str)=> {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }
  componentDidMount(){
    document.getElementById('loader').classList.add('d-block'); 
   
    fetch(process.env.REACT_APP_ENV+'/client/GetCallbackAPIUrl',{
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'Authorization':'bearer '+cookies.get("auth")
           
         },
         body: JSON.stringify({ 
        //  Id : cookies.get("cid")  
        })
         })
       .then((response) => response.json())
       .then((responseJson) => {
         let tempData = responseJson;  
         console.log(tempData)   
         if(tempData.status.statusMessage === 'Success'){
           this.state.uri = tempData.data;
           this.setState({
            uri:this.state.uri
           })
          
           cookies.set('auth', tempData.status.authtoken, { path: '/'});   
         }else{
             // toast.error('Error ocured. Please Try Again')
         }
        document.getElementById('loader').classList.remove('d-block'); 
        
       })
       .catch(error =>{
         console.log(error); 
         document.getElementById('loader').classList.remove('d-block');
       })
   }
    goBack() {
        window.history.back();
      }
    render(){
        return(
          <div className='h100percent'>
          <div className='main-header'>
            
            <div className='row'>
              <div className='col-md-6'><h2 className="page-header"><a onClick={this.goBack}   className="back-button cpointer" >
                 <i className="fas fa-arrow-circle-left"></i></a> Callback API URL</h2></div>
              <div className='col-md-6 text-right'>
              
                   
                   </div>
             
            </div>
            </div>
            <div className='main-panel'>
            <div className='content '>
            <ToastContainer />
            <div className='container-fluid h100percent'>
            <div className='h100percent text-selection-none '>
            <Form onSubmit={this.onSubmit}>
            
              <div className='row'>
                <div className='col-md-12'>
                <div className="form-row">
              <div className="form-group col-md-12">
                <label>Callback API URL <span className='text-danger'>*</span></label>
                <input type='text' placeholder='Full URL path (e.g. : https://xyz.com/api/callback)' onChange={this.onChange} value={this.state.uri} name='uri'  className="form-control" />
                <small className='text-danger'>
                *Note: You will receive <span className='text-dark font-italic'>&#123;"filename": "xxxx.json"&#125;</span>  (as application/json via POST) 
                </small>
              </div>
              </div>
              <button type="submit" className="btn btn-primary">Save</button>
                </div>
                
              </div>
             
             </Form>
              
           
             </div>   
</div>
</div>
        </div>
        </div>
     
        )
    }
}
export default AddUriscreen