import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
class ManuDetails extends Component {  
     constructor(props) {
          super(props);
          this.state = {
              
          };
        }
       
      
     render() {
          const { tgname, tgdesc} = this.state;
  return (
     <div className='h100percent'>
     <div className='main-header'>
       
       <div className='row'>
         <div className='col-md-6'><h2 className="page-header">Manufacturer Details</h2></div>
        
       </div>
       </div>
 <div className='main-panel'>
        <div className='content '>
         <div className='container-fluid h100percent'>
 <div className='h100percent text-selection-none '>
     <div className='row'>
        <div className='col-md-12'>
        <div className="form-group ">
            <label>Search Manufacturer  </label>
                <input type="text" name='mfgNameen'    className="form-control" />
              </div>
        </div>

     </div>
        <div className='row  manudetailheight'>
        <div className="col-md-12  h-100">
   <div id="tabledata" className=" position-relative overflowy h-100">
      <table className="table bg-white mb-0">
         <thead>
            <tr>
               
               <th>Manufacturer Name</th>
               <th>Uploaded Files</th>
               <th>Tagged Files</th>
               <th>Processed Files</th>
            </tr>
         </thead>
         <tbody>
            <tr>              
               <td>Sigma-Aldrich</td>
               <td>
               <button type="button" className="btn btn-primary"> <span className="badge badge-light">155</span> Details</button>
                </td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">120</span> Details</button></td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">70</span> Details</button></td>
            </tr>
            <tr>              
               <td>Sigma-Aldrich</td>
               <td>
               <button type="button" className="btn btn-primary"> <span className="badge badge-light">155</span> Details</button>
                </td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">120</span> Details</button></td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">70</span> Details</button></td>
            </tr>
            <tr>              
               <td>Sigma-Aldrich</td>
               <td>
               <button type="button" className="btn btn-primary"> <span className="badge badge-light">155</span> Details</button>
                </td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">120</span> Details</button></td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">70</span> Details</button></td>
            </tr>
            <tr>              
               <td>Sigma-Aldrich</td>
               <td>
               <button type="button" className="btn btn-primary"> <span className="badge badge-light">155</span> Details</button>
                </td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">120</span> Details</button></td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">70</span> Details</button></td>
            </tr>
            <tr>              
               <td>Sigma-Aldrich</td>
               <td>
               <button type="button" className="btn btn-primary"> <span className="badge badge-light">155</span> Details</button>
                </td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">120</span> Details</button></td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">70</span> Details</button></td>
            </tr>
            <tr>              
               <td>Sigma-Aldrich</td>
               <td>
               <button type="button" className="btn btn-primary"> <span className="badge badge-light">155</span> Details</button>
                </td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">120</span> Details</button></td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">70</span> Details</button></td>
            </tr>
            <tr>              
               <td>Sigma-Aldrich</td>
               <td>
               <button type="button" className="btn btn-primary"> <span className="badge badge-light">155</span> Details</button>
                </td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">120</span> Details</button></td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">70</span> Details</button></td>
            </tr>
            <tr>              
               <td>Sigma-Aldrich</td>
               <td>
               <button type="button" className="btn btn-primary"> <span className="badge badge-light">155</span> Details</button>
                </td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">120</span> Details</button></td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">70</span> Details</button></td>
            </tr>
            <tr>              
               <td>Sigma-Aldrich</td>
               <td>
               <button type="button" className="btn btn-primary"> <span className="badge badge-light">155</span> Details</button>
                </td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">120</span> Details</button></td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">70</span> Details</button></td>
            </tr>
            <tr>              
               <td>Sigma-Aldrich</td>
               <td>
               <button type="button" className="btn btn-primary"> <span className="badge badge-light">155</span> Details</button>
                </td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">120</span> Details</button></td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">70</span> Details</button></td>
            </tr>
            <tr>              
               <td>Sigma-Aldrich</td>
               <td>
               <button type="button" className="btn btn-primary"> <span className="badge badge-light">155</span> Details</button>
                </td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">120</span> Details</button></td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">70</span> Details</button></td>
            </tr>
            <tr>              
               <td>Sigma-Aldrich</td>
               <td>
               <button type="button" className="btn btn-primary"> <span className="badge badge-light">155</span> Details</button>
                </td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">120</span> Details</button></td>
               <td><button type="button" className="btn btn-primary"> <span className="badge badge-light">70</span> Details</button></td>
            </tr>
            
         </tbody>
      </table>
   </div>
</div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
  )};
};

export default ManuDetails;