import React, { Component } from 'react';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
import Select from 'react-select';
import TagdocReadOnly from "./TagDocReadOnly.jsx"
import JsonDownload from "./JsonDownload.jsx"
import { saveAs } from 'file-saver';
const cookies = new Cookies();
class TrainModel extends Component {  
  constructor(props) {
    super(props);
     this.state = {
         trainlist:[],
        docType:[],
        selectedTrain:'1',
        docTypeDrop:[],
        docTypeDropSelected:'',
        trainlistSent:[],
        isSetTrainListModal:false,
        modalList:false,
      ifDesigner:false,
      selectedGid:'',
      selectedMNFid:'',
      tagFileList:[],
      ifDownload:false,
      isSubmitted:true,
      tagkeyList:[]
     }
     
     this.train = this.train.bind(this)
     this.updatedocTypeDrop = this.updatedocTypeDrop.bind(this)
}
closeTagDesigner = () =>{
  this.setState({
    ifDesigner:!this.state.ifDesigner
  })
}
togglemodalList = () => {
  this.setState({
    modalList:!this.state.modalList
  })
}
updatedocTypeDrop(newValue){
  this.state.docTypeDropSelected = newValue
  this.state.trainlist = [];
  this.state.trainlistSent = []
  this.setState({
    docTypeDropSelected: this.state.docTypeDropSelected,
    trainlist:this.state.trainlist,
    trainlistSent:this.state.trainlistSent
});
if(this.state.docTypeDropSelected.value){
//  if(this.state.docTypeDropSelected.value !==1){
    document.getElementById('loader').classList.add('d-block'); 
   fetch(process.env.REACT_APP_ENV+'/api/Document/GetGroupList',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
          
        },
        body: JSON.stringify({              
         cid : cookies.get("cid"),
         workflow_status:200,
         category:this.state.docTypeDropSelected.value
       })
        })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson; 
        //console.log(tempData.data[0].id)   
         if(tempData.status.statusMessage === 'Success'){
           if(tempData.data.length > 0){
            this.setState({
              trainlistSent: [],
         })
      const timestamp = new Date().getTime();
      const expireauth = timestamp + 1800000;
      const exdate =  new Date(expireauth);
      cookies.set('auth', tempData.status.authtoken, { path: '/'});   
          this.state.trainlistSent = tempData.data;
           this.setState({
              trainlistSent: this.state.trainlistSent,
         })
           }else{
           // toast.success('No records found..');
            this.setState({
              trainlistSent: [],
         })
           }
           
           // console.log(this.state.id)
           }else{
              toast.success('Error ocured. Please Try Again')
         }
       document.getElementById('loader').classList.remove('d-block'); 
       
      })
      .catch(error =>{
        console.log(error); 
      }) 
 // }
this.gettrained(this.state.selectedTrain);

}else{
  this.setState({
    trainlist: [],
})
}
}
componentDidMount(){   
  document.getElementById('loader').classList.add('d-block'); 
  fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocumentTypeList',{
       method: 'POST',
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'Authorization':'bearer '+cookies.get("auth")
         
       },
       body: JSON.stringify({ 
       
      })
       })
     .then((response) => response.json())
     .then((responseJson) => {
       let tempData = responseJson;  
     //  console.log(tempData)   
       if(tempData.status.statusMessage === 'Success'){
            let exdate = new Date();
            cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
            this.state.docType =  tempData.data;
            let storedata = [];
         storedata.push({value:'',label:'Select'});
			   for (var i = 0; i < tempData.data.length; i++) {
						   storedata.push({
							 value: tempData.data[i].id, 
							 label:  tempData.data[i].doctype_name,
						   })
						  
				}
            this.setState({
              docType:this.state.docType,
              docTypeDrop:storedata,
				      docTypeDropSelected:storedata[0],
            })
            document.getElementById('loader').classList.remove('d-block');  
       }else{
            toast.error('Error ocured. Please Try Again');
            document.getElementById('loader').classList.remove('d-block'); 
       }
      
      
     })
     .catch(error =>{
       console.log(error); 
     })  
   this.getTagKey()
 }    
 gettrained = (type) =>{
   let workflowstatus = 150
   if(type == 2){
    workflowstatus = 200
   }
   
  document.getElementById('loader').classList.add('d-block'); 
   fetch(process.env.REACT_APP_ENV+'/api/Document/GetGroupList',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
          
        },
        body: JSON.stringify({              
         cid : cookies.get("cid"),
         workflow_status:workflowstatus,
         category:this.state.docTypeDropSelected.value
       })
        })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson; 
        //console.log(tempData.data[0].id)   
         if(tempData.status.statusMessage === 'Success'){
           if(tempData.data.length > 0){
            this.setState({
              trainlist: [],
         })
      const timestamp = new Date().getTime();
      const expireauth = timestamp + 1800000;
      const exdate =  new Date(expireauth);
      cookies.set('auth', tempData.status.authtoken, { path: '/'});   
          this.state.trainlist = tempData.data;
           this.setState({
              trainlist: this.state.trainlist,
         })
           }else{
            toast.success('No records found..');
            this.setState({
              trainlist: [],
         })
           }
           
           // console.log(this.state.id)
           }else{
              toast.success('Error ocured. Please Try Again')
         }
       document.getElementById('loader').classList.remove('d-block'); 
       
      })
      .catch(error =>{
        console.log(error); 
      }) 
 }
 changeTraine = (e) =>{
  this.state.selectedTrain =  e.target.value;
 this.setState({selectedTrain:this.state.selectedTrain});
 this.gettrained(this.state.selectedTrain)
}

trainCheckStatus  = (e,gid) => {
  toast.success('Check Train Status.')
}
train = (e,gid) => {
  document.getElementById('loader').classList.add('d-block'); 
  fetch(process.env.REACT_APP_ENV+'/api/TagV2/SendForTraining',{
       method: 'POST',
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'Authorization':'bearer '+cookies.get("auth")
         
       },
       body: JSON.stringify({   
        GroupId:gid,
        category:this.state.docTypeDropSelected.value
      })
       })
     .then((response) => response.json())
     .then((responseJson) => {
       let tempData = responseJson; 
       //console.log(tempData.data[0].id)   
        if(tempData.status.statusMessage === 'Success'){
         const timestamp = new Date().getTime();
         const expireauth = timestamp + 1800000;
         const exdate =  new Date(expireauth);
         cookies.set('auth', tempData.status.authtoken, { path: '/'});   
            //  this.state.trainlist =   [...this.state.trainlist, ...tempData.data]
            //   this.setState({
            //      trainlist: this.state.trainlist,
            // })
          // console.log(this.state.id)
          toast.error('Train request successfully send.');
          let filteredArray = this.state.trainlist.filter(item => item.id !== gid);
          this.state.trainlist = filteredArray;
        this.setState({trainlist: this.state.trainlist});
          }else{
             toast.error('Error ocured. Please Try Again')
        }
      document.getElementById('loader').classList.remove('d-block'); 
      
     })
     .catch(error =>{
       console.log(error); 
       toast.error('Error ocured. Please Try Again')
       document.getElementById('loader').classList.remove('d-block'); 
     }) 
   
}
goSentForTrain = (e) => {
   this.setState({
     isSetTrainListModal:!this.state.isSetTrainListModal
   })
}
toggleFileCount= (e,id) =>{
  document.getElementById(id).classList.toggle("fivefileSHow");
}
selectRowt =(e,id) =>{
  var elements = document.getElementsByClassName("tableRowDetectt");
  for (var i = 0, len = elements.length; i < len; i++) {
    elements[i].classList.remove('selected')
  }
  document.getElementById(id).classList.add('selected')
}
viewModelFiles =(e,gid,mnfid,index) =>{
  this.state.selectedGid = gid;
  this.state.selectedMNFid=mnfid;
  // let files = this.state.trainlist[index].files;
  // console.log(this.state.trainlist[index].files)
  // this.state.tagFileList = files;
  document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/Document/GetFilesForGroup',{
     method: 'POST',
     headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/json',
       'Authorization':'bearer '+cookies.get("auth")
       
     },
     body: JSON.stringify({   
      groupid:parseInt(gid),
      category:parseInt(this.state.docTypeDropSelected.value)
    })
     })
   .then((response) => response.json())
   .then((responseJson) => {
     cookies.set('auth', responseJson.status.authtoken, { path: '/'}); 
   document.getElementById('loader').classList.remove('d-block'); 
   if(responseJson.status.statusMessage === 'Success'){
    this.state.tagFileList = responseJson.data;
    this.setState({
      tagFileList:this.state.tagFileList
    })
   // toast.success('Group added for re-tagging');
   }else{
    toast.success('Error occurred. Please try later');
    document.getElementById('loader').classList.remove('d-block'); 
   }
   
})
   .catch(error =>{
     console.log(error); 
   })
  // this.setState({
  //   selectedGid:this.state.selectedGid,
  //   selectedMNFid:this.state.selectedMNFid,
  //   tagFileList:this.state.tagFileList
  // })
  this.togglemodalList()
}
taggfile(e,file,page,docid){
  let exdate = new Date();
  cookies.set('TaggedFile', file, { path: '/'});
  cookies.set('TaggedFilePage', page, { path: '/'});
  cookies.set('mfgid', this.state.selectedMNFid, { path: '/' });
  cookies.set('docID', docid, { path: '/' });
  cookies.set('groupid', this.state.selectedGid, { path: '/'});
  cookies.set('TagCategory', this.state.docTypeDropSelected.value, { path: '/'});
  this.state.tagFilename = file
  this.setState({
    ifDesigner:!this.state.ifDesigner,
    tagFilename:this.state.tagFilename
  })
} 
getalljsonDownload = async() => {
  let i=0;
  for(i;i< this.state.tagFileList.length;i++){
    //if(i < 30){
    //  this.getDownload(this.state.tagFileList[i].docid)
      document.getElementById("loader").classList.add("d-block");
  await fetch(process.env.REACT_APP_ENV+'/api/TagV2/GetTagData',{
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization':'bearer '+cookies.get("auth")    
    },
    
body: JSON.stringify({ 
  DocID: this.state.tagFileList[i].docid,
  status : 1
})
})
.then((response) => response.json())
.then((json) => {
if(json.data.length){
  let filename = json.data[0].imageName;
  var startFileName = filename.slice(0, filename.lastIndexOf("_"));
  let taggedAreas = [];
  console.log(json.data)
  for (let  x= 0; x < json.data.length; x++) {
    let pageno = json.data[x].imageName.split("_").pop();
    let tagindex = this.state.tagkeyList.findIndex(tagkey => tagkey.value === json.data[x].tagID);
    taggedAreas.push({
      id: json.data[x].id,
      left: json.data[x].left,
      top: json.data[x].top,
      right: json.data[x].right,
      bottom: json.data[x].bottom,
      width: json.data[x].width,
      height: json.data[x].height,
      tag_id: json.data[x].tagID,
      tag_name: this.state.tagkeyList[tagindex].name,
      image_name: json.data[x].imageName,
      doc_id: json.data[x].docID,
      version: json.data[x].version,
      status: json.data[x].status,
      update_tag_data: 0,
      tbl: json.data[x].tbl,
      pageno: pageno,
      img_width: json.data[x].img_width,
      tag_type: json.data[x].tag_type
    })              
  }
   let storedata = [
    {
      file: {
        bucket: "clientuploadedfilesbyapi",
        key_name: startFileName+".pdf",
        file_type: "pdf"
      },
      manufacturer: "",
      tagged_areas: taggedAreas
    }
  ];         
  console.log(storedata)
  let jsonfile = JSON.stringify(storedata,undefined,2);
  let fileToSave = new Blob([jsonfile], {type: 'application/json'});
  saveAs(fileToSave,startFileName+'.json'); 
  document.getElementById("loader").classList.remove("d-block"); 
}
})
  //}   
  }
   
 }
 getDownload = async(docid)=>{
  document.getElementById("loader").classList.add("d-block");
  await fetch(process.env.REACT_APP_ENV+'/api/TagV2/GetTagData',{
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization':'bearer '+cookies.get("auth")    
    },
    
body: JSON.stringify({ 
  DocID: docid,
  status : 1
})
})
.then((response) => response.json())
.then((json) => {
if(json.data.length){
  let filename = json.data[0].imageName;
  var startFileName = filename.slice(0, filename.lastIndexOf("_"));
  let taggedAreas = [];
  console.log(json.data)
  for (let  x= 0; x < json.data.length; x++) {
    let pageno = json.data[x].imageName.split("_").pop();
    let tagindex = this.state.tagkeyList.findIndex(tagkey => tagkey.value === json.data[x].tagID);
    taggedAreas.push({
      id: json.data[x].id,
      left: json.data[x].left,
      top: json.data[x].top,
      right: json.data[x].right,
      bottom: json.data[x].bottom,
      width: json.data[x].width,
      height: json.data[x].height,
      tag_id: json.data[x].tagID,
      tag_name: this.state.tagkeyList[tagindex].name,
      image_name: json.data[x].imageName,
      doc_id: json.data[x].docID,
      version: json.data[x].version,
      status: json.data[x].status,
      update_tag_data: 0,
      tbl: json.data[x].tbl,
      pageno: pageno,
      img_width: json.data[x].img_width,
      tag_type: json.data[x].tag_type
    })              
  }
   let storedata = [
    {
      file: {
        bucket: "clientuploadedfilesbyapi",
        key_name: startFileName+".pdf",
        file_type: "pdf"
      },
      manufacturer: "",
      tagged_areas: taggedAreas
    }
  ];         
  console.log(storedata)
  let jsonfile = JSON.stringify(storedata,undefined,2);
  let fileToSave = new Blob([jsonfile], {type: 'application/json'});
  saveAs(fileToSave,startFileName+'.json'); 
  document.getElementById("loader").classList.remove("d-block"); 
}
})
 }
 getTagKey = ()=>{
  fetch(process.env.REACT_APP_ENV+'/api/TagV2/GetTagKeyValList',{
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization':'bearer '+cookies.get("auth")

    },
    body: JSON.stringify({ 
      category:this.state.docTypeDropSelected.value,
      status:1
   })
    }) 
  .then((response) => response.json())
  .then((responseJson) => {
    let tempData = responseJson.data;  
      if(tempData){
        let storedata = [];
        for (var i = 0; i < tempData.length; i++) {
          storedata.push({
            value: tempData[i].id, 
            label:  tempData[i].desc,
            type:tempData[i].type,
            section:tempData[i].section,
            name:tempData[i].val,
        })
          // console.log(tempData[i].val)
        }
        this.state.tagkeyList = [...this.state.tagkeyList, ...storedata ]
         this.setState({
           tagkeyList: this.state.tagkeyList,
      })
     // console.log(this.state.tagkeyList)
     const timestamp = new Date().getTime();
     const expireauth = timestamp + 1800000;
     const exdate =  new Date(expireauth);
     cookies.set('auth', responseJson.status.authtoken, { path: '/'});  
      }
     
      
  })
  .catch(error =>{
    console.log(error);  
   // document.getElementById('loader').classList.remove('d-block')        
   
  })
 }
  render() {  
    let taggedFileperModel = [];
    let countfile = this.state.tagFileList.length
    this.state.tagFileList.forEach ((s,index) => {   
      taggedFileperModel.push(
        <Row className='tableRowDetectt' onClick={(e) =>this.selectRowt(e,'rowIDf-'+index)} id={'rowIDf-'+index} key={index}>          
        <Cell>{s.filename}</Cell>
        <Cell>
         
            <button title='View Tag Information' onClick={(e)=> this.taggfile(e,s.filename,1,s.docid)} className='btn btn-primary btn-sm mr-1'><i className='fas fa-tags'></i> View Tag Information</button>
        
        </Cell>
       
        
      </Row>
                 
          )
    })
    let ifTrainSent = false;
    let sentforCountFiles = 0;
    let trainlistSentD =[];
    let modelCount = this.state.trainlistSent.length;
    if(this.state.trainlistSent.length){
      
      this.state.trainlistSent.forEach ((s,index) => { 
        let files = s.files
        let getfiles = files.split(',');
        sentforCountFiles = sentforCountFiles+getfiles.length
        let groupName = s.groupname;
        let filename ='';
        if(s.files){
          filename = s.files.trim();
        }
        let cdate = s.modified_date.split("T")[0];  
                let ctime = s.modified_date.split("T")[1].toString();
                let output = ctime.split(/(?=(?:..)*$)/);          
                let datestring = cdate + "T" + ("0" + output[0]).slice(-2) + ":" + ("0" + output[1]).slice(-2)+ ":" + ("0" + output[2]).slice(-2);
                
              //  let latest_ms = new Date(datestring);
        if(s.category === 1){    
          trainlistSentD.push(
            <Row key={index}>
              
            <Cell>{groupName}</Cell>
            <Cell>
             
            {cdate}
            </Cell>
            {/* <Cell>{getDocTypeName}</Cell> */}
            
          </Row>
                     
              )
        }else{
          let files = [];
          let filenames =[];
          if(s.files){
            filenames = s.files.split(',');
          }
          
          if(filenames.length){
            filenames.forEach ((s,index) => { 
              let filename = s.split('#')
              files.push(
                  <span key={index}>{filename[2]}</span> 
              )
             })
          }
          
          trainlistSentD.push(
            <Row key={index}>
            <Cell><div id={'trainfile-'+s.id} className='trainFileName fivefileSHow'>
              {files}
              <div onClick={(e)=>this.toggleFileCount(e,'trainfile-'+s.id)}><button className='btn btn-sm btn-primary mt-2'></button></div>
              </div></Cell>
            <Cell>
             
            {cdate}
            </Cell>
            {/* <Cell>{getDocTypeName}</Cell> */}
            
          </Row>
                     
              )
        }
  
      })
      ifTrainSent = true;
    }
   let filelists = [];
       this.state.trainlist.forEach ((s,index) => {   
        let dTindex = this.state.docType.findIndex(docTypes => docTypes.id === s.category);
        console.log(this.state.docType[dTindex])
        let getDocTypeName = this.state.docType[dTindex].doctype_name;
        let groupName = s.groupname;
        let filename ='';
        if(s.files){
          filename = s.files.trim();
        }
        if(s.category === 1){    
          filelists.push(
            <Row key={index}>
              <Cell>
              {this.state.selectedTrain === '1' &&
              <div>
    
    <button  onClick={(e) => this.viewModelFiles(e,s.id,s.mfgId,index)}  className='btn btn-primary btn-sm'>View Files</button> 
    </div>
        }
        
            
            </Cell>
            <Cell>{groupName}</Cell>
            {/* <Cell>{getDocTypeName}</Cell> */}
            
          </Row>
                     
              )
        }else{
          let files = [];
          let filenames =[];
          if(s.files){
            filenames = s.files.split(',');
          }
          
          if(filenames.length){
            filenames.forEach ((s,index) => { 
              let filename = s.split('#')
              files.push(
                  <span key={index}>{filename[0]}</span> 
              )
             })
          }
          
          filelists.push(
            <Row key={index}>
              <Cell>
              {this.state.selectedTrain === '1' &&
              <div>
    <button   title='Train model' onClick={(e) => this.train(e,s.id)}  type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-book-reader"></i> Train model</button> 
    <button  onClick={(e) => this.viewModelFiles(e,s.id,s.mfgId,index)}  className='btn btn-primary btn-sm'>View Files</button> 
    </div>
      }
         {this.state.selectedTrain === '2' &&
    <button   title='Train model' onClick={(e) => this.train(e,s.id)}  type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-book-reader"></i> Re-train model</button> 
        }
            </Cell>
            {/* <Cell><div className='trainFileName'>{files}</div></Cell> */}
            {/* <Cell>{getDocTypeName}</Cell> */}
            
          </Row>
                     
              )
        }
         
         })
  return (
    <div className='h100percent'>
        <div className='main-header'>
        <div className='row'>
            <div className='col-md-6'><h2 className="page-header">Json Download Submitted group</h2></div>
            <div className='col-md-6 text-right'>           
              {/* <button onClick={(e)=>this.AllpublishModel(e)} className='btn btn-primary mt-2'>All Published Model</button>
          */}
             </div>
          </div>
          </div>
          <div className='main-panel'>
        <div className='content '>
         <div className='container-fluid h100percent'>
         <ToastContainer />
 <div className='h100percent text-selection-none bg-white  round-corner-all'>
     <div className='row'>
     <div className='col-md-4' >
        <div className="form-group ">
                 <div >
                 <label>Document Type</label>
         <Select    
         className ='orgindex'            
        value={this.state.docTypeDropSelected}
        onChange={this.updatedocTypeDrop}
        options={this.state.docTypeDrop}
        defaultValue={{ value: '', label: '----' }}
      />
                 </div>
                 </div> 
               </div>
        
      </div>
        <div className='row  manudetailheight'>
        <div className="col-md-12  h-100">
        <div style={{width: '100%', height: '100%'}}>
        {this.state.docTypeDropSelected.value === 1 && filelists.length > 0 &&
          <StickyTable leftStickyColumnCount={0} >
            <Row>
            <Cell className='tagfile-tool-width'></Cell>
              <Cell>Group Name</Cell>
            
            </Row>
            {filelists}
            
          </StickyTable>
  }
  {this.state.docTypeDropSelected.value && this.state.docTypeDropSelected.value !== 1 && filelists.length > 0 &&
  <div>
    
   
          <StickyTable leftStickyColumnCount={0} >
            <Row>
            <Cell className='tagfile-tool-width' ></Cell>              
            </Row>
            {filelists}
            
          </StickyTable>
          
          </div>
  }
  {ifTrainSent &&  filelists.length === 0 &&
  <div class="alert alert-primary" >Model has been sent for training</div>
  }
        </div>
   
</div>
        </div>
      </div>
    </div>
    
    </div>
    </div>
      <Modal isOpen={this.state.modalList} centered={true} toggle={this.togglemodalList} className="modal modalViewGroup">
        <ModalHeader toggle={this.togglemodalList}>File Information ({countfile} Files)</ModalHeader>
        <ModalBody>
        
        <div style={{width: '100%', height: '100%'}}>
          <StickyTable leftStickyColumnCount={0} >
            <Row>
              <Cell>
              File  Name 
              </Cell>
              <Cell>
                
              </Cell>
              
            </Row>
            {taggedFileperModel}
            
          </StickyTable>
        
   </div>

       
        
        </ModalBody>
       
        <ModalFooter>
        <button  onClick={this.getalljsonDownload} className='btn btn-primary'>Download Json File(s)</button>
        </ModalFooter>
      </Modal> 
     
      <Modal id='modal-tagDesigner-readonly' size="lg" isOpen={this.state.ifDesigner} centered={true} toggle={this.closeTagDesigner} className="modal modal-tagDesigner-readonly">
        <ModalHeader toggle={this.closeTagDesigner}>Tag Details - {this.state.tagFilename}</ModalHeader>
        <ModalBody>
        <TagdocReadOnly/>
        </ModalBody>
       
        
      </Modal> 
      {this.state.ifDownload && 
  <JsonDownload closedownload={this.closeIfDownload} submitted={this.state.isSubmitted}/> 
}
           </div>
  );
}
}


export default TrainModel;