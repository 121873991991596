import React, { Component } from 'react';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { Form, Button, FormGroup, Label, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
import Select from 'react-select';
import ProcessFile from "./Process-File.jsx";
import TagdocReadOnly from "./TagDocReadOnly.jsx"
const cookies = new Cookies();

class PublishModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trainlist: [],
      trainlistORG: [],
      docType: [],
      docTypeDrop: [],
      docTypeDropSelected: '',
      selectedPublish: 2,
      publishedList: [],
      publishedListORG: [],
      sortOrderPublish: 'PDD',
      sortOrderTrain: 'PDD',
      sortPublishdate: false,
      sortPublisAccuracy: false,
      sortTraindate: false,
      mfgNameen: '',
      mfgNameList: [],
      ifCloudsds: false,
      processFile: false,
      modalList: false,
      ifDesigner: false,
      selectedGid: '',
      selectedMNFid: '',
      tagFileList: []
    }
    this.trainStatus = this.trainStatus.bind(this)
    this.evaulateStatus = this.evaulateStatus.bind(this)
    this.publishStatus = this.publishStatus.bind(this)
    this.train = this.train.bind(this)
    this.evaulate = this.evaulate.bind(this)
    this.updatedocTypeDrop = this.updatedocTypeDrop.bind(this)

  }
  closeTagDesigner = () => {
    this.setState({
      ifDesigner: !this.state.ifDesigner
    })
  }
  togglemodalList = () => {
    this.setState({
      modalList: !this.state.modalList
    })
  }
  onChange = event => {
    this.state.mfgNameen = event.target.value;
    this.setState({ mfgNameen: this.state.mfgNameen })
    // console.log(this.state.mfgNameen.length);
    //if(this.state.mfgNameen.length>1){
    this.setState({ mfgNameList: [] })
    document.getElementById("loader").classList.add("show");
    fetch(process.env.REACT_APP_ENV + '/api/Common/SearchManufacturer', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + cookies.get("auth")

      },
      body: JSON.stringify({
        MfgName: this.state.mfgNameen,
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        document.getElementById("loader").classList.remove("show");
        let tempData = responseJson.data;
        const timestamp = new Date().getTime();
        const expireauth = timestamp + 1800000;
        const exdate = new Date(expireauth);
        cookies.set('auth', responseJson.status.authtoken, { path: '/' });
        this.setState({
          mfgNameList: [...this.state.mfgNameList, ...tempData],
        })
        document.getElementById('mfglist').classList.remove('d-none')
        console.log(this.state.mfgNameList)

      })
      .catch(error => {
        console.log(error);
        document.getElementById("loader").classList.remove("show");
      })

    //}
  }
  selectNFG(e, id, name) {
    this.state.mfgID = id;
    this.state.mfgNameen = name;
    this.state.mfgId = id;
    this.setState({
      mfgID: this.state.mfgID,
      mfgNameen: this.state.mfgNameen,
      mfgId: this.state.mfgId
    })
    document.getElementById('mfglist').classList.add('d-none')
    console.log(this.state.mfgNameen + this.state.mfgID)
  }
  updatedocTypeDrop(newValue) {
    this.state.docTypeDropSelected = newValue;
    let pmode = 1;
    if (newValue.process_mode === 1) {
      pmode = 3
    }
    if (newValue.process_mode === 0) {
      pmode = 2
    }
    this.setState({
      docTypeDropSelected: this.state.docTypeDropSelected,
      selectedPublish: pmode,
      tagFileList: []
    });
    setTimeout(() => {
      console.log(this.state.docTypeDropSelected)
      if (this.state.docTypeDropSelected.value) {
        this.getpublished();
      } else {
        this.setState({
          trainlist: [],
          publishedList: []
        })
      }
      
    }, 300);
    

  }
  sortPublishdate = (e) => {
    if (this.state.sortPublishdate) {
      this.state.sortPublishdate = !this.state.sortPublishdate;
      this.state.sortOrderPublish = 'PDD';
      this.setState({
        sortPublishdate: this.state.sortPublishdate,
        sortOrderPublish: this.state.sortOrderPublish,
        publishedList: []
      })
      this.sortOrderPublish()
    } else {
      this.state.sortPublishdate = !this.state.sortPublishdate;
      this.state.sortOrderPublish = 'PDA';
      this.setState({
        sortPublishdate: this.state.sortPublishdate,
        sortOrderPublish: this.state.sortOrderPublish,
        publishedList: []
      })
      this.sortOrderPublish()
    }
  }
  sortPublishAccuracy = (e) => {
    if (this.state.sortPublisAccuracy) {
      this.state.sortPublisAccuracy = !this.state.sortPublisAccuracy;
      this.state.sortOrderPublish = 'APD';
      this.setState({
        sortPublisAccuracy: this.state.sortPublisAccuracy,
        sortOrderPublish: this.state.sortOrderPublish,
        publishedList: []
      })
      this.sortOrderPublish()
    } else {
      this.state.sortPublisAccuracy = !this.state.sortPublisAccuracy;
      this.state.sortOrderPublish = 'APA';
      this.setState({
        sortPublisAccuracy: this.state.sortPublisAccuracy,
        sortOrderPublish: this.state.sortOrderPublish,
        publishedList: []
      })
      this.sortOrderPublish()
    }
  }
  sortTrainDate = (e) => {
    if (this.state.sortTraindate) {
      this.state.sortTraindate = !this.state.sortTraindate;
      this.state.sortOrderTrain = 'PDD';
      this.setState({
        sortTraindate: this.state.sortTraindate,
        sortOrderTrain: this.state.sortOrderTrain,
        trainlist: []
      })
      this.sortOrderTrain()
    } else {
      this.state.sortTraindate = !this.state.sortTraindate;
      this.state.sortOrderTrain = 'PDA';
      this.setState({
        sortTraindate: this.state.sortTraindate,
        sortOrderTrain: this.state.sortOrderTrain,
        trainlist: []
      })
      this.sortOrderTrain()
    }
  }
  sortTrainAccuracy = (e) => {
    if (this.state.sortTraindate) {
      this.state.sortTraindate = !this.state.sortTraindate;
      this.state.sortOrderTrain = 'APD';
      this.setState({
        sortTraindate: this.state.sortTraindate,
        sortOrderTrain: this.state.sortOrderTrain,
        publishedList: []
      })
      this.sortOrderTrain()
    } else {
      this.state.sortTraindate = !this.state.sortTraindate;
      this.state.sortOrderTrain = 'APA';
      this.setState({
        sortTraindate: this.state.sortTraindate,
        sortOrderTrain: this.state.sortOrderTrain,
        publishedList: []
      })
      this.sortOrderTrain()
    }
  }
  componentDidMount() {
    if (cookies.get("cid") == '1') {
      this.setState({
        ifCloudsds: true
      })
    }
    document.getElementById('loader').classList.add('d-block');
    fetch(process.env.REACT_APP_ENV + '/api/Document/GetDocumentTypeList', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + cookies.get("auth")

      },
      body: JSON.stringify({

      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        //  console.log(tempData)   
        if (tempData.status.statusMessage === 'Success') {
          let exdate = new Date();
          cookies.set('auth', tempData.status.authtoken, { path: '/' });
          this.state.docType = tempData.data;
          let storedata = [];
          storedata.push({ value: '', label: 'Select' });
          for (var i = 0; i < tempData.data.length; i++) {
            storedata.push({
              value: tempData.data[i].id,
              label: tempData.data[i].doctype_name,
              process_mode: tempData.data[i].process_mode
            })

          }
          this.setState({
            docType: this.state.docType,
            docTypeDrop: storedata,
            docTypeDropSelected: storedata[0],
          })
          document.getElementById('loader').classList.remove('d-block');
        } else {
          toast.error('Error ocured. Please Try Again');
          document.getElementById('loader').classList.remove('d-block');
        }


      })
      .catch(error => {
        console.log(error);
        document.getElementById('loader').classList.remove('d-block');
      })

  }
  AllpublishModel = (e) => {
    this.props.history.push('/published-models')

  }
  trainStatus = (e) => {
    this.props.history.push('/train-status')

  }
  evaulateStatus = (e) => {
    this.props.history.push('/evaluate-status')

  }
  publishStatus = (e) => {
    this.props.history.push('/publish-status')

  }
  train = (e) => {
    // this.props.history.push('/edit-tag') 

  }
  evaulate = (e) => {
    // this.props.history.push('/edit-tag') 

  }
  sortOrderTrain = (e) => {
    // this.setState({[e.target.name]: e.target.value})
    let sortType = this.state.sortOrderTrain;
    if (sortType) {
      if (sortType === 'PDA') {
        this.state.trainlist = this.state.trainlist.sort((d1, d2) => new Date(d1.model_date).getTime() - new Date(d2.model_date).getTime());
      }
      if (sortType === 'PDD') {
        this.state.trainlist = this.state.trainlist.sort((d1, d2) => new Date(d2.model_date).getTime() - new Date(d1.model_date).getTime());
      }
      if (sortType === 'APA') {
        this.state.trainlist = this.state.trainlist.sort((d1, d2) => new Date(d1.model_accuracy).getTime() - new Date(d2.model_accuracy).getTime());

      }
      if (sortType === 'APD') {
        this.state.trainlist = this.state.trainlist.sort((d1, d2) => new Date(d2.model_accuracy).getTime() - new Date(d1.model_accuracy).getTime());
      }
      this.setState({
        trainlist: this.state.trainlist
      })
    }
  }
  sortOrderPublish = (e) => {
    //this.setState({[e.target.name]: e.target.value})
    let sortType = this.state.sortOrderPublish;
    if (sortType) {
      if (sortType === 'PDA') {
        this.state.publishedList = this.state.publishedList.sort((d1, d2) => new Date(d1.model_date).getTime() - new Date(d2.model_date).getTime());
      }
      if (sortType === 'PDD') {
        this.state.publishedList = this.state.publishedList.sort((d1, d2) => new Date(d2.model_date).getTime() - new Date(d1.model_date).getTime());
      }
      if (sortType === 'APA') {
        this.state.publishedList = this.state.publishedList.sort((d1, d2) => new Date(d1.model_accuracy).getTime() - new Date(d2.model_accuracy).getTime());

      }
      if (sortType === 'APD') {
        this.state.publishedList = this.state.publishedList.sort((d1, d2) => new Date(d2.model_accuracy).getTime() - new Date(d1.model_accuracy).getTime());
      }
      this.setState({
        trainlist: [],
        publishedList: this.state.publishedList
      })
    }
  }
  publish = (e, filename) => {
    document.getElementById('loader').classList.add('d-block');
    fetch(process.env.REACT_APP_ENV + '/api/TagV2/PublishModel', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + cookies.get("auth")
      },
      body: JSON.stringify({
        category: this.state.docTypeDropSelected.value,
        model_file_name: filename
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        //console.log(tempData.data[0].id)   

        if (tempData.status.statusMessage === 'Success') {
          const timestamp = new Date().getTime();
          const expireauth = timestamp + 1800000;
          const exdate = new Date(expireauth);
          if (tempData.status.authtoken != undefined && tempData.status.authtoken != null && tempData.status.authtoken != '') {
            cookies.set('auth', tempData.status.authtoken, { path: '/' });
          }

          toast.success('Model Published');
          this.state.trainlist = this.state.trainlist.filter(item => item.model_file_name !== filename);
          this.setState({ trainlist: this.state.trainlist });
          // console.log(this.state.id)
        } else {
          toast.success(tempData.status.statusMessage)
        }
        document.getElementById('loader').classList.remove('d-block');

      })
      .catch(error => {
        console.log(error);
        document.getElementById('loader').classList.remove('d-block');
      })


  }

  // publishOthers = (e,filename) => {
  //   document.getElementById('loader').classList.add('d-block'); 
  //   fetch(process.env.REACT_APP_ENV+'/api/TagV2/PublishModel',{
  //         method: 'POST',
  //         headers: {
  //           'Accept': 'application/json',
  //           'Content-Type': 'application/json',
  //           'Authorization':'bearer '+cookies.get("auth")          
  //         },
  //         body: JSON.stringify({   
  //           category :this.state.docTypeDropSelected.value,
  //           model_file_name:filename
  //        })
  //         })
  //       .then((response) => response.json())
  //       .then((responseJson) => {
  //         let tempData = responseJson; 
  //          if(tempData.status.statusMessage === 'Success'){           
  //           const timestamp = new Date().getTime();
  //           const expireauth = timestamp + 1800000;
  //           const exdate =  new Date(expireauth);
  //           if(tempData.status.authtoken != undefined && tempData.status.authtoken != null && tempData.status.authtoken != ''){
  //             cookies.set('auth', tempData.status.authtoken, { path: '/'});  
  //           }

  //           toast.success('Model Published');
  //           this.state.trainlist = this.state.trainlist.filter(item => item.model_file_name !== filename);
  //           this.setState({trainlist: this.state.trainlist});
  //            // console.log(this.state.id)
  //            }else{
  //               toast.error(tempData.status.statusMessage)
  //          }
  //        document.getElementById('loader').classList.remove('d-block'); 

  //       })
  //       .catch(error =>{
  //         console.log(error); 
  //         toast.error(error)
  //         document.getElementById('loader').classList.remove('d-block'); 
  //       }) 


  // }
  changepublished = (e) => {
    this.state.selectedPublish = e.target.value;
    this.setState({ selectedPublish: this.state.selectedPublish });
    this.getpublished()
  }
  getpublished = (e) => {
    this.setState({
      trainlist: [],
      publishedList: []
    })
    let cid = parseInt(cookies.get("cid"))
    if (this.state.selectedPublish == 1) {
      document.getElementById('loader').classList.add('d-block');
      fetch(process.env.REACT_APP_ENV + '/api/common/GetTrainedModel', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + cookies.get("auth")
        },
        body: JSON.stringify({
          category: this.state.docTypeDropSelected.value
          //cid : cookies.get("cid"),
          // workflow_status:200
        })
      })
        .then((response) => response.json())
        .then((responseJson) => {
          let tempData = responseJson;
          //console.log(tempData.data[0].id)   
          if (tempData.status.statusMessage === 'Success') {
            if (!tempData.data.length) {
              toast.success('No record found.')
            }
            this.setState({
              trainlist: [],
            })
            const timestamp = new Date().getTime();
            const expireauth = timestamp + 1800000;
            const exdate = new Date(expireauth);
            cookies.set('auth', tempData.status.authtoken, { path: '/' });
            this.state.trainlist = tempData.data;
            let newtrainlist = [];
            tempData.data.forEach((s, index) => {
              let model_file_name = s.model_file_name;
              let accuracy = s.model_accuracy;
              if (accuracy) {
                accuracy = accuracy.replace('%', '');
                accuracy = parseFloat(accuracy)
              } else {
                accuracy = 0
              }
              let files = s.files;
              let cdate = s.model_date.split("T")[0];
              let ctime = s.model_date.split("T")[1].toString();
              let output = ctime.split(/(?=(?:..)*$)/);
              let datestring = cdate + "T" + ("0" + output[0]).slice(-2) + ":" + ("0" + output[1]).slice(-2) + ":" + ("0" + output[2]).slice(-2);

              let latest_ms = new Date(datestring);
              if (this.state.docTypeDropSelected.value === 1) {
                newtrainlist.push({ model_date: latest_ms, model_accuracy: accuracy, model_file_name: model_file_name, groupName: s.groupname, mnfname: s.mnfname, files: files, groupid: s.groupid, mnfid: s.mnfid })
              } else {
                newtrainlist.push({ model_date: latest_ms, model_accuracy: accuracy, model_file_name: model_file_name, groupName: s.groupname, files: files, groupid: s.groupid, mnfid: s.mnfid })
              }

            })

            newtrainlist.sort((d1, d2) => new Date(d2.model_date).getTime() - new Date(d1.model_date).getTime());
            this.setState({
              trainlist: newtrainlist,
              trainlistORG: this.state.trainlist
            })
            document.getElementById('loader').classList.remove('d-block');
            // console.log(this.state.id)
          } else {
            toast.danger('Error ocured. Please Try Again')
            document.getElementById('loader').classList.remove('d-block');
          }


        })
        .catch(error => {
          console.log(error);
          toast.danger(error)
          document.getElementById('loader').classList.remove('d-block');
        })



    }
    else if (this.state.selectedPublish == 2) {
      document.getElementById('loader').classList.add('d-block');

      fetch(process.env.REACT_APP_ENV + '/api/common/GetPublishedModel', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + cookies.get("auth")

        },
        body: JSON.stringify({
          category: this.state.docTypeDropSelected.value
          //cid : cookies.get("cid"),
          // workflow_status:200
        })
      })
        .then((response) => response.json())
        .then((responseJson) => {
          let tempData = responseJson;
          //console.log(tempData.data[0].id)   
          if (tempData.status.statusMessage === 'Success') {
            if (!tempData.data.length) {
              toast.success('No record found.')
            }
            this.setState({
              publishedList: [],
            })
            const timestamp = new Date().getTime();
            const expireauth = timestamp + 1800000;
            const exdate = new Date(expireauth);
            cookies.set('auth', tempData.status.authtoken, { path: '/' });
            this.state.publishedList = tempData.data;
            let newpublisheData = [];
            tempData.data.forEach((s, index) => {
              let model_file_name = s.model_file_name;
              let accuracy = s.model_accuracy;
              if (accuracy) {
                accuracy = accuracy.replace('%', '');
                accuracy = parseFloat(accuracy)
              } else {
                accuracy = 0
              }
              let cdate = s.model_date.split("T")[0];
              let ctime = s.model_date.split("T")[1].toString();
              let output = ctime.split(/(?=(?:..)*$)/);
              let datestring = cdate + "T" + ("0" + output[0]).slice(-2) + ":" + ("0" + output[1]).slice(-2) + ":" + ("0" + output[2]).slice(-2);

              let latest_ms = new Date(datestring);
              if (this.state.docTypeDropSelected.value === 1) {
                newpublisheData.push({ model_date: latest_ms, model_accuracy: accuracy, model_file_name: model_file_name, groupid: s.groupid, groupName: s.groupName, mnfname: s.mnfname })
              } else {
                newpublisheData.push({ model_date: latest_ms, model_accuracy: accuracy, model_file_name: model_file_name, groupid: s.groupid, groupName: s.groupName })
              }

            })

            newpublisheData.sort((d1, d2) => new Date(d2.model_date).getTime() - new Date(d1.model_date).getTime());
            this.setState({
              publishedList: newpublisheData,
              publishedListORG: this.state.publishedList
            })
            console.log(newpublisheData)
            document.getElementById('loader').classList.remove('d-block');
            // console.log(this.state.id)
          } else {
            toast.danger('Error ocured. Please Try Again')
            document.getElementById('loader').classList.remove('d-block');
          }


        })
        .catch(error => {
          console.log(error);
          toast.danger(error)
          document.getElementById('loader').classList.remove('d-block');
        })
    } else {
      fetch(process.env.REACT_APP_ENV + '/api/common/GetPublishedModelDD', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + cookies.get("auth")

        },
        body: JSON.stringify({
          category: this.state.docTypeDropSelected.value
          //cid : cookies.get("cid"),
          // workflow_status:200
        })
      })
        .then((response) => response.json())
        .then((responseJson) => {
          let tempData = responseJson;
          //console.log(tempData.data[0].id)   
          if (tempData.status.statusMessage === 'Success') {
            if (!tempData.data.length) {
              toast.success('No record found.')
            }
            this.setState({
              publishedList: [],
            })
            const timestamp = new Date().getTime();
            const expireauth = timestamp + 1800000;
            const exdate = new Date(expireauth);
            cookies.set('auth', tempData.status.authtoken, { path: '/' });
            this.state.publishedList = tempData.data;
            let newpublisheData = [];
            tempData.data.forEach((s, index) => {
              let model_file_name = s.model_file_name;
              let accuracy = s.model_accuracy;
              if (accuracy) {
                accuracy = accuracy.replace('%', '');
                accuracy = parseFloat(accuracy)
              } else {
                accuracy = 0
              }
              let cdate = s.model_date.split("T")[0];
              let ctime = s.model_date.split("T")[1].toString();
              let output = ctime.split(/(?=(?:..)*$)/);
              let datestring = cdate + "T" + ("0" + output[0]).slice(-2) + ":" + ("0" + output[1]).slice(-2) + ":" + ("0" + output[2]).slice(-2);

              let latest_ms = new Date(datestring);
              if (this.state.docTypeDropSelected.value === 1) {
                newpublisheData.push({ model_date: latest_ms, model_accuracy: accuracy, model_file_name: model_file_name, groupid: s.groupid, groupName: s.groupName, mnfname: s.mnfname })
              } else {
                newpublisheData.push({ model_date: latest_ms, model_accuracy: accuracy, model_file_name: model_file_name, groupid: s.groupid, groupName: s.groupName })
              }

            })

            newpublisheData.sort((d1, d2) => new Date(d2.model_date).getTime() - new Date(d1.model_date).getTime());
            this.setState({
              publishedList: newpublisheData,
              publishedListORG: this.state.publishedList
            })
            console.log(newpublisheData)
            document.getElementById('loader').classList.remove('d-block');
            // console.log(this.state.id)
          } else {
            toast.danger('Error ocured. Please Try Again')
            document.getElementById('loader').classList.remove('d-block');
          }


        })
        .catch(error => {
          console.log(error);
          toast.danger(error)
          document.getElementById('loader').classList.remove('d-block');
        })
    }
  }
  processFiles = (e, gid, modelname, type) => {
    let ptype = 0;
    if (type === '3') {
      ptype = 1;
    }
    const timestamp = new Date().getTime();
    const expireauth = timestamp + (60 * 1000);
    const exdate = new Date(expireauth);
    cookies.set('process_group', gid, { path: '/' });
    cookies.set('process_ptype', ptype, { path: '/' });
    cookies.set('process_category', this.state.docTypeDropSelected, { path: '/' });
    cookies.set('process_model', modelname, { path: '/' });
    //this.props.history.push('/process-file') 
    this.setState({
      processFile: true
    })
  }
  processFilesOthers = (e, gid, modelname, type) => {
    let ptype = 0;
    if (type === '3') {
      ptype = 1;
    }
    const timestamp = new Date().getTime();
    const expireauth = timestamp + (60 * 1000);
    const exdate = new Date(expireauth);
    console.log(this.state.docTypeDropSelected)
    cookies.set('process_group', gid, { path: '/' });
    cookies.set('process_ptype', ptype, { path: '/' });
    cookies.set('process_category', this.state.docTypeDropSelected, { path: '/' });
    //cookies.set('process_group', '17', { path: '/'});
    // cookies.set('process_category', {value: 1, label: "Safety Data Sheet"}, { path: '/'});
    cookies.set('process_model', modelname, { path: '/' });
    // this.props.history.push('/process-file') 
    this.setState({
      processFile: true
    })
  }
  publishType = (e) => {
    this.state.selectedPublish = e.target.value;
    this.setState({ selectedPublish: this.state.selectedPublish });
    this.getpublished()
  }
  closeProcessFIle = (status) => {
    this.setState({
      processFile: !this.state.processFile
    })
    if (status) {
      this.getTagList()
    }
  }
  retagGroup = (e, gid) => {
    let groupid = gid;
    document.getElementById('loader').classList.add('d-block');
    fetch(process.env.REACT_APP_ENV + '/api/tagv2/SetReTagging', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + cookies.get("auth")

      },
      body: JSON.stringify({
        groupId: groupid,
        category: this.state.docTypeDropSelected.value
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson)
        let exdate = new Date();
        cookies.set('auth', responseJson.status.authtoken, { path: '/' });
        let language = '';
        document.getElementById('loader').classList.remove('d-block');
        if (responseJson.status.statusMessage === 'Success') {
          toast.success('Group added for re-tagging');
          //this.getdocbygroupById();
        } else {
          toast.success('Error occurred. Please try later');
        }

      })
      .catch(error => {
        console.log(error);
      })

  }
  selectRow = (e, id) => {
    var elements = document.getElementsByClassName("tableRowDetect");
    for (var i = 0, len = elements.length; i < len; i++) {
      elements[i].classList.remove('selected')
    }
    document.getElementById(id).classList.add('selected')
  }
  selectRowt = (e, id) => {
    var elements = document.getElementsByClassName("tableRowDetectt");
    for (var i = 0, len = elements.length; i < len; i++) {
      elements[i].classList.remove('selected')
    }
    document.getElementById(id).classList.add('selected')
  }
  viewModelFiles = (e, gid, mnfid, index) => {
    this.state.selectedGid = gid;
    this.state.selectedMNFid = mnfid;
    // let files = this.state.trainlist[index].files;
    // console.log(this.state.trainlist[index].files)
    // this.state.tagFileList = files;
    document.getElementById('loader').classList.add('d-block');
    fetch(process.env.REACT_APP_ENV + '/api/Document/GetFilesForGroup', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + cookies.get("auth")

      },
      body: JSON.stringify({
        groupid: gid.toString(),
        category: this.state.docTypeDropSelected.value.toString()
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        cookies.set('auth', responseJson.status.authtoken, { path: '/' });
        document.getElementById('loader').classList.remove('d-block');
        if (responseJson.status.statusMessage === 'Success') {
          this.state.tagFileList = responseJson.data;
          this.setState({
            tagFileList: this.state.tagFileList
          })
          // toast.success('Group added for re-tagging');
        } else {
          toast.success('Error occurred. Please try later');
          document.getElementById('loader').classList.remove('d-block');
        }

      })
      .catch(error => {
        console.log(error);
      })
    // this.setState({
    //   selectedGid:this.state.selectedGid,
    //   selectedMNFid:this.state.selectedMNFid,
    //   tagFileList:this.state.tagFileList
    // })
    this.togglemodalList()
  }
  taggfile(e, file, page, docid) {
    let exdate = new Date();
    cookies.set('TaggedFile', file, { path: '/' });
    cookies.set('TaggedFilePage', page, { path: '/' });
    cookies.set('mfgid', this.state.selectedMNFid, { path: '/' });
    cookies.set('docID', docid, { path: '/' });
    cookies.set('groupid', this.state.selectedGid, { path: '/' });
    cookies.set('TagCategory', this.state.docTypeDropSelected.value, { path: '/' });
    this.state.tagFilename = file
    this.setState({
      ifDesigner: !this.state.ifDesigner,
      tagFilename: this.state.tagFilename
    })
  }
  render() {
    let detectCloudsds = false;
    if (cookies.get("cid") === '1') {
      detectCloudsds = true
    }
    let taggedFileperModel = []
    this.state.tagFileList.forEach((s, index) => {
      taggedFileperModel.push(
        <Row className='tableRowDetectt' onClick={(e) => this.selectRowt(e, 'rowIDf-' + index)} id={'rowIDf-' + index} key={index}>
          <Cell>{s.filename}</Cell>
          <Cell>
            {/* <button onClick={(e) => this.viewImage(e,s.filename)} className='btn btn-primary btn-sm mr-1'>View File</button> */}
            <button title='View Tag Information' onClick={(e) => this.taggfile(e, s.filename, 1, s.docid)} className='btn btn-primary btn-sm mr-1'><i className='fas fa-tags'></i> View Tag Information</button>

          </Cell>


        </Row>

      )
    })
    let ifCloudsds = this.state.ifCloudsds
    let mfgname = this.state.mfgNameen;
    let mfgNameLists = [];
    this.state.mfgNameList.forEach((s, index) => {
      //  console.log(s.companyName)
      mfgNameLists.push(
        <li onClick={(e) => this.selectNFG(e, s.mnfID, s.mnfName)} key={index} >{s.mnfName + ' (' + s.mnfID + ')'}</li>
      )
    })
    let filelists = [];
    let publishList = [];
    if (this.state.selectedPublish == 1) {
      this.state.trainlist.forEach((s, index) => {

        let data = []
        let datestring = '';
        let accuracy = s.model_accuracy;
        let model_file_name = '';
        if (this.state.docTypeDropSelected.value === 1) {

          let latest_ms = new Date(s.model_date);
          datestring = ("0" + (latest_ms.getMonth() + 1)).slice(-2) + "-" + ("0" + (latest_ms.getDate())).slice(-2) + "-" + latest_ms.getFullYear() + " " +
            ("0" + latest_ms.getHours()).slice(-2) + ":" + ("0" + latest_ms.getMinutes()).slice(-2) + ":" + ("0" + latest_ms.getSeconds()).slice(-2);
          if (accuracy === '0%' || accuracy === '%' || accuracy === '') {
            accuracy = '--'
          }
          var lastname = s.model_file_name.split("/").pop();
          filelists.push(
            <Row className='tableRowDetect' onClick={(e) => this.selectRow(e, 'rowID-' + index)} id={'rowID-' + index} key={index}>
              <Cell><button title='Publish model' onClick={(e) => this.publish(e, s.model_file_name)} type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-book-reader"></i> Publish model</button>
                <button onClick={(e) => this.retagGroup(e, s.groupid)} className='btn btn-primary btn-sm mr-2'>Re-tag</button>
                {/* <button  onClick={(e) => this.viewModelFiles(e,s.groupid,s.mnfid,index)}  className='btn btn-primary btn-sm'>View Files</button>  */}
              </Cell>
              <Cell>{s.mnfname}</Cell>
              <Cell>{datestring}</Cell>
              {/* <Cell><h5 className='m-0 p-0'><span className="badge badge-primary fbwidth py-2">{accuracy}%</span></h5></Cell>  */}

            </Row>

          )

        } else {
          accuracy = s.model_accuracy;
          let latest_ms = new Date(s.model_date);
          // console.log(latest_ms)
          datestring = ("0" + (latest_ms.getMonth() + 1)).slice(-2) + "-" + ("0" + (latest_ms.getDate())).slice(-2) + "-" + latest_ms.getFullYear() + " " +
            ("0" + latest_ms.getHours()).slice(-2) + ":" + ("0" + latest_ms.getMinutes()).slice(-2) + ":" + ("0" + latest_ms.getSeconds()).slice(-2);

          if (accuracy === '0' || accuracy === '') {
            accuracy = '--'
          }
          let lastname = s.model_file_name.split('###');
          lastname = lastname[0].split('/').pop()
          filelists.push(
            <Row className='tableRowDetect' onClick={(e) => this.selectRow(e, 'rowID-' + index)} id={'rowID-' + index} key={index}>
              <Cell>
                <button title='Publish model' onClick={(e) => this.publish(e, s.model_file_name)} type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-book-reader"></i> Publish model</button>
                <button onClick={(e) => this.retagGroup(e, s.groupid)} className='btn btn-primary btn-sm mr-2'>Re-tag</button>
                {/* <button  onClick={(e) => this.viewModelFiles(e,s.groupid,s.mnfid,index)}  className='btn btn-primary btn-sm '>View Model Files</button> */}
              </Cell>
              <Cell>{lastname}</Cell>
              <Cell>{datestring}</Cell>
              {/* <Cell><h5 className='m-0 p-0'><span className="badge badge-primary fbwidth py-2">{accuracy}%</span></h5></Cell>  */}

            </Row>

          )
        }


      })
    } else {

      this.state.publishedList.forEach((s, index) => {
        let data = []
        let datestring = '';
        let accuracy = s.model_accuracy;
        console.log(accuracy)
        let model_file_name = s.model_file_name;
        if (this.state.docTypeDropSelected.value === 1) {
          let latest_ms = new Date(s.model_date);
          // console.log(latest_ms)
          datestring = ("0" + (latest_ms.getMonth() + 1)).slice(-2) + "-" + ("0" + (latest_ms.getDate())).slice(-2) + "-" + latest_ms.getFullYear() + " " +
            ("0" + latest_ms.getHours()).slice(-2) + ":" + ("0" + latest_ms.getMinutes()).slice(-2) + ":" + ("0" + latest_ms.getSeconds()).slice(-2);

          if (accuracy === '0' || accuracy === '') {
            accuracy = '--'
          }
          publishList.push(
            <Row key={index}>
              <Cell>
                <button onClick={(e) => this.processFiles(e, s.groupid, model_file_name, this.state.selectedPublish)} title='Process Files' type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-copy"></i> Process Files</button>
                {/* <button  onClick={(e) => this.viewModelFiles(e,s.groupid,s.mnfid,index)}  className='btn btn-primary btn-sm '>View Model Files</button> */}
              </Cell>
              <Cell>{s.mnfname}</Cell>
              <Cell>{datestring}</Cell>
              {/* <Cell><h5 className='m-0 p-0'><span className="badge badge-primary fbwidth py-2">{accuracy}%</span></h5></Cell>  */}
            </Row>

          )

        } else {
          accuracy = s.model_accuracy;
          let latest_ms = new Date(s.model_date);
          // console.log(latest_ms)getDate
          datestring = ("0" + (latest_ms.getMonth() + 1)).slice(-2) + "-" + ("0" + (latest_ms.getDate())).slice(-2) + "-" + latest_ms.getFullYear() + " " +
            ("0" + latest_ms.getHours()).slice(-2) + ":" + ("0" + latest_ms.getMinutes()).slice(-2) + ":" + ("0" + latest_ms.getSeconds()).slice(-2);

          if (accuracy === '0' || accuracy === '') {
            accuracy = '--'
          }
          var lastname = s.model_file_name.split("/").pop();
          publishList.push(
            <Row key={index}>
              <Cell>
                <button title='Process Files' onClick={(e) => this.processFilesOthers(e, s.groupid, s.model_file_name, this.state.selectedPublish)} type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-book-reader"></i> Process Files</button>
                {/* <button  onClick={(e) => this.viewModelFiles(e,s.groupid,s.mnfid,index)}  className='btn btn-primary btn-sm '>View Model  Files</button> */}
              </Cell>
              <Cell>{lastname}</Cell>
              <Cell>{datestring}</Cell>
              {/* <Cell><h5 className='m-0 p-0'><span className="badge badge-primary fbwidth py-2">{accuracy}%</span></h5></Cell>   */}

            </Row>

          )
        }
        // let accuracy = s.modelFiles[0].model_accuracy;



      })
    }
    return (
      <div className='h100percent'>
        <div className='main-header'>
          <div className='row'>
            <div className='col-md-6'><h2 className="page-header">Process Files</h2></div>
            <div className='col-md-6 text-right'>
              {/* <button onClick={(e)=>this.AllpublishModel(e)} className='btn btn-primary mt-2'>All Published Model</button>
          */}
            </div>
          </div>
        </div>
        <div className='main-panel'>
          <div className='content '>
            <div className='container-fluid h100percent'>
              <ToastContainer />
              <div className='h100percent text-selection-none bg-white  round-corner-all'>
                <div className='row'>
                  <div className='col-md-4' >
                    <div className="form-group ">
                      <div >
                        <label>Document Type</label>
                        <Select
                          className='orgindex'
                          value={this.state.docTypeDropSelected}
                          onChange={this.updatedocTypeDrop}
                          options={this.state.docTypeDrop}
                          defaultValue={{ value: '', label: '----' }}
                        />
                      </div>
                    </div>
                  </div>
                  {this.state.docTypeDropSelected.value && !detectCloudsds &&
                    <div className='col-md-3 d-none'>
                      <label>View By</label>
                      <select value={this.state.selectedPublish} onChange={(e) => this.publishType(e)} className='form-control'>

                        <option value='2'>Custom Published Model</option>
                        {!this.state.ifCloudsds &&
                          <option value='3'>Predefined Published Model</option>
                        }
                      </select>

                    </div>
                  }
                   {this.state.docTypeDropSelected.value && !detectCloudsds &&
                  <div className="col-md-5">
                    <label className='vhidden'>View By</label>
                    <div style={{height: "calc(2.25rem + 2px)"}} className='row align-items-center'>
                      <div className='col-12'>
                      {!this.state.ifCloudsds &&
                    <div>
                      {this.state.docTypeDropSelected.process_mode === 0 &&
                      <span>Custom Model selected by admin for process by default.</span>
                      }
                      {this.state.docTypeDropSelected.process_mode === 1 &&
                      <span>Predefined Model selected by admin for process by default.</span>
                      }
                    </div>
                    }
                        </div>
                    </div>
                    
                   

                  </div>
  }
                </div>
                <div className='row  manudetailheight'>
                  <div className="col-md-12  h-100">
                    <div style={{ width: '100%', height: '100%' }}>
                      {this.state.docTypeDropSelected.value && this.state.docTypeDropSelected.value === 1 && this.state.selectedPublish == 1 && filelists.length > 0 &&
                        <StickyTable leftStickyColumnCount={0} >
                          <Row>
                            {/* <Cell>Model Name</Cell> */}
                            {/* <Cell>Group Name</Cell> */}
                            <Cell></Cell>
                            <Cell>Manufacturer Name</Cell>
                            <Cell>
                              <span onClick={(e) => this.sortTrainDate(e)} className='cursor-pointer'>Trained Date  <i className="fas fa-sort"></i></span>
                            </Cell>
                            {/* <Cell>
              <span onClick={(e)=>this.sortTrainAccuracy(e)} className='cursor-pointer'>Accuracy  <i className="fas fa-sort"></i></span>

              </Cell> */}

                          </Row>
                          {filelists}

                        </StickyTable>
                      }
                      {this.state.docTypeDropSelected.value && this.state.docTypeDropSelected.value === 1 && this.state.selectedPublish != 1 && publishList.length > 0 &&
                        <StickyTable leftStickyColumnCount={0} >
                          <Row>
                            {/* <Cell>Model Name</Cell> */}
                            {/* <Cell>Group Name</Cell> */}
                            <Cell></Cell>
                            <Cell>Manufacturer Name</Cell>
                            <Cell>
                              <span onClick={(e) => this.sortPublishdate(e)} className='cursor-pointer'>Published Date  <i className="fas fa-sort"></i></span>
                            </Cell>
                            {/* <Cell>
              <span onClick={(e)=>this.sortPublishAccuracy(e)} className='cursor-pointer'>Accuracy <i className="fas fa-sort"></i></span>

              </Cell> */}
                          </Row>
                          {publishList}

                        </StickyTable>
                      }
                      {this.state.docTypeDropSelected.value && this.state.docTypeDropSelected.value !== 1 && this.state.selectedPublish == 1 && filelists.length > 0 &&
                        <StickyTable leftStickyColumnCount={0} >
                          <Row>
                            <Cell></Cell>
                            <Cell>Model Name</Cell>
                            <Cell>
                              <span onClick={(e) => this.sortTrainDate(e)} className='cursor-pointer'>Trained Date  <i className="fas fa-sort"></i></span>
                            </Cell>
                            {/* <Cell>
              <span onClick={(e)=>this.sortTrainAccuracy(e)} className='cursor-pointer'>Accuracy  <i className="fas fa-sort"></i></span>

              </Cell> */}

                          </Row>
                          {filelists}
                        </StickyTable>
                      }
                      {this.state.docTypeDropSelected.value && this.state.docTypeDropSelected.value !== 1 && this.state.selectedPublish != 1 && publishList.length > 0 &&
                        <StickyTable leftStickyColumnCount={0} >
                          <Row>
                            <Cell></Cell>
                            <Cell>Model Name</Cell>
                            <Cell>
                              <span onClick={(e) => this.sortPublishdate(e)} className='cursor-pointer'>Published Date  <i className="fas fa-sort"></i></span>
                            </Cell>
                            {/* <Cell>
              <span onClick={(e)=>this.sortPublishAccuracy(e)} className='cursor-pointer'>Accuracy <i className="fas fa-sort"></i></span>

              </Cell> */}

                          </Row>
                          {publishList}
                        </StickyTable>
                      }

                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        {this.state.processFile &&
          <div className="modal fade show d-block">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header px-4 d-block">
                  <div className="row">
                    <div className="col-md-10">
                      <h4 className="modal-title" ><a onClick={(e) => this.closeProcessFIle(false)} className="back-button cpointer" >
                        <i className="fas fa-arrow-circle-left"></i></a> Process File</h4>
                    </div>
                    <div className="col-md-2 text-right">
                      <button type="button" className="close" onClick={(e) => this.closeProcessFIle(false)}  >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>

                </div>

                <ProcessFile
                // documentType={this.state.docTypeDropSelected.value} 
                // uid={this.state.uID} 
                // closeProcessFIle={this.closeProcessFIle} 
                />
              </div>

            </div>
          </div>
        }
        <Modal isOpen={this.state.modalList} centered={true} toggle={this.togglemodalList} className="modal modalViewGroup">
          <ModalHeader toggle={this.togglemodalList}>File Information</ModalHeader>
          <ModalBody>
            <div style={{ width: '100%', height: '100%' }}>
              <StickyTable leftStickyColumnCount={0} >
                <Row>
                  <Cell>
                    File  Name
                  </Cell>
                  <Cell>

                  </Cell>

                </Row>
                {taggedFileperModel}

              </StickyTable>

            </div>



          </ModalBody>


        </Modal>

        <Modal id='modal-tagDesigner-readonly' size="lg" isOpen={this.state.ifDesigner} centered={true} toggle={this.closeTagDesigner} className="modal modal-tagDesigner-readonly">
          <ModalHeader toggle={this.closeTagDesigner}>Tag Details - {this.state.tagFilename}</ModalHeader>
          <ModalBody>
            <TagdocReadOnly />
          </ModalBody>


        </Modal>

      </div>
    );
  }
}


export default PublishModel;