import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class EditTags extends Component {  
     constructor(props) {
          super(props);
          this.state = {
               id : '',
               type : "",
               val :  "",
               granular : 0,
               desc : "",
               section : "",
               subsection : "", 
               note : "",
               cid : 0,
               category : 0, 
               created_by : 0,
               created_date : "",
               status : 	0,
               docTypes: [],
               tag_common_name:'',
               uid:0,
               docType:'',
               subsections:[
                {section:'1',subsection:'1.1'},
                {section:'1',subsection:'1.2'},
                {section:'1',subsection:'1.3'},
                {section:'1',subsection:'1.4'},
                {section:'2',subsection:'2.1'},
                {section:'2',subsection:'2.2'},
                {section:'2',subsection:'2.3'},
                {section:'3',subsection:'3.1'},
                {section:'4',subsection:'4.1'},
                {section:'4',subsection:'4.2'},
                {section:'4',subsection:'4.3'},
                {section:'5',subsection:'5.1'},
                {section:'5',subsection:'5.2'},
                {section:'5',subsection:'5.3'},
                {section:'5',subsection:'5.4'},
                {section:'6',subsection:'6.1'},
                {section:'6',subsection:'6.2'},
                {section:'6',subsection:'6.3'},
                {section:'6',subsection:'6.4'},
                {section:'7',subsection:'7.1'},
                {section:'7',subsection:'7.2'},
                {section:'7',subsection:'7.3'},
                {section:'8',subsection:'8.1'},
                {section:'8',subsection:'8.2'},
                {section:'9',subsection:'9.1'},
                {section:'9',subsection:'9.2'},
                {section:'10',subsection:'10.1'},
                {section:'10',subsection:'10.2'},
                {section:'10',subsection:'10.3'},
                {section:'10',subsection:'10.4'},
                {section:'10',subsection:'10.5'},
                {section:'10',subsection:'10.6'},
                {section:'11',subsection:'11.1'},
                {section:'12',subsection:'12.1'},
                {section:'12',subsection:'12.2'},
                {section:'12',subsection:'12.3'},
                {section:'12',subsection:'12.4'},
                {section:'12',subsection:'12.5'},
                {section:'12',subsection:'12.6'},
                {section:'13',subsection:'13.1'},
                {section:'14',subsection:'14.1'},
                {section:'15',subsection:'15.1'},
                {section:'15',subsection:'15.2'},
                {section:'16',subsection:'16.1'}
                               ]
          };
        }
        onChange = (e) => {
          this.setState({[e.target.name]: e.target.value})
        }
        onSubmit = (e) => {
          e.preventDefault();
          let error = false;
          let section = this.state.section;
          const { val, desc,tag_common_name} = this.state;
          if(val === ''){
               toast.error('Please enter tag name.')
               error = true
          }
          if(desc === ''){
               toast.error('Please enter tag description.')
               error = true
          } 
          if(tag_common_name === ''){
            toast.error('Please enter tag human readable.')
            error = true
       }   
          if(this.state.docType === 1){
            if(!section){
              toast.error('Please enter section.')
                 error = true;
            }
       }       
          if(!error){
            document.getElementById('loader').classList.add('d-block'); 
            fetch(process.env.REACT_APP_ENV+'/api/TagV2/UpdateTag',{
                 method: 'POST',
                 headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization':'bearer '+cookies.get("auth")
                   
                 },
                 body: JSON.stringify({              
                           id : this.state.id,
                           type : this.state.type,
                           val :  this.state.val,
                           granular : this.state.granular,
                           desc : this.state.desc,
                           section : this.state.section,
                           subsection : this.state.subsection, 
                           note : this.state.note,
                           cid : this.state.cid,
                           category : this.state.category, 
                           created_by : this.state.created_by,
                           created_date : this.state.created_date,
                           status : 	this.state.status,
                           tag_common_name:this.state.tag_common_name
                })
                 })
               .then((response) => response.json())
               .then((responseJson) => {
                 let tempData = responseJson; 
                 //console.log(tempData.data[0].id)   
                  if(tempData.status.statusMessage === 'Success'){
                      const timestamp = new Date().getTime();
                      const expireauth = timestamp + 1800000;
                      const exdate =  new Date(expireauth);
                      cookies.set('auth', tempData.status.authtoken, { path: '/'});   
                       toast.success('Successfully Updated Tag.');
                      //  setTimeout(function(){ 
                      //   this.props.history.push('/tag-management') 
                      //   }, 1000);
                      this.props.closeEditTag(true)
                    }else{
                       toast.error('Error ocured. Please Try Again');
                       this.props.closeEditTag(false)
                       
                  }
                  document.getElementById('loader').classList.remove('d-block'); 
                
               })
               .catch(error =>{
                // console.log(error); 
                 toast.error(error);
                 document.getElementById('loader').classList.remove('d-block'); 
                 this.props.closeEditTag(false)
               })
          }
          
          
        }
        componentDidMount(){
          this.state.docType = this.props.documentType;          
          this.state.uid = this.props.uid;
          this.setState({
            uid:this.state.uid,
            docType:this.state.docType
          })
         
          document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocumentTypeList',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization':'bearer '+cookies.get("auth")
              
            },
            body: JSON.stringify({ 
            
           })
            })
          .then((response) => response.json())
          .then((responseJson) => {
            let tempData = responseJson;  
          //  console.log(tempData)   
            if(tempData.status.statusMessage === 'Success'){
                 let exdate = new Date();
                 cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
                 this.state.docTypes =  tempData.data;
                 this.setState({
                      docTypes:this.state.docTypes
                 })
                
            }else{
                 toast.error('Error ocured. Please Try Again')
            }
           document.getElementById('loader').classList.remove('d-block'); 
           
          })
          .catch(error =>{
            console.log(error); 
          }) 
          document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/TagV2/GetTagKeyValList',{
               method: 'POST',
               headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 'Authorization':'bearer '+cookies.get("auth")
                 
               },
               body: JSON.stringify({              
                    Id : this.state.uid,
                    category:this.state.docType,
                    status: 1
              })
               })
             .then((response) => response.json())
             .then((responseJson) => {
               let tempData = responseJson; 
               console.log(tempData.data[0].id)   
                if(tempData.status.statusMessage === 'Success'){
                    const timestamp = new Date().getTime();
                    const expireauth = timestamp + 1800000;
                    const exdate =  new Date(expireauth);
                    cookies.set('auth', tempData.status.authtoken, { path: '/'});    
                     this.setState({
                         id : tempData.data[0].id,
                         type : tempData.data[0].type,
                         val :  tempData.data[0].val,
                         granular : tempData.data[0].granular,
                         desc : tempData.data[0].desc,
                         section : tempData.data[0].section,
                         subsection : tempData.data[0].subsection, 
                         note : tempData.data[0].note,
                         cid : tempData.data[0].cid,
                         category : tempData.data[0].category, 
                         created_by : tempData.data[0].created_by,
                         created_date : tempData.data[0].created_date,
                         status : 	tempData.data[0].status,
                         tag_common_name : 	tempData.data[0].tag_common_name
                    })
                    this.setState({
                         id : this.state.id,
                         type : this.state.type,
                         val :  this.state.val,
                         granular : this.state.granular,
                         desc : this.state.desc,
                         section : this.state.section,
                         subsection : this.state.subsection, 
                         note : this.state.note,
                         cid : this.state.cid,
                         category : this.state.category, 
                         created_by : this.state.created_by,
                         created_date : this.state.created_date,
                         status : 	this.state.status,
                         tag_common_name:this.state.tag_common_name
                    })
                   
                 
               
                  
                  }else{
                     toast.success('Error ocured. Please Try Again')
                }
              document.getElementById('loader').classList.remove('d-block'); 
              
             })
             .catch(error =>{
               console.log(error); 
             })
        }
        goBack() {
          cookies.remove("tagID")
          window.history.back();
        }
        changeSection= (e) => {         
          this.state.section = e.target.value;
          this.setState({
            section:this.state.section
          })
        }
        changeSubSection= (e) => {         
          this.state.subsection = e.target.value;
          this.setState({
            subsection:this.state.subsection
          })
        }
     render() {
          const { type, val,desc,category, tag_common_name} = this.state;
          let docTypeList = [];
          this.state.docTypes.forEach ((s,index) => {   
              docTypeList.push(
                   <option key={index} value={s.id}>{s.doctype_name}</option>
              )
          })
          let subsection =[];
      let checkSubsection = [];
      if( this.state.docType === 1 ){
      subsection.push(<option key={this.state.subsections.length+1} value=''>---</option>);
      this.state.subsections.forEach ((d,index) => {   
           if(d.subsection !== '' && d.section === this.state.section){
               checkSubsection.push(d.subsection)
               subsection.push(
                    <option key={index} value={d.subsection}>{d.subsection}</option>
               )
           }
          
      })
    }
    console.log('sub-'+checkSubsection.length)
  return (
         <div className="modal-body p-4">
         {/* <ToastContainer /> */}
           <Form onSubmit={this.onSubmit}>
     <div className="row">
     <div className="col-md-6">
          <FormGroup>
          <Label >Document Type <span className='text-danger'>*</span></Label>
          <select name='category'  readOnly  value={category} className='form-control' >
           {docTypeList}
          </select>    
          </FormGroup>
     </div>
      	  
     <div className="col-md-6">
          <FormGroup>
          <Label >Tag Type <span className='text-danger'>*</span></Label>
          <div className='mt-2'>
          <div className="form-check-inline">
  <label className="form-check-label">
    <input readOnly type="radio" className="form-check-input" checked={type === "K"}  name='type'   value="K" />Key Tag
  </label>
</div>
<div className="form-check-inline">
  <label className="form-check-label">
    <input readOnly type="radio" className="form-check-input"  checked={type === "V"}  name='type'   value='V' />Value Tag
  </label>
</div>
<div className="form-check-inline">
  <label className="form-check-label">
    <input readOnly type="radio" className="form-check-input"  checked={type === "C"}  name='type'   value='C' />Checkbox Tag
  </label>
</div>
          </div>
             
          
          </FormGroup>
     </div>
    {this.state.docType === 1 &&
     <div className="col-md-2">
          <FormGroup>
          <Label >Section <span className='text-danger'>*</span></Label>
          <Input type="text" className='form-control' name="section" value={this.state.section}  onChange={this.changeSection} placeholder="Section"  /> 
        
          </FormGroup>
     </div>
    }
    {this.state.docType === 1 &&
     <div className="col-md-2">
          <FormGroup>
          <Label >Sub-section</Label>
       
          <Input type="text" className='form-control' name="subsection" value={this.state.subsection}  onChange={this.changeSubSection} placeholder="Sub-section"  />   
                        
                       
                     
          </FormGroup>
     </div>
     }
     <div className="col-md-6">
          <FormGroup>
          <Label >Tag Name <span className='text-danger'>*</span></Label>
          <Input type="text" className='form-control' name="val" value={val}  onChange={this.onChange} placeholder="Tag Name"  />          
          </FormGroup>
     </div>
     <div className="col-md-6">
          <FormGroup>
          <Label>Human Readable <span className='text-danger'>*</span></Label>
          <Input type="text" className='form-control' name="tag_common_name" value={tag_common_name}  onChange={this.onChange} placeholder="Human Readable"  />          
          </FormGroup>
     </div>
     <div className="col-md-12">
          <FormGroup>
          <Label >Tag Description <span className='text-danger'>*</span></Label>
          <input type="text" name="desc" rows='5' className='form-control' value={desc}  onChange={this.onChange}  placeholder="Tag Description" />     
          </FormGroup>
     </div>
     
     
     <div className="col-md-12">
     <Button color="primary"  type="submit" size="lg">Save</Button>
     </div>
    </div>
    </Form>
    
          
             
</div>
                                
  )};
};

export default EditTags;