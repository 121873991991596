import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Select from 'react-select';
import Cookies from 'universal-cookie';
const cookies = new Cookies(); 
class editAccountClient extends Component { 
  constructor(props) {
    super(props);
	this.state = {	
		company:'',
		address1:'',
		address2:'',
		country:'',
		state:'',
		city:'',
		postal:'',
		status:'',
		ctype:'',
		created_by:'',
		created_date:'',
		documnent_type:[],
		selectedOptiongdoc:'',
		existDoc:[],
		existSelectedDoc:[]
		
	}
	this.updateValueDoc = this.updateValueDoc.bind(this)
  }
  updateValueDoc(newValue) {
	this.state.selectedOptiongdoc = newValue
  this.setState({
	selectedOptiongdoc: this.state.selectedOptiongdoc
  });
  
  }
  componentDidMount(){
    document.getElementById('loader').classList.add('d-block'); 
   fetch(process.env.REACT_APP_ENV+'/api/UserAuth/GetCompanyList',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
          
        },
        body: JSON.stringify({ 
			Id:parseInt(cookies.get("CLid"))
       })
        })
      .then((response) => response.json()) 
      .then((responseJson) => {
        let tempData = responseJson;  
        console.log(tempData.data.cname)   
        if(tempData.status.statusMessage === 'Success'){
             let exdate = new Date();
             cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
		  //   this.state.useres =  tempData.data;
		  let company,address1, address2, country, state, city, postal, id, status, ctype, created_by, created_date ='';
		  let existDoc =[];
		  if(tempData.data[0].cname){
			company = tempData.data[0].cname
		  }
		  if(tempData.data[0].address_1){
			address1 = tempData.data[0].address_1
		  }
		  if(tempData.data[0].address_2){
			address2 = tempData.data[0].address_2
		  }
		  if(tempData.data[0].country_code){
			country = tempData.data[0].country_code
		  }
		  if(tempData.data[0].state){
			state = tempData.data[0].state
		  }
		  if(tempData.data[0].city){
			city = tempData.data[0].city
		  }
		  if(tempData.data[0].zip){
			postal = tempData.data[0].zip
		  }
		  if(tempData.data[0].id){
			id = tempData.data[0].id
		  }
		  if(tempData.data[0].status){
			status = tempData.data[0].status
		  }
		  if(tempData.data[0].ctype){
			ctype = tempData.data[0].ctype
		  }
		  if(tempData.data[0].created_by){
			created_by = tempData.data[0].created_by
		  }
		  if(tempData.data[0].created_date){
			created_date = tempData.data[0].created_date
		  }
		  if(tempData.data[0].categories){
			
			let doc = tempData.data[0].categories.split(",");
			for (var i = 0; i < doc.length; i++) {
				existDoc.push(doc[i])
			}
		  }
             this.setState({
				company:company,
				address1:address1,
				address2:address2,
				country:country,
				state:state,
				city:city,
				postal:postal,
				id:id,
				status:status,
				ctype:ctype,
				created_by:created_by,
				created_date:created_date,
				existDoc:existDoc
             })
			 document.getElementById('loader').classList.add('d-block'); 
			 fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocumentTypeListAll',{
				  method: 'POST',
				  headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization':'bearer '+cookies.get("auth")
					
				  },
				  body: JSON.stringify({ 
				  
				 })
				  })
				.then((response) => response.json())
				.then((responseJson) => {
				  let tempData = responseJson;  
				//  console.log(tempData)   
				  if(tempData.status.statusMessage === 'Success'){
					   let exdate = new Date();
					   cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
					   this.state.docType =  tempData.data;
					   let storedata = [];
					   let existSelectedDoc = [];
					   for (var i = 0; i < tempData.data.length; i++) {
						var checkarray = this.isInArray(String(tempData.data[i].id), this.state.existDoc);
						 if(checkarray){
							existSelectedDoc.push({
								value: tempData.data[i].id, 
								label:  tempData.data[i].doctype_name,
							  })
						 }
						
							storedata.push({
								value: tempData.data[i].id, 
								label:  tempData.data[i].doctype_name,
							  })
						
								   
								  
								   }
					   this.setState({
						 documnent_type:storedata,
						 selectedOptiongdoc:existSelectedDoc
					   })
					  
					   document.getElementById('loader').classList.remove('d-block'); 
						 
					  
					   
					
				  }else{
					   toast.error('Error ocured. Please Try Again');
					   document.getElementById('loader').classList.remove('d-block'); 
				  }
				 
				 
				})
				.catch(error =>{
				  console.log(error); 
				}) 
        }else{
             toast.error('Error ocured. Please Try Again')
        }
       document.getElementById('loader').classList.remove('d-block'); 
       
      })
      .catch(error =>{
		console.log(error); 
		document.getElementById('loader').classList.remove('d-block'); 
      })
	  
  }
  isInArray = (value, array) =>{
	return array.indexOf(value) > -1;
  }
  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
  onSubmit = (e) => {
    e.preventDefault();  
    let error = false;
	const { company, address1, address2, country,state,city,postal,ctype,status,created_by,created_date,id} = this.state;
	let docs = []
	if(this.state.selectedOptiongdoc){
   for(let i=0; this.state.selectedOptiongdoc.length>i; i++){
    docs.push(this.state.selectedOptiongdoc[i].value)
  		}
	}
	if(this.state.existSelectedDoc){
		for(let i=0; this.state.existSelectedDoc.length>i; i++){
		 docs.push(this.state.existSelectedDoc[i].value)
			   }
		 }

    if(!company){
         toast.error('Please enter company name.')
         error = true;
         document.getElementsByName('company')[0].focus()
         return false;
	}
    if(!address1){
         toast.error('Please enter address 1.');         
         error = true;
         document.getElementsByName('address1')[0].focus();
         return false;
    }
    if(!country){
         toast.error('Please select country.')
         error = true
         document.getElementsByName('country')[0].focus();
         return false;
    
    }
    if(!state){
         toast.error('Please enter state.')
         error = true
         document.getElementsByName('state')[0].focus();
         return false;
    
    }
    if(!city){
      toast.error('Please enter city.')
      error = true
      document.getElementsByName('city')[0].focus();
      return false;
 }
    if(!postal){
      toast.error('Please enter postal code.')
      error = true
      document.getElementsByName('postal')[0].focus();
      return false;
 }
 if(docs.length<1){
	toast.error('Please select document type.')
	error = true;
	
    
 }
   // console.log('name : ' + tgname+', desc: ' +tgdesc+',tagType:'+tagType+',docType'+docType);
    if(error !== true){
         document.getElementById('loader').classList.add('d-block'); 
         fetch(process.env.REACT_APP_ENV+'/api/UserAuth/UpdateCompany',{
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'bearer '+cookies.get("auth")
                
              },
              body: JSON.stringify({ 
                cname : company,
				address_1 : address1,
				address_2 : address2,
				country_code : country,
				state : state,
				city : city,
				zip : postal,
				ctype : ctype,
				status : status,
				created_by : created_by,
				created_date : created_date,
				id:id,
				categories:docs.join()
             })
              })
            .then((response) => response.json())
            .then((responseJson) => {
              let tempData = responseJson;  
              if(tempData.status.statusMessage === 'Success'){
                const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', tempData.status.authtoken, { path: '/'});                
			  toast.success('Successfully Updated Company.');
			  setTimeout(function(){ 
                window.history.back();
                }, 1000);
              }else{
				toast.success('Error ocured. Please Try Again');
				document.getElementById('loader').classList.remove('d-block'); 
			  }
			  
             
            })
            .catch(error =>{
			  console.log(error); 
			  document.getElementById('loader').classList.remove('d-block'); 
            })
     }
    
    
  }
 
    goBack() {
        window.history.back();
      }
	  removeDOc = (index) =>{
		let alldoc = this.state.documnent_type;
		let getremoved = this.state.existSelectedDoc[index];
		 alldoc.push({
			value: getremoved.value, 
			label:  getremoved.label,
		  })
		let filtCat = this.state.existSelectedDoc.filter(docType => docType != getremoved);
		this.state.existSelectedDoc = filtCat;
		this.state.documnent_type = alldoc;
		this.setState({
			existSelectedDoc:this.state.existSelectedDoc,
			documnent_type:this.state.documnent_type
		})
		this.setState({
			documnent_type:this.state.documnent_type
		})
		console.log(this.state.documnent_type)
	  }
    render(){
		let doctype = [];
		this.state.documnent_type.forEach ((s,index) => { 		
				doctype.push({
				   value: s.value, 
				   label:  s.label,
				 })
				
			console.log(doctype)
		})
		let existdoc = []
		this.state.existSelectedDoc.forEach ((s,index) => { 
			existdoc.push(
				<span className='selecteddoc' key={index}>{s.label} <span onClick={(e) => this.removeDOc(index)} className='cpointer text-primary'><i className="fas fa-window-close"></i></span></span>
			)
		  })
        return(
            <div className="modal fade show d-block"     >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header px-4 d-block">
            
              
               <div className="row">
              	<div className="col-md-10">
              		 <h4 className="modal-title" ><a onClick={this.goBack}   className="back-button cpointer" >
                <i className="fas fa-arrow-circle-left"></i></a> Update Client Account</h4>
              	</div>
              	<div className="col-md-2 text-right">
              	</div>
              </div>
            
            </div>
            <div className="modal-body p-4">
              
            
			<ToastContainer />
			<Form onSubmit={this.onSubmit}>
         
              <div className='row'>
                <div className='col-md-6'>
                <div className="form-row">
              <div className="form-group col-md-12">
              <label>Company Name <span className='text-danger lead'>*</span></label>
                <input type='text'  onChange={this.onChange} value={this.state.company} name='company' type='text'  className="form-control" />                
              </div>
			  <div className="form-group col-md-6">
              <label>Address 1 <span className='text-danger lead'>*</span></label>
                <input value={this.state.address1}  name='address1' onChange={this.onChange} type='text' className="form-control" />             
              </div>
			  <div className="form-group col-md-6">
              <label>Address 2</label>
                <input name='address2' value={this.state.address2} onChange={this.onChange} type='text'  className="form-control" />              
              </div>
              <div className="form-group col-md-6">
              <label>Country <span className='text-danger lead'>*</span></label>
              <select value={this.state.country}  name='country' onChange={this.onChange} className="form-control">
                <option value="">----</option>
                <option value="AFG">Afghanistan</option>
	<option value="ALA">Åland Islands</option>
	<option value="ALB">Albania</option>
	<option value="DZA">Algeria</option>
	<option value="ASM">American Samoa</option>
	<option value="AND">Andorra</option>
	<option value="AGO">Angola</option>
	<option value="AIA">Anguilla</option>
	<option value="ATA">Antarctica</option>
	<option value="ATG">Antigua and Barbuda</option>
	<option value="ARG">Argentina</option>
	<option value="ARM">Armenia</option>
	<option value="ABW">Aruba</option>
	<option value="AUS">Australia</option>
	<option value="AUT">Austria</option>
	<option value="AZE">Azerbaijan</option>
	<option value="BHS">Bahamas</option>
	<option value="BHR">Bahrain</option>
	<option value="BGD">Bangladesh</option>
	<option value="BRB">Barbados</option>
	<option value="BLR">Belarus</option>
	<option value="BEL">Belgium</option>
	<option value="BLZ">Belize</option>
	<option value="BEN">Benin</option>
	<option value="BMU">Bermuda</option>
	<option value="BTN">Bhutan</option>
	<option value="BOL">Bolivia, Plurinational State of</option>
	<option value="BES">Bonaire, Sint Eustatius and Saba</option>
	<option value="BIH">Bosnia and Herzegovina</option>
	<option value="BWA">Botswana</option>
	<option value="BVT">Bouvet Island</option>
	<option value="BRA">Brazil</option>
	<option value="IOT">British Indian Ocean Territory</option>
	<option value="BRN">Brunei Darussalam</option>
	<option value="BGR">Bulgaria</option>
	<option value="BFA">Burkina Faso</option>
	<option value="BDI">Burundi</option>
	<option value="KHM">Cambodia</option>
	<option value="CMR">Cameroon</option>
	<option value="CAN">Canada</option>
	<option value="CPV">Cape Verde</option>
	<option value="CYM">Cayman Islands</option>
	<option value="CAF">Central African Republic</option>
	<option value="TCD">Chad</option>
	<option value="CHL">Chile</option>
	<option value="CHN">China</option>
	<option value="CXR">Christmas Island</option>
	<option value="CCK">Cocos (Keeling) Islands</option>
	<option value="COL">Colombia</option>
	<option value="COM">Comoros</option>
	<option value="COG">Congo</option>
	<option value="COD">Congo, the Democratic Republic of the</option>
	<option value="COK">Cook Islands</option>
	<option value="CRI">Costa Rica</option>
	<option value="CIV">Côte d'Ivoire</option>
	<option value="HRV">Croatia</option>
	<option value="CUB">Cuba</option>
	<option value="CUW">Curaçao</option>
	<option value="CYP">Cyprus</option>
	<option value="CZE">Czech Republic</option>
	<option value="DNK">Denmark</option>
	<option value="DJI">Djibouti</option>
	<option value="DMA">Dominica</option>
	<option value="DOM">Dominican Republic</option>
	<option value="ECU">Ecuador</option>
	<option value="EGY">Egypt</option>
	<option value="SLV">El Salvador</option>
	<option value="GNQ">Equatorial Guinea</option>
	<option value="ERI">Eritrea</option>
	<option value="EST">Estonia</option>
	<option value="ETH">Ethiopia</option>
	<option value="FLK">Falkland Islands (Malvinas)</option>
	<option value="FRO">Faroe Islands</option>
	<option value="FJI">Fiji</option>
	<option value="FIN">Finland</option>
	<option value="FRA">France</option>
	<option value="GUF">French Guiana</option>
	<option value="PYF">French Polynesia</option>
	<option value="ATF">French Southern Territories</option>
	<option value="GAB">Gabon</option>
	<option value="GMB">Gambia</option>
	<option value="GEO">Georgia</option>
	<option value="DEU">Germany</option>
	<option value="GHA">Ghana</option>
	<option value="GIB">Gibraltar</option>
	<option value="GRC">Greece</option>
	<option value="GRL">Greenland</option>
	<option value="GRD">Grenada</option>
	<option value="GLP">Guadeloupe</option>
	<option value="GUM">Guam</option>
	<option value="GTM">Guatemala</option>
	<option value="GGY">Guernsey</option>
	<option value="GIN">Guinea</option>
	<option value="GNB">Guinea-Bissau</option>
	<option value="GUY">Guyana</option>
	<option value="HTI">Haiti</option>
	<option value="HMD">Heard Island and McDonald Islands</option>
	<option value="VAT">Holy See (Vatican City State)</option>
	<option value="HND">Honduras</option>
	<option value="HKG">Hong Kong</option>
	<option value="HUN">Hungary</option>
	<option value="ISL">Iceland</option>
	<option value="IND">India</option>
	<option value="IDN">Indonesia</option>
	<option value="IRN">Iran, Islamic Republic of</option>
	<option value="IRQ">Iraq</option>
	<option value="IRL">Ireland</option>
	<option value="IMN">Isle of Man</option>
	<option value="ISR">Israel</option>
	<option value="ITA">Italy</option>
	<option value="JAM">Jamaica</option>
	<option value="JPN">Japan</option>
	<option value="JEY">Jersey</option>
	<option value="JOR">Jordan</option>
	<option value="KAZ">Kazakhstan</option>
	<option value="KEN">Kenya</option>
	<option value="KIR">Kiribati</option>
	<option value="PRK">Korea, Democratic People's Republic of</option>
	<option value="KOR">Korea, Republic of</option>
	<option value="KWT">Kuwait</option>
	<option value="KGZ">Kyrgyzstan</option>
	<option value="LAO">Lao People's Democratic Republic</option>
	<option value="LVA">Latvia</option>
	<option value="LBN">Lebanon</option>
	<option value="LSO">Lesotho</option>
	<option value="LBR">Liberia</option>
	<option value="LBY">Libya</option>
	<option value="LIE">Liechtenstein</option>
	<option value="LTU">Lithuania</option>
	<option value="LUX">Luxembourg</option>
	<option value="MAC">Macao</option>
	<option value="MKD">Macedonia, the former Yugoslav Republic of</option>
	<option value="MDG">Madagascar</option>
	<option value="MWI">Malawi</option>
	<option value="MYS">Malaysia</option>
	<option value="MDV">Maldives</option>
	<option value="MLI">Mali</option>
	<option value="MLT">Malta</option>
	<option value="MHL">Marshall Islands</option>
	<option value="MTQ">Martinique</option>
	<option value="MRT">Mauritania</option>
	<option value="MUS">Mauritius</option>
	<option value="MYT">Mayotte</option>
	<option value="MEX">Mexico</option>
	<option value="FSM">Micronesia, Federated States of</option>
	<option value="MDA">Moldova, Republic of</option>
	<option value="MCO">Monaco</option>
	<option value="MNG">Mongolia</option>
	<option value="MNE">Montenegro</option>
	<option value="MSR">Montserrat</option>
	<option value="MAR">Morocco</option>
	<option value="MOZ">Mozambique</option>
	<option value="MMR">Myanmar</option>
	<option value="NAM">Namibia</option>
	<option value="NRU">Nauru</option>
	<option value="NPL">Nepal</option>
	<option value="NLD">Netherlands</option>
	<option value="NCL">New Caledonia</option>
	<option value="NZL">New Zealand</option>
	<option value="NIC">Nicaragua</option>
	<option value="NER">Niger</option>
	<option value="NGA">Nigeria</option>
	<option value="NIU">Niue</option>
	<option value="NFK">Norfolk Island</option>
	<option value="MNP">Northern Mariana Islands</option>
	<option value="NOR">Norway</option>
	<option value="OMN">Oman</option>
	<option value="PAK">Pakistan</option>
	<option value="PLW">Palau</option>
	<option value="PSE">Palestinian Territory, Occupied</option>
	<option value="PAN">Panama</option>
	<option value="PNG">Papua New Guinea</option>
	<option value="PRY">Paraguay</option>
	<option value="PER">Peru</option>
	<option value="PHL">Philippines</option>
	<option value="PCN">Pitcairn</option>
	<option value="POL">Poland</option>
	<option value="PRT">Portugal</option>
	<option value="PRI">Puerto Rico</option>
	<option value="QAT">Qatar</option>
	<option value="REU">Réunion</option>
	<option value="ROU">Romania</option>
	<option value="RUS">Russian Federation</option>
	<option value="RWA">Rwanda</option>
	<option value="BLM">Saint Barthélemy</option>
	<option value="SHN">Saint Helena, Ascension and Tristan da Cunha</option>
	<option value="KNA">Saint Kitts and Nevis</option>
	<option value="LCA">Saint Lucia</option>
	<option value="MAF">Saint Martin (French part)</option>
	<option value="SPM">Saint Pierre and Miquelon</option>
	<option value="VCT">Saint Vincent and the Grenadines</option>
	<option value="WSM">Samoa</option>
	<option value="SMR">San Marino</option>
	<option value="STP">Sao Tome and Principe</option>
	<option value="SAU">Saudi Arabia</option>
	<option value="SEN">Senegal</option>
	<option value="SRB">Serbia</option>
	<option value="SYC">Seychelles</option>
	<option value="SLE">Sierra Leone</option>
	<option value="SGP">Singapore</option>
	<option value="SXM">Sint Maarten (Dutch part)</option>
	<option value="SVK">Slovakia</option>
	<option value="SVN">Slovenia</option>
	<option value="SLB">Solomon Islands</option>
	<option value="SOM">Somalia</option>
	<option value="ZAF">South Africa</option>
	<option value="SGS">South Georgia and the South Sandwich Islands</option>
	<option value="SSD">South Sudan</option>
	<option value="ESP">Spain</option>
	<option value="LKA">Sri Lanka</option>
	<option value="SDN">Sudan</option>
	<option value="SUR">Suriname</option>
	<option value="SJM">Svalbard and Jan Mayen</option>
	<option value="SWZ">Swaziland</option>
	<option value="SWE">Sweden</option>
	<option value="CHE">Switzerland</option>
	<option value="SYR">Syrian Arab Republic</option>
	<option value="TWN">Taiwan, Province of China</option>
	<option value="TJK">Tajikistan</option>
	<option value="TZA">Tanzania, United Republic of</option>
	<option value="THA">Thailand</option>
	<option value="TLS">Timor-Leste</option>
	<option value="TGO">Togo</option>
	<option value="TKL">Tokelau</option>
	<option value="TON">Tonga</option>
	<option value="TTO">Trinidad and Tobago</option>
	<option value="TUN">Tunisia</option>
	<option value="TUR">Turkey</option>
	<option value="TKM">Turkmenistan</option>
	<option value="TCA">Turks and Caicos Islands</option>
	<option value="TUV">Tuvalu</option>
	<option value="UGA">Uganda</option>
	<option value="UKR">Ukraine</option>
	<option value="ARE">United Arab Emirates</option>
	<option value="GBR">United Kingdom</option>
	<option value="USA">United States</option>
	<option value="UMI">United States Minor Outlying Islands</option>
	<option value="URY">Uruguay</option>
	<option value="UZB">Uzbekistan</option>
	<option value="VUT">Vanuatu</option>
	<option value="VEN">Venezuela, Bolivarian Republic of</option>
	<option value="VNM">Viet Nam</option>
	<option value="VGB">Virgin Islands, British</option>
	<option value="VIR">Virgin Islands, U.S.</option>
	<option value="WLF">Wallis and Futuna</option>
	<option value="ESH">Western Sahara</option>
	<option value="YEM">Yemen</option>
	<option value="ZMB">Zambia</option>
	<option value="ZWE">Zimbabwe</option>
                </select>
                            
              </div>
              
              <div className="form-group col-md-6">
              <label>State <span className='text-danger lead'>*</span></label>
                <input name='state' value={this.state.state} onChange={this.onChange} type='text'  className="form-control" />                
              </div>
              <div className="form-group col-md-6">
              <label>City <span className='text-danger lead'>*</span></label>
                <input type='text' value={this.state.city} onChange={this.onChange} name='city'  className="form-control" />                
              </div>
			  <div className="form-group col-md-6">
              <label>Postal Code <span className='text-danger lead'>*</span></label>
                <input type='text' value={this.state.postal} onChange={this.onChange} name='postal'  className="form-control" />                
              </div> 
              <div className="form-group col-md-6 ">
              <label>Document Type </label>             
          <Select                
        value={this.state.selectedOptiongdoc}
        onChange={this.updateValueDoc}
        isMulti
        options={doctype}
        defaultValue={{ value: '', label: '----' }}
      />
              </div>
			  <div className="form-group col-md-12 ">
			  
				 
			  </div>
              
              
              </div>
              <button type="submit" className="btn btn-primary">Save</button>
                </div>
                
              </div>
             
            </Form> 
              
           
</div>
                                  
       
   
          </div>
        
        </div>
      </div>
        )
    }
}
export default editAccountClient