import React, { Component } from 'react';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import {NavLink} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
const cookies = new Cookies();
class ViewDocType extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      docType:[],
      cid:'',
      role:'',
      ifCloudsds:false,
  };
  }
  componentDidMount(){
    if(cookies.get("cid") == '1'){
      this.setState({
        ifCloudsds:true
       })
    }
    let cid = cookies.get("cid");
      let role = cookies.get("roleid");
      this.state.cid = cid;
      this.state.role = role
      this.setState({
        cid: this.state.cid,
        role:this.state.role
       })
   document.getElementById('loader').classList.add('d-block'); 
   let url = '/api/Document/GetDocumentTypeList';
   if(this.state.cid === '1' && this.state.role === '1'){
    url = '/api/Document/GetDocumentTypeListAll';
   }
   fetch(process.env.REACT_APP_ENV+url,{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")
          
        },
        body: JSON.stringify({ 
        
       })
        })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;  
        console.log(tempData)   
        if(tempData.status.statusMessage === 'Success'){
             let exdate = new Date();
             cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
             this.state.docType =  tempData.data;
             this.setState({
               docType:this.state.docType
             })
        }else{
             toast.error('Error ocured. Please Try Again')
        }
       document.getElementById('loader').classList.remove('d-block'); 
       
      })
      .catch(error =>{
        console.log(error); 
      })
  }
  editgroupg = (e,id) => {
    const timestamp = new Date().getTime();
        const expireauth = timestamp + 1800000;
        const exdate =  new Date(expireauth);
         cookies.set('UpdDocID', id, { path: '/'});  
    this.props.history.push('/update-doc-type') 
    
}
editPModeStatus = (e,id,status,index) => {
  confirmAlert({
    title: 'Confirm to Proceed',
    message: 'Are you sure to do this.',
    buttons: [
      {
        label: 'Yes',
        onClick: () => {
          this.editPModeStatusconfirm('',id,status,index)
        }
      },
      {
        label: 'No',
        onClick: () => {}
      }
    ]
  });
}

editPModeStatusconfirm= (e,id,status,index) => {
  document.getElementById('loader').classList.add('d-block'); 
  let gstatus = 0; 
              if(status === 1){
                gstatus = 0;
              }else{
                gstatus = 1;
              }
         fetch(process.env.REACT_APP_ENV+'/api/Document/ProcessModeForDocumentType',{
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'bearer '+cookies.get("auth")
                
              },
              body: JSON.stringify({ 
                id : parseInt(id),
                process_mode : parseInt(gstatus),
             })
              })
            .then((response) => response.json())
            .then((responseJson) => {
              let tempData = responseJson;  
            //  console.log(tempData)   
              if(tempData.status.statusMessage === 'Success'){
                toast.success('Api Setting successfully Updated')
              cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
              
             this.state.docType[index].process_mode = gstatus;
              this.setState({
                docType:this.state.docType,
              })
             
              }else{
                toast.error('Error ocured.Please try again later.');
               
              }
              document.getElementById('loader').classList.remove('d-block'); 
             
            })
            .catch(error =>{
              console.log(error); 
            })
  
}
editDocStatus =(e,id,status,index)=>{
  confirmAlert({
    title: 'Confirm to Proceed',
    message: 'Are you sure to do this.',
    buttons: [
      {
        label: 'Yes',
        onClick: () => {
          this.editDocStatusCOnfirm('',id,status,index)
        }
      },
      {
        label: 'No',
        onClick: () => {}
      }
    ]
  });
}
editDocStatusCOnfirm= (e,id,status,index) => {
  console.log(status)
  document.getElementById('loader').classList.add('d-block'); 
  let gstatus = 0; 
              if(status === 1){
                gstatus = 0;
              }else{
                gstatus = 1;
              }
         fetch(process.env.REACT_APP_ENV+'/api/Document/EnableDisableDoctype',{
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'bearer '+cookies.get("auth")
                
              },
              body: JSON.stringify({ 
                Id : parseInt(id),
                status : parseInt(gstatus),
             })
              })
            .then((response) => response.json())
            .then((responseJson) => {
              let tempData = responseJson;  
            //  console.log(tempData)   
              if(tempData.status.statusMessage === 'Success'){
                toast.success('Document Type status successfully Updated')
                const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
              
             this.state.docType[index].status = gstatus;
              this.setState({
                docType:this.state.docType,
              })
             
              }else{
                toast.error('Error ocured.Please try again later.');
               
              }
              document.getElementById('loader').classList.remove('d-block'); 
             
            })
            .catch(error =>{
              console.log(error); 
            })
 // this.props.history.push('/update-doc-type') 
  
}
    goBack() {
        window.history.back();
      }
    render(){
      let userlist = [];
      if(this.state.docType.length){
         this.state.docType.forEach ((s,index) => {   
            let cdate = s.created_date.split("T")[0];
            let mdate = s.modified_date.split("T")[0];
            let status = s.status;
            if(status == 1){
              status ='Active'
            }else{
              status ='Inactive'
            }
            let pMode = s.process_mode;
            userlist.push(
                       <Row   key={index} >                        
                       <Cell>{s.doctype_name}</Cell>
                       {this.state.ifCloudsds && 
                       <Cell>{s.id}</Cell>
         }
                       <Cell>{status}</Cell>
                       <Cell>
                    <div>{cdate}</div>
                    <div>{s.created_by_name}</div>
                    </Cell>
                    <Cell>
                    <div>{mdate}</div>
                    <div>{s.modified_by_name}</div>
                    </Cell>
                    {this.state.cid !== '1'  && 
                    <Cell>
                   
            <div>
            
                       <button onClick={(e) => this.editPModeStatus(e,s.process_mode_id,pMode,index)} type="button" className="btn btn-primary btn-sm">
                       <span className={pMode == 1?'pmdis':'pmpre'}>Custom</span> <i className={pMode === 1?'fas fa-toggle-on':'fas fa-toggle-off'}></i> <span className={pMode == 1?'pmpre':'pmdis'}>Predefined</span> </button>
                        </div>
              
               
                 </Cell>
                 }
                  {this.state.cid === '1' && this.state.role == '1' && 
                 <Cell>
           
            <div>
            <button onClick={(e) => this.editgroupg(e,s.id)}  type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-pencil-alt"></i></button>
                       <button onClick={(e) => this.editDocStatus(e,s.id,s.status,index)} type="button" className="btn btn-primary btn-sm">
                        <i className={s.status === 1?'fas fa-toggle-on':'fas fa-toggle-off'}></i></button>
                        </div>
              
              
                 </Cell>  
                  }     
                       </Row>
               )
            })
      }
     
        return(
         <div className='h100percent'>
         <div className='main-header'>
           
           <div className='row'>
             <div className='col-md-6'><h2 className="page-header"><a onClick={this.goBack}   className="back-button cpointer" >
                <i className="fas fa-arrow-circle-left"></i></a> Document Type</h2></div>
             <div className='col-md-6 text-right'>
             
                  
                  </div>
            
           </div>
           </div>
           <div className='main-panel'>
            <div className='content '>
            <ToastContainer />
            <div className='container-fluid h100percent'>
            <div className='h100percent text-selection-none '>
            <div className='row mb-3'>
     <div className='col-md-12 text-right'>             
     {this.state.cid == 1 && this.state.role == 1 && <NavLink  className='btn btn-primary  mt-2'  title='Add Document Type'  activeClassName={"active"} to="/add-doc-type">Add Document Type</NavLink> }
               </div>
               </div>
     <div className='row viewGroupheight'>
            <div className="col-md-12  h-100">
            <div style={{width: '100%', height: '100%'}}>
              <StickyTable leftStickyColumnCount={0} >         
              <Row>                 
                 <Cell>Document Type</Cell>
                 {this.state.ifCloudsds && 
                 <Cell>ID</Cell>
                }
                 <Cell>Status</Cell>
                 <Cell>Created</Cell>
                <Cell>Updated</Cell>
                {!this.state.ifCloudsds &&  
                <Cell>API Setting</Cell> 
    }             
                 <Cell ></Cell>
              </Row>
           
           
              {userlist}
             
              
              
         
           </StickyTable>
           </div>
</div>

    </div>   
</div>
</div>
        </div>
        </div>
       </div>
     
        )
    }
}
export default ViewDocType