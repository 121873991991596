import React, { Component } from 'react';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody,Tooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {NavLink} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import Cookies from 'universal-cookie';
import Addgroup from "./Add-group.jsx";
import Editgroup from "./EditGroup.jsx";
const cookies = new Cookies();
class viewgroup extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      grouplist:[],     
      docType:[],
      docTypeDrop:[],
      docTypeDropSelected:'',
      modalDepartment:false,
      modalGroupTitle:'',
      selectFilter:'',
      meta_json:[],
	        field_json:[],
           metalist:[],
      langlist:[
        { value:'',label:'----'},
        { value:'AF',label:'Afrikaans'},
        { value:'SQ',label:'Albanian'},
        { value:'AR',label:'Arabic'},
        { value:'HY',label:'Armenian'},
        { value:'EU',label:'Basque'},
        { value:'BN',label:'Bengali'},
        { value:'BG',label:'Bulgarian'},
        { value:'CA',label:'Catalan'},
        { value:'KM',label:'Cambodian'},
        { value:'ZH',label:'Chinese (Mandarin)'},
        { value:'HR',label:'Croatian'},
        { value:'CS',label:'Czech'},
        { value:'DA',label:'Danish'},
        { value:'NL',label:'Dutch'},
        { value:'EN',label:'English'},
        { value:'ET',label:'Estonian'},
        { value:'FJ',label:'Fiji'},
        { value:'FI',label:'Finnish'},
        { value:'FR',label:'French'},
        { value:'KA',label:'Georgian'},
        { value:'DE',label:'German'},
        { value:'EL',label:'Greek'},
        { value:'GU',label:'Gujarati'},
        { value:'HE',label:'Hebrew'},
        { value:'HI',label:'Hindi'},
        { value:'HU',label:'Hungarian'},
        { value:'IS',label:'Icelandic'},
        { value:'ID',label:'Indonesian'},
        { value:'GA',label:'Irish'},
        { value:'IT',label:'Italian'},
        { value:'JA',label:'Japanese'},
        { value:'JW',label:'Javanese'},
        { value:'KO',label:'Korean'},
        { value:'LA',label:'Latin'},
        { value:'LV',label:'Latvian'},
        { value:'LT',label:'Lithuanian'},
        { value:'MK',label:'Macedonian'},
        { value:'MS',label:'Malay'},
        { value:'ML',label:'Malayalam'},
        { value:'MT',label:'Maltese'},
        { value:'MI',label:'Maori'},
        { value:'MR',label:'Marathi'},
        { value:'MN',label:'Mongolian'},
        { value:'NE',label:'Nepali'},
        { value:'NO',label:'Norwegian'},
        { value:'FA',label:'Persian'},
        { value:'PL',label:'Polish'},
        { value:'PT',label:'Portuguese'},
        { value:'PA',label:'Punjabi'},
        { value:'QU',label:'Quechua'},
        { value:'RO',label:'Romanian'},
        { value:'RU',label:'Russian'},
        { value:'SM',label:'Samoan'},
        { value:'SR',label:'Serbian'},
        { value:'SK',label:'Slovak'},
        { value:'SL',label:'Slovenian'},
        { value:'ES',label:'Spanish'},
        { value:'SW',label:'Swahili'},
        { value:'SV',label:'Swedish '},
        { value:'TA',label:'Tamil'},
        { value:'TT',label:'Tatar'},
        { value:'TE',label:'Telugu'},
        { value:'TH',label:'Thai'},
        { value:'BO',label:'Tibetan'},
        { value:'TO',label:'Tonga'},
        { value:'TR',label:'Turkish'},
        { value:'UK',label:'Ukrainian'},
        { value:'UR',label:'Urdu'},
        { value:'UZ',label:'Uzbek'},
        { value:'VI',label:'Vietnamese'},
        { value:'CY',label:'Welsh'},
        { value:'XH',label:'Xhosa'}
  ],
  groupSortName:false,
  isAddGroup:false,
  isEditGroup:false
  
    }
    this.updatedocTypeDrop = this.updatedocTypeDrop.bind(this)
  }
  toggleDepartment = () => {
    this.setState({
        modalDepartment:!this.state.modalDepartment
    })
}
  sortGroupName = (e,name) =>{ 
    if(this.state.groupSortName){
      this.state.groupSortName = !this.state.groupSortName;
      this.state.selectFilter = name+' '+'desc';
      this.setState({
        groupSortName:this.state.groupSortName,
        selectFilter:this.state.selectFilter,
        taglist:[]
      })
      this.getGroupList() 
    }else{
      this.state.groupSortName = !this.state.groupSortName;
      this.state.selectFilter = name+' '+'asc';
      this.setState({
        groupSortName:this.state.groupSortName,
        selectFilter:this.state.selectFilter,
        taglist:[]
      })
      this.getGroupList() 
    }
  }
  getGroupList= () =>{
    
    document.getElementById('loader').classList.add('d-block'); 
    let catid = 1;
        if( this.state.docTypeDropSelected != undefined &&  this.state.docTypeDropSelected != null 
            &&  this.state.docTypeDropSelected.value != ""){
          catid = this.state.docTypeDropSelected.value
        }
    fetch(process.env.REACT_APP_ENV+'/api/Document/GetGroupList',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'bearer '+cookies.get("auth")          
        },
        body: JSON.stringify({  
              cid : cookies.get("cid"),
              category:catid,
              sortColumn:this.state.selectFilter,
      })
        })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson; 
      // console.log(tempData)   
      const timestamp = new Date().getTime();
                const expireauth = timestamp + 1800000;
                const exdate =  new Date(expireauth);
                cookies.set('auth', tempData.status.authtoken, { path: '/'});
        if(tempData.data === null){
          toast.error('Error ocured. Please Try Again')
              
            // console.log(this.state.grouplist)
          }else{
            this.setState({
              grouplist: tempData.data,
          })
          document.getElementById('loader').classList.remove('d-block'); 
        }
      
      
      })
      .catch(error =>{
        console.log(error); 
      })
  }
  componentDidMount(){
    document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocumentTypeList',{
               method: 'POST',
               headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 'Authorization':'bearer '+cookies.get("auth")
                 
               },
               body: JSON.stringify({ 
               
              })
               })
             .then((response) => response.json())
             .then((responseJson) => {
               let tempData = responseJson;  
             //  console.log(tempData)   
               if(tempData.status.statusMessage === 'Success'){
                    let exdate = new Date();
                    cookies.set('auth', tempData.status.authtoken, { path: '/' }); 
                    this.state.docType =  tempData.data;
                    let storedata = [];
                  //  storedata.push({value:'',label:'Select'});
                    for (var i = 0; i < tempData.data.length; i++) {
                      if(tempData.data[i].id === 1){
                        storedata.push({
                          value: tempData.data[i].id, 
                          label:  tempData.data[i].doctype_name,
                        })
                      }
                                
                               
                                }
                    this.setState({
                      docType:this.state.docType,
                      docTypeDrop:storedata,
                      docTypeDropSelected:storedata[0]
                    })
                    console.log(this.state.docType)
                    this.getGroupList()
               }else{
                    toast.error('Error ocured. Please Try Again');
                    document.getElementById('loader').classList.remove('d-block'); 
               }
              
              
             })
             .catch(error =>{
               console.log(error); 
             })   
   
  }
  updatedocTypeDrop(newValue){
    this.state.docTypeDropSelected = newValue
    this.setState({
      docTypeDropSelected: this.state.docTypeDropSelected
    });
    this.getGroupList()
  }
  onChangeFilter = (e) => {
    this.state.selectFilter = e.target.value
    this.setState({
      selectFilter: this.state.selectFilter,
      grouplist:[]
    })
    this.getGroupList()
  }
    goBack() {
        window.history.back(); 
      }
      getGroupMetaInfo =(e,id,title)=>{
        let field_json = [];
          let fj = this.state.grouplist.filter(groupList => groupList.id == id);
          field_json = JSON.parse(fj[0].field_json);   
          this.state.field_json = field_json;
          this.state.modalGroupTitle = title;
          this.setState({
            field_json:this.state.field_json,
            modalGroupTitle:this.state.modalGroupTitle
          });
          this.getMetaListall();
      }
      getMetaListall = (e) =>{
        console.log(this.state.field_json)
          let ArrMetaList = [];
          if(this.state.field_json && this.state.field_json.length > 0 ){
      this.state.field_json.forEach((ml,index,arr) => { 
        if(ml.type === '1'){
          ArrMetaList.push({
            api: ml.api,
            document_type_id: ml.document_type_id,
            error: ml.error,
            id: ml.id,
            key: ml.key,
            label: ml.label,
            mandatroy: ml.mandatroy,
            type: ml.type,
            value:ml.value,
            option:[],
            selectedOptiongDrop:[],
            valueLabel:''
          })
          fetch(process.env.REACT_APP_ENV+ml.api,{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization':'bearer '+cookies.get("auth")
              
            },
            body: JSON.stringify({              
              MfgName:'',
              mfgId:ml.value
           })
            })
          .then((response) => response.json())
          .then((responseJson) => {
            let tempData = responseJson; 
            console.log(tempData.data[0].mnfName)   
             if(tempData.status.statusMessage === 'Success'){
              const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', tempData.status.authtoken, { path: '/'});   
               let gindex = index;
               this.state.metalist[gindex].valueLabel = tempData.data[0].mnfName;
               this.setState({
                metalist:this.state.metalist
                 })
                      
               }else{
                  toast.success('Error ocured. Please Try Again')
             }
             
           
          })
          .catch(error =>{
            console.log(error); 
          })
         
         
        }
        if(ml.type === '2'){
          ArrMetaList.push({
            api: ml.api,
            document_type_id: ml.document_type_id,
            error: ml.error,
            id: ml.id,
            key: ml.key,
            label: ml.label,
            mandatroy: ml.mandatroy,
            type: ml.type,
            value:ml.value,
            option:[],
            selectedOptiongDrop:'',
            valueLabel:''
          })  
         document.getElementById('loader').classList.add('d-block'); 
         fetch(process.env.REACT_APP_ENV+ml.api,{
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'bearer '+cookies.get("auth")
                
              },
              body: JSON.stringify({ 
             })
              })
            .then((response) => response.json())
            .then((responseJson) => {
              let tempData = responseJson;  
            //  console.log(tempData)   
              if(tempData){
                   // let exdate = new Date();
                   // cookies.set('auth', tempData.status.authtoken, { path: '/',expires: new Date(Date.now()+2592000) }); 
                  // console.log(tempData.replace(/'/g,"'");
                  // let optionsold = tempData.data;
                  // let attachoption = [{'value':'','label':'----'}];
                  // let options = [attachoption, ...optionsold]
                  let options = tempData.data;
                   let gindex = index;
                   let val = ml.value;
                   console.log(options)
                   this.state.metalist[gindex].option = options;
                  this.state.metalist[gindex].selectedOptiongDrop = options.filter(option => option.value === val);
                  let valulabel = options.filter(option => option.value === val);
                  this.state.metalist[gindex].valueLabel = valulabel[0].label;
                   this.setState({
                    metalist:this.state.metalist
                   })
                                  
                   document.getElementById('loader').classList.remove('d-block'); 
              }else{
                   toast.error('Error ocured. Please Try Again');
                   document.getElementById('loader').classList.remove('d-block'); 
              }
               
             
            })
            .catch(error =>{
              console.log(error); 
              document.getElementById('loader').classList.remove('d-block'); 
            }) 
         
        }
        
      })}
         this.state.metalist = ArrMetaList;
         this.setState({
          metalist:this.state.metalist 
           })
         this.toggleDepartment()
        } 
      editgroupg = (e,id) => {
        const timestamp = new Date().getTime();
        const expireauth = timestamp + 1800000;
        const exdate =  new Date(expireauth);
         cookies.set('groupID', id, { path: '/'});  
         this.setState({
          isEditGroup:!this.state.isEditGroup
        })
          //this.props.history.push('/edit-group');
        }
        delGroup = (e,delid,ind) => {
         console.log(delid);
         fetch(process.env.REACT_APP_ENV+'/api/TagV2/DeleteTag',{
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'bearer '+cookies.get("auth")
            
          },
          body: JSON.stringify({ 
             Id : delid
         })
          })
        .then((response) => response.json())
        .then((responseJson) => {
          let tempData = responseJson; 
          console.log(tempData)   
          if(tempData.status.statusMessage === 'Success'){
             
               toast.success('Successfully Deleted Tag.');
               const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
               const taglisted = this.state.taglist.filter(taglists => taglists.id !== delid)
             this.setState({taglist:taglisted})
          }else{
               toast.error('Error ocured. Please Try Again')
          }
         document.getElementById('loader').classList.remove('d-block'); 
         
        })
        .catch(error =>{
          console.log(error); 
        })
          
      }
      addGroup =()=>{
        this.setState({
          isAddGroup:!this.state.isAddGroup
        })
      }
      closeAddGroup =(status)=>{
        this.setState({
          isAddGroup:!this.state.isAddGroup
        })
        if(status){       
        this.getGroupList() 
      }
      }
      closeEditGroup =(status)=>{
        this.setState({
          isEditGroup:!this.state.isEditGroup
        })
        if(status){   
          this.getGroupList() 
        }
        
      }
    render(){
      let grouplists = [];
      if(this.state.grouplist){
        this.state.grouplist.forEach ((s,index) => {   
          //  console.log(s.companyName)
          let cat = s.category.toString();
          let lang = s.langId;    
          let langindex = this.state.langlist.findIndex(tagkey => tagkey.value === lang);           
         // console.log(dtindex)
          let type = '';
          let language = '';
         
          if(langindex !== -1){
            language = this.state.langlist[langindex].label;
          }
          //let type = this.state.documnent_type[dtindex].label;
          let cdate = s.created_date.split("T")[0];
let latestcdate = cdate.split('-');
let formatedCdate = latestcdate[1]+'-'+latestcdate[2]+'-'+latestcdate[0];
let mdate = s.modified_date.split("T")[0];
let latestmdate = mdate.split('-');
let formatedMdate = latestmdate[1]+'-'+latestmdate[2]+'-'+latestmdate[0];
          // let getDocTypeName = '';
          // if(s.category > 0){
          //   let dTindex = this.state.docType.findIndex(docTypes => docTypes.id === s.category);
          //   getDocTypeName = this.state.docType[dTindex].doctype_name;
          // }
          
         
          grouplists.push(
            <Row key={index}>
              <Cell>
                <button onClick={(e) => this.editgroupg(e,s.id)}  type="button" className="btn btn-primary btn-sm mr-2"><i className="fas fa-pencil-alt"></i> Update</button>
             <button onClick={(e) => this.delGroup(e,s.id,index)}  type="button" className="btn btn-danger btn-sm d-none"><i className="fas fa-trash-alt"></i></button>
                </Cell>
                <Cell>{s.groupname} 
                 <span onClick={(e)=>this.getGroupMetaInfo(e,s.id,s.groupname)} className="tools openinfo text-primary cursor-pointer ml-2"><i className="fas fa-info-circle"></i></span>
                
                 </Cell>
                {/* <Cell>{getDocTypeName}</Cell> */}
                {/* <Cell>{s.mfgId}</Cell> */}
                {/* <Cell>{language}</Cell> */}
                <Cell>
                  <div>{formatedCdate}</div>
                  <div>{s.created_by_name}</div>
                  </Cell>
                  <Cell>
                  <div>{mdate !== '0001-01-01' ?formatedMdate:formatedCdate}</div>
                  <div>{s.modified_by_name.trim() !== '' ? s.modified_by_name:s.created_by_name}</div>
                  </Cell>
                
              </Row>
                     
             )
          })
      }
      let groupListMeta = [];
this.state.metalist.forEach ((gl,index) => {
  let label = gl.label.replace("Search", "").replace("Select", "");
	groupListMeta.push(
		<div key={index} className=''> 
                 <label><b>{label} :</b> {gl.valueLabel}</label>
    </div>
	)
	
})
        return(
         <div className='h100percent'> 
         <div className='main-header'>
         <div className='row'>
             <div className='col-md-6'><h2 className="page-header"><a onClick={this.goBack}   className="back-button cpointer" >
                <i className="fas fa-arrow-circle-left"></i></a> Group Management</h2></div>
             <div className='col-md-6 text-right'>             
             
               </div>
           </div>
           </div>
         <div className='main-panel'>
            <div className='content '>
            <ToastContainer />
            <div className='container-fluid h100percent'>
            <div className='h100percent text-selection-none bg-white  round-corner-all'>
            <div className='row '>
            <div className='col-md-3' >
        <div className="form-group ">
                 <div >
                 <label>Document Type</label>
         <Select    
         className ='orgindex'            
        value={this.state.docTypeDropSelected}
        onChange={this.updatedocTypeDrop}
        options={this.state.docTypeDrop}
        defaultValue={{ value: '', label: '----' }}
      />
                 </div>
                 </div> 
               </div>
     
            {/* <div className='col-md-3'> 
            <label >Sort By   </label>
            <select 
                onChange={this.onChangeFilter}
                name='selectFilter'
                value={this.state.selectFilter}
                  className="form-control">
                    <option value=''>------</option>
                    <option value='groupname asc'>Group Name (ASC)</option>
                    <option value='groupname desc'>Group Name (DESC)</option>
                </select>
              </div> */}
              
     
     <div className='col-md-9 text-right'>   
     <div><label className="vhidden">Tools</label></div>          
             <button  className='btn btn-primary '  title='Add Group' onClick={this.addGroup}  >Add Group</button>
               </div>
               </div>
     <div className='row manudetailheight'>
   
            <div className="col-md-12  h-100">
   
       <div style={{width: '100%', height: '100%'}}>
         {grouplists.length > 0 && 
          <StickyTable leftStickyColumnCount={0} >
            <Row>
            <Cell  className='trainfile-tool-width'></Cell>
              <Cell>
              <span onClick={(e)=>this.sortGroupName(e,'groupname')} className='cursor-pointer'>Group Name <i className="fas fa-sort"></i></span>
                </Cell>
              {/* <Cell>Document Type</Cell> */}
              {/* <Cell>Manufacturer</Cell> */}
              {/* <Cell>Language</Cell> */}
              <Cell>Created</Cell>
              <Cell>Updated</Cell>
              
            </Row>
            {grouplists}
            
          </StickyTable>
        }
   </div>

</div>

    </div>   
</div>
</div>
</div>
</div>  
{this.state.isAddGroup &&
  <div className="modal fade show d-block"     >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
          
            <div className="modal-header px-4 d-block">
            
              
               <div className="row">
              	<div className="col-md-10">
              		 <h4 className="modal-title" ><a onClick={(e)=>this.closeAddGroup(false)}   className="back-button cpointer" >
                <i className="fas fa-arrow-circle-left"></i></a> Add Group</h4>
              	</div>
              	<div className="col-md-2 text-right">
                <button type="button"  className="close" onClick={(e)=>this.closeAddGroup(false)}   >
          <span aria-hidden="true">&times;</span>
        </button>
              	</div>
              </div>
            
            </div>
            
<Addgroup  closeAddGroup={this.closeAddGroup}/>
</div>
        
        </div>
      </div>
}
{this.state.isEditGroup &&
  <div className="modal fade show d-block"     >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header px-4 d-block">
            
              
               <div className="row">
              	<div className="col-md-10">
              		 <h4 className="modal-title" ><a onClick={(e)=>this.closeEditGroup(false)}   className="back-button cpointer" >
                <i className="fas fa-arrow-circle-left"></i></a> Update Group</h4>
              	</div>
              	<div className="col-md-2 text-right">
                <button type="button"  className="close" onClick={(e)=>this.closeEditGroup(false)}  >
          <span aria-hidden="true">&times;</span>
        </button>
              	</div>
              </div>
            
            </div>
<Editgroup closeEditGroup={this.closeEditGroup} />
</div>
        
        </div>
      </div>
}
<Modal isOpen={this.state.modalDepartment} centered={true} toggle={this.toggleDepartment} className="modal modalViewGroup">
        <ModalHeader toggle={this.toggleDepartment}>Group Information</ModalHeader>
        <ModalBody>
          <div><label><strong>Group Name</strong> : {this.state.modalGroupTitle}</label></div>
      {groupListMeta}
       
        
        </ModalBody>
       
        
      </Modal> 
</div>
        )
    }
}
export default viewgroup