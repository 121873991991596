import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
class ClientDetails extends Component { 
  constructor(props) {
    super(props);

  }
  componentDidMount(){
   
  }
    goBack() {
        window.history.back();
      }
    render(){
        return(
            <div className="modal fade show d-block"     >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header px-4 d-block">
            
              
               <div className="row">
              	<div className="col-md-10">
              		 <h4 className="modal-title" ><a onClick={this.goBack}   className="back-button cpointer" >
                <i className="fas fa-arrow-circle-left"></i></a> Client Name - Details</h4>
              	</div>
              	<div className="col-md-2 text-right">
              	</div>
              </div>
            
            </div>
            <div className="modal-body p-4">
              
            
              <div className='row'>
              <div className='col-md-2'>
                  <div><h5>Client Name</h5></div>
                  <div>Client Name</div>
              </div>
             
              <div className='col-md-2'>
                  <div><h5>Company Name</h5></div>
                  <div>Company Name</div>
              </div>
              <div className='col-md-2'>
                  <div><h5>Country</h5></div>
                  <div>Country Name</div>
              </div>
              <div className='col-md-2'>
                  <div><h5>Address</h5></div>
                  <div>Address</div>
              </div>
              <div className='col-md-2'>
                  <div><h5>Email</h5></div>
                  <div>email@companyname.com</div>
              </div>
              <div className='col-md-2'>
                  <div><h5>Phone</h5></div>
                  <div>0987654321</div>
              </div>
              <div className='col-md-12'>
              <hr className='solid' />
              </div>
                <div className='col-md-12'>
                <table className="table bg-white mb-0 table-striped-cust">      
                <tbody>
            <tr>
               
               <th className='bg-primary text-white'></th>
               <th>Total</th>
               <th>File Upload</th>
               <th>Tagged File</th>
               <th>Train Model</th>
               <th>Publish Model</th>
               
            </tr>
        
        
            <tr>
            <th className='bg-primary text-white'>Storage</th>
               <td>80 GB</td>
               <td>20 GB</td>
               <td>20 GB</td>
               <td>20 GB</td>
               <td>20 GB</td> 
           
            </tr>
            <tr>
            <th className='bg-primary text-white'>Time</th>
               <td>20:00:00 Hour</td>
               <td>5:00:00 Hour</td>
               <td>5:00:00 Hour</td>
               <td>5:00:00 Hour</td>
               <td>5:00:00 Hour</td> 
            
            </tr>
            <tr>
            <th className='bg-primary text-white'>File</th>
               <td>2000</td>
               <td>500</td>
               <td>500</td>
               <td>500</td>
               <td>500</td> 
            
            </tr>
           
            
            
            </tbody> 
      </table>
                </div>
                
              </div>
             
             
              
           
</div>
                                  
       
   
          </div>
        
        </div>
      </div>
        )
    }
}
export default ClientDetails