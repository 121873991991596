import React, { Component } from 'react';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody,Tooltip, Dropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';
import {NavLink} from 'react-router-dom';
import Select from 'react-select';
import { CSVLink, CSVDownload } from "react-csv";
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import CreateTags from "./Create-Tags.jsx";
import EditTags from "./Edit-Tag.jsx";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const{REACT_APP_AUTH_EXPIRE} = process.env;

class TagManagement extends Component {   
     constructor(props) {
          super(props);
          this.state = {
               taglist:[],
               disableTaglist:[],
               docType:[],
               docTypeDrop:[],
               docTypeDropSelected:'',
               selectFilter:'section asc',
               companyList:[],
              ifCloudsds:false,
              searchKey:'',
              searchKeySend:'',
              upload:false,
              selectedFile: null,
              tagSortName:false,
              tagSortSection:true,
              tagSortType:false,
              tagSortDesc:false,
              selectFilterTag:'2',
              tooltipOpen:false,
              lastPublishedTaganomy:'',
              dropdownOpen:false,
              diableTagList:[],
              enableTagList:[], 
              cid:'',
              isAddTag:false,
              isEditTag:false,
              uID:0,
          };
          this.edittag = this.edittag.bind(this);
          this.delTag = this.delTag.bind(this)
          this.updateValueCompany = this.updateValueCompany.bind(this);
          this.updatedocTypeDrop = this.updatedocTypeDrop.bind(this)
        }
        toggletooltip = (e) =>{
          this.setState({
            tooltipOpen: !this.state.tooltipOpen
          });
        }
        toggledropDown = () => {
          this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
          }));
        }
        updateValueCompany(newValue) {
          this.state.SelectedCompany = newValue
        this.setState({
          SelectedCompany: this.state.SelectedCompany
        });
        this.Getallusers();
      }
      sortTagName = (e,name) =>{ 
        if(this.state.tagSortName){
          this.state.tagSortName = !this.state.tagSortName;
          this.state.selectFilter = name+' '+'desc';
          this.setState({
            tagSortName:this.state.tagSortName,
            selectFilter:this.state.selectFilter,
            taglist:[]
          })
          this.getTagList() 
        }else{
          this.state.tagSortName = !this.state.tagSortName;
          this.state.selectFilter = name+' '+'asc';
          this.setState({
            tagSortName:this.state.tagSortName,
            selectFilter:this.state.selectFilter,
            taglist:[]
          })
          this.getTagList() 
        }
      }
      sortTagSection = (e,name) =>{ 
        if(this.state.tagSortSection){
          this.state.tagSortSection = !this.state.tagSortSection;
          this.state.selectFilter = name+' '+'desc';
          this.setState({
            tagSortSection:this.state.tagSortSection,
            selectFilter:this.state.selectFilter,
            taglist:[]
          })
          this.getTagList() 
        }else{
          this.state.tagSortSection = !this.state.tagSortSection;
          this.state.selectFilter = name+' '+'asc';
          this.setState({
            tagSortSection:this.state.tagSortSection,
            selectFilter:this.state.selectFilter,
            taglist:[]
          })
          this.getTagList() 
        }
      }
      sortTagType = (e,name) =>{ 
        if(this.state.tagSortType){
          this.state.tagSortType = !this.state.tagSortType;
          this.state.selectFilter = name+' '+'desc';
          this.setState({
            tagSortType:this.state.tagSortType,
            selectFilter:this.state.selectFilter,
            taglist:[]
          })
          this.getTagList() 
        }else{
          this.state.tagSortType = !this.state.tagSortType;
          this.state.selectFilter = name+' '+'asc';
          this.setState({
            tagSortType:this.state.tagSortType,
            selectFilter:this.state.selectFilter,
            taglist:[]
          })
          this.getTagList() 
        }
      }
      sortTagDesc = (e,name) =>{ 
        if(this.state.tagSortDesc){
          this.state.tagSortDesc = !this.state.tagSortDesc;
          this.state.selectFilter = name+' '+'desc';
          this.setState({
            tagSortDesc:this.state.tagSortDesc,
            selectFilter:this.state.selectFilter,
            taglist:[]
          })
          this.getTagList() 
        }else{
          this.state.tagSortDesc = !this.state.tagSortDesc;
          this.state.selectFilter = name+' '+'asc';
          this.setState({
            tagSortDesc:this.state.tagSortDesc,
            selectFilter:this.state.selectFilter,
            taglist:[]
          })
          this.getTagList() 
        }
      }
      GetPublishedTaxonomyDate = () =>{
        fetch(process.env.REACT_APP_ENV+'/api/TagV2/GetPublishedTaxonomyDate',{
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'bearer '+cookies.get("auth")
            
          },
          body: JSON.stringify({ 
          category:this.state.docTypeDropSelected.value,
          cid:cookies.get("cid")
         })
          })
        .then((response) => response.json())
        .then((responseJson) => {
          let tempData = responseJson; 
          if(tempData.status.statusMessage === 'Success'){
               let exdate = new Date();

               cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
               if(tempData.data.length > 0){
                let cdate = tempData.data[0].modified_date.split('T')[0];
                let latestcdate = cdate.split('-');
                let formatedCdate = latestcdate[1]+'-'+latestcdate[2]+'-'+latestcdate[0];
                 this.state.lastPublishedTaganomy =  formatedCdate;               
                 this.setState({
                   lastPublishedTaganomy:this.state.lastPublishedTaganomy
                 })
               }
               
            
          }else{
               toast.error('Error ocured. Please Try Again');
              
          }
         
         
        })
        .catch(error =>{
          console.log(error); 
        }) 
      }
      updatedocTypeDrop(newValue){
        this.state.docTypeDropSelected = newValue
        this.state.lastPublishedTaganomy = '';
        this.setState({
          docTypeDropSelected: this.state.docTypeDropSelected,
          lastPublishedTaganomy:this.state.lastPublishedTaganomy
        });        
        this.GetPublishedTaxonomyDate()
        this.getTagList();
        
        const timestamp = new Date().getTime();
        const expireauth = timestamp + 1800000;
        const exdate =  new Date(expireauth);
        cookies.set('TMDOC', newValue.value, { path: '/'}); 
      }
        componentDidMount() {   
         let gettagfilter = cookies.get('tagFilter');
         this.state.cid = cookies.get('cid');
         if(this.state.cid === '1'){
          gettagfilter = '1'
        }
         if(gettagfilter){          
          this.setState({
            selectFilterTag: gettagfilter,
            cid:this.state.cid
          })
         }else{
          this.setState({
            cid:this.state.cid
          })
         }
         
          document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocumentTypeList',{
               method: 'POST',
               headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 'Authorization':'bearer '+cookies.get("auth")
                 
               },
               body: JSON.stringify({ 
               
              })
               })
             .then((response) => response.json())
             .then((responseJson) => {
               let tempData = responseJson;  
             //  console.log(tempData)   
               if(tempData.status.statusMessage === 'Success'){
                    let exdate = new Date();
                    cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
                    this.state.docType =  tempData.data;
                    let storedata = [];
                    for (var i = 0; i < tempData.data.length; i++) {
                                storedata.push({
                                  value: tempData.data[i].id, 
                                  label:  tempData.data[i].doctype_name,
                                })
                               
                                }
                    this.setState({
                      docType:this.state.docType,
                      docTypeDrop:storedata
                    })
                    if(cookies.get("TMDOC")){
                      let getdoc = cookies.get("TMDOC");
                     // alert(getdoc);
                      let index =  this.state.docTypeDrop.findIndex(docType => docType.value === parseInt(getdoc)); 
                      let selecteddoc = this.state.docTypeDrop.filter(docType => docType.value == parseInt(getdoc));                     
                      this.state.docTypeDropSelected = this.state.docTypeDrop[index];      
                    //  console.log(this.state.docTypeDropSelected)
                     // console.log(this.state.docTypeDropSelected.value)                
                      this.setState({
                        docTypeDropSelected:this.state.docTypeDropSelected
                      })
                      if(this.state.docTypeDropSelected.value !==''){
                        this.GetPublishedTaxonomyDate()
                      }
                      
                      
                    }
                    document.getElementById('loader').classList.remove('d-block'); 
                      this.getTagList()        
                   
                    
                 
               }else{
                    toast.error('Error ocured. Please Try Again');
                    document.getElementById('loader').classList.remove('d-block'); 
               }
              
              
             })
             .catch(error =>{
               console.log(error); 
             })      
        
        
      }
      ShowImportTag = (e) =>{
        this.setState({
          upload: !this.state.upload
        });
        if(this.state.upload){
          let selval = document.getElementById('selectedFile').value; this.state.selectedFile
          if(selval){
            document.getElementById('selectedFile').value = null;
            this.state.selectedFile = null;
            this.setState({
              selectedFile: this.state.selectedFile
            });
          }
        }
        
        
      }
      searchTag = () =>{
        this.getTagList()
      }
      resetSearchTag = () =>{
        this.state.searchKey = '';
        this.setState({
          searchKey:this.state.searchKey
        })
        this.getTagList()
      }
      getTagList= (e) =>{
        this.setState({
          taglist:[],
          disableTaglist:[]
        })
        let catid = '';
        if(this.state.docTypeDropSelected != undefined &&  this.state.docTypeDropSelected != null 
            &&  this.state.docTypeDropSelected.value != ""){
          catid = this.state.docTypeDropSelected.value
        }
        if(catid){
          if(this.state.selectFilterTag !== '3'){
            document.getElementById('loader').classList.add('d-block'); 
            fetch(process.env.REACT_APP_ENV+'/api/TagV2/GetTagKeyValList',{
               method: 'POST',
               headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 'Authorization':'bearer '+cookies.get("auth")
                 
               },
               body: JSON.stringify({              
                 sortColumn:this.state.selectFilter,
                 category:catid,
                 val:this.state.searchKey.trim(),
                 status:1
                })
               })
             .then((response) => response.json())
             .then((responseJson) => {
               let tempData = responseJson; 
              // console.log(tempData)   
                if(tempData.status.statusMessage === 'Success'){ 
                  if(tempData.data.length < 1){   
                    toast.success('No record found')
                  }    
                 
                    const timestamp = new Date().getTime();
                    const expireauth = timestamp + 1800000;
                    const exdate =  new Date(expireauth);
                    cookies.set('auth', tempData.status.authtoken, { path: '/'});  
                        this.setState({
                           taglist: tempData.data,
                       })
                    
                 
                  
                  }else{
                     toast.error('Error ocured. Please Try Again')
                }
              document.getElementById('loader').classList.remove('d-block'); 
              
             })
             .catch(error =>{
              toast.error(error)
             })
          }else{
            document.getElementById('loader').classList.add('d-block'); 
        fetch(process.env.REACT_APP_ENV+'/api/TagV2/GetTagKeyValList',{
           method: 'POST',
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'Authorization':'bearer '+cookies.get("auth")
             
           },
           body: JSON.stringify({              
             sortColumn:this.state.selectFilter,
             category:catid,
             val:this.state.searchKey.trim(),
             status:2
            })
           })
         .then((response) => response.json())
         .then((responseJson) => {
           let tempData = responseJson; 
          // console.log(tempData)   
            if(tempData.status.statusMessage === 'Success'){ 
              if(tempData.data.length < 1){   
                toast.success('No record found')
              }    
             
                const timestamp = new Date().getTime();
                const expireauth = timestamp + 1800000;
                const exdate =  new Date(expireauth);
                cookies.set('auth', tempData.status.authtoken, { path: '/'});  
                    this.setState({
                      disableTaglist: tempData.data,
                   })
                console.log(this.state.disableTaglist)
             
              
              }else{
                 toast.error('Error ocured. Please Try Again')
            }
          document.getElementById('loader').classList.remove('d-block'); 
           
         })
         .catch(error =>{
          toast.error(error)
         })
          }
         
         
        }else{
          this.setState({
            taglist: [],
            disableTaglist:[]
        })
        }
      }
     
        onChange = (e) => {
          this.setState({[e.target.name]: e.target.value})
        }
        onChangeFilter = (e) => {
          this.state.selectFilter = e.target.value
          this.setState({
            selectFilter: this.state.selectFilter,
            taglist:[]
          })
          this.getTagList()
        }
        onChangeFilterTag = (e) => {
          this.state.selectFilterTag = e.target.value
          this.state.searchKey = '';
          this.setState({
            selectFilterTag: this.state.selectFilterTag,
            searchKey:this.state.searchKey
          })
          this.getTagList()
          cookies.set('tagFilter', this.state.selectFilterTag, { path: '/'}); 
        }
        onSubmit = (e) => {
          e.preventDefault();
          const { tgname, tgdesc} = this.state;
           this.setState({
                tgname: '',
                tgdesc: ''
          });
          
        }
        edittag = (e,id) => {
          const timestamp = new Date().getTime();
          const expireauth = timestamp + 1800000;
          const exdate =  new Date(expireauth);
         cookies.set('tagID', id, { path: '/'});  
         cookies.set('tagCategory', this.state.docTypeDropSelected.value, { path: '/'});
         // this.props.history.push('/edit-tag');
         this.state.uID = id;
         this.setState({
           uID:this.state.uID,
          isEditTag:!this.state.isEditTag
        })
              
            
        }
        delTag = (e,delid,ind) => {
        console.log(delid);
        fetch(process.env.REACT_APP_ENV+'/api/TagV2/DeleteTag',{
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'Authorization':'bearer '+cookies.get("auth")
           
         },
         body: JSON.stringify({ 
            Id : delid
        })
         })
       .then((response) => response.json())
       .then((responseJson) => {
         let tempData = responseJson; 
         console.log(tempData)   
         if(tempData.status.statusMessage === 'Success'){            
              toast.success('Successfully Deleted Tag.');
              const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', tempData.status.authtoken, { path: '/'});   
              const taglisted = this.state.taglist.filter(taglists => taglists.id !== delid)
            this.setState({taglist:taglisted})
         }else{
              toast.error('Error ocured. Please Try Again')
         }
        document.getElementById('loader').classList.remove('d-block'); 
        
       })
       .catch(error =>{
         console.log(error); 
       })
         
     }
     checkMimeType=(event)=>{
    
      //getting file object
      let files = event.target.files;
      //define message container
      let err = []
      // list allow mime type
    // const types = ['application/excel','application/x-msexcel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
    const types = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      // loop access array
      for(var x = 0; x<files.length; x++) {   
       // compare file type find doesn't matach
           if (types.every(type => files[x].type !== type)) {
           // create error message and assign to container   
           err[x] = files[x].type+' is not a supported format\n';
         }
       };
       for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
           // discard selected file
          toast.error(err[z])
          event.target.value = null
      }
     return true;
    }
    maxSelectFile=(event)=>{
      let files = event.target.files;
          if (files.length > 10) { 
             const msg = 'Only 10 file can be uploaded at a time'
             event.target.value = null
             toast.warn(msg)
             return false;
        }
      return true;
   }
   checkFileSize=(event)=>{
    let files = event.target.files;
    let size = 500000000 
    let err = []; 
    for(var x = 0; x<files.length; x++) {
    if (files[x].size > size) {
     err[x] = files[x].type+'is too large, please pick a smaller file\n';
   }
  };
  for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
    // discard selected file
   toast.error(err[z])
   event.target.value = null
  }
  return true;
  }
  onChangeHandler=event=>{
    var files = event.target.files
    if(this.maxSelectFile(event) && this.checkMimeType(event) &&    this.checkFileSize(event)){ 
    // if return true allow to setState
    this.state.selectedFile = files
       this.setState({
       selectedFile: this.state.selectedFile,
    })
  } 
  //console.log(this.state.selectedFile)
  } 
     onClickHandlerg = () => {         
       if(this.state.docTypeDropSelected.value){
        if(this.state.selectedFile.length > 0){
          for(var x = 0; x<this.state.selectedFile.length; x++) {
            document.getElementById("loader").classList.add("d-block");
            const formData = new FormData();
             formData.append('document_type', parseInt(this.state.docTypeDropSelected.value))
             formData.append('file', this.state.selectedFile[x])
                 fetch(process.env.REACT_APP_ENV+'/api/TagV2/UploadExcel', {  
                headers: {
                       'Authorization':'bearer '+cookies.get("auth")						   
                     },     
           method: 'POST',
          body: formData
        })
        .then((response) => response.json())
        .then((responseJson) => {
          toast.success('Upload success');
          document.getElementById("loader").classList.remove("d-block");
          this.setState({
          selectedFile: null
         })
         document.getElementById('selectedFile').value = null;
         console.log(responseJson);
        })
        .catch(error =>{
          console.log(error);
          toast.error('upload fail');
          document.getElementById("loader").classList.remove("d-block");
          this.setState({
          selectedFile: null,
         })
         document.getElementById('selectedFile').value = null;
        })
            //  for (var value of formData.values()) {
            //   console.log(value); 
            //  }
             this.getTagList()
             }
        }
       }else{
        toast.error('Please select document type.');
      }
          
   
        
        
   
      
        
    //	return false;
    
    
      
        } 

        publishTaxonomy = () =>{
          document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/TagV2/PublishTaxonomy',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization':'bearer '+cookies.get("auth")
            },
            body: JSON.stringify({ 
              category : this.state.docTypeDropSelected.value,
              status:1,
              sortColumn:"section asc"
           })
            })
          .then((response) => response.json())
          .then((responseJson) => {
            let tempData = responseJson; 
            console.log(tempData)   
            if(tempData.status.statusMessage === 'Success'){            
                 toast.success('Successfully Published Taxonomy.');
                 const timestamp = new Date().getTime();
                 const expireauth = timestamp + 1800000;
                 const exdate =  new Date(expireauth);
                 cookies.set('auth', tempData.status.authtoken, { path: '/'});   
                
              
            }else{
                 toast.error('Error ocured. Please Try Again')
            }
           document.getElementById('loader').classList.remove('d-block'); 
           
          })
          .catch(error =>{
            console.log(error); 
            document.getElementById('loader').classList.remove('d-block'); 
          })
        }  
        disbaleTag = (e,id,index)=>{
          document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/TagV2/UpdateTag',{
               method: 'POST',
               headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 'Authorization':'bearer '+cookies.get("auth")
                 
               },
               body: JSON.stringify({              
                         id : this.state.taglist[index].id,
                         type : this.state.taglist[index].type,
                         val :  this.state.taglist[index].val,
                         granular : this.state.taglist[index].granular,
                         desc : this.state.taglist[index].desc,
                         section : this.state.taglist[index].section,
                         subsection : this.state.taglist[index].subsection, 
                         note : this.state.taglist[index].note,
                         cid : this.state.taglist[index].cid,
                         category : this.state.taglist[index].category, 
                         created_by : this.state.taglist[index].created_by,
                         created_date : this.state.taglist[index].created_date,
                         status : 	2,
                         tag_common_name:this.state.taglist[index].tag_common_name
              })
               })
             .then((response) => response.json())
             .then((responseJson) => {
               let tempData = responseJson; 
               //console.log(tempData.data[0].id)   
                if(tempData.status.statusMessage === 'Success'){
                    const timestamp = new Date().getTime();
                    const expireauth = timestamp + 1800000;
                    const exdate =  new Date(expireauth);
                    cookies.set('auth', tempData.status.authtoken, { path: '/'});   
                     toast.success('Successfully disabled Tag.');
                     let tagkeyList = this.state.taglist;
                     let disablelist = this.state.diableTagList
                     this.state.taglist = tagkeyList.filter(item => item.id !== id);
                     this.state.diableTagList = disablelist.filter(item => item !== index);
                     this.setState({
                       taglist:this.state.taglist,
                       diableTagList:this.state.diableTagList
                      }) 
                    console.log(this.state.diableTagList)
                    document.getElementById('loader').classList.remove('d-block'); 
                  }else{
                     toast.error('Error ocured. Please Try Again');
                     document.getElementById('loader').classList.remove('d-block'); 
                }
             
              
             })
             .catch(error =>{
               console.log(error); 
             })
        }
          
        disbaleTagAll = (e) =>{
          if(this.state.diableTagList.length > 0){
            document.getElementById('loader').classList.add('d-block'); 
            
            let tagkeyListFinal = this.state.diableTagList;
            this.state.diableTagList.forEach ((valIndex, key, arr) => {
             
              fetch(process.env.REACT_APP_ENV+'/api/TagV2/UpdateTag',{
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization':'bearer '+cookies.get("auth")
                  
                },
                body: JSON.stringify({              
                          id : this.state.taglist[valIndex].id,
                          type : this.state.taglist[valIndex].type,
                          val :  this.state.taglist[valIndex].val,
                          granular : this.state.taglist[valIndex].granular,
                          desc : this.state.taglist[valIndex].desc,
                          section : this.state.taglist[valIndex].section,
                          subsection : this.state.taglist[valIndex].subsection, 
                          note : this.state.taglist[valIndex].note,
                          cid : this.state.taglist[valIndex].cid,
                          category : this.state.taglist[valIndex].category, 
                          created_by : this.state.taglist[valIndex].created_by,
                          created_date : this.state.taglist[valIndex].created_date,
                          status : 	2,
                          tag_common_name:this.state.taglist[valIndex].tag_common_name
               })
                })
              .then((response) => response.json())
              .then((responseJson) => {
                let tempData = responseJson; 
                //console.log(tempData.data[0].id)   
                 if(tempData.status.statusMessage === 'Success'){
                     const timestamp = new Date().getTime();
                     const expireauth = timestamp + 1800000;
                     const exdate =  new Date(expireauth);
                     cookies.set('auth', tempData.status.authtoken, { path: '/'});   
                      document.getElementById('disbale-tag_'+valIndex).checked = false;
                      
                      
                       if (Object.is(arr.length - 1, key)) {
                        toast.success('Successfully disabled Tag.');
                        document.getElementById('loader').classList.remove('d-block'); 
                        let tagkeyListUpdate = [];
                        let tagkeyList = this.state.taglist; 
                        for (var i = tagkeyListFinal.length -1; i >= 0; i--) {
                          tagkeyList.splice(tagkeyListFinal[i], 1); 
                        }
                
                        this.state.taglist = tagkeyList;
                        this.setState({
                          taglist:this.state.taglist,
                          diableTagList:[]
                         }) 
                       
                      }
                     
                   }else{
                      toast.error('Error ocured. Please Try Again');
                      document.getElementById('loader').classList.remove('d-block'); 
                 }
              
               
              })
              .catch(error =>{
                console.log(error); 
              })
              this.setState({
                taglist:this.state.taglist,
               }) 
              
            })
            
          }else{
            toast.error('Please check the tags to disable')
          }
        }
        disbaleTagAdd   = (e,id,index)=>{
          let dTag = this.state.diableTagList;
          if (e.target.checked == true){
            dTag.push(index)
          }else{
            for( var i = 0; i < dTag.length; i++){ 
              if ( dTag[i] === index) { 
                dTag.splice(i, 1); 
              }
          }
          }
          this.state.diableTagList = dTag;
          this.setState({diableTagList:this.state.diableTagList}) 
          console.log(this.state.diableTagList)
        }  

        enableTag = (e,id,index)=>{
          document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/TagV2/UpdateTag',{
               method: 'POST',
               headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 'Authorization':'bearer '+cookies.get("auth")
                 
               },
               body: JSON.stringify({              
                         id : this.state.disableTaglist[index].id,
                         type : this.state.disableTaglist[index].type,
                         val :  this.state.disableTaglist[index].val,
                         granular : this.state.disableTaglist[index].granular,
                         desc : this.state.disableTaglist[index].desc,
                         section : this.state.disableTaglist[index].section,
                         subsection : this.state.disableTaglist[index].subsection, 
                         note : this.state.disableTaglist[index].note,
                         cid : this.state.disableTaglist[index].cid,
                         category : this.state.disableTaglist[index].category, 
                         created_by : this.state.disableTaglist[index].created_by,
                         created_date : this.state.disableTaglist[index].created_date,
                         status : 	1,
                         tag_common_name:this.state.disableTaglist[index].tag_common_name
              })
               })
             .then((response) => response.json())
             .then((responseJson) => {
               let tempData = responseJson; 
               //console.log(tempData.data[0].id)   
                if(tempData.status.statusMessage === 'Success'){
                    const timestamp = new Date().getTime();
                    const expireauth = timestamp + 1800000;
                    const exdate =  new Date(expireauth);
                    cookies.set('auth', tempData.status.authtoken, { path: '/'});   
                     toast.success('Successfully disabled Tag.');
                     let tagkeyList = this.state.disableTaglist;
                     let disablelist = this.state.enableTagList
                     this.state.disableTaglist = tagkeyList.filter(item => item.id !== id);
                     this.state.enableTagList = disablelist.filter(item => item !== index);
                     this.setState({
                      disableTaglist:this.state.disableTaglist,
                      enableTagList:this.state.enableTagList
                      }) 
                    console.log(this.state.enableTagList)
                    document.getElementById('loader').classList.remove('d-block'); 
                  }else{
                     toast.error('Error ocured. Please Try Again');
                     document.getElementById('loader').classList.remove('d-block'); 
                }
             
              
             })
             .catch(error =>{
               console.log(error); 
             })
        }
          
        enableTagAll = (e) =>{
          if(this.state.enableTagList.length > 0){
            document.getElementById('loader').classList.add('d-block'); 
            
            let tagkeyListFinal = this.state.enableTagList;
            this.state.enableTagList.forEach ((valIndex, key, arr) => {
             
              fetch(process.env.REACT_APP_ENV+'/api/TagV2/UpdateTag',{
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization':'bearer '+cookies.get("auth")
                  
                },
                body: JSON.stringify({              
                          id : this.state.disableTaglist[valIndex].id,
                          type : this.state.disableTaglist[valIndex].type,
                          val :  this.state.disableTaglist[valIndex].val,
                          granular : this.state.disableTaglist[valIndex].granular,
                          desc : this.state.disableTaglist[valIndex].desc,
                          section : this.state.disableTaglist[valIndex].section,
                          subsection : this.state.disableTaglist[valIndex].subsection, 
                          note : this.state.disableTaglist[valIndex].note,
                          cid : this.state.disableTaglist[valIndex].cid,
                          category : this.state.disableTaglist[valIndex].category, 
                          created_by : this.state.disableTaglist[valIndex].created_by,
                          created_date : this.state.disableTaglist[valIndex].created_date,
                          status : 	1,
                          tag_common_name:this.state.disableTaglist[valIndex].tag_common_name
               })
                })
              .then((response) => response.json())
              .then((responseJson) => {
                let tempData = responseJson; 
                //console.log(tempData.data[0].id)   
                 if(tempData.status.statusMessage === 'Success'){
                     const timestamp = new Date().getTime();
                     const expireauth = timestamp + 1800000;
                     const exdate =  new Date(expireauth);
                     cookies.set('auth', tempData.status.authtoken, { path: '/'});   
                      document.getElementById('enable-tag_'+valIndex).checked = false;
                      
                      
                       if (Object.is(arr.length - 1, key)) {
                        toast.success('Successfully disabled Tag.');
                        document.getElementById('loader').classList.remove('d-block'); 
                        let tagkeyListUpdate = [];
                        let tagkeyList = this.state.disableTaglist; 
                        for (var i = tagkeyListFinal.length -1; i >= 0; i--) {
                          tagkeyList.splice(tagkeyListFinal[i], 1); 
                        }
                
                        this.state.disableTaglist = tagkeyList;
                        this.setState({
                          disableTaglist:this.state.disableTaglist,
                          enableTagList:[]
                         }) 
                       
                      }
                     
                   }else{
                      toast.error('Error ocured. Please Try Again');
                      document.getElementById('loader').classList.remove('d-block'); 
                 }
              
               
              })
              .catch(error =>{
                console.log(error); 
              })
              this.setState({
                taglist:this.state.taglist,
               }) 
              
            })
            
          }else{
            toast.error('Please check the tags to disable')
          }
        }
        enableTagAdd   = (e,id,index)=>{
          let dTag = this.state.enableTagList;
          if (e.target.checked == true){
            dTag.push(index)
          }else{
            for( var i = 0; i < dTag.length; i++){ 
              if ( dTag[i] === index) { 
                dTag.splice(i, 1); 
              }
          }
          }
          this.state.enableTagList = dTag;
          this.setState({enableTagList:this.state.enableTagList}) 
          console.log(this.state.enableTagList)
        } 
        addTag =() =>{
          this.setState({
            isAddTag:!this.state.isAddTag
          })
        }
        closeAddTag=(status) =>{
          this.setState({
            isAddTag:!this.state.isAddTag
          })
          if(status){
            this.getTagList()
          }
         
        }
        closeEditTag=(status) =>{
          this.setState({
            isEditTag:!this.state.isEditTag
          })
          if(status){
            this.getTagList()
          }
        }
     render() {
      
      const headers = [
        { label: "\"Tag Name\"", key: "val" },
        { label: "Tag Type", key: "type" },
        { label: "Tag Description", key: "desc" },
        { label: "Human Readable", key: "tag_common_name" }
      ];
      const csvData = [];
      this.state.taglist.forEach ((s,index) => { 
        let tagname = s.val;
        let tagType = s.type;
        let description = s.desc;
        let humanReadable = s.tag_common_name;
        if(humanReadable === null){
          humanReadable = '';
        }
        if(tagType === 'V'){
          tagType = 'Value'
       }else{
        tagType = 'Key'
       }
        csvData.push({val:tagname,type:tagType,desc:description,tag_common_name:humanReadable})
       })
     //  console.log(csvData)
          const { tgname, tgdesc} = this.state;
          let tagLists = [];
          let disableTaglist = [];
          if(this.state.selectFilterTag === '3'){
            this.state.disableTaglist.forEach ((s,index) => {   
              //  console.log(s.companyName)
              var type = s.type;
              if(type === 'V'){
                 type = 'Value tag'
              }else if(type === 'K'){
                 type = 'Key tag'
              }else{
                type = 'Checkbox'
              }
              let cdate = s.created_date.split("T")[0];
              let latestcdate = cdate.split('-');
              let formatedCdate = latestcdate[1]+'-'+latestcdate[2]+'-'+latestcdate[0];
              let mdate = s.modified_date.split("T")[0];
              let latestmdate = mdate.split('-');
              let formatedMdate = latestmdate[1]+'-'+latestmdate[2]+'-'+latestmdate[0];
       
        
          tagLists.push(
              <Row key={index}>           
             
                {/* <Cell>
             <input id={'enable-tag_'+index} onChange={(e) => this.enableTagAdd(e,s.id,index)} name='Enable-tag' type='checkbox' />
             </Cell>
             <Cell>
             <button title='Enable' onClick={(e) => this.enableTag(e,s.id,index)}  type="button" className="btn btn-primary btn-sm"><i className="fas fa-toggle-on"></i></button>
              </Cell> */}
             
              <Cell>{s.val}</Cell>
              <Cell>{type}</Cell>
              <Cell className='descwidth colwidth'>{s.desc}</Cell>
              {/* <Cell>{getDocTypeName}</Cell> */}
              <Cell>{s.tag_common_name}</Cell>
              <Cell>
                <div>{formatedCdate}</div>
                <div>{s.created_by_name.trim()}</div>
                </Cell>
                <Cell>
                <div>{mdate !== '0001-01-01' ?formatedMdate:formatedCdate}</div>
                <div>{s.modified_by_name.trim() !== '' ? s.modified_by_name.trim():s.created_by_name.trim()}</div>
                </Cell>
              
            </Row>
                       
               )
       
         
      
              
              })
          }else{
            this.state.taglist.forEach ((s,index) => {   
              //  console.log(s.companyName)
              var type = s.type;
              if(type === 'V'){
                 type = 'Value tag'
              }else if(type === 'K'){
                 type = 'Key tag'
              }else{
                type = 'Checkbox'
              }
              let cdate = s.created_date.split("T")[0];
              let latestcdate = cdate.split('-');
              let formatedCdate = latestcdate[1]+'-'+latestcdate[2]+'-'+latestcdate[0];
              let mdate = s.modified_date.split("T")[0];
              let latestmdate = mdate.split('-');
              let formatedMdate = latestmdate[1]+'-'+latestmdate[2]+'-'+latestmdate[0];
        if(this.state.selectFilterTag === '2'){
          if(s.is_editable !== 1){
            tagLists.push(
              <Row key={index}> 
                {/* <Cell>
             <input id={'disbale-tag_'+index} onChange={(e) => this.disbaleTagAdd(e,s.id,index)} name='disbale-tag' type='checkbox' />
             </Cell>
           
             <Cell>
             <button title='Disable' onClick={(e) => this.disbaleTag(e,s.id,index)}  type="button" className="btn btn-danger btn-sm"><i className="fas  fa-toggle-off"></i></button>
              </Cell> */}
           {this.state.docTypeDropSelected.value === 1 &&
                  <Cell>{s.section}</Cell>
                }
                  {this.state.docTypeDropSelected.value === 1 &&
              <Cell>{s.subsection}</Cell>
                  }
              <Cell>{s.val}</Cell>
              <Cell>{type}</Cell>
              <Cell className='descwidth colwidth'>{s.desc}</Cell>
              {/* <Cell>{getDocTypeName}</Cell> */}
              <Cell>{s.tag_common_name}</Cell>
              
              <Cell>
                <div>{formatedCdate}</div>
                <div>{s.created_by_name.trim()}</div>
                </Cell>
                <Cell>
                <div>{mdate !== '0001-01-01' ?formatedMdate:formatedCdate}</div>
                <div>{s.modified_by_name.trim() !== '' ? s.modified_by_name.trim():s.created_by_name.trim()}</div>
                </Cell>
              
            </Row>
                       
               )
          }
         
        }else{
          
          
          if(this.state.cid === '1'){
            
            tagLists.push(
              <Row key={index}>
               
                <Cell>
              <button title='Update' onClick={(e) => this.edittag(e,s.id)}  type="button" className="btn btn-primary btn-sm mr-1"><i className="fas fa-pencil-alt"></i></button>
               <button title='Remove' onClick={(e) => this.delTag(e,s.id,index)}  type="button" className="btn btn-danger btn-sm"><i className="fas fa-trash-alt"></i></button>
              
              </Cell>
              {this.state.docTypeDropSelected.value === 1 &&
                  <Cell>{s.section}</Cell>
                }
                  {this.state.docTypeDropSelected.value === 1 &&
              <Cell>{s.subsection}</Cell>
                  }
             
              <Cell>{s.val}</Cell>
              <Cell>{type}</Cell>
              <Cell className='descwidth colwidth'>{s.desc}</Cell>
              {/* <Cell>{getDocTypeName}</Cell> */}
              <Cell>{s.tag_common_name}</Cell>
              
              <Cell>
                <div>{formatedCdate}</div>
                <div>{s.created_by_name.trim()}</div>
                </Cell>
                <Cell>
                <div>{mdate !== '0001-01-01' ?formatedMdate:formatedCdate}</div>
                <div>{s.modified_by_name.trim() !== '' ? s.modified_by_name.trim():s.created_by_name.trim()}</div>
                </Cell>
              
            </Row>
                       
               )
            }else{
              if(s.is_editable === 1){
                        
                tagLists.push(
                  <Row key={index}>
                   
                    <Cell>
                  <button title='Update' onClick={(e) => this.edittag(e,s.id)}  type="button" className="btn btn-primary btn-sm mr-1"><i className="fas fa-pencil-alt"></i></button>
                   <button title='Remove' onClick={(e) => this.delTag(e,s.id,index)}  type="button" className="btn btn-danger btn-sm"><i className="fas fa-trash-alt"></i></button>
                  
                  </Cell>
                  {this.state.docTypeDropSelected.value === 1 &&
                  <Cell>{s.section}</Cell>
                }
                  {this.state.docTypeDropSelected.value === 1 &&
              <Cell>{s.subsection}</Cell>
                  }
                 
                  <Cell>{s.val}</Cell>
                  <Cell>{type}</Cell>
                  <Cell className='descwidth colwidth'>{s.desc}</Cell>
                  {/* <Cell>{getDocTypeName}</Cell> */}
                  <Cell>{s.tag_common_name}</Cell>
                  
                  <Cell>
                    <div>{formatedCdate}</div>
                    <div>{s.created_by_name.trim()}</div>
                    </Cell>
                    <Cell>
                    <div>{mdate !== '0001-01-01' ?formatedMdate:formatedCdate}</div>
                    <div>{s.modified_by_name.trim() !== '' ? s.modified_by_name.trim():s.created_by_name.trim()}</div>
                    </Cell>
                  
                </Row>
                           
                   )
                }
            }
        }
              
              })
          }
         
           
  return (
     <div className='h100percent'>
     <div className='main-header'>
       
       <div className='row'>
         <div className='col-md-6'>
           <h2 className="page-header">Tag Management</h2>
           </div>
         <div className='col-md-6 text-right'>
              
              
              </div>
        
       </div>
       </div>
       <div className='main-panel'>
        <div className='content '>
        <ToastContainer />
         <div className='container-fluid h100percent'>
 <div className='h100percent text-selection-none bg-white  round-corner-all'>
     <div className='row'>
     <div  className='col-md-3' >
        <div className="form-group ">
                 <div >
                 <label>Document Type</label>
         <Select    
         className ='orgindex'            
        value={this.state.docTypeDropSelected}
        onChange={this.updatedocTypeDrop}
        options={this.state.docTypeDrop}
        defaultValue={{ value: '', label: '----' }}
      />
                 </div>
                 </div> 
               </div>
             
             
               {this.state.cid !== '1' && 
     <div className='col-md-3'>
     <div className="form-group ">
            <label >Tag Filter  </label>
                <select 
                onChange={this.onChangeFilterTag}
                name='selectFilter'
                value={this.state.selectFilterTag}
                  className="form-control">
                    <option value='1'>Custom Tag</option>
                    
                    <option value='2'>Predefined Tag</option>
  
                    {/* <option value='3'>Disabled Tag</option> */}
                </select>
              </div>
        
        
        {/* <div className="form-group ">
            <label >Sort By  </label>
                <select 
                onChange={this.onChangeFilter}
                name='selectFilter'
                value={this.state.selectFilter}
                  className="form-control">
                    <option value=''>------</option>
                    <option value='name asc'>Tag Name (ASC)</option>
                    <option value='name desc'>Tag Name (DESC)</option>
                    <option value='type asc'>Tag Type (ASC)</option>
                    <option value='type desc'>Tag Type (DESC)</option>
                    <option value='description asc'>Tag Description (ASC)</option>
                    <option value='description desc'>Tag Description (DESC)</option>
                </select>
              </div> */}
        </div>
       
            }{/* <div className={this.state.ifCloudsds?'col-md-3':' d-none'} >
        <div className="form-group ">
                 <div >
                 <label>View By Organization</label>
         <Select    
         className ='orgindex'            
        value={this.state.SelectedCompany}
        onChange={this.updateValueCompany}
        options={this.state.companyList}
        defaultValue={{ value: '', label: '----' }}
      />
                 </div>
                 </div> 
               </div> */}
              
        <div className={this.state.cid === '1'?'col-md-6':'col-md-3' } >
        <div className="form-group ">
        <label >Search Tag  </label>
        <div className="input-group">
                <input className="form-control " type="search"  name ='searchKey' onChange={this.onChange} value={this.state.searchKey} placeholder='search' />
                <div className="input-group-append">
                    <button onClick={this.searchTag} className="btn  btn-primary" type="button">
                        <i className="fa fa-search"></i>
                    </button>
                    <button onClick={this.resetSearchTag} className="btn  btn-primary" type="button">
                    <i className="fas fa-sync-alt"></i>
                    </button>
                </div>
            </div>
            </div>
        
        </div>
        <div className='col-md-3 text-right pl-0'>
          <label className='vhidden'>Tools</label>
          <div>
          {this.state.docTypeDropSelected.value && 
             <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggledropDown}>
      <DropdownToggle className='btn-primary' caret>
        Actions
      </DropdownToggle>
      <DropdownMenu className='tagManagementBtnGroup'>
      {this.state.lastPublishedTaganomy && 
              <Tooltip placement="top" isOpen={this.state.tooltipOpen} target="TooltipExample" toggle={this.toggletooltip}>
       Last taxonomy  published date is <strong>{this.state.lastPublishedTaganomy}</strong> for this document type.
      </Tooltip>}
              <button onClick={this.publishTaxonomy}id='TooltipExample' className='btn btn-block m-0'>Publish Taxanomy</button>
            <CSVLink className='btn mr-1 btn-block m-0' data={csvData} filename='d2studio-tag.csv' headers={headers} >Export Tags</CSVLink>
        <button  className='btn  btn-block m-0' onClick={this.addTag}  title='Create Tag' >Add Tag(s)</button>
       {/* {this.state.selectFilterTag === '2' && 
        <button onClick={(e)=>this.disbaleTagAll(e)} className='btn btn-block m-0'>Disable Predefined Tag(s)</button>
      }
      {this.state.selectFilterTag === '3' && 
        <button onClick={(e)=>this.enableTagAll(e)} className='btn btn-block m-0'>Enable Predefined Tag(s)</button>
      } */}
      </DropdownMenu>
    </Dropdown>
     }
            {this.state.docTypeDropSelected.value && 
            <div>
              
        </div>
      }
        {/* {this.state.docTypeDropSelected.value && <button onClick={this.ShowImportTag} className="btn  btn-primary ml-1" type="button">
                        Import From XL
                    </button>
                    }
         {!this.state.docTypeDropSelected.value && <button disabled  className="btn  btn-primary ml-1" type="button">
                        Import From XL
                    </button>
                    }            */}
        </div>
  </div>
     </div>
     
     {this.state.upload && 
     <div className='row'>
     <div className="col-md-12 "> 
     <div className='border-bottom pb-3 mb-3'>
		 <div className="form-group files">
				 <input type="file" className="form-control" id='selectedFile'     onChange={this.onChangeHandler}/>
			   </div> 
         {this.state.selectedFile && <button type="button" className="btn btn-primary  mr-2" onClick={this.onClickHandlerg}>Import</button>}
         {!this.state.selectedFile && <button type="button" disabled className="btn btn-primary  mr-2" >Import</button>}
			    
         <button type="button" className="btn btn-primary  " onClick={this.ShowImportTag}>Cancel</button>  
         <button type="button" className="btn btn-dark float-right" >Download Excel Template</button>  
			   </div>
         </div>
     
     </div>
       }
        <div className={this.state.upload?'row manudetailheight smallh':'row manudetailheight'}>
        <div className="col-md-12  h-100">
   <div id="tabledata" className=" position-relative overflowy h-100">    
      <div style={{width: '100%', height: '100%'}}>
        {tagLists.length > 0 && 
          <StickyTable leftStickyColumnCount={0} >
            <Row>
              {/* {this.state.selectFilterTag === '2' && <Cell></Cell>}
              {this.state.selectFilterTag === '3' && <Cell></Cell>} */}
              {this.state.selectFilterTag === '1' && 
            <Cell className='toolsTMwidth'></Cell>
     }
     {this.state.docTypeDropSelected.value === 1 &&
                  <Cell className='sectionwidth'>
                    <span onClick={(e)=>this.sortTagName(e,'section')} className='cursor-pointer'>
                    Section <i className="fas fa-sort"></i>
                    </span>
                    </Cell>
                }
                  {this.state.docTypeDropSelected.value === 1 &&
              <Cell className='sectionwidth'>Sub-section</Cell>
                  }
              <Cell>
              
                <span onClick={(e)=>this.sortTagName(e,'name')} className='cursor-pointer'>Tag Name <i className="fas fa-sort"></i></span>
             
            
              </Cell>
              <Cell className='tagType-width'>
             
                <span onClick={(e)=>this.sortTagType(e,'type')} className='cursor-pointer'>Tag Type <i className="fas fa-sort"></i></span>
           
           
                </Cell>
              <Cell className='descwidth'>
             
                <span onClick={(e)=>this.sortTagDesc(e,'description')} className='cursor-pointer'>Tag Description <i className="fas fa-sort"></i></span>
        
        
                </Cell>
                
               
              <Cell className='descwidth'>Human Readable</Cell>
              
              
              <Cell className='tagdate-width'>Created</Cell>
              <Cell className='tagdate-width'>Updated</Cell>
              
            </Row>
            {tagLists}
            
          </StickyTable>
          }
        </div>
   </div>
</div>
        </div>
      </div>
    </div>
    </div>
    </div>
  {this.state.isAddTag &&
  <div className="modal fade show d-block">
         
  <div className="modal-dialog modal-lg" role="document">
    <div className="modal-content">
      <div className="modal-header px-4 d-block"> 
      
        
         <div className="row">
             <div className="col-md-10">
                   <h4 className="modal-title" ><a onClick={(e)=>this.closeAddTag(false)}   className="back-button cpointer" >
          <i className="fas fa-arrow-circle-left"></i></a> Add Tag(s) for {this.state.docTypeDropSelected.label}</h4>
             </div>
             <div className="col-md-2 text-right">
             <button type="button"  className="close" onClick={(e)=>this.closeAddTag(false)}  >
          <span aria-hidden="true">&times;</span>
        </button>
             </div>
        </div>
      
      </div>
    
     
   <CreateTags documentType={this.state.docTypeDropSelected.value} closeAddTag={this.closeAddTag} />
             


                                  

    
   
                                  </div>
                                
                                </div>
                              </div>
  }
  {this.state.isEditTag &&
  <div className="modal fade show d-block">          
  <div className="modal-dialog modal-lg" role="document">
    <div className="modal-content">
      <div className="modal-header px-4 d-block">
         <div className="row">
             <div className="col-md-10">
                   <h4 className="modal-title" ><a onClick={(e)=>this.closeEditTag(false)}   className="back-button cpointer" >
          <i className="fas fa-arrow-circle-left"></i></a> Update Tag</h4>
             </div>
             <div className="col-md-2 text-right">
             <button type="button"  className="close" onClick={(e)=>this.closeEditTag(false)}  >
          <span aria-hidden="true">&times;</span>
        </button>
             </div>
        </div>
      
      </div>
     
  <EditTags documentType={this.state.docTypeDropSelected.value} uid={this.state.uID} closeEditTag={this.closeEditTag} />
  </div>
                                
                                </div>
                              </div>
  }
   </div>
  )};
};

export default TagManagement;