import React, { Component } from 'react';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {NavLink} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Cookies from 'universal-cookie';
import Select from 'react-select';
import ViewFile from "./ViewFile.jsx"
import { ThemeProvider } from 'styled-components';
const cookies = new Cookies();
class UploadedfileList extends Component {  
     constructor(props) {
          super(props);
          this.state = {
               tgname: '',
               tgdesc:'',
               filelist:[],
               groupList:[],
               groupListAll:[],
               selectedgroup:'',
               docTypeDrop:[],
               docTypeDropSelected:{label:'Select',value:''},
               langlist:[
                { value:'',label:'----'},
                { value:'AF',label:'Afrikaans'},
                { value:'SQ',label:'Albanian'},
                { value:'AR',label:'Arabic'},
                { value:'HY',label:'Armenian'},
                { value:'EU',label:'Basque'},
                { value:'BN',label:'Bengali'},
                { value:'BG',label:'Bulgarian'},
                { value:'CA',label:'Catalan'},
                { value:'KM',label:'Cambodian'},
                { value:'ZH',label:'Chinese (Mandarin)'},
                { value:'HR',label:'Croatian'},
                { value:'CS',label:'Czech'},
                { value:'DA',label:'Danish'},
                { value:'NL',label:'Dutch'},
                { value:'EN',label:'English'},
                { value:'ET',label:'Estonian'},
                { value:'FJ',label:'Fiji'},
                { value:'FI',label:'Finnish'},
                { value:'FR',label:'French'},
                { value:'KA',label:'Georgian'},
                { value:'DE',label:'German'},
                { value:'EL',label:'Greek'},
                { value:'GU',label:'Gujarati'},
                { value:'HE',label:'Hebrew'},
                { value:'HI',label:'Hindi'},
                { value:'HU',label:'Hungarian'},
                { value:'IS',label:'Icelandic'},
                { value:'ID',label:'Indonesian'},
                { value:'GA',label:'Irish'},
                { value:'IT',label:'Italian'},
                { value:'JA',label:'Japanese'},
                { value:'JW',label:'Javanese'},
                { value:'KO',label:'Korean'},
                { value:'LA',label:'Latin'},
                { value:'LV',label:'Latvian'},
                { value:'LT',label:'Lithuanian'},
                { value:'MK',label:'Macedonian'},
                { value:'MS',label:'Malay'},
                { value:'ML',label:'Malayalam'},
                { value:'MT',label:'Maltese'},
                { value:'MI',label:'Maori'},
                { value:'MR',label:'Marathi'},
                { value:'MN',label:'Mongolian'},
                { value:'NE',label:'Nepali'},
                { value:'NO',label:'Norwegian'},
                { value:'FA',label:'Persian'},
                { value:'PL',label:'Polish'},
                { value:'PT',label:'Portuguese'},
                { value:'PA',label:'Punjabi'},
                { value:'QU',label:'Quechua'},
                { value:'RO',label:'Romanian'},
                { value:'RU',label:'Russian'},
                { value:'SM',label:'Samoan'},
                { value:'SR',label:'Serbian'},
                { value:'SK',label:'Slovak'},
                { value:'SL',label:'Slovenian'},
                { value:'ES',label:'Spanish'},
                { value:'SW',label:'Swahili'},
                { value:'SV',label:'Swedish '},
                { value:'TA',label:'Tamil'},
                { value:'TT',label:'Tatar'},
                { value:'TE',label:'Telugu'},
                { value:'TH',label:'Thai'},
                { value:'BO',label:'Tibetan'},
                { value:'TO',label:'Tonga'},
                { value:'TR',label:'Turkish'},
                { value:'UK',label:'Ukrainian'},
                { value:'UR',label:'Urdu'},
                { value:'UZ',label:'Uzbek'},
                { value:'VI',label:'Vietnamese'},
                { value:'CY',label:'Welsh'},
                { value:'XH',label:'Xhosa'}
          ],
          countryList:[
            { value:'',label:'----'},
            { value:'AF',label:'Afghanistan'},
            { value:'AX',label:'Åland Islands'},
            { value:'AL',label:'Albania'},
            { value:'DZ',label:'Algeria'},
            { value:'AS',label:'American Samoa'},
            { value:'AD',label:'Andorra'},
            { value:'AO',label:'Angola'},
            { value:'AI',label:'Anguilla'},
            { value:'AQ',label:'Antarctica'},
            { value:'AG',label:'Antigua and Barbuda'},
            { value:'AR',label:'Argentina'},
            { value:'AM',label:'Armenia'},
            { value:'AW',label:'Aruba'},
            { value:'AU',label:'Australia'},
            { value:'AT',label:'Austria'},
            { value:'AZ',label:'Azerbaijan'},
            { value:'BS',label:'Bahamas'},
            { value:'BH',label:'Bahrain'},
            { value:'BD',label:'Bangladesh'},
            { value:'BB',label:'Barbados'},
            { value:'BY',label:'Belarus'},
            { value:'BE',label:'Belgium'},
            { value:'BZ',label:'Belize'},
            { value:'BJ',label:'Benin'},
            { value:'BM',label:'Bermuda'},
            { value:'BT',label:'Bhutan'},
            { value:'BO',label:'Bolivia, Plurinational State of'},
            { value:'BQ',label:'Bonaire, Sint Eustatius and Saba'},
            { value:'BA',label:'Bosnia and Herzegovina'},
            { value:'BW',label:'Botswana'},
            { value:'BV',label:'Bouvet Island'},
            { value:'BR',label:'Brazil'},
            { value:'IO',label:'British Indian Ocean Territory'},
            { value:'BN',label:'Brunei Darussalam'},
            { value:'BG',label:'Bulgaria'},
            { value:'BF',label:'Burkina Faso'},
            { value:'BI',label:'Burundi'},
            { value:'KH',label:'Cambodia'},
            { value:'CM',label:'Cameroon'},
            { value:'CA',label:'Canada'},
            { value:'CV',label:'Cape Verde'},
            { value:'KY',label:'Cayman Islands'},
            { value:'CF',label:'Central African Republic'},
            { value:'TD',label:'Chad'},
            { value:'CL',label:'Chile'},
            { value:'CN',label:'China'},
            { value:'CX',label:'Christmas Island'},
            { value:'CC',label:'Cocos (Keeling) Islands'},
            { value:'CO',label:'Colombia'},
            { value:'KM',label:'Comoros'},
            { value:'CG',label:'Congo'},
            { value:'CD',label:'Congo, the Democratic Republic of the'},
            { value:'CK',label:'Cook Islands'},
            { value:'CR',label:'Costa Rica'},
            { value:'CI',label:'Côte d\'Ivoire'},
            { value:'HR',label:'Croatia'},
            { value:'CU',label:'Cuba'},
            { value:'CW',label:'Curaçao'},
            { value:'CY',label:'Cyprus'},
            { value:'CZ',label:'Czech Republic'},
            { value:'DK',label:'Denmark'},
            { value:'DJ',label:'Djibouti'},
            { value:'DM',label:'Dominica'},
            { value:'DO',label:'Dominican Republic'},
            { value:'EC',label:'Ecuador'},
            { value:'EG',label:'Egypt'},
            { value:'SV',label:'El Salvador'},
            { value:'GQ',label:'Equatorial Guinea'},
            { value:'ER',label:'Eritrea'},
            { value:'EE',label:'Estonia'},
            { value:'ET',label:'Ethiopia'},
            { value:'FK',label:'Falkland Islands (Malvinas)'},
            { value:'FO',label:'Faroe Islands'},
            { value:'FJ',label:'Fiji'},
            { value:'FI',label:'Finland'},
            { value:'FR',label:'France'},
            { value:'GF',label:'French Guiana'},
            { value:'PF',label:'French Polynesia'},
            { value:'TF',label:'French Southern Territories'},
            { value:'GA',label:'Gabon'},
            { value:'GM',label:'Gambia'},
            { value:'GE',label:'Georgia'},
            { value:'DE',label:'Germany'},
            { value:'GH',label:'Ghana'},
            { value:'GI',label:'Gibraltar'},
            { value:'GR',label:'Greece'},
            { value:'GL',label:'Greenland'},
            { value:'GD',label:'Grenada'},
            { value:'GP',label:'Guadeloupe'},
            { value:'GU',label:'Guam'},
            { value:'GT',label:'Guatemala'},
            { value:'GG',label:'Guernsey'},
            { value:'GN',label:'Guinea'},
            { value:'GW',label:'Guinea-Bissau'},
            { value:'GY',label:'Guyana'},
            { value:'HT',label:'Haiti'},
            { value:'HM',label:'Heard Island and McDonald Islands'},
            { value:'VA',label:'Holy See (Vatican City State)'},
            { value:'HN',label:'Honduras'},
            { value:'HK',label:'Hong Kong'},
            { value:'HU',label:'Hungary'},
            { value:'IS',label:'Iceland'},
            { value:'IN',label:'India'},
            { value:'ID',label:'Indonesia'},
            { value:'IR',label:'Iran, Islamic Republic of'},
            { value:'IQ',label:'Iraq'},
            { value:'IE',label:'Ireland'},
            { value:'IM',label:'Isle of Man'},
            { value:'IL',label:'Israel'},
            { value:'IT',label:'Italy'},
            { value:'JM',label:'Jamaica'},
            { value:'JP',label:'Japan'},
            { value:'JE',label:'Jersey'},
            { value:'JO',label:'Jordan'},
            { value:'KZ',label:'Kazakhstan'},
            { value:'KE',label:'Kenya'},
            { value:'KI',label:'Kiribati'},
            { value:'KP',label:'Korea, Democratic People\'s Republic of'},
            { value:'KR',label:'Korea, Republic of'},
            { value:'KW',label:'Kuwait'},
            { value:'KG',label:'Kyrgyzstan'},
            { value:'LA',label:'Lao People\'s Democratic Republic'},
            { value:'LV',label:'Latvia'},
            { value:'LB',label:'Lebanon'},
            { value:'LS',label:'Lesotho'},
            { value:'LR',label:'Liberia'},
            { value:'LY',label:'Libya'},
            { value:'LI',label:'Liechtenstein'},
            { value:'LT',label:'Lithuania'},
            { value:'LU',label:'Luxembourg'},
            { value:'MO',label:'Macao'},
            { value:'MK',label:'Macedonia, the former Yugoslav Republic of'},
            { value:'MG',label:'Madagascar'},
            { value:'MW',label:'Malawi'},
            { value:'MY',label:'Malaysia'},
            { value:'MV',label:'Maldives'},
            { value:'ML',label:'Mali'},
            { value:'MT',label:'Malta'},
            { value:'MH',label:'Marshall Islands'},
            { value:'MQ',label:'Martinique'},
            { value:'MR',label:'Mauritania'},
            { value:'MU',label:'Mauritius'},
            { value:'YT',label:'Mayotte'},
            { value:'MX',label:'Mexico'},
            { value:'FM',label:'Micronesia, Federated States of'},
            { value:'MD',label:'Moldova, Republic of'},
            { value:'MC',label:'Monaco'},
            { value:'MN',label:'Mongolia'},
            { value:'ME',label:'Montenegro'},
            { value:'MS',label:'Montserrat'},
            { value:'MA',label:'Morocco'},
            { value:'MZ',label:'Mozambique'},
            { value:'MM',label:'Myanmar'},
            { value:'NA',label:'Namibia'},
            { value:'NR',label:'Nauru'},
            { value:'NP',label:'Nepal'},
            { value:'NL',label:'Netherlands'},
            { value:'NC',label:'New Caledonia'},
            { value:'NZ',label:'New Zealand'},
            { value:'NI',label:'Nicaragua'},
            { value:'NE',label:'Niger'},
            { value:'NG',label:'Nigeria'},
            { value:'NU',label:'Niue'},
            { value:'NF',label:'Norfolk Island'},
            { value:'MP',label:'Northern Mariana Islands'},
            { value:'NO',label:'Norway'},
            { value:'OM',label:'Oman'},
            { value:'PK',label:'Pakistan'},
            { value:'PW',label:'Palau'},
            { value:'PS',label:'Palestinian Territory, Occupied'},
            { value:'PA',label:'Panama'},
            { value:'PG',label:'Papua New Guinea'},
            { value:'PY',label:'Paraguay'},
            { value:'PE',label:'Peru'},
            { value:'PH',label:'Philippines'},
            { value:'PN',label:'Pitcairn'},
            { value:'PL',label:'Poland'},
            { value:'PT',label:'Portugal'},
            { value:'PR',label:'Puerto Rico'},
            { value:'QA',label:'Qatar'},
            { value:'RE',label:'Réunion'},
            { value:'RO',label:'Romania'},
            { value:'RU',label:'Russian Federation'},
            { value:'RW',label:'Rwanda'},
            { value:'BL',label:'Saint Barthélemy'},
            { value:'SH',label:'Saint Helena, Ascension and Tristan da Cunha'},
            { value:'KN',label:'Saint Kitts and Nevis'},
            { value:'LC',label:'Saint Lucia'},
            { value:'MF',label:'Saint Martin (French part)'},
            { value:'PM',label:'Saint Pierre and Miquelon'},
            { value:'VC',label:'Saint Vincent and the Grenadines'},
            { value:'WS',label:'Samoa'},
            { value:'SM',label:'San Marino'},
            { value:'ST',label:'Sao Tome and Principe'},
            { value:'SA',label:'Saudi Arabia'},
            { value:'SN',label:'Senegal'},
            { value:'RS',label:'Serbia'},
            { value:'SC',label:'Seychelles'},
            { value:'SL',label:'Sierra Leone'},
            { value:'SG',label:'Singapore'},
            { value:'SX',label:'Sint Maarten (Dutch part)'},
            { value:'SK',label:'Slovakia'},
            { value:'SI',label:'Slovenia'},
            { value:'SB',label:'Solomon Islands'},
            { value:'SO',label:'Somalia'},
            { value:'ZA',label:'South Africa'},
            { value:'GS',label:'South Georgia and the South Sandwich Islands'},
            { value:'SS',label:'South Sudan'},
            { value:'ES',label:'Spain'},
            { value:'LK',label:'Sri Lanka'},
            { value:'SD',label:'Sudan'},
            { value:'SR',label:'Suriname'},
            { value:'SJ',label:'Svalbard and Jan Mayen'},
            { value:'SZ',label:'Swaziland'},
            { value:'SE',label:'Sweden'},
            { value:'CH',label:'Switzerland'},
            { value:'SY',label:'Syrian Arab Republic'},
            { value:'TW',label:'Taiwan, Province of China'},
            { value:'TJ',label:'Tajikistan'},
            { value:'TZ',label:'Tanzania, United Republic of'},
            { value:'TH',label:'Thailand'},
            { value:'TL',label:'Timor-Leste'},
            { value:'TG',label:'Togo'},
            { value:'TK',label:'Tokelau'},
            { value:'TO',label:'Tonga'},
            { value:'TT',label:'Trinidad and Tobago'},
            { value:'TN',label:'Tunisia'},
            { value:'TR',label:'Turkey'},
            { value:'TM',label:'Turkmenistan'},
            { value:'TC',label:'Turks and Caicos Islands'},
            { value:'TV',label:'Tuvalu'},
            { value:'UG',label:'Uganda'},
            { value:'UA',label:'Ukraine'},
            { value:'AE',label:'United Arab Emirates'},
            { value:'GB',label:'United Kingdom'},
            { value:'US',label:'United States'},
            { value:'UM',label:'United States Minor Outlying Islands'},
            { value:'UY',label:'Uruguay'},
            { value:'UZ',label:'Uzbekistan'},
            { value:'VU',label:'Vanuatu'},
            { value:'VE',label:'Venezuela, Bolivarian Republic of'},
            { value:'VN',label:'Viet Nam'},
            { value:'VG',label:'Virgin Islands, British'},
            { value:'VI',label:'Virgin Islands, U.S.'},
            { value:'WF',label:'Wallis and Futuna'},
            { value:'EH',label:'Western Sahara'},
            { value:'YE',label:'Yemen'},
            { value:'ZM',label:'Zambia'},
            { value:'ZW',label:'Zimbabwe'}
          ],
          meta_json:[],
	        field_json:[],
           metalist:[],
           ginfo:false,
           TaggedFilePage:1,
           selectedImage:'',
           imageView:false,
           modal: false,
        fade: false,
        selectedFilename:'',
        allcheck:false,
        assignModal:false,
        selectedTagger:'',
        singleAssignGid:'',
        singleAssignDid:'',
        singleAssignIndex:'',
        role:'',
        taggerList:[],
        delID:'',
        delIndex:'',
        isDuplicate:false,
        duplicateFileCount:0,
          };
          this.addtogroup = this.addtogroup.bind(this)
          this.getdocbygroupById = this.getdocbygroupById.bind(this)
          this.updateValueGroup = this.updateValueGroup.bind(this)
          this.addtoqueue = this.addtoqueue.bind(this)
          this.updatedocTypeDrop = this.updatedocTypeDrop.bind(this)
          this.toggleModal = this.toggleModal.bind(this);
        }
        toggleModal(){
          this.setState({
              modal: !this.state.modal,
              imageView: !this.state.imageView
          });
         // console.log( 'after setState: ', this.state );
      }
      toggleAssignModal = () =>{
        this.setState({
          assignModal: !this.state.assignModal
        });
    }
    viewImage = (e,filename) =>{
      this.state.selectedFilename = filename
      this.state.TaggedFilePage = 1;
      this.setState({
        imageView: !this.state.imageView,
        selectedFilename: this.state.selectedFilename,
    });
    }
    closefile =(status)=>{
      if(status){
        this.state.imageView = false;
        this.setState({
          imageView:this.state.imageView
        })
      }
      
    }
        updatedocTypeDrop(newValue){
         
          this.state.docTypeDropSelected = newValue
          this.setState({
            docTypeDropSelected: this.state.docTypeDropSelected,
            duplicateFileCount:0,
            isDuplicate:false
        });
        if(newValue.value){        
        if(newValue.value === 1){
          document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/Document/GetGroupList_UnTrained',{
             method: 'POST',
             headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'Authorization':'bearer '+cookies.get("auth")
             
             },
             body: JSON.stringify({  
               cid : parseInt(cookies.get("cid")),
               category:parseInt(this.state.docTypeDropSelected.value)
            })
             })
           .then((response) => response.json())
           .then((responseJson) => {
             let tempData = responseJson; 
            // console.log(tempData)   
            let exdate = new Date();
             cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
             if(tempData.data === null){
             toast.error('Error ocured. Please Try Again')
               
               // console.log(this.state.grouplist)
              }else if(tempData.data.length === 0){

              }
                else{
              let storedata = [];
              let tempDatas = responseJson.data; 
              for (var i = 0; i < tempDatas.length; i++) {
                storedata.push({
                  value: tempDatas[i].id, 
                  label:  tempDatas[i].groupname,
                })
                
                }
                this.state.groupListAll = tempDatas;
               this.setState({
              groupList: storedata,
              groupListAll:this.state.groupListAll
             })
             this.setState({
              selectedgroup:this.state.groupList[0],
             })
             let field_json = [];
            let fj = this.state.groupListAll.filter(groupList => groupList.id == this.state.groupList[0].value);
            field_json = JSON.parse(fj[0].field_json);   
           this.state.field_json = field_json;
            this.setState({
              selectedgroup:this.state.selectedgroup,
              field_json:this.state.field_json
             })

           //  console.log(this.state.groupList[1].value)
             
             this.getdocbygroupById(this.state.selectedgroup.value);
             this.getMetaListall();
            }
            document.getElementById('loader').classList.remove('d-block'); 
            
           })
           .catch(error =>{
             console.log(error); 
           })
        }else{
          this.getdocbygroupById()
        }
      }else{
        this.setState({
          filelist: []
      });
      }
    }
    
        addtoqueue = (e,gid,did,index) => {          
          document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/Document/AssignDocument',{
               method: 'POST',
               headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 'Authorization':'bearer '+cookies.get("auth")
                 
               },
               body: JSON.stringify({              
                assigned_user_id:cookies.get("userid"),
                group_id : gid,
                doc_id : did,
                cid : cookies.get("cid"),
                status : 1,
                category:this.state.docTypeDropSelected.value
              })
               })
             .then((response) => response.json())
             .then((responseJson) => {
               let tempData = responseJson; 
              // console.log(tempData.data[0].id)   
               let exdate = new Date();
                cookies.set('auth', tempData.status.authtoken, { path: '/'});  
                     
                if(tempData.status.statusMessage === 'Success'){                         
                 toast.success('File added to queue for tagging');     
                 const draw = this.state.filelist;
              if (index !== -1) {
                draw.splice(index, 1);
                this.setState({filelist: draw});
              }
                  }else{      
                   toast.error('Error occured.Please try again')    
                }
              document.getElementById('loader').classList.remove('d-block'); 
              
             })
             .catch(error =>{
               console.log(error); 
             })
            
           
       }
        updateValueGroup(newValue) {
          this.state.selectedgroup = newValue;
          let field_json = [];
          let fj = this.state.groupListAll.filter(groupList => groupList.id == newValue.value);
          field_json = JSON.parse(fj[0].field_json);   
          this.state.field_json = field_json;
        this.setState({
          selectedgroup: this.state.selectedgroup,
          field_json:this.state.field_json
        });
       
        this.getdocbygroupById();
        this.getMetaListall();
        let exdate = new Date();
        cookies.set('SeleQGroup', newValue, { path: '/'});  
       }
        componentDidMount(){
          if(cookies.get("roleid")){
            this.state.role = cookies.get("roleid")
            this.setState({
              role:this.state.role
             })
          }
          
          document.getElementById('loader').classList.add('d-block'); 
	 fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocumentTypeList',{
		  method: 'POST',
		  headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization':'bearer '+cookies.get("auth")
			
		  },
		  body: JSON.stringify({ 
		  
		 })
		  })
		.then((response) => response.json())
		.then((responseJson) => {
		  let tempData = responseJson;  
		//  console.log(tempData)   
		  if(tempData.status.statusMessage === 'Success'){
			   let exdate = new Date();
			   cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
			   this.state.docType =  tempData.data;
         let storedata = [];
         storedata.push({value:'',label:'Select'});
			   for (var i = 0; i < tempData.data.length; i++) {
						   storedata.push({
							 value: tempData.data[i].id, 
							 label:  tempData.data[i].doctype_name,
						   })
						  
				}
        if(cookies.get("unassigncat")){
          let getcat = cookies.get("unassigncat");
          cookies.remove('unassigncat')
          let getdocType = storedata.filter(item => item.value === parseInt(getcat));
          console.log(getdocType)
			   this.setState({
				 docTypeDrop:storedata,
				 docTypeDropSelected:getdocType[0],
			   })
         if(this.state.docTypeDropSelected.value === 1){
          document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/Document/GetGroupList_UnTrained',{
             method: 'POST',
             headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'Authorization':'bearer '+cookies.get("auth")
             
             },
             body: JSON.stringify({  
               cid : parseInt(cookies.get("cid")),
               category:parseInt(this.state.docTypeDropSelected.value)
            })
             })
           .then((response) => response.json())
           .then((responseJson) => {
             let tempData = responseJson; 
            // console.log(tempData)   
            let exdate = new Date();
             cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
             if(tempData.data === null){
             toast.error('Error ocured. Please Try Again')
               
               // console.log(this.state.grouplist)
              }else if(tempData.data.length === 0){

              }
                else{
              let storedata = [];
              let tempDatas = responseJson.data; 
              for (var i = 0; i < tempDatas.length; i++) {
                storedata.push({
                  value: tempDatas[i].id, 
                  label:  tempDatas[i].groupname,
                })
                
                }
                this.state.groupListAll = tempDatas;
               this.setState({
              groupList: storedata,
              groupListAll:this.state.groupListAll
             })
             this.setState({
              selectedgroup:this.state.groupList[0],
             })
             let field_json = [];
            let fj = this.state.groupListAll.filter(groupList => groupList.id == this.state.groupList[0].value);
            field_json = JSON.parse(fj[0].field_json);   
           this.state.field_json = field_json;
           if(cookies.get("unassigncgroup")){
            let getgroup = cookies.get("unassigncgroup");
            cookies.remove('unassigncgroup')
            let getgroupValue = this.state.groupList.filter(item => item.value === parseInt(getgroup));
            this.setState({
              selectedgroup:getgroupValue[0],
              field_json:this.state.field_json
             })
           }else{
            this.setState({
              selectedgroup:this.state.selectedgroup,
              field_json:this.state.field_json
             })
           }
            

           //  console.log(this.state.groupList[1].value)
             
             this.getdocbygroupById(this.state.selectedgroup.value);
             this.getMetaListall();
            }
            document.getElementById('loader').classList.remove('d-block'); 
            
           })
           .catch(error =>{
             console.log(error); 
           })
        }else{
          this.getdocbygroupById()
        }
        }else{
          this.setState({
            docTypeDrop:storedata,
            docTypeDropSelected:storedata[0],
            })
        }
			  //  if(cookies.get("unassigncat")){
        //   let getcat = cookies.get("unassigncat");
        //   alert(getcat)
        //   //let docdrop = this.state.docTypeDrop;
        //   let getdocType = storedata.filter(item => item.value === getcat);
        //   console.log(getdocType)
        //   // this.setState({
        //   //   role:this.state.role
        //   //  })
        // }
			   console.log(this.state.docTypeDropSelected)
			   document.getElementById('loader').classList.remove('d-block'); 
		  }else{
			   toast.error('Error ocured. Please Try Again');
			   document.getElementById('loader').classList.remove('d-block'); 
		  }
		 
		 
		})
		.catch(error =>{
		  console.log(error); 
		  document.getElementById('loader').classList.remove('d-block'); 
		}) 
     this.Getallusers()
       }  
       getdocbygroupById(){
        this.setState({
          filelist: [],
      })
        let parms = '';     
      
 if(!this.state.isDuplicate){
        if(this.state.docTypeDropSelected.value === 1){
          parms = JSON.stringify({              
            group_id:this.state.selectedgroup.value,
            category:parseInt(this.state.docTypeDropSelected.value),
            cid : cookies.get("cid"),
            workflow_status:100 
          })
        }else{
          parms = JSON.stringify({  
            category:parseInt(this.state.docTypeDropSelected.value),
            cid : cookies.get("cid"),
            workflow_status:100
          })
        }
        document.getElementById('loader').classList.add('d-block'); 
        let apiurl = '/api/Document/GetUnAssignDocument'
       
        
        fetch(process.env.REACT_APP_ENV+apiurl,{
             method: 'POST',
             headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json',
               'Authorization':'bearer '+cookies.get("auth")
               
             },
             body: parms
             })
           .then((response) => response.json())
           .then((responseJson) => {
             let tempData = responseJson; 
            // console.log(tempData.data[0].id)   
             let exdate = new Date();
              cookies.set('auth', tempData.status.authtoken, { path: '/'});  
                   
              if(!tempData.data.length){
                 toast.error('No records found..') ;
                 document.getElementById('loader').classList.remove('d-block');                
                }else{  
                  let preList = [];  
                  tempData.data.forEach((s,index) =>{
                    preList.push({
                      categoryId: s.categoryId,
                      cid: s.cid,
countrycode: s.countrycode,
created_by_name: s.created_by_name,
docpath: s.docpath,
doctype: s.doctype,
field_json: s.field_json,
filename: s.filename,
groupId: s.groupId,
groupname: s.groupname,
id: s.id,
langId: s.langId,
mfgId: s.mfgId,
modified_by_name: s.modified_by_name,
status: s.status,
uploadtime: s.uploadtime,
checked:false
                    })
                  })
                  this.state.filelist = preList;
                 this.setState({
                   filelist: this.state.filelist,
               })
               console.log(this.state.filelist)
              }
            document.getElementById('loader').classList.remove('d-block'); 
            
           })
           .catch(error =>{
             console.log(error); 
           })

 }
 let parmsDuplicate = ''
 if(this.state.docTypeDropSelected.value === 1){
  parmsDuplicate = JSON.stringify({  
    groupId:this.state.selectedgroup.value,
    category:parseInt(this.state.docTypeDropSelected.value),
    cid : cookies.get("cid"),
  })
}else{
  parmsDuplicate = JSON.stringify({  
    category:parseInt(this.state.docTypeDropSelected.value),
    cid : cookies.get("cid"),
  })
 fetch(process.env.REACT_APP_ENV+'/api/Document/GetDocListDuplicate',{
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization':'bearer '+cookies.get("auth")
    
  },
  body: parmsDuplicate
  })
.then((response) => response.json())
.then((responseJson) => {
  let tempData = responseJson; 
 // console.log(tempData.data[0].id)   
  let exdate = new Date();
   cookies.set('auth', tempData.status.authtoken, { path: '/'});  
        
   if(!tempData.data.length){
    //  toast.error('No records found..') ;
      document.getElementById('loader').classList.remove('d-block');     
        
                 
     }
     else
     {  
       let preList = [];  
       let filecount = tempData.data.length
       tempData.data.forEach((s,index) =>{
         preList.push({
           categoryId: s.categoryId,
           cid: s.cid,
countrycode: s.countrycode,
created_by_name: s.created_by_name,
docpath: s.docpath,
doctype: s.doctype,
field_json: s.field_json,
filename: s.filename,
groupId: s.groupId,
groupname: s.groupname,
id: s.id,
langId: s.langId,
mfgId: s.mfgId,
modified_by_name: s.modified_by_name,
status: s.status,
uploadtime: s.uploadtime,
checked:false
         })
       })
       this.state.duplicateFileCount = filecount
        if(this.state.isDuplicate){
       this.state.filelist = preList;
      this.setState({
        filelist: this.state.filelist,
        duplicateFileCount:this.state.duplicateFileCount
    })

  
  //  console.log(this.state.filelist)
   }else{
    this.setState({
      duplicateFileCount:this.state.duplicateFileCount
  })
   }
 document.getElementById('loader').classList.remove('d-block'); 
  }
})
.catch(error =>{
  console.log(error); 
})
}      
          }      
        onChange = (e) => {
          this.setState({[e.target.name]: e.target.value})
        }
        onSubmit = (e) => {
          e.preventDefault();
          const { tgname, tgdesc} = this.state;
        //  alert('name : ' + tgname+', desc: ' +tgdesc);
           this.setState({
                tgname: '',
                tgdesc: ''
          });
          
        }
        addtogroup = (e) => {
            this.props.history.push('/add-to-group') 
            
        }
        delTag(e,docid,gid,index){
          document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/Document/AssignDocument',{
     method: 'POST',
     headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/json',
       'Authorization':'bearer '+cookies.get("auth")
       
     },
     body: JSON.stringify({   
      assigned_user_id:0,
     group_id : gid,
     doc_id : docid,
     cid : cookies.get("cid"),
     status : 2,
     category:this.state.docTypeDropSelected.value
    })
     })
    .then((response) => response.json())
    .then((responseJson) => {
     console.log(responseJson)
     let exdate = new Date();
     cookies.set('auth', responseJson.status.authtoken, { path: '/'}); 
     var array = [...this.state.filelist];
     if (index !== -1) {
      array.splice(index, 1);
      this.setState({filelist: array});
    }
    document.getElementById('loader').classList.remove('d-block'); 
    toast.success('File removed');
    })
    .catch(error =>{
     console.log(error); 
    })
         
        }  

        collapse = (e) =>{
          this.setState({ginfo: false})
        }
        openginfo = (e) =>{
          this.setState({ginfo: !this.state.ginfo})
        }  
        getMetaListall = (e) =>{
        console.log(this.state.field_json)
          let ArrMetaList = [];
          if(this.state.field_json && this.state.field_json.length > 0 ){
      this.state.field_json.forEach((ml,index,arr) => { 
        if(ml.type === '1'){
          ArrMetaList.push({
            api: ml.api,
            document_type_id: ml.document_type_id,
            error: ml.error,
            id: ml.id,
            key: ml.key,
            label: ml.label,
            mandatroy: ml.mandatroy,
            type: ml.type,
            value:ml.value,
            option:[],
            selectedOptiongDrop:[],
            valueLabel:''
          })
          fetch(process.env.REACT_APP_ENV+ml.api,{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization':'bearer '+cookies.get("auth")
              
            },
            body: JSON.stringify({              
              MfgName:'',
              mfgId:ml.value
           })
            })
          .then((response) => response.json())
          .then((responseJson) => {
            let tempData = responseJson; 
            console.log(tempData.data[0].mnfName)   
             if(tempData.status.statusMessage === 'Success'){
              const timestamp = new Date().getTime();
              const expireauth = timestamp + 1800000;
              const exdate =  new Date(expireauth);
              cookies.set('auth', tempData.status.authtoken, { path: '/'});   
               let gindex = index;
               this.state.metalist[gindex].valueLabel = tempData.data[0].mnfName;
               this.setState({
                metalist:this.state.metalist
                 })
                      
               }else{
                  toast.success('Error ocured. Please Try Again')
             }
             
           
          })
          .catch(error =>{
            console.log(error); 
          })
         
         
        }
        if(ml.type === '2'){
          ArrMetaList.push({
            api: ml.api,
            document_type_id: ml.document_type_id,
            error: ml.error,
            id: ml.id,
            key: ml.key,
            label: ml.label,
            mandatroy: ml.mandatroy,
            type: ml.type,
            value:ml.value,
            option:[],
            selectedOptiongDrop:'',
            valueLabel:''
          })  
         document.getElementById('loader').classList.add('d-block'); 
         fetch(process.env.REACT_APP_ENV+ml.api,{
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'bearer '+cookies.get("auth")
                
              },
              body: JSON.stringify({ 
             })
              })
            .then((response) => response.json())
            .then((responseJson) => {
              let tempData = responseJson;  
            //  console.log(tempData)   
              if(tempData){
                   // let exdate = new Date();
                   // cookies.set('auth', tempData.status.authtoken, { path: '/',expires: new Date(Date.now()+2592000) }); 
                  // console.log(tempData.replace(/'/g,"'");
                  // let optionsold = tempData.data;
                  // let attachoption = [{'value':'','label':'----'}];
                  // let options = [attachoption, ...optionsold]
                  let options = tempData.data;
                   let gindex = index;
                   let val = ml.value;
                   console.log(options)
                   this.state.metalist[gindex].option = options;
                  this.state.metalist[gindex].selectedOptiongDrop = options.filter(option => option.value === val);
                  let valulabel = options.filter(option => option.value === val);
                  this.state.metalist[gindex].valueLabel = valulabel[0].label;
                   this.setState({
                    metalist:this.state.metalist
                   })
                                  
                   document.getElementById('loader').classList.remove('d-block'); 
              }else{
                   toast.error('Error ocured. Please Try Again');
                   document.getElementById('loader').classList.remove('d-block'); 
              }
               
             
            })
            .catch(error =>{
              console.log(error); 
              document.getElementById('loader').classList.remove('d-block'); 
            }) 
         
        }
        
      })}
         this.state.metalist = ArrMetaList;
         this.setState({
          metalist:this.state.metalist 
           })
         
        }  
        checkQueue =(e,index) =>{
          this.state.filelist[index].checked = !this.state.filelist[index].checked;
          this.state.allcheck = false;
          this.setState({
           filelist:this.state.filelist,
           allcheck:this.state.allcheck
          })
        }
        checkQueueAll = (e) =>{
          this.state.allcheck = !this.state.allcheck;
         
            let postFile = this.state.filelist
            postFile.forEach((s,index)=>{
              if(this.state.allcheck){
               s.checked = true
              }else{
                s.checked = false
              }
            })
          
          
          this.setState({
           filelist:postFile,
           allcheck:this.state.allcheck
          })
        }
        assignOthers =(e)=>{
          this.toggleAssignModal()
        }
        assignTagger=(e)=>{
          let draw = this.state.filelist;
          let countchecked = [];
          draw.forEach ((s, idx, arr) => {  
            let ifChecked = s.checked;
            if(ifChecked){
              countchecked.push(s.id)
            }
            
          })
          let getremoved = [];
          if(countchecked.length){
          draw.forEach ((s, idx, arr) => {  
            let ifChecked = s.checked;
            if(ifChecked){              
              document.getElementById('loader').classList.add('d-block'); 
              fetch(process.env.REACT_APP_ENV+'/api/Document/AssignDocument',{
                   method: 'POST',
                   headers: {
                     'Accept': 'application/json',
                     'Content-Type': 'application/json',
                     'Authorization':'bearer '+cookies.get("auth")
                     
                   },
                   body: JSON.stringify({              
                    assigned_user_id:this.state.selectedTagger,
                    group_id : s.groupId,
                    doc_id : s.id,
                    cid : cookies.get("cid"),
                    status : 1,
                    category:this.state.docTypeDropSelected.value
                  })
                   })
                 .then((response) => response.json())
                 .then((responseJson) => {
                   let tempData = responseJson; 
                  // console.log(tempData.data[0].id)   
                   let exdate = new Date();
                    cookies.set('auth', tempData.status.authtoken, { path: '/'});  
                         
                    if(tempData.status.statusMessage === 'Success'){                         
                     toast.success(s.filename+ ' added to queue for tagging');
                     const tagkeyList = this.state.filelist;
                     this.state.filelist = tagkeyList.filter(item =>  item.id !== s.id);
                     this.setState({
                      filelist:this.state.filelist,
                      allcheck:false
                     })
                    
                      }else{      
                       toast.error('Error occured.Please try again')  ;
                       
                    }
                 
                    document.getElementById('loader').classList.remove('d-block');   
                 })
                 .catch(error =>{
                   console.log(error); 
                   document.getElementById('loader').classList.remove('d-block');   
                 })
               
                 
              if (idx === arr.length - 1){ 
                document.getElementById('loader').classList.remove('d-block'); 
                }
            }
          })
        }else{
          let gindex = this.state.singleAssignIndex;
          let did = this.state.filelist[gindex].id
          document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/Document/AssignDocument',{
               method: 'POST',
               headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 'Authorization':'bearer '+cookies.get("auth")
                 
               },
               body: JSON.stringify({              
                assigned_user_id:this.state.singleAssignGid,
                group_id : this.state.singleAssignGid,
                doc_id : did,
                cid : cookies.get("cid"),
                status : 1,
                category:this.state.docTypeDropSelected.value
              })
               })
             .then((response) => response.json())
             .then((responseJson) => {
               let tempData = responseJson; 
              // console.log(tempData.data[0].id)   
               let exdate = new Date();
                cookies.set('auth', tempData.status.authtoken, { path: '/'});  
                     
                if(tempData.status.statusMessage === 'Success'){                         
                 toast.success('File added to queue for tagging');     
                 const draw = this.state.filelist;
              if (gindex !== -1) {
                draw.splice(gindex, 1);
                this.setState({filelist: draw});
              }
                  }else{      
                   toast.error('Error occured.Please try again')    
                }
              document.getElementById('loader').classList.remove('d-block'); 
              
             })
             .catch(error =>{
               console.log(error); 
             })
        }
          this.toggleAssignModal()
        }
        assignListChange=(e)=>{
          this.setState({[e.target.name]: e.target.value})
        }
        removeFile =(e,id,index) =>{
          this.state.delID = id;
          this.state.delIndex = index;
          confirmAlert({
            title: 'Confirm to remove',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                   fetch(process.env.REACT_APP_ENV+'/api/Document/removeFileFromUploadedList',{
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'Authorization':'bearer '+cookies.get("auth")           
         },
         body: JSON.stringify({   
          doc_id:id,
         category : this.state.docTypeDropSelected.value
        })
         })
        .then((response) => response.json())
        .then((responseJson) => {
          if(responseJson.status.statusMessage == 'Success'){
            cookies.set('auth', responseJson.status.authtoken, { path: '/'}); 
         var array = this.state.filelist;
         if (index !== -1) {
           array.splice(index, 1);
           this.setState({filelist: array});
         }
        
        toast.success('File removed.');
          }else{
            toast.success('Error occurred.');
          }
          document.getElementById('loader').classList.remove('d-block'); 
        })
        .catch(error =>{
         console.log(error); 
         document.getElementById('loader').classList.remove('d-block'); 
         toast.error('Error occurred. Please try again later.');
        })        
        
                }
              },
              {
                label: 'No',
                onClick: () => {
                  
                }
              }
            ]
          });
        }
       

        Getallusers = () =>{
          document.getElementById('loader').classList.add('d-block'); 
          let cid = parseInt(cookies.get('cid'));
         
           fetch(process.env.REACT_APP_ENV+'/api/UserAuth/Getallusers',{
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization':'bearer '+cookies.get("auth")
                  
                },
                body: JSON.stringify({ 
                  id:cid
               })
                })
              .then((response) => response.json())
              .then((responseJson) => {
                let tempData = responseJson;  
                console.log(tempData)   
                if(tempData.status.statusMessage === 'Success'){
                //  taggerList
                     cookies.set('auth', tempData.status.authtoken, { path: '/'}); 
                     let taggers = []
                     tempData.data.forEach((s,index)=>{
                       if(s.roleid === 3){
                        taggers.push({id:s.id,value:s.first_name+' '+s.last_name})
                       }
                      
                     })
                     this.state.taggerList =  taggers;
                     this.setState({
                      taggerList:this.state.taggerList
                     })
                     console.log(this.state.taggerList)
                     if(this.state.taggerList.length){
                      let selectedTagger = this.state.taggerList[0].id;
                      this.state.selectedTagger = selectedTagger;
                      this.setState({
                        selectedTagger:this.state.selectedTagger
                      }) 
                     }
                     
                }else{
                     toast.error('Error ocured. Please Try Again')
                }
               document.getElementById('loader').classList.remove('d-block');  
               
              })
              .catch(error =>{
                console.log(error); 
                document.getElementById('loader').classList.remove('d-block'); 
                toast.error('Error ocured. Please Try Again')
              })
        }
        switchDuplicateView = () =>{
          this.state.isDuplicate = !this.state.isDuplicate
          this.setState({
            isDuplicate:this.state.isDuplicate
          })
          this.getdocbygroupById()
        }
        unDuplicate=(e,groupId,id,index)=>{
          document.getElementById('loader').classList.add('d-block'); 
          fetch(process.env.REACT_APP_ENV+'/api/Document/UnMarkUploadedFileDuplicate',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization':'bearer '+cookies.get("auth")           
            },
            body: JSON.stringify({   
             doc_id:id,
            category : this.state.docTypeDropSelected.value
           })
            })
           .then((response) => response.json())
           .then((responseJson) => {
             if(responseJson.status.statusMessage == 'Success'){
              var array = this.state.filelist;
              this.state.duplicateFileCount = this.state.duplicateFileCount - 1;
              
              if (index !== -1) {
               array.splice(index, 1);
               this.setState({
                 filelist: array,
                 duplicateFileCount:this.state.duplicateFileCount,
                 isDuplicate:this.state.isDuplicate
                });
             }
             if(this.state.duplicateFileCount < 1){
              this.switchDuplicateView()
            }
             document.getElementById('loader').classList.remove('d-block'); 
              toast.success('File un-duplicate done');
             }else{
               toast.success('Error occurred.');
             }
             document.getElementById('loader').classList.remove('d-block'); 
           })
           .catch(error =>{
            console.log(error); 
            document.getElementById('loader').classList.remove('d-block'); 
            toast.error('Error occurred. Please try again later.');
           })
        }
     render() {
          const { tgname, tgdesc, docTypeDropSelected} = this.state;
          const role =  parseInt(this.state.role);
          let taggerList = [];
          this.state.taggerList.forEach((t,index)=>{
            taggerList.push(
              <option key={index} value={t.id}>{t.value}</option>
            )
          })
          let filelists = [];
          let filelistsO = [];
          let ifChecked = false;
          this.state.filelist.forEach ((s,index) => {  
            var delitem = '';
            if(s.checked === true){
              ifChecked = true
            }
            if(role !== 3) {
                delitem = <button onClick={(e) => this.delTag(e,s.id,s.groupId,index)}  type="button" className="btn btn-danger btn-sm"><i className="fas fa-trash-alt"></i> Remove</button>
            }
            let lang = s.langId;
            let language = '';
            if(lang !== null){
              let langindex = this.state.langlist.findIndex(tagkey => tagkey.value === lang);   
              if(langindex !== -1){
                language = this.state.langlist[langindex].label;
              }
            }
            
            
            

            let coun = s.countrycode;
            let country = '';
            if(coun !== null){
              let counindex = this.state.countryList.findIndex(tagkey => tagkey.value === coun); 
              if(counindex !== -1){
                country = this.state.countryList[counindex].label;
              }
            }
              
            let uploaddate = s.uploadtime.split("T")[0];
            let latestcdate = uploaddate.split('-');
            let formatedCdate = latestcdate[1]+'-'+latestcdate[2]+'-'+latestcdate[0];
            if(this.state.docTypeDropSelected.value === 1){
              filelists.push(
                <Row key={index}>
                  {role !==3 &&
                  <Cell>
                  <input checked={s.checked === true?true:false} type='checkbox' onChange={(e)=>this.checkQueue(e,index)} name='addtoQueue' id={s.id+'_addtoQueue'} />  
                  </Cell>
                  }
                <Cell>
                <button onClick={(e) => this.viewImage(e,s.filename)} className='btn btn-primary btn-sm mr-1'>View File</button>
                <button onClick={(e) => this.removeFile(e,s.id,index)} className='btn btn-danger btn-sm mr-1'><i className="fas fa-trash"></i> Remove</button>
                {this.state.isDuplicate &&
                    <button  title='Un-Duplicate' onClick={(e) => this.unDuplicate(e,s.groupId,s.id,index)}  type="button" className="btn btn-primary btn-sm "><i className="fas fa-plus"></i> Un-Duplicate</button>
                  }
                  {!this.state.isDuplicate &&
                    <button  title='Add to queue' onClick={(e) => this.addtoqueue(e,s.groupId,s.id,index)}  type="button" className="btn btn-primary btn-sm "><i className="fas fa-plus"></i> Add to Queue</button>
                  }
                    {/* <button  title='Add to queue' onClick={(e) => this.addtoqueue(e,s.groupId,s.id,index)}  type="button" className="btn btn-primary btn-sm"><i className="fas fa-plus"></i> Add to Queue</button> */}
                </Cell>
                <Cell>{s.filename}</Cell>
                {/* <Cell>{s.groupname}</Cell> */}
                {/* <Cell>{country}</Cell>
                <Cell>{language}</Cell> */}
                <Cell><div>{formatedCdate}</div><div>{s.created_by_name}</div></Cell>
                
              </Row>
                        
                 )
            }else{
              filelistsO.push(
                <Row  key={index}>
                  {role !==3 &&
                  <Cell>
                  <input checked={s.checked === true?true:false} type='checkbox' onChange={(e)=>this.checkQueue(e,index)} name='addtoQueue' id={s.id+'_addtoQueue'} />  
                  </Cell>
                  }
                  <Cell>
                  <button onClick={(e) => this.viewImage(e,s.filename)} className='btn btn-primary btn-sm mr-1'>View File</button>
                  <button onClick={(e) => this.removeFile(e,s.id,index)} className='btn btn-danger btn-sm mr-1'><i className="fas fa-trash"></i> Remove</button>
                  {this.state.isDuplicate &&
                    <button  title='Un-Duplicate' onClick={(e) => this.unDuplicate(e,s.groupId,s.id,index)}  type="button" className="btn btn-primary btn-sm "><i className="fas fa-plus"></i> Un-Duplicate</button>
                  }
                  {!this.state.isDuplicate &&
                    <button  title='Add to queue' onClick={(e) => this.addtoqueue(e,s.groupId,s.id,index)}  type="button" className="btn btn-primary btn-sm "><i className="fas fa-plus"></i> Add to Queue</button>
                  }
                  
                    </Cell>
                <Cell>{s.filename}</Cell>
                <Cell><div>{formatedCdate}</div><div>{s.created_by_name}</div></Cell>
               
              </Row>
                        
                 )
            }
            
            })
let groupListMeta = [];
this.state.metalist.forEach ((gl,index) => {
  let label = gl.label.replace("Search", "").replace("Select", "");
	groupListMeta.push(
		<div key={index} className=''> 
                 <label><b>{label} :</b> {gl.valueLabel}</label>
    </div>
	)
	
})

  return (
     <div className='h100percent'>
     <div className='main-header'>       
       <div className='row'>
         <div className='col-md-6'><h2 className="page-header">View Unassigned Files</h2></div>
         <div className='col-md-6 text-right'> 
              </div>
        
       </div>
       </div>
       <div className='main-panel'>
        <div className='content '>
        <ToastContainer />
         <div className='container-fluid h100percent'>
 <div className='h100percent text-selection-none bg-white  round-corner-all'>
     <div className='row'>
     <div className='col-md-3' >
        <div className="form-group ">
                 <div >
                 <label>Document Type</label>
         <Select    
         className ='orgindex'            
        value={this.state.docTypeDropSelected}
        onChange={this.updatedocTypeDrop}
        options={this.state.docTypeDrop}
        defaultValue={{ value: '', label: '----' }}
      />
                 </div>
                 </div> 
               </div>
               {this.state.docTypeDropSelected.value === 1 &&    
     <div className='col-md-3'>
        <div className="form-group ">
        <label>Select  Group</label>
        <Select 
        className='orgindex'        
        value={this.state.selectedgroup}
        onChange={this.updateValueGroup}
        options={this.state.groupList}
        defaultValue={{ value: '', label: '----' }}
      />
        
          </div>
        
        </div>
         }
         {this.state.docTypeDropSelected.value === 1 && groupListMeta.length > 0 &&  
        <div className='col-md-4'>
           <div className="form-group ">
        <label>Group Information</label>
        <div className={this.state.ginfo ?'viewgInfo-parent open':'viewgInfo-parent'}>
          <div className='oc'>
            <span onBlur={ this.collapse } onClick={this.openginfo} tabIndex="0" className='tools openinfo'><i className="fas fa-info-circle"></i></span>
            
          </div>
        <div className='bg-light viewgInfo'>{groupListMeta}</div>
        </div>
        
          
        </div>     
        </div> 
    
    }
    {this.state.docTypeDropSelected.value !=='' && this.state.duplicateFileCount > 0 &&
     <div className='col-md-2 '>
        <div className="form-group ">
            <label className='vhidden'>Filter By  </label>
            <div className="form-check mt-2">
  <input checked={this.state.isDuplicate === true?true:false} className="form-check-input" type="checkbox" value="" onChange={(e)=>this.switchDuplicateView()} name='isDuplicate' id="isDuplicate" />
  <label className="form-check-label" htmlFor="isDuplicate">
    
    <span >View Duplicate File <span className='text-danger'>({this.state.duplicateFileCount})</span></span>
  
  
  </label>
</div>
                {/* <select  className="form-control">
                    <option value=''>------</option>
                    <option value=''>Manufacturer Name (ASC)</option>
                    <option value=''>Manufacturer Name (DESC)</option>
                    <option value=''>Group Name (ASC)</option>
                    <option value=''>Group Name (DESC)</option>
                    <option value=''>Upload Date (ASC)</option>
                    <option value=''>Upload Date (DESC)</option>
                </select>
              */}
              </div>
        </div>
        }
      {ifChecked && 
         <div className='col-md-1 pl-0'>
        <div className="form-group text-right">
        <label className='v-hidden'>. </label>
        <div className="input-group">
               <button onClick={(e)=>this.assignOthers(e)} className='btn btn-primary'>Assign</button>
            </div>
            </div>
        
        </div>
         }
     </div>
        <div className='row  manudetailheight'>
        <div className="col-md-12  h-100">
        <div style={{width: '100%', height: '100%'}}>
        {this.state.docTypeDropSelected.value === 1 &&  filelists.length > 0 &&
          <StickyTable leftStickyColumnCount={0} >
            <Row>
            {role !==3  &&
                  <Cell>
                  <input checked={this.state.allcheck === true?true:false} onChange={(e)=>this.checkQueueAll(e)} type='checkbox' name='addtoQueueAll' id={'addtoQueueAll'} />  
                  </Cell>
                  }
            <Cell></Cell>
              <Cell>File Name</Cell>
              {/* <Cell>Group Name</Cell> */}
              {/* <Cell>Country</Cell>
              <Cell>Language</Cell> */}
              <Cell>Uploaded</Cell>
              
            </Row>
            {filelists}
            
          </StickyTable>
     }
     {this.state.docTypeDropSelected.value && this.state.docTypeDropSelected.value !== 1 &&   filelistsO.length > 0 &&
          <StickyTable leftStickyColumnCount={0} >
            <Row>
            {role !==3 && 
                              <Cell>
                  <input checked={this.state.allcheck === true?true:false} onChange={(e)=>this.checkQueueAll(e)} type='checkbox' name='addtoQueueAll' id={'addtoQueueAll'} />  
                  </Cell>
                  }
            <Cell></Cell>
              <Cell>File Name</Cell>
              <Cell>Uploaded</Cell>
              
            </Row>
            {filelistsO}
            
          </StickyTable>
     }
        </div>
   
</div>
        </div>
      </div>
    </div>
    
    </div>
    </div>
    {this.state.imageView && 
    <ViewFile 
    file={this.state.selectedFilename} 
    category={this.state.docTypeDropSelected.value} 
    close={this.closefile} 
    />  
      }
      
   </div>
  )};
};

export default UploadedfileList;