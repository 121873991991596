import React, { Component } from 'react';
import {Form, Button,  FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
class Addtogroup extends Component { 
  constructor(props) {
    super(props);

  }
  componentDidMount(){
   
  }
    goBack() {
        window.history.back();
      }
    render(){
        return(
            <div className="modal fade show d-block"     >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header px-4 d-block">
            
              
               <div className="row">
              	<div className="col-md-10">
              		 <h4 className="modal-title" ><a onClick={this.goBack}   className="back-button cpointer" >
                <i className="fas fa-arrow-circle-left"></i></a> Add to Group</h4>
              	</div>
              	<div className="col-md-2 text-right">
              	</div>
              </div>
            
            </div>
            <div className="modal-body p-4">
            <div className='row'>
         <div className='col-md-2'>
         <div className="custom-control addtogroup-sec custom-radio ">
    <input defaultChecked type="radio" className="custom-control-input" id="groupname" name="process-file" />
    <label className="custom-control-label" htmlFor="groupname">Group Name</label>
  </div>
  </div>
  <div className='col-md-2'>
  <div className="custom-control addtogroup-sec  custom-radio ">
    <input type="radio" className="custom-control-input" id="groupname1" name="process-file" />
    <label className="custom-control-label" htmlFor="groupname1">Group Name</label>
  </div>
         </div>
         <div className='col-md-2'>
  <div className="custom-control addtogroup-sec  custom-radio ">
    <input type="radio" className="custom-control-input" id="groupname2" name="process-file" />
    <label className="custom-control-label" htmlFor="groupname2">Group Name</label>
  </div>
         </div>
         <div className='col-md-2'>
  <div className="custom-control addtogroup-sec  custom-radio">
    <input type="radio" className="custom-control-input" id="groupname3" name="process-file" />
    <label className="custom-control-label" htmlFor="groupname3">Group Name</label>
  </div>
         </div>
         <div className='col-md-2'>
  <div className="custom-control addtogroup-sec  custom-radio ">
    <input type="radio" className="custom-control-input" id="groupname4" name="process-file" />
    <label className="custom-control-label" htmlFor="groupname4">Group Name</label>
  </div>
         </div>
         <div className='col-md-2'>
  <div className="custom-control addtogroup-sec  custom-radio ">
    <input type="radio" className="custom-control-input" id="groupname5" name="process-file" />
    <label className="custom-control-label" htmlFor="groupname5">Group Name</label>
  </div>
         </div>
         <div className='col-md-2'>
  <div className="custom-control addtogroup-sec  custom-radio ">
    <input type="radio" className="custom-control-input" id="groupname6" name="process-file" />
    <label className="custom-control-label" htmlFor="groupname6">Group Name</label>
  </div>
         </div>
         <div className='col-md-2'>
  <div className="custom-control addtogroup-sec  custom-radio ">
    <input type="radio" className="custom-control-input" id="groupname7" name="process-file" />
    <label className="custom-control-label" htmlFor="groupname7">Group Name</label>
  </div>
         </div>
     
    
     </div>
      
             
              
           
</div>
<div className="modal-footer">
<button type="submit" className="btn btn-primary">Save</button> 
</div>
                          
       
   
          </div>
        
        </div>
      </div>
        )
    }
}
export default Addtogroup